import { MeQuery } from '@/api/queries/User'
import { SUBSCRIBED_TO_NOTIFICATIONS_STATUS, USER_PRIVILEGE_LEVEL, type Me, type Me_me } from '@/generatedTypes'
import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { createContext, useContext, useEffect, useMemo, type FC, type PropsWithChildren } from 'react'

export let language: 'sv' | 'en' = 'sv'

const MeContext = createContext<Me_me | null>(null)

const MeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth0()
  const { data } = useQuery<Me>(MeQuery, { skip: !isAuthenticated })
  const me = data?.me

  useEffect(() => {
    language = (me?.language as 'sv' | 'en') || 'sv'
  }, [me?.language])

  const initialMe = useMemo<Me_me>(
    () => ({
      __typename: 'User',
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      language: 'sv',
      profileImage: null,
      jobTitle: '',
      company: {
        __typename: 'Company',
        id: '',
        name: '',
        apiKey: '',
        language: 'en',
        type: null,
        profileImage: null,
        childCompanies: [],
        parentCompany: {
          __typename: 'Company',
          id: '',
          name: '',
        },
        enabledFeatures: {
          __typename: 'EnabledFeatures',
          id: '',
          assignments: false,
          calendar: false,
          consultantProjects: false,
          contracts: false,
          screeningForms: false,
          referenceForms: false,
          statusDeadlines: false,
          trustcruit: false,
          interviewBooking: false,
          applicationsRankedWithAI: false,
          alvaLabs: false,
          tengai: false,
          interviewBookingGroups: false,
          allApplicationsPage: false,
          videoInApplications: false,
          aiGeneration: false,
          mediaLibrary: false,
          careerPage: false,
          jobtip: false,
          evaluationForm: false,
        },
      },
      privilegeLevel: USER_PRIVILEGE_LEVEL.USER,
      subscribedMailNotificationTypes: [],
      subscribedToNotificationsStatus: SUBSCRIBED_TO_NOTIFICATIONS_STATUS.NONE,
    }),
    [],
  )

  return <MeContext.Provider value={me || initialMe}>{children}</MeContext.Provider>
}

const useMe = () => {
  const context = useContext(MeContext)

  if (!context) {
    throw new Error('useMe must be used within a MeProvider')
  }

  return context
}

export { MeProvider, useMe }
