/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAd
// ====================================================

export interface UpdateAd_updateAd_coverImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateAd_updateAd_blocks_block_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateAd_updateAd_blocks_block_coverImage_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateAd_updateAd_blocks_block_coverImage {
  __typename: "CoverImage";
  id: string;
  fullScreen: boolean | null;
  variant: COVER_IMAGE_VARIANT | null;
  fade: boolean | null;
  height: number | null;
  file: UpdateAd_updateAd_blocks_block_coverImage_file | null;
}

export interface UpdateAd_updateAd_blocks_block_contactPerson_profileImage {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
}

export interface UpdateAd_updateAd_blocks_block_contactPerson {
  __typename: "AdContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  copyFromRecruitment: boolean | null;
  profileImage: UpdateAd_updateAd_blocks_block_contactPerson_profileImage | null;
}

export interface UpdateAd_updateAd_blocks_block_video {
  __typename: "Video";
  id: string;
  url: string | null;
  youtubeId: string | null;
  autoPlay: boolean | null;
  loop: boolean | null;
}

export interface UpdateAd_updateAd_blocks_block {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
  title: string | null;
  text: string | null;
  url: string | null;
  copyShortPresentationFromCompany: boolean | null;
  file: UpdateAd_updateAd_blocks_block_file | null;
  adMetaInfo: string[] | null;
  shareButtons: SOCIAL_MEDIA_SOURCE[] | null;
  coverImage: UpdateAd_updateAd_blocks_block_coverImage | null;
  contactPerson: UpdateAd_updateAd_blocks_block_contactPerson | null;
  video: UpdateAd_updateAd_blocks_block_video | null;
}

export interface UpdateAd_updateAd_blocks {
  __typename: "ConnectedContentBlock";
  id: string;
  listIndex: number;
  block: UpdateAd_updateAd_blocks_block;
}

export interface UpdateAd_updateAd {
  __typename: "Ad";
  id: string;
  title: string | null;
  design: string | null;
  content: string[];
  titleFont: string | null;
  textFont: string | null;
  applyButtonText: string | null;
  applyButtonLink: string | null;
  useCustomOgImage: boolean | null;
  ogDescription: string | null;
  coverImage: UpdateAd_updateAd_coverImage | null;
  blocks: UpdateAd_updateAd_blocks[];
}

export interface UpdateAd {
  updateAd: UpdateAd_updateAd | null;
}

export interface UpdateAdVariables {
  id: string;
  title?: string | null;
  titleFont?: string | null;
  textFont?: string | null;
  applyButtonText?: string | null;
  applyButtonLink?: string | null;
  ogDescription?: string | null;
  useCustomOgImage?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAdDistribution
// ====================================================

export interface CreateAdDistribution_createAdDistribution {
  __typename: "AdDistribution";
  id: string;
}

export interface CreateAdDistribution {
  createAdDistribution: CreateAdDistribution_createAdDistribution | null;
}

export interface CreateAdDistributionVariables {
  recruitmentId: string;
  type: AD_DISTRIBUTION_TYPE;
  title?: string | null;
  text?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAdDistribution
// ====================================================

export interface DeleteAdDistribution_deleteAdDistribution {
  __typename: "AdDistribution";
  id: string;
}

export interface DeleteAdDistribution {
  deleteAdDistribution: DeleteAdDistribution_deleteAdDistribution | null;
}

export interface DeleteAdDistributionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DesyncAdDistributionOfRecruitment
// ====================================================

export interface DesyncAdDistributionOfRecruitment_desyncAdDistributionOfRecruitment {
  __typename: "AdDistribution";
  id: string;
}

export interface DesyncAdDistributionOfRecruitment {
  desyncAdDistributionOfRecruitment: DesyncAdDistributionOfRecruitment_desyncAdDistributionOfRecruitment | null;
}

export interface DesyncAdDistributionOfRecruitmentVariables {
  recruitmentId: string;
  type: AD_DISTRIBUTION_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncAdDistributionOfRecruitment
// ====================================================

export interface SyncAdDistributionOfRecruitment_syncAdDistributionOfRecruitment {
  __typename: "AdDistribution";
  id: string;
}

export interface SyncAdDistributionOfRecruitment {
  syncAdDistributionOfRecruitment: SyncAdDistributionOfRecruitment_syncAdDistributionOfRecruitment | null;
}

export interface SyncAdDistributionOfRecruitmentVariables {
  recruitmentId: string;
  type: AD_DISTRIBUTION_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAdDistribution
// ====================================================

export interface UpdateAdDistribution_updateAdDistribution {
  __typename: "AdDistribution";
  id: string;
  type: AD_DISTRIBUTION_TYPE;
  title: string | null;
  text: string | null;
  isPublished: boolean | null;
  isNotSynced: boolean | null;
}

export interface UpdateAdDistribution {
  updateAdDistribution: UpdateAdDistribution_updateAdDistribution | null;
}

export interface UpdateAdDistributionVariables {
  id: string;
  data: AdDistributionUpdateInput;
  removeUpload: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddUserToRecruitment
// ====================================================

export interface AddUserToRecruitment_addUserToRecruitment {
  __typename: "User";
  id: string;
}

export interface AddUserToRecruitment {
  addUserToRecruitment: AddUserToRecruitment_addUserToRecruitment | null;
}

export interface AddUserToRecruitmentVariables {
  userId: string;
  recruitmentId: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCompanyType
// ====================================================

export interface SetCompanyType_setCompanyType {
  __typename: "Company";
  id: string;
  type: COMPANY_TYPE | null;
  paymentType: COMPANY_PAYMENT_TYPE | null;
}

export interface SetCompanyType {
  setCompanyType: SetCompanyType_setCompanyType | null;
}

export interface SetCompanyTypeVariables {
  id: string;
  type?: COMPANY_TYPE | null;
  paymentType?: COMPANY_PAYMENT_TYPE | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCompanyAgreementExpirationDate
// ====================================================

export interface SetCompanyAgreementExpirationDate_setCompanyAgreementExpirationDate {
  __typename: "Company";
  id: string;
  agreementExpirationDate: DateTime | null;
}

export interface SetCompanyAgreementExpirationDate {
  setCompanyAgreementExpirationDate: SetCompanyAgreementExpirationDate_setCompanyAgreementExpirationDate | null;
}

export interface SetCompanyAgreementExpirationDateVariables {
  id: string;
  agreementExpirationDate: DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkRecruitmentAsInvoiced
// ====================================================

export interface MarkRecruitmentAsInvoiced_markRecruitmentAsInvoiced {
  __typename: "Recruitment";
  id: string;
  adminHasInvoiced: boolean | null;
}

export interface MarkRecruitmentAsInvoiced {
  markRecruitmentAsInvoiced: MarkRecruitmentAsInvoiced_markRecruitmentAsInvoiced | null;
}

export interface MarkRecruitmentAsInvoicedVariables {
  id: string;
  invoiced: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAlvaLabsJob
// ====================================================

export interface CreateAlvaLabsJob_createAlvaLabsJob {
  __typename: "AlvaLabsJob";
  id: string | null;
}

export interface CreateAlvaLabsJob {
  createAlvaLabsJob: CreateAlvaLabsJob_createAlvaLabsJob | null;
}

export interface CreateAlvaLabsJobVariables {
  recruitmentId: string;
  testProfileId?: string | null;
  requireLogicTest: boolean;
  requirePersonalityTest: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetAlvaLabsTestProfile
// ====================================================

export interface SetAlvaLabsTestProfile_setAlvaLabsTestProfile {
  __typename: "AlvaLabsTestProfile";
  id: string;
}

export interface SetAlvaLabsTestProfile {
  setAlvaLabsTestProfile: SetAlvaLabsTestProfile_setAlvaLabsTestProfile | null;
}

export interface SetAlvaLabsTestProfileVariables {
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
  companyId?: string | null;
  testProfileId: string;
  requireLogicTest: boolean;
  requirePersonalityTest: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditApplicantAdmin
// ====================================================

export interface EditApplicantAdmin_editApplicantAdmin {
  __typename: "Applicant";
  id: string;
  email: string;
}

export interface EditApplicantAdmin {
  editApplicantAdmin: EditApplicantAdmin_editApplicantAdmin | null;
}

export interface EditApplicantAdminVariables {
  id: string;
  email?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteApplicantGrade
// ====================================================

export interface DeleteApplicantGrade_deleteApplicantGrade {
  __typename: "ApplicantGrade";
  id: string;
}

export interface DeleteApplicantGrade {
  deleteApplicantGrade: DeleteApplicantGrade_deleteApplicantGrade | null;
}

export interface DeleteApplicantGradeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendMessage
// ====================================================

export interface SendMessage_sendMessage {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  createdAt: DateTime;
  sentByApplicant: boolean;
  sentVia: MESSAGE_CHANNEL[];
}

export interface SendMessage {
  sendMessage: SendMessage_sendMessage[];
}

export interface SendMessageVariables {
  companyId: string;
  subject?: string | null;
  text: string;
  attachments?: Upload[] | null;
  applicationIds?: string[] | null;
  applicantIds?: string[] | null;
  companyIds?: string[] | null;
  sendSms?: boolean | null;
  sendEmail?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetConversationReadStatus
// ====================================================

export interface SetConversationReadStatus {
  setConversationReadStatus: boolean;
}

export interface SetConversationReadStatusVariables {
  applicationId?: string | null;
  applicantId?: string | null;
  read: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AnalyzeApplicationWithAI
// ====================================================

export interface AnalyzeApplicationWithAI_analyzeApplicationWithAI {
  __typename: "Application";
  id: string;
}

export interface AnalyzeApplicationWithAI {
  analyzeApplicationWithAI: AnalyzeApplicationWithAI_analyzeApplicationWithAI | null;
}

export interface AnalyzeApplicationWithAIVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeApplicationStatus
// ====================================================

export interface ChangeApplicationStatus_changeApplicationStatus_status {
  __typename: "ApplicationStatus";
  id: string;
}

export interface ChangeApplicationStatus_changeApplicationStatus {
  __typename: "Application";
  id: string;
  listIndex: number | null;
  status: ChangeApplicationStatus_changeApplicationStatus_status | null;
}

export interface ChangeApplicationStatus {
  changeApplicationStatus: ChangeApplicationStatus_changeApplicationStatus | null;
}

export interface ChangeApplicationStatusVariables {
  id: string;
  statusId: string;
  newListIndex: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeApplicationStatuses
// ====================================================

export interface ChangeApplicationStatuses_changeApplicationStatuses_status {
  __typename: "ApplicationStatus";
  id: string;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
  title: string | null;
  color: string | null;
}

export interface ChangeApplicationStatuses_changeApplicationStatuses {
  __typename: "Application";
  id: string;
  status: ChangeApplicationStatuses_changeApplicationStatuses_status | null;
}

export interface ChangeApplicationStatuses {
  changeApplicationStatuses: ChangeApplicationStatuses_changeApplicationStatuses[];
}

export interface ChangeApplicationStatusesVariables {
  companyId: string;
  ids: string[];
  statusId: string;
  recruitmentId: string;
  messageTemplateId?: string | null;
  attachments?: Upload[] | null;
  eventTriggerIds?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ComputeRankingsOfApplications
// ====================================================

export interface ComputeRankingsOfApplications_computeRankingsOfApplications {
  __typename: "Application";
  id: string;
  computedMatchRanking: number | null;
  computedMatchScore: number;
}

export interface ComputeRankingsOfApplications {
  computeRankingsOfApplications: ComputeRankingsOfApplications_computeRankingsOfApplications[];
}

export interface ComputeRankingsOfApplicationsVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateApplicationFromApplicant
// ====================================================

export interface CreateApplicationFromApplicant_createApplicationFromApplicant {
  __typename: "Application";
  id: string;
}

export interface CreateApplicationFromApplicant {
  createApplicationFromApplicant: CreateApplicationFromApplicant_createApplicationFromApplicant | null;
}

export interface CreateApplicationFromApplicantVariables {
  applicantId: string;
  recruitmentId: string;
  includedDocumentTypes: DOCUMENT_TYPE[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteApplication
// ====================================================

export interface DeleteApplication_deleteApplication {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface DeleteApplication {
  deleteApplication: DeleteApplication_deleteApplication | null;
}

export interface DeleteApplicationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GradeApplication
// ====================================================

export interface GradeApplication_gradeApplication_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GradeApplication_gradeApplication_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  setBy: GradeApplication_gradeApplication_grades_setBy | null;
}

export interface GradeApplication_gradeApplication {
  __typename: "Application";
  id: string;
  averageGrade: number;
  grades: GradeApplication_gradeApplication_grades[];
}

export interface GradeApplication {
  gradeApplication: GradeApplication_gradeApplication | null;
}

export interface GradeApplicationVariables {
  id: string;
  grade: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HireApplicantFromApplication
// ====================================================

export interface HireApplicantFromApplication_hireApplicantFromApplication {
  __typename: "Application";
  id: string;
}

export interface HireApplicantFromApplication {
  hireApplicantFromApplication: HireApplicantFromApplication_hireApplicantFromApplication | null;
}

export interface HireApplicantFromApplicationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateApplicationNote
// ====================================================

export interface UpdateApplicationNote_updateApplicationNote {
  __typename: "Application";
  id: string;
  note: string | null;
}

export interface UpdateApplicationNote {
  updateApplicationNote: UpdateApplicationNote_updateApplicationNote | null;
}

export interface UpdateApplicationNoteVariables {
  id: string;
  note: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateApplicationSubmission
// ====================================================

export interface UpdateApplicationSubmission_updateApplicationSubmission_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  listIndex: number | null;
  createdAt: DateTime;
  type: APPLICATION_STATUS_TYPE;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: UpdateApplicationSubmission_updateApplicationSubmission_grades_visitor | null;
  setBy: UpdateApplicationSubmission_updateApplicationSubmission_grades_setBy | null;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  company: UpdateApplicationSubmission_updateApplicationSubmission_recruitment_company;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_companyApplicant_applications {
  __typename: "Application";
  id: string;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
  email: string;
  applications: UpdateApplicationSubmission_updateApplicationSubmission_companyApplicant_applications[];
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_interviewBookingGroups_application {
  __typename: "Application";
  id: string;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_interviewBookingGroups {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  application: UpdateApplicationSubmission_updateApplicationSubmission_interviewBookingGroups_application;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_video {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_competences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: UpdateApplicationSubmission_updateApplicationSubmission_competences_competence;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: UpdateApplicationSubmission_updateApplicationSubmission_documents_file;
  type: DOCUMENT_TYPE;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission_workExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface UpdateApplicationSubmission_updateApplicationSubmission {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  approvedForInterview: boolean;
  offeredEmployment: boolean | null;
  latestMessageTime: DateTime | null;
  messagesRead: boolean | null;
  anonymizedWords: (string | null)[];
  isAnonymized: boolean;
  status: UpdateApplicationSubmission_updateApplicationSubmission_status | null;
  averageGrade: number;
  grades: UpdateApplicationSubmission_updateApplicationSubmission_grades[];
  recruitment: UpdateApplicationSubmission_updateApplicationSubmission_recruitment;
  companyApplicant: UpdateApplicationSubmission_updateApplicationSubmission_companyApplicant | null;
  interviewBookingGroups: UpdateApplicationSubmission_updateApplicationSubmission_interviewBookingGroups[];
  video: UpdateApplicationSubmission_updateApplicationSubmission_video | null;
  competences: UpdateApplicationSubmission_updateApplicationSubmission_competences[];
  documents: UpdateApplicationSubmission_updateApplicationSubmission_documents[];
  freeText: string | null;
  educations: UpdateApplicationSubmission_updateApplicationSubmission_educations[];
  workExperiences: UpdateApplicationSubmission_updateApplicationSubmission_workExperiences[];
}

export interface UpdateApplicationSubmission {
  updateApplicationSubmission: UpdateApplicationSubmission_updateApplicationSubmission | null;
}

export interface UpdateApplicationSubmissionVariables {
  id: string;
  personalData?: PersonalDataInput | null;
  answers: EditedFormQuestionAnswerInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateApplicationStatus
// ====================================================

export interface CreateApplicationStatus_createApplicationStatus {
  __typename: "ApplicationStatus";
  id: string;
  type: APPLICATION_STATUS_TYPE;
  title: string | null;
  listIndex: number | null;
  color: string | null;
}

export interface CreateApplicationStatus {
  createApplicationStatus: CreateApplicationStatus_createApplicationStatus | null;
}

export interface CreateApplicationStatusVariables {
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
  title: string;
  description?: string | null;
  color?: string | null;
  messageTemplateInput?: SelectOrCreateMessageTemplateInput | null;
  eventTriggerIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteApplicationStatus
// ====================================================

export interface DeleteApplicationStatus_deleteApplicationStatus {
  __typename: "ApplicationStatus";
  id: string;
  type: APPLICATION_STATUS_TYPE;
  title: string | null;
  color: string | null;
}

export interface DeleteApplicationStatus {
  deleteApplicationStatus: DeleteApplicationStatus_deleteApplicationStatus | null;
}

export interface DeleteApplicationStatusVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditApplicationStatus
// ====================================================

export interface EditApplicationStatus_editApplicationStatus_messageTemplate {
  __typename: "MessageTemplate";
  id: string;
}

export interface EditApplicationStatus_editApplicationStatus_eventTriggers {
  __typename: "EventTrigger";
  id: string;
}

export interface EditApplicationStatus_editApplicationStatus {
  __typename: "ApplicationStatus";
  id: string;
  title: string | null;
  description: string | null;
  color: string | null;
  messageTemplate: EditApplicationStatus_editApplicationStatus_messageTemplate | null;
  eventTriggers: EditApplicationStatus_editApplicationStatus_eventTriggers[] | null;
}

export interface EditApplicationStatus {
  editApplicationStatus: EditApplicationStatus_editApplicationStatus | null;
}

export interface EditApplicationStatusVariables {
  id: string;
  title?: string | null;
  description?: string | null;
  color?: string | null;
  messageTemplateInput?: SelectOrCreateMessageTemplateInput | null;
  eventTriggers?: EventTriggerInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderApplicationStatuses
// ====================================================

export interface ReorderApplicationStatuses_reorderApplicationStatuses {
  __typename: "ApplicationStatus";
  id: string;
  type: APPLICATION_STATUS_TYPE;
  listIndex: number | null;
  title: string | null;
  color: string | null;
}

export interface ReorderApplicationStatuses {
  reorderApplicationStatuses: ReorderApplicationStatuses_reorderApplicationStatuses[];
}

export interface ReorderApplicationStatusesVariables {
  ids: string[];
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignmentConnection
// ====================================================

export interface AssignmentConnection_assignmentConnection_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface AssignmentConnection_assignmentConnection_applicant {
  __typename: "CompanyApplicant";
  id: string;
}

export interface AssignmentConnection_assignmentConnection_application {
  __typename: "Application";
  id: string;
}

export interface AssignmentConnection_assignmentConnection {
  __typename: "Assignment";
  id: string;
  recruitment: AssignmentConnection_assignmentConnection_recruitment | null;
  applicant: AssignmentConnection_assignmentConnection_applicant | null;
  application: AssignmentConnection_assignmentConnection_application | null;
}

export interface AssignmentConnection {
  assignmentConnection: AssignmentConnection_assignmentConnection | null;
}

export interface AssignmentConnectionVariables {
  assignmentId: string;
  recruitmentId?: string | null;
  applicationId?: string | null;
  applicantId?: string | null;
  disconnectRecruitment?: boolean | null;
  disconnectApplication?: boolean | null;
  disconnectApplicant?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAssignment
// ====================================================

export interface CreateAssignment_createAssignment_column {
  __typename: "AssignmentColumn";
  id: string;
}

export interface CreateAssignment_createAssignment_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface CreateAssignment_createAssignment_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CreateAssignment_createAssignment_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CreateAssignment_createAssignment_users_profileImage | null;
}

export interface CreateAssignment_createAssignment {
  __typename: "Assignment";
  id: string;
  column: CreateAssignment_createAssignment_column | null;
  title: string;
  order: number;
  createdBy: CreateAssignment_createAssignment_createdBy | null;
  users: CreateAssignment_createAssignment_users[];
}

export interface CreateAssignment {
  createAssignment: CreateAssignment_createAssignment | null;
}

export interface CreateAssignmentVariables {
  companyId: string;
  columnId: string;
  order: number;
  title: string;
  users?: string[] | null;
  recruitment?: string | null;
  application?: string | null;
  applicant?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAssignmentColumn
// ====================================================

export interface CreateAssignmentColumn_createAssignmentColumn {
  __typename: "AssignmentColumn";
  id: string;
}

export interface CreateAssignmentColumn {
  createAssignmentColumn: CreateAssignmentColumn_createAssignmentColumn | null;
}

export interface CreateAssignmentColumnVariables {
  boardId: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAssignment
// ====================================================

export interface DeleteAssignment_deleteAssignment {
  __typename: "Assignment";
  id: string;
}

export interface DeleteAssignment {
  deleteAssignment: DeleteAssignment_deleteAssignment | null;
}

export interface DeleteAssignmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAssignmentColumn
// ====================================================

export interface DeleteAssignmentColumn_deleteAssignmentColumn {
  __typename: "AssignmentColumn";
  id: string;
}

export interface DeleteAssignmentColumn {
  deleteAssignmentColumn: DeleteAssignmentColumn_deleteAssignmentColumn | null;
}

export interface DeleteAssignmentColumnVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MoveAssignment
// ====================================================

export interface MoveAssignment {
  moveAssignment: boolean;
}

export interface MoveAssignmentVariables {
  id: string;
  boardId: string;
  sourceParentId: string;
  destinationParentId: string;
  destinationIndex: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderAssignmentColumns
// ====================================================

export interface ReorderAssignmentColumns_reorderAssignmentColumns {
  __typename: "AssignmentColumn";
  id: string;
}

export interface ReorderAssignmentColumns {
  reorderAssignmentColumns: ReorderAssignmentColumns_reorderAssignmentColumns[];
}

export interface ReorderAssignmentColumnsVariables {
  boardId: string;
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAssignment
// ====================================================

export interface UpdateAssignment_updateAssignment_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateAssignment_updateAssignment_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: UpdateAssignment_updateAssignment_users_profileImage | null;
}

export interface UpdateAssignment_updateAssignment {
  __typename: "Assignment";
  id: string;
  status: ASSIGNMENT_STATUS | null;
  title: string;
  description: string | null;
  users: UpdateAssignment_updateAssignment_users[];
}

export interface UpdateAssignment {
  updateAssignment: UpdateAssignment_updateAssignment | null;
}

export interface UpdateAssignmentVariables {
  id: string;
  title?: string | null;
  description?: string | null;
  addedUser?: string | null;
  deletedUser?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAssignmentColumnTitle
// ====================================================

export interface UpdateAssignmentColumnTitle_updateAssignmentColumnTitle {
  __typename: "AssignmentColumn";
  id: string;
}

export interface UpdateAssignmentColumnTitle {
  updateAssignmentColumnTitle: UpdateAssignmentColumnTitle_updateAssignmentColumnTitle | null;
}

export interface UpdateAssignmentColumnTitleVariables {
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAssignmentBoard
// ====================================================

export interface CreateAssignmentBoard_createAssignmentBoard_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface CreateAssignmentBoard_createAssignmentBoard_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  title: string;
}

export interface CreateAssignmentBoard_createAssignmentBoard {
  __typename: "AssignmentBoard";
  id: string;
  title: string | null;
  createdAt: DateTime;
  recruitment: CreateAssignmentBoard_createAssignmentBoard_recruitment | null;
  consultantProject: CreateAssignmentBoard_createAssignmentBoard_consultantProject | null;
}

export interface CreateAssignmentBoard {
  createAssignmentBoard: CreateAssignmentBoard_createAssignmentBoard | null;
}

export interface CreateAssignmentBoardVariables {
  companyId: string;
  title: string;
  users?: string[] | null;
  recruitmentId?: string | null;
  consultantProjectId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAssignmentBoard
// ====================================================

export interface UpdateAssignmentBoard_updateAssignmentBoard_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateAssignmentBoard_updateAssignmentBoard_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: UpdateAssignmentBoard_updateAssignmentBoard_users_profileImage | null;
}

export interface UpdateAssignmentBoard_updateAssignmentBoard {
  __typename: "AssignmentBoard";
  id: string;
  title: string | null;
  active: boolean;
  users: UpdateAssignmentBoard_updateAssignmentBoard_users[];
}

export interface UpdateAssignmentBoard {
  updateAssignmentBoard: UpdateAssignmentBoard_updateAssignmentBoard | null;
}

export interface UpdateAssignmentBoardVariables {
  id: string;
  active?: boolean | null;
  title?: string | null;
  addedUsers?: string[] | null;
  deletedUsers?: string[] | null;
  recruitmentId?: string | null;
  consultantProjectId?: string | null;
  disconnectRecruitment?: boolean | null;
  disconnectConsultantProject?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAssignmentBoard
// ====================================================

export interface DeleteAssignmentBoard_deleteAssignmentBoard {
  __typename: "AssignmentBoard";
  id: string;
}

export interface DeleteAssignmentBoard {
  deleteAssignmentBoard: DeleteAssignmentBoard_deleteAssignmentBoard | null;
}

export interface DeleteAssignmentBoardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Signup
// ====================================================

export interface Signup_signup {
  __typename: "User";
  id: string;
}

export interface Signup {
  signup: Signup_signup | null;
}

export interface SignupVariables {
  invitationId: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBillingInformation
// ====================================================

export interface UpdateBillingInformation_updateBillingInformation {
  __typename: "BillingInformation";
  id: string;
}

export interface UpdateBillingInformation {
  updateBillingInformation: UpdateBillingInformation_updateBillingInformation | null;
}

export interface UpdateBillingInformationVariables {
  id: string;
  data?: BillingInformationUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddMatchingEventParticipantInterviewBooking
// ====================================================

export interface AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_attendees_application_recruitment;
}

export interface AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_attendees_user_profileImage | null;
}

export interface AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  application: AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_attendees_application | null;
  user: AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  childCompanyHasSelectedBookableInterview: boolean;
  recruitment: AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_recruitment | null;
  attendees: AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_attendees[];
  interviewBookingGroup: AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_interviewBookingGroup | null;
  matchingEventTalentProfiles: AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface AddMatchingEventParticipantInterviewBooking {
  addMatchingEventParticipantInterviewBooking: (AddMatchingEventParticipantInterviewBooking_addMatchingEventParticipantInterviewBooking | null)[] | null;
}

export interface AddMatchingEventParticipantInterviewBookingVariables {
  companyId: string;
  calendarEventIds: string[];
  talentProfileIds: string[];
  participantId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCalendarEvent
// ====================================================

export interface CreateCalendarEvent_createCalendarEvent {
  __typename: "CalendarEvent";
  id: string;
}

export interface CreateCalendarEvent {
  createCalendarEvent: CreateCalendarEvent_createCalendarEvent | null;
}

export interface CreateCalendarEventVariables {
  companyId: string;
  startTime: DateTime;
  endTime: DateTime;
  title?: string | null;
  description?: string | null;
  location?: string | null;
  url?: string | null;
  userIds: string[];
  recruitmentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInterviewBooking
// ====================================================

export interface CreateInterviewBooking_createInterviewBooking {
  __typename: "CalendarEvent";
  id: string;
  startTime: DateTime;
  endTime: DateTime;
  title: string | null;
  location: string | null;
  description: string | null;
  url: string | null;
}

export interface CreateInterviewBooking {
  createInterviewBooking: CreateInterviewBooking_createInterviewBooking | null;
}

export interface CreateInterviewBookingVariables {
  companyId: string;
  startTime: DateTime;
  endTime: DateTime;
  title?: string | null;
  description?: string | null;
  location?: string | null;
  url?: string | null;
  userIds: string[];
  applicationId: string;
  sendInquiry: boolean;
  messageFromRecruiter?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMatchingEventCompanyBookableInterviews
// ====================================================

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_eventMatch_talentProfile;
}

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_application_recruitment;
}

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_user_profileImage | null;
}

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_eventMatch | null;
  application: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_application | null;
  user: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_recruitment | null;
  company: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_company;
  attendees: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_attendees[];
  interviewBookingGroup: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface CreateMatchingEventCompanyBookableInterviews {
  createMatchingEventCompanyBookableInterviews: CreateMatchingEventCompanyBookableInterviews_createMatchingEventCompanyBookableInterviews[];
}

export interface CreateMatchingEventCompanyBookableInterviewsVariables {
  companyId: string;
  matchingEventId: string;
  title?: string | null;
  description?: string | null;
  url?: string | null;
  location?: string | null;
  times: CalendarEventTimeInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCalendarEvent
// ====================================================

export interface DeleteCalendarEvent_deleteCalendarEvent {
  __typename: "CalendarEvent";
  id: string;
}

export interface DeleteCalendarEvent {
  deleteCalendarEvent: DeleteCalendarEvent_deleteCalendarEvent | null;
}

export interface DeleteCalendarEventVariables {
  id: string;
  messageFromRecruiter?: string | null;
  notifyApplicant?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCalendarEvent
// ====================================================

export interface UpdateCalendarEvent_updateCalendarEvent {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  startTime: DateTime;
  endTime: DateTime;
  type: CALENDAR_EVENT_TYPE;
}

export interface UpdateCalendarEvent {
  updateCalendarEvent: UpdateCalendarEvent_updateCalendarEvent | null;
}

export interface UpdateCalendarEventVariables {
  id: string;
  startTime?: DateTime | null;
  endTime?: DateTime | null;
  title?: string | null;
  description?: string | null;
  location?: string | null;
  url?: string | null;
  userIds?: string[] | null;
  recruitmentId?: string | null;
  disconnectRecruitment?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInterviewBooking
// ====================================================

export interface UpdateInterviewBooking_updateInterviewBooking {
  __typename: "CalendarEvent";
  id: string;
}

export interface UpdateInterviewBooking {
  updateInterviewBooking: UpdateInterviewBooking_updateInterviewBooking | null;
}

export interface UpdateInterviewBookingVariables {
  id: string;
  startTime?: DateTime | null;
  endTime?: DateTime | null;
  title?: string | null;
  description?: string | null;
  url?: string | null;
  location?: string | null;
  userIds?: string[] | null;
  notificationMethod?: string | null;
  messageFromRecruiter?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteComment
// ====================================================

export interface DeleteComment_deleteComment {
  __typename: "Comment";
  id: string;
}

export interface DeleteComment {
  deleteComment: DeleteComment_deleteComment | null;
}

export interface DeleteCommentVariables {
  commentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LikeComment
// ====================================================

export interface LikeComment_likeComment_likes {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface LikeComment_likeComment_application {
  __typename: "Application";
  id: string;
}

export interface LikeComment_likeComment_mentions {
  __typename: "User";
  id: string;
}

export interface LikeComment_likeComment_sentBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface LikeComment_likeComment_sentBy {
  __typename: "User";
  id: string;
  profileImage: LikeComment_likeComment_sentBy_profileImage | null;
}

export interface LikeComment_likeComment {
  __typename: "Comment";
  id: string;
  text: string;
  createdAt: DateTime;
  likes: LikeComment_likeComment_likes[];
  application: LikeComment_likeComment_application | null;
  mentions: LikeComment_likeComment_mentions[];
  sentBy: LikeComment_likeComment_sentBy | null;
}

export interface LikeComment {
  likeComment: LikeComment_likeComment | null;
}

export interface LikeCommentVariables {
  commentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PostComment
// ====================================================

export interface PostComment_postComment_application {
  __typename: "Application";
  id: string;
}

export interface PostComment_postComment_mentions {
  __typename: "User";
  id: string;
}

export interface PostComment_postComment_sentBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PostComment_postComment_sentBy {
  __typename: "User";
  id: string;
  profileImage: PostComment_postComment_sentBy_profileImage | null;
}

export interface PostComment_postComment_likes {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface PostComment_postComment {
  __typename: "Comment";
  id: string;
  text: string;
  createdAt: DateTime;
  application: PostComment_postComment_application | null;
  mentions: PostComment_postComment_mentions[];
  sentBy: PostComment_postComment_sentBy | null;
  likes: PostComment_postComment_likes[];
}

export interface PostComment {
  postComment: PostComment_postComment | null;
}

export interface PostCommentVariables {
  companyId: string;
  text: string;
  applicationId?: string | null;
  recruitmentId?: string | null;
  applicantId?: string | null;
  assignmentId?: string | null;
  identifiedNeedId?: string | null;
  consultantProjectId?: string | null;
  consultantId?: string | null;
  consultantCustomerId?: string | null;
  commentId?: string | null;
  mentions?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompanySlug
// ====================================================

export interface UpdateCompanySlug_updateCompanySlug {
  __typename: "Company";
  id: string;
  slug: string | null;
}

export interface UpdateCompanySlug {
  updateCompanySlug: UpdateCompanySlug_updateCompanySlug | null;
}

export interface UpdateCompanySlugVariables {
  id: string;
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompanyLogo
// ====================================================

export interface UpdateCompanyLogo_updateCompanyLogo {
  __typename: "Media";
  id: string;
}

export interface UpdateCompanyLogo {
  updateCompanyLogo: UpdateCompanyLogo_updateCompanyLogo | null;
}

export interface UpdateCompanyLogoVariables {
  companyId: string;
  mediaElementId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateChildCompany
// ====================================================

export interface CreateChildCompany_createChildCompany_users {
  __typename: "User";
  id: string;
}

export interface CreateChildCompany_createChildCompany {
  __typename: "Company";
  id: string;
  name: string;
  users: CreateChildCompany_createChildCompany_users[];
}

export interface CreateChildCompany {
  createChildCompany: CreateChildCompany_createChildCompany | null;
}

export interface CreateChildCompanyVariables {
  companyId: string;
  name: string;
  userInvites?: BasicPersonalInformationInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCompanyAdmin
// ====================================================

export interface CreateCompanyAdmin_createCompanyAdmin_users {
  __typename: "User";
  id: string;
}

export interface CreateCompanyAdmin_createCompanyAdmin {
  __typename: "Company";
  id: string;
  name: string;
  users: CreateCompanyAdmin_createCompanyAdmin_users[];
}

export interface CreateCompanyAdmin {
  createCompanyAdmin: CreateCompanyAdmin_createCompanyAdmin | null;
}

export interface CreateCompanyAdminVariables {
  name: string;
  language: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateApiKey
// ====================================================

export interface GenerateApiKey_generateApiKey {
  __typename: "Company";
  id: string;
}

export interface GenerateApiKey {
  generateApiKey: GenerateApiKey_generateApiKey | null;
}

export interface GenerateApiKeyVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCompanyWebFont
// ====================================================

export interface SetCompanyWebFont_setCompanyWebFont {
  __typename: "Company";
  id: string;
  titleFont: string | null;
  textFont: string | null;
}

export interface SetCompanyWebFont {
  setCompanyWebFont: SetCompanyWebFont_setCompanyWebFont | null;
}

export interface SetCompanyWebFontVariables {
  companyId: string;
  titleFont?: string | null;
  textFont?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetEnabledFeatures
// ====================================================

export interface SetEnabledFeatures_setEnabledFeatures_enabledFeatures {
  __typename: "EnabledFeatures";
  id: string;
  assignments: boolean | null;
  calendar: boolean | null;
  consultantProjects: boolean | null;
  contracts: boolean | null;
  screeningForms: boolean | null;
  referenceForms: boolean | null;
  trustcruit: boolean | null;
  applicationsRankedWithAI: boolean | null;
  interviewBooking: boolean | null;
  interviewBookingGroups: boolean | null;
  alvaLabs: boolean | null;
  tengai: boolean | null;
  allApplicationsPage: boolean | null;
  aiGeneration: boolean | null;
  careerPage: boolean | null;
  mediaLibrary: boolean | null;
  jobtip: boolean | null;
  evaluationForm: boolean | null;
  statusDeadlines: boolean | null;
  videoInApplications: boolean | null;
}

export interface SetEnabledFeatures_setEnabledFeatures {
  __typename: "Company";
  id: string;
  enabledFeatures: SetEnabledFeatures_setEnabledFeatures_enabledFeatures | null;
}

export interface SetEnabledFeatures {
  setEnabledFeatures: SetEnabledFeatures_setEnabledFeatures | null;
}

export interface SetEnabledFeaturesVariables {
  companyId: string;
  assignments?: boolean | null;
  calendar?: boolean | null;
  internalChat?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetEnabledFeaturesAdmin
// ====================================================

export interface SetEnabledFeaturesAdmin_setEnabledFeaturesAdmin_enabledFeatures {
  __typename: "EnabledFeatures";
  id: string;
  assignments: boolean | null;
  calendar: boolean | null;
  consultantProjects: boolean | null;
  contracts: boolean | null;
  screeningForms: boolean | null;
  referenceForms: boolean | null;
  trustcruit: boolean | null;
  applicationsRankedWithAI: boolean | null;
  interviewBooking: boolean | null;
  interviewBookingGroups: boolean | null;
  alvaLabs: boolean | null;
  tengai: boolean | null;
  allApplicationsPage: boolean | null;
  aiGeneration: boolean | null;
  careerPage: boolean | null;
  mediaLibrary: boolean | null;
  jobtip: boolean | null;
  evaluationForm: boolean | null;
  statusDeadlines: boolean | null;
  videoInApplications: boolean | null;
}

export interface SetEnabledFeaturesAdmin_setEnabledFeaturesAdmin {
  __typename: "Company";
  id: string;
  enabledFeatures: SetEnabledFeaturesAdmin_setEnabledFeaturesAdmin_enabledFeatures | null;
}

export interface SetEnabledFeaturesAdmin {
  setEnabledFeaturesAdmin: SetEnabledFeaturesAdmin_setEnabledFeaturesAdmin | null;
}

export interface SetEnabledFeaturesAdminVariables {
  companyId: string;
  assignments?: boolean | null;
  calendar?: boolean | null;
  internalChat?: boolean | null;
  contracts?: boolean | null;
  consultantProjects?: boolean | null;
  screeningForms?: boolean | null;
  referenceForms?: boolean | null;
  trustcruit?: boolean | null;
  applicationsRankedWithAI?: boolean | null;
  interviewBooking?: boolean | null;
  interviewBookingGroups?: boolean | null;
  allApplicationsPage?: boolean | null;
  alvaLabs?: boolean | null;
  tengai?: boolean | null;
  aiGeneration?: boolean | null;
  mediaLibrary?: boolean | null;
  jobtip?: boolean | null;
  careerPage?: boolean | null;
  statusDeadlines?: boolean | null;
  evaluationForm?: boolean | null;
  videoInApplications?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetSubdomain
// ====================================================

export interface SetSubdomain_setSubdomain {
  __typename: "Company";
  id: string;
  subdomain: string | null;
}

export interface SetSubdomain {
  setSubdomain: SetSubdomain_setSubdomain | null;
}

export interface SetSubdomainVariables {
  companyId: string;
  subdomain: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateArbetsformedlingenConnectionAdmin
// ====================================================

export interface UpdateArbetsformedlingenConnectionAdmin_updateArbetsformedlingenConnectionAdmin {
  __typename: "Company";
  id: string;
}

export interface UpdateArbetsformedlingenConnectionAdmin {
  updateArbetsformedlingenConnectionAdmin: UpdateArbetsformedlingenConnectionAdmin_updateArbetsformedlingenConnectionAdmin | null;
}

export interface UpdateArbetsformedlingenConnectionAdminVariables {
  companyId: string;
  arbetsformedlingenSenderId?: string | null;
  orgNumber?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompanyAdmin
// ====================================================

export interface UpdateCompanyAdmin_updateCompanyAdmin {
  __typename: "Company";
  id: string;
}

export interface UpdateCompanyAdmin {
  updateCompanyAdmin: UpdateCompanyAdmin_updateCompanyAdmin | null;
}

export interface UpdateCompanyAdminVariables {
  id: string;
  data?: CompanyUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompany
// ====================================================

export interface UpdateCompany_updateCompany {
  __typename: "Company";
  id: string;
  name: string;
  subdomain: string | null;
  language: string | null;
  orgNumber: string | null;
  primaryColor: string | null;
  shortPresentation: string | null;
  postalCode: string | null;
  postalAddress: string | null;
  municipality: string | null;
  websiteUrl: string | null;
}

export interface UpdateCompany {
  updateCompany: UpdateCompany_updateCompany | null;
}

export interface UpdateCompanyVariables {
  companyId: string;
  data?: CompanyUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTengaiInterviewUrl
// ====================================================

export interface UpdateTengaiInterviewUrl_updateTengaiInterviewUrl {
  __typename: "Company";
  id: string;
  tengaiInterviewUrl: string | null;
}

export interface UpdateTengaiInterviewUrl {
  updateTengaiInterviewUrl: UpdateTengaiInterviewUrl_updateTengaiInterviewUrl | null;
}

export interface UpdateTengaiInterviewUrlVariables {
  companyId: string;
  tengaiInterviewUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTrustcruitSprancherId
// ====================================================

export interface UpdateTrustcruitSprancherId_updateTrustcruitSprancherId {
  __typename: "Company";
  id: string;
  trustcruitCustomerSprancherId: string | null;
}

export interface UpdateTrustcruitSprancherId {
  updateTrustcruitSprancherId: UpdateTrustcruitSprancherId_updateTrustcruitSprancherId | null;
}

export interface UpdateTrustcruitSprancherIdVariables {
  companyId: string;
  trustcruitCustomerSprancherId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadCompanyProfileImage
// ====================================================

export interface UploadCompanyProfileImage_uploadCompanyProfileImage_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UploadCompanyProfileImage_uploadCompanyProfileImage {
  __typename: "Company";
  id: string;
  profileImage: UploadCompanyProfileImage_uploadCompanyProfileImage_profileImage | null;
}

export interface UploadCompanyProfileImage {
  uploadCompanyProfileImage: UploadCompanyProfileImage_uploadCompanyProfileImage | null;
}

export interface UploadCompanyProfileImageVariables {
  companyId: string;
  file: Upload;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCompanyApplicant
// ====================================================

export interface DeleteCompanyApplicant_deleteCompanyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface DeleteCompanyApplicant {
  deleteCompanyApplicant: DeleteCompanyApplicant_deleteCompanyApplicant | null;
}

export interface DeleteCompanyApplicantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GradeApplicant
// ====================================================

export interface GradeApplicant_gradeApplicant_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GradeApplicant_gradeApplicant_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  setBy: GradeApplicant_gradeApplicant_grades_setBy | null;
}

export interface GradeApplicant_gradeApplicant {
  __typename: "CompanyApplicant";
  id: string;
  averageGrade: number;
  grades: GradeApplicant_gradeApplicant_grades[];
}

export interface GradeApplicant {
  gradeApplicant: GradeApplicant_gradeApplicant | null;
}

export interface GradeApplicantVariables {
  id: string;
  grade: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCareerPage
// ====================================================

export interface UpdateCareerPage_updateCareerPage {
  __typename: "CompanyCareerPage";
  id: string;
}

export interface UpdateCareerPage {
  updateCareerPage: UpdateCareerPage_updateCareerPage | null;
}

export interface UpdateCareerPageVariables {
  companyId: string;
  published?: boolean | null;
  coverImageTitle?: string | null;
  coverImageDescription?: string | null;
  coverImageVideoUrl?: string | null;
  webTitle?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadCareerPageWebIcon
// ====================================================

export interface UploadCareerPageWebIcon_uploadCareerPageWebIcon {
  __typename: "CompanyCareerPage";
  id: string;
}

export interface UploadCareerPageWebIcon {
  uploadCareerPageWebIcon: UploadCareerPageWebIcon_uploadCareerPageWebIcon | null;
}

export interface UploadCareerPageWebIconVariables {
  companyId: string;
  file: Upload;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCompetence
// ====================================================

export interface CreateCompetence_createCompetence {
  __typename: "Competence";
  id: string;
}

export interface CreateCompetence {
  createCompetence: CreateCompetence_createCompetence | null;
}

export interface CreateCompetenceVariables {
  companyId: string;
  name: string;
  description?: string | null;
  type: COMPETENCE_TYPE;
  answerType: COMPETENCE_ANSWER_TYPE;
  questions: InterviewQuestionInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCompetence
// ====================================================

export interface DeleteCompetence_deleteCompetence {
  __typename: "Competence";
  id: string;
  name: string;
}

export interface DeleteCompetence {
  deleteCompetence: DeleteCompetence_deleteCompetence | null;
}

export interface DeleteCompetenceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompetence
// ====================================================

export interface UpdateCompetence_updateCompetence {
  __typename: "Competence";
  id: string;
}

export interface UpdateCompetence {
  updateCompetence: UpdateCompetence_updateCompetence | null;
}

export interface UpdateCompetenceVariables {
  companyId: string;
  id: string;
  name?: string | null;
  description?: string | null;
  type?: COMPETENCE_TYPE | null;
  answerType?: COMPETENCE_ANSWER_TYPE | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddConsultant
// ====================================================

export interface AddConsultant_addConsultant {
  __typename: "Consultant";
  id: string;
}

export interface AddConsultant {
  addConsultant: AddConsultant_addConsultant;
}

export interface AddConsultantVariables {
  companyId: string;
  companyApplicantId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddConsultantToProject
// ====================================================

export interface AddConsultantToProject_addConsultantToProject {
  __typename: "Consultant";
  id: string;
}

export interface AddConsultantToProject {
  addConsultantToProject: AddConsultantToProject_addConsultantToProject | null;
}

export interface AddConsultantToProjectVariables {
  companyId: string;
  consultantProjectId: string;
  companyApplicantId?: string | null;
  consultantId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantFromApplicant
// ====================================================

export interface ConsultantFromApplicant_consultantFromApplicant {
  __typename: "Consultant";
  id: string;
}

export interface ConsultantFromApplicant {
  consultantFromApplicant: ConsultantFromApplicant_consultantFromApplicant | null;
}

export interface ConsultantFromApplicantVariables {
  applicantId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GradeConsultant
// ====================================================

export interface GradeConsultant_gradeConsultant_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GradeConsultant_gradeConsultant_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GradeConsultant_gradeConsultant_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: GradeConsultant_gradeConsultant_grades_visitor | null;
  setBy: GradeConsultant_gradeConsultant_grades_setBy | null;
}

export interface GradeConsultant_gradeConsultant_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface GradeConsultant_gradeConsultant_formAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface GradeConsultant_gradeConsultant_formAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: GradeConsultant_gradeConsultant_formAnswers_question_matchingEventProfile | null;
}

export interface GradeConsultant_gradeConsultant_formAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: GradeConsultant_gradeConsultant_formAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface GradeConsultant_gradeConsultant_projects_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface GradeConsultant_gradeConsultant_projects_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface GradeConsultant_gradeConsultant_projects_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface GradeConsultant_gradeConsultant_projects_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: GradeConsultant_gradeConsultant_projects_users_user_profileImage | null;
}

export interface GradeConsultant_gradeConsultant_projects_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface GradeConsultant_gradeConsultant_projects_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: GradeConsultant_gradeConsultant_projects_users_user;
  consultantProject: GradeConsultant_gradeConsultant_projects_users_consultantProject;
}

export interface GradeConsultant_gradeConsultant_projects_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface GradeConsultant_gradeConsultant_projects_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface GradeConsultant_gradeConsultant_projects_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GradeConsultant_gradeConsultant_projects_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface GradeConsultant_gradeConsultant_projects_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: GradeConsultant_gradeConsultant_projects_customer_customerCompany_profileImage | null;
}

export interface GradeConsultant_gradeConsultant_projects_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: GradeConsultant_gradeConsultant_projects_customer_invoiceInformation | null;
  status: GradeConsultant_gradeConsultant_projects_customer_status | null;
  companiesWithAccess: GradeConsultant_gradeConsultant_projects_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: GradeConsultant_gradeConsultant_projects_customer_customerCompany | null;
}

export interface GradeConsultant_gradeConsultant_projects_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface GradeConsultant_gradeConsultant_projects_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface GradeConsultant_gradeConsultant_projects_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: GradeConsultant_gradeConsultant_projects_responsible_profileImage | null;
}

export interface GradeConsultant_gradeConsultant_projects_tags {
  __typename: "TagLabel";
  id: string;
}

export interface GradeConsultant_gradeConsultant_projects_comments {
  __typename: "Comment";
  id: string;
}

export interface GradeConsultant_gradeConsultant_projects_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface GradeConsultant_gradeConsultant_projects_files {
  __typename: "File";
  id: string;
}

export interface GradeConsultant_gradeConsultant_projects_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface GradeConsultant_gradeConsultant_projects_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: GradeConsultant_gradeConsultant_projects_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface GradeConsultant_gradeConsultant_projects {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: GradeConsultant_gradeConsultant_projects_invoiceInformation;
  status: GradeConsultant_gradeConsultant_projects_status | null;
  users: GradeConsultant_gradeConsultant_projects_users[];
  customer: GradeConsultant_gradeConsultant_projects_customer | null;
  customerName: string | null;
  recruitment: GradeConsultant_gradeConsultant_projects_recruitment | null;
  responsible: GradeConsultant_gradeConsultant_projects_responsible | null;
  tags: GradeConsultant_gradeConsultant_projects_tags[];
  comments: GradeConsultant_gradeConsultant_projects_comments[];
  consultants: GradeConsultant_gradeConsultant_projects_consultants[];
  files: GradeConsultant_gradeConsultant_projects_files[];
  fieldExtensionValues: GradeConsultant_gradeConsultant_projects_fieldExtensionValues[];
}

export interface GradeConsultant_gradeConsultant_applicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
}

export interface GradeConsultant_gradeConsultant_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface GradeConsultant_gradeConsultant_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: GradeConsultant_gradeConsultant_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface GradeConsultant_gradeConsultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  averageGrade: number;
  createdAt: DateTime;
  note: string | null;
  grades: GradeConsultant_gradeConsultant_grades[];
  status: GradeConsultant_gradeConsultant_status | null;
  formAnswers: GradeConsultant_gradeConsultant_formAnswers[];
  projects: GradeConsultant_gradeConsultant_projects[];
  applicant: GradeConsultant_gradeConsultant_applicant;
  fieldExtensionValues: GradeConsultant_gradeConsultant_fieldExtensionValues[];
}

export interface GradeConsultant {
  gradeConsultant: GradeConsultant_gradeConsultant | null;
}

export interface GradeConsultantVariables {
  grade: number;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportAnswersToConsultantProfile
// ====================================================

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_grades_visitor | null;
  setBy: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_grades_setBy | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_formAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_formAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_formAnswers_question_matchingEventProfile | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_formAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_formAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_users_user_profileImage | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_users_user;
  consultantProject: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_users_consultantProject;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer_customerCompany_profileImage | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer_invoiceInformation | null;
  status: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer_status | null;
  companiesWithAccess: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer_customerCompany | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_responsible_profileImage | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_tags {
  __typename: "TagLabel";
  id: string;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_comments {
  __typename: "Comment";
  id: string;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_files {
  __typename: "File";
  id: string;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_invoiceInformation;
  status: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_status | null;
  users: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_users[];
  customer: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_customer | null;
  customerName: string | null;
  recruitment: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_recruitment | null;
  responsible: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_responsible | null;
  tags: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_tags[];
  comments: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_comments[];
  consultants: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_consultants[];
  files: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_files[];
  fieldExtensionValues: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects_fieldExtensionValues[];
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_applicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface ImportAnswersToConsultantProfile_importAnswersToConsultantProfile {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  averageGrade: number;
  createdAt: DateTime;
  note: string | null;
  grades: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_grades[];
  status: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_status | null;
  formAnswers: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_formAnswers[];
  projects: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_projects[];
  applicant: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_applicant;
  fieldExtensionValues: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile_fieldExtensionValues[];
}

export interface ImportAnswersToConsultantProfile {
  importAnswersToConsultantProfile: ImportAnswersToConsultantProfile_importAnswersToConsultantProfile | null;
}

export interface ImportAnswersToConsultantProfileVariables {
  consultantId: string;
  answerImports: FormQuestionAnswerImportInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateConsultant
// ====================================================

export interface UpdateConsultant_updateConsultant_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface UpdateConsultant_updateConsultant_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface UpdateConsultant_updateConsultant_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: UpdateConsultant_updateConsultant_grades_visitor | null;
  setBy: UpdateConsultant_updateConsultant_grades_setBy | null;
}

export interface UpdateConsultant_updateConsultant_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface UpdateConsultant_updateConsultant_formAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface UpdateConsultant_updateConsultant_formAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: UpdateConsultant_updateConsultant_formAnswers_question_matchingEventProfile | null;
}

export interface UpdateConsultant_updateConsultant_formAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: UpdateConsultant_updateConsultant_formAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface UpdateConsultant_updateConsultant_projects_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface UpdateConsultant_updateConsultant_projects_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface UpdateConsultant_updateConsultant_projects_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateConsultant_updateConsultant_projects_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: UpdateConsultant_updateConsultant_projects_users_user_profileImage | null;
}

export interface UpdateConsultant_updateConsultant_projects_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface UpdateConsultant_updateConsultant_projects_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: UpdateConsultant_updateConsultant_projects_users_user;
  consultantProject: UpdateConsultant_updateConsultant_projects_users_consultantProject;
}

export interface UpdateConsultant_updateConsultant_projects_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface UpdateConsultant_updateConsultant_projects_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface UpdateConsultant_updateConsultant_projects_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface UpdateConsultant_updateConsultant_projects_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateConsultant_updateConsultant_projects_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: UpdateConsultant_updateConsultant_projects_customer_customerCompany_profileImage | null;
}

export interface UpdateConsultant_updateConsultant_projects_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: UpdateConsultant_updateConsultant_projects_customer_invoiceInformation | null;
  status: UpdateConsultant_updateConsultant_projects_customer_status | null;
  companiesWithAccess: UpdateConsultant_updateConsultant_projects_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: UpdateConsultant_updateConsultant_projects_customer_customerCompany | null;
}

export interface UpdateConsultant_updateConsultant_projects_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface UpdateConsultant_updateConsultant_projects_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateConsultant_updateConsultant_projects_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: UpdateConsultant_updateConsultant_projects_responsible_profileImage | null;
}

export interface UpdateConsultant_updateConsultant_projects_tags {
  __typename: "TagLabel";
  id: string;
}

export interface UpdateConsultant_updateConsultant_projects_comments {
  __typename: "Comment";
  id: string;
}

export interface UpdateConsultant_updateConsultant_projects_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface UpdateConsultant_updateConsultant_projects_files {
  __typename: "File";
  id: string;
}

export interface UpdateConsultant_updateConsultant_projects_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface UpdateConsultant_updateConsultant_projects_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: UpdateConsultant_updateConsultant_projects_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface UpdateConsultant_updateConsultant_projects {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: UpdateConsultant_updateConsultant_projects_invoiceInformation;
  status: UpdateConsultant_updateConsultant_projects_status | null;
  users: UpdateConsultant_updateConsultant_projects_users[];
  customer: UpdateConsultant_updateConsultant_projects_customer | null;
  customerName: string | null;
  recruitment: UpdateConsultant_updateConsultant_projects_recruitment | null;
  responsible: UpdateConsultant_updateConsultant_projects_responsible | null;
  tags: UpdateConsultant_updateConsultant_projects_tags[];
  comments: UpdateConsultant_updateConsultant_projects_comments[];
  consultants: UpdateConsultant_updateConsultant_projects_consultants[];
  files: UpdateConsultant_updateConsultant_projects_files[];
  fieldExtensionValues: UpdateConsultant_updateConsultant_projects_fieldExtensionValues[];
}

export interface UpdateConsultant_updateConsultant_applicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
}

export interface UpdateConsultant_updateConsultant_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface UpdateConsultant_updateConsultant_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: UpdateConsultant_updateConsultant_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface UpdateConsultant_updateConsultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  averageGrade: number;
  createdAt: DateTime;
  note: string | null;
  grades: UpdateConsultant_updateConsultant_grades[];
  status: UpdateConsultant_updateConsultant_status | null;
  formAnswers: UpdateConsultant_updateConsultant_formAnswers[];
  projects: UpdateConsultant_updateConsultant_projects[];
  applicant: UpdateConsultant_updateConsultant_applicant;
  fieldExtensionValues: UpdateConsultant_updateConsultant_fieldExtensionValues[];
}

export interface UpdateConsultant {
  updateConsultant: UpdateConsultant_updateConsultant | null;
}

export interface UpdateConsultantVariables {
  id: string;
  statusId?: string | null;
  note?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateConsultantProfile
// ====================================================

export interface UpdateConsultantProfile_updateConsultantProfile {
  __typename: "Consultant";
  id: string;
}

export interface UpdateConsultantProfile {
  updateConsultantProfile: UpdateConsultantProfile_updateConsultantProfile | null;
}

export interface UpdateConsultantProfileVariables {
  id: string;
  answers: EditedFormQuestionAnswerInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeConsultantCustomerShareAccessStatus
// ====================================================

export interface ChangeConsultantCustomerShareAccessStatus_changeConsultantCustomerShareAccessStatus {
  __typename: "ConsultantCustomer";
  id: string;
}

export interface ChangeConsultantCustomerShareAccessStatus {
  changeConsultantCustomerShareAccessStatus: ChangeConsultantCustomerShareAccessStatus_changeConsultantCustomerShareAccessStatus | null;
}

export interface ChangeConsultantCustomerShareAccessStatusVariables {
  consultantCustomerId: string;
  shareAccessStatus: TEMPLATE_SHARE_STATUS;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateConsultantCustomer
// ====================================================

export interface CreateConsultantCustomer_createConsultantCustomer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
  description: string | null;
}

export interface CreateConsultantCustomer {
  createConsultantCustomer: CreateConsultantCustomer_createConsultantCustomer | null;
}

export interface CreateConsultantCustomerVariables {
  companyId: string;
  name: string;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteConsultantCustomer
// ====================================================

export interface DeleteConsultantCustomer_deleteConsultantCustomer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
}

export interface DeleteConsultantCustomer {
  deleteConsultantCustomer: DeleteConsultantCustomer_deleteConsultantCustomer | null;
}

export interface DeleteConsultantCustomerVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShareConsultantCustomerWithCompanies
// ====================================================

export interface ShareConsultantCustomerWithCompanies_shareConsultantCustomerWithCompanies {
  __typename: "ConsultantCustomer";
  id: string;
}

export interface ShareConsultantCustomerWithCompanies {
  shareConsultantCustomerWithCompanies: ShareConsultantCustomerWithCompanies_shareConsultantCustomerWithCompanies | null;
}

export interface ShareConsultantCustomerWithCompaniesVariables {
  consultantCustomerId: string;
  companyIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateConsultantCustomer
// ====================================================

export interface UpdateConsultantCustomer_updateConsultantCustomer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface UpdateConsultantCustomer_updateConsultantCustomer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface UpdateConsultantCustomer_updateConsultantCustomer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface UpdateConsultantCustomer_updateConsultantCustomer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateConsultantCustomer_updateConsultantCustomer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: UpdateConsultantCustomer_updateConsultantCustomer_customerCompany_profileImage | null;
}

export interface UpdateConsultantCustomer_updateConsultantCustomer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: UpdateConsultantCustomer_updateConsultantCustomer_invoiceInformation | null;
  status: UpdateConsultantCustomer_updateConsultantCustomer_status | null;
  companiesWithAccess: UpdateConsultantCustomer_updateConsultantCustomer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: UpdateConsultantCustomer_updateConsultantCustomer_customerCompany | null;
}

export interface UpdateConsultantCustomer {
  updateConsultantCustomer: UpdateConsultantCustomer_updateConsultantCustomer | null;
}

export interface UpdateConsultantCustomerVariables {
  companyId: string;
  customerId: string;
  statusId?: string | null;
  name?: string | null;
  description?: string | null;
  about?: string | null;
  note?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRecruitmentConsultantCustomer
// ====================================================

export interface UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer_customerCompany_profileImage | null;
}

export interface UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer_invoiceInformation | null;
  status: UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer_status | null;
  companiesWithAccess: UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer_customerCompany | null;
}

export interface UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer {
  __typename: "Recruitment";
  id: string;
  consultantCustomer: UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer_consultantCustomer | null;
  useCustomerProfile: boolean | null;
}

export interface UpdateRecruitmentConsultantCustomer {
  updateRecruitmentConsultantCustomer: UpdateRecruitmentConsultantCustomer_updateRecruitmentConsultantCustomer | null;
}

export interface UpdateRecruitmentConsultantCustomerVariables {
  recruitmentId: string;
  customerId?: string | null;
  useCustomerProfile?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectConsultantToProject
// ====================================================

export interface ConnectConsultantToProject_connectConsultantToProject_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: ConnectConsultantToProject_connectConsultantToProject_grades_visitor | null;
  setBy: ConnectConsultantToProject_connectConsultantToProject_grades_setBy | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_formAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ConnectConsultantToProject_connectConsultantToProject_formAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: ConnectConsultantToProject_connectConsultantToProject_formAnswers_question_matchingEventProfile | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_formAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: ConnectConsultantToProject_connectConsultantToProject_formAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConnectConsultantToProject_connectConsultantToProject_projects_users_user_profileImage | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: ConnectConsultantToProject_connectConsultantToProject_projects_users_user;
  consultantProject: ConnectConsultantToProject_connectConsultantToProject_projects_users_consultantProject;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: ConnectConsultantToProject_connectConsultantToProject_projects_customer_customerCompany_profileImage | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: ConnectConsultantToProject_connectConsultantToProject_projects_customer_invoiceInformation | null;
  status: ConnectConsultantToProject_connectConsultantToProject_projects_customer_status | null;
  companiesWithAccess: ConnectConsultantToProject_connectConsultantToProject_projects_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: ConnectConsultantToProject_connectConsultantToProject_projects_customer_customerCompany | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConnectConsultantToProject_connectConsultantToProject_projects_responsible_profileImage | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_tags {
  __typename: "TagLabel";
  id: string;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_comments {
  __typename: "Comment";
  id: string;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_files {
  __typename: "File";
  id: string;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: ConnectConsultantToProject_connectConsultantToProject_projects_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_projects {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: ConnectConsultantToProject_connectConsultantToProject_projects_invoiceInformation;
  status: ConnectConsultantToProject_connectConsultantToProject_projects_status | null;
  users: ConnectConsultantToProject_connectConsultantToProject_projects_users[];
  customer: ConnectConsultantToProject_connectConsultantToProject_projects_customer | null;
  customerName: string | null;
  recruitment: ConnectConsultantToProject_connectConsultantToProject_projects_recruitment | null;
  responsible: ConnectConsultantToProject_connectConsultantToProject_projects_responsible | null;
  tags: ConnectConsultantToProject_connectConsultantToProject_projects_tags[];
  comments: ConnectConsultantToProject_connectConsultantToProject_projects_comments[];
  consultants: ConnectConsultantToProject_connectConsultantToProject_projects_consultants[];
  files: ConnectConsultantToProject_connectConsultantToProject_projects_files[];
  fieldExtensionValues: ConnectConsultantToProject_connectConsultantToProject_projects_fieldExtensionValues[];
}

export interface ConnectConsultantToProject_connectConsultantToProject_applicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface ConnectConsultantToProject_connectConsultantToProject_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: ConnectConsultantToProject_connectConsultantToProject_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface ConnectConsultantToProject_connectConsultantToProject {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  averageGrade: number;
  createdAt: DateTime;
  note: string | null;
  grades: ConnectConsultantToProject_connectConsultantToProject_grades[];
  status: ConnectConsultantToProject_connectConsultantToProject_status | null;
  formAnswers: ConnectConsultantToProject_connectConsultantToProject_formAnswers[];
  projects: ConnectConsultantToProject_connectConsultantToProject_projects[];
  applicant: ConnectConsultantToProject_connectConsultantToProject_applicant;
  fieldExtensionValues: ConnectConsultantToProject_connectConsultantToProject_fieldExtensionValues[];
}

export interface ConnectConsultantToProject {
  connectConsultantToProject: ConnectConsultantToProject_connectConsultantToProject | null;
}

export interface ConnectConsultantToProjectVariables {
  consultantId: string;
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectUserToConsultantProject
// ====================================================

export interface ConnectUserToConsultantProject_connectUserToConsultantProject_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConnectUserToConsultantProject_connectUserToConsultantProject_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConnectUserToConsultantProject_connectUserToConsultantProject_user_profileImage | null;
}

export interface ConnectUserToConsultantProject_connectUserToConsultantProject_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface ConnectUserToConsultantProject_connectUserToConsultantProject {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: ConnectUserToConsultantProject_connectUserToConsultantProject_user;
  consultantProject: ConnectUserToConsultantProject_connectUserToConsultantProject_consultantProject;
}

export interface ConnectUserToConsultantProject {
  connectUserToConsultantProject: ConnectUserToConsultantProject_connectUserToConsultantProject | null;
}

export interface ConnectUserToConsultantProjectVariables {
  userId: string;
  projectId: string;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantProjectUser
// ====================================================

export interface ConsultantProjectUser_consultantProjectUser_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProjectUser_consultantProjectUser_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConsultantProjectUser_consultantProjectUser_user_profileImage | null;
}

export interface ConsultantProjectUser_consultantProjectUser_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface ConsultantProjectUser_consultantProjectUser {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: ConsultantProjectUser_consultantProjectUser_user;
  consultantProject: ConsultantProjectUser_consultantProjectUser_consultantProject;
}

export interface ConsultantProjectUser {
  consultantProjectUser: ConsultantProjectUser_consultantProjectUser | null;
}

export interface ConsultantProjectUserVariables {
  consultantProjectUserId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateConsultantProject
// ====================================================

export interface CreateConsultantProject_createConsultantProject {
  __typename: "ConsultantProject";
  id: string;
  title: string;
  description: string | null;
}

export interface CreateConsultantProject {
  createConsultantProject: CreateConsultantProject_createConsultantProject | null;
}

export interface CreateConsultantProjectVariables {
  companyId: string;
  title: string;
  description?: string | null;
  customerId?: string | null;
  recruitmentId?: string | null;
  users: AddedConsultantProjectUserInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateConsultantProjectCost
// ====================================================

export interface CreateConsultantProjectCost_createConsultantProjectCost {
  __typename: "ConsultantProjectCost";
  id: string;
}

export interface CreateConsultantProjectCost {
  createConsultantProjectCost: CreateConsultantProjectCost_createConsultantProjectCost | null;
}

export interface CreateConsultantProjectCostVariables {
  projectId: string;
  title: string;
  type?: COST_TYPE | null;
  note?: string | null;
  roleId?: string | null;
  hourlyRate?: number | null;
  fixedCost?: number | null;
  quantity?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateConsultantProjectRole
// ====================================================

export interface CreateConsultantProjectRole_createConsultantProjectRole {
  __typename: "ConsultantProjectRole";
  id: string;
}

export interface CreateConsultantProjectRole {
  createConsultantProjectRole: CreateConsultantProjectRole_createConsultantProjectRole | null;
}

export interface CreateConsultantProjectRoleVariables {
  projectId: string;
  title: string;
  hourlyRate?: number | null;
  hoursWorked?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteConsultantProject
// ====================================================

export interface DeleteConsultantProject_deleteConsultantProject_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface DeleteConsultantProject_deleteConsultantProject_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface DeleteConsultantProject_deleteConsultantProject_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface DeleteConsultantProject_deleteConsultantProject_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: DeleteConsultantProject_deleteConsultantProject_users_user_profileImage | null;
}

export interface DeleteConsultantProject_deleteConsultantProject_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface DeleteConsultantProject_deleteConsultantProject_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: DeleteConsultantProject_deleteConsultantProject_users_user;
  consultantProject: DeleteConsultantProject_deleteConsultantProject_users_consultantProject;
}

export interface DeleteConsultantProject_deleteConsultantProject_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface DeleteConsultantProject_deleteConsultantProject_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface DeleteConsultantProject_deleteConsultantProject_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface DeleteConsultantProject_deleteConsultantProject_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface DeleteConsultantProject_deleteConsultantProject_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: DeleteConsultantProject_deleteConsultantProject_customer_customerCompany_profileImage | null;
}

export interface DeleteConsultantProject_deleteConsultantProject_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: DeleteConsultantProject_deleteConsultantProject_customer_invoiceInformation | null;
  status: DeleteConsultantProject_deleteConsultantProject_customer_status | null;
  companiesWithAccess: DeleteConsultantProject_deleteConsultantProject_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: DeleteConsultantProject_deleteConsultantProject_customer_customerCompany | null;
}

export interface DeleteConsultantProject_deleteConsultantProject_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface DeleteConsultantProject_deleteConsultantProject_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface DeleteConsultantProject_deleteConsultantProject_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: DeleteConsultantProject_deleteConsultantProject_responsible_profileImage | null;
}

export interface DeleteConsultantProject_deleteConsultantProject_tags {
  __typename: "TagLabel";
  id: string;
}

export interface DeleteConsultantProject_deleteConsultantProject_comments {
  __typename: "Comment";
  id: string;
}

export interface DeleteConsultantProject_deleteConsultantProject_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface DeleteConsultantProject_deleteConsultantProject_files {
  __typename: "File";
  id: string;
}

export interface DeleteConsultantProject_deleteConsultantProject_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface DeleteConsultantProject_deleteConsultantProject_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: DeleteConsultantProject_deleteConsultantProject_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface DeleteConsultantProject_deleteConsultantProject {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: DeleteConsultantProject_deleteConsultantProject_invoiceInformation;
  status: DeleteConsultantProject_deleteConsultantProject_status | null;
  users: DeleteConsultantProject_deleteConsultantProject_users[];
  customer: DeleteConsultantProject_deleteConsultantProject_customer | null;
  customerName: string | null;
  recruitment: DeleteConsultantProject_deleteConsultantProject_recruitment | null;
  responsible: DeleteConsultantProject_deleteConsultantProject_responsible | null;
  tags: DeleteConsultantProject_deleteConsultantProject_tags[];
  comments: DeleteConsultantProject_deleteConsultantProject_comments[];
  consultants: DeleteConsultantProject_deleteConsultantProject_consultants[];
  files: DeleteConsultantProject_deleteConsultantProject_files[];
  fieldExtensionValues: DeleteConsultantProject_deleteConsultantProject_fieldExtensionValues[];
}

export interface DeleteConsultantProject {
  deleteConsultantProject: DeleteConsultantProject_deleteConsultantProject | null;
}

export interface DeleteConsultantProjectVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteConsultantProjectRole
// ====================================================

export interface DeleteConsultantProjectRole_deleteConsultantProjectRole {
  __typename: "ConsultantProjectRole";
  id: string;
}

export interface DeleteConsultantProjectRole {
  deleteConsultantProjectRole: DeleteConsultantProjectRole_deleteConsultantProjectRole | null;
}

export interface DeleteConsultantProjectRoleVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectConsultantFromProject
// ====================================================

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: DisconnectConsultantFromProject_disconnectConsultantFromProject_grades_visitor | null;
  setBy: DisconnectConsultantFromProject_disconnectConsultantFromProject_grades_setBy | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_formAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_formAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: DisconnectConsultantFromProject_disconnectConsultantFromProject_formAnswers_question_matchingEventProfile | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_formAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: DisconnectConsultantFromProject_disconnectConsultantFromProject_formAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_users_user_profileImage | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_users_user;
  consultantProject: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_users_consultantProject;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer_customerCompany_profileImage | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer_invoiceInformation | null;
  status: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer_status | null;
  companiesWithAccess: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer_customerCompany | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_responsible_profileImage | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_tags {
  __typename: "TagLabel";
  id: string;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_comments {
  __typename: "Comment";
  id: string;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_files {
  __typename: "File";
  id: string;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_projects {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_invoiceInformation;
  status: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_status | null;
  users: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_users[];
  customer: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_customer | null;
  customerName: string | null;
  recruitment: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_recruitment | null;
  responsible: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_responsible | null;
  tags: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_tags[];
  comments: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_comments[];
  consultants: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_consultants[];
  files: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_files[];
  fieldExtensionValues: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects_fieldExtensionValues[];
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_applicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: DisconnectConsultantFromProject_disconnectConsultantFromProject_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface DisconnectConsultantFromProject_disconnectConsultantFromProject {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  averageGrade: number;
  createdAt: DateTime;
  note: string | null;
  grades: DisconnectConsultantFromProject_disconnectConsultantFromProject_grades[];
  status: DisconnectConsultantFromProject_disconnectConsultantFromProject_status | null;
  formAnswers: DisconnectConsultantFromProject_disconnectConsultantFromProject_formAnswers[];
  projects: DisconnectConsultantFromProject_disconnectConsultantFromProject_projects[];
  applicant: DisconnectConsultantFromProject_disconnectConsultantFromProject_applicant;
  fieldExtensionValues: DisconnectConsultantFromProject_disconnectConsultantFromProject_fieldExtensionValues[];
}

export interface DisconnectConsultantFromProject {
  disconnectConsultantFromProject: DisconnectConsultantFromProject_disconnectConsultantFromProject | null;
}

export interface DisconnectConsultantFromProjectVariables {
  consultantId: string;
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectUserFromConsultantProject
// ====================================================

export interface DisconnectUserFromConsultantProject_disconnectUserFromConsultantProject_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface DisconnectUserFromConsultantProject_disconnectUserFromConsultantProject_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: DisconnectUserFromConsultantProject_disconnectUserFromConsultantProject_user_profileImage | null;
}

export interface DisconnectUserFromConsultantProject_disconnectUserFromConsultantProject_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface DisconnectUserFromConsultantProject_disconnectUserFromConsultantProject {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: DisconnectUserFromConsultantProject_disconnectUserFromConsultantProject_user;
  consultantProject: DisconnectUserFromConsultantProject_disconnectUserFromConsultantProject_consultantProject;
}

export interface DisconnectUserFromConsultantProject {
  disconnectUserFromConsultantProject: DisconnectUserFromConsultantProject_disconnectUserFromConsultantProject | null;
}

export interface DisconnectUserFromConsultantProjectVariables {
  consultantProjectUserId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyConsultantProjectUser
// ====================================================

export interface MyConsultantProjectUser_myConsultantProjectUser_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MyConsultantProjectUser_myConsultantProjectUser_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: MyConsultantProjectUser_myConsultantProjectUser_user_profileImage | null;
}

export interface MyConsultantProjectUser_myConsultantProjectUser_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface MyConsultantProjectUser_myConsultantProjectUser {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: MyConsultantProjectUser_myConsultantProjectUser_user;
  consultantProject: MyConsultantProjectUser_myConsultantProjectUser_consultantProject;
}

export interface MyConsultantProjectUser {
  myConsultantProjectUser: MyConsultantProjectUser_myConsultantProjectUser | null;
}

export interface MyConsultantProjectUserVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateConsultantProjectCustomer
// ====================================================

export interface UpdateConsultantProjectCustomer_updateConsultantProjectCustomer_customer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
}

export interface UpdateConsultantProjectCustomer_updateConsultantProjectCustomer {
  __typename: "ConsultantProject";
  id: string;
  customer: UpdateConsultantProjectCustomer_updateConsultantProjectCustomer_customer | null;
}

export interface UpdateConsultantProjectCustomer {
  updateConsultantProjectCustomer: UpdateConsultantProjectCustomer_updateConsultantProjectCustomer | null;
}

export interface UpdateConsultantProjectCustomerVariables {
  projectId: string;
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateConsultantProject
// ====================================================

export interface UpdateConsultantProject_updateConsultantProject_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface UpdateConsultantProject_updateConsultantProject_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface UpdateConsultantProject_updateConsultantProject_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateConsultantProject_updateConsultantProject_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: UpdateConsultantProject_updateConsultantProject_users_user_profileImage | null;
}

export interface UpdateConsultantProject_updateConsultantProject_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface UpdateConsultantProject_updateConsultantProject_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: UpdateConsultantProject_updateConsultantProject_users_user;
  consultantProject: UpdateConsultantProject_updateConsultantProject_users_consultantProject;
}

export interface UpdateConsultantProject_updateConsultantProject_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface UpdateConsultantProject_updateConsultantProject_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface UpdateConsultantProject_updateConsultantProject_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface UpdateConsultantProject_updateConsultantProject_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateConsultantProject_updateConsultantProject_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: UpdateConsultantProject_updateConsultantProject_customer_customerCompany_profileImage | null;
}

export interface UpdateConsultantProject_updateConsultantProject_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: UpdateConsultantProject_updateConsultantProject_customer_invoiceInformation | null;
  status: UpdateConsultantProject_updateConsultantProject_customer_status | null;
  companiesWithAccess: UpdateConsultantProject_updateConsultantProject_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: UpdateConsultantProject_updateConsultantProject_customer_customerCompany | null;
}

export interface UpdateConsultantProject_updateConsultantProject_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface UpdateConsultantProject_updateConsultantProject_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateConsultantProject_updateConsultantProject_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: UpdateConsultantProject_updateConsultantProject_responsible_profileImage | null;
}

export interface UpdateConsultantProject_updateConsultantProject_tags {
  __typename: "TagLabel";
  id: string;
}

export interface UpdateConsultantProject_updateConsultantProject_comments {
  __typename: "Comment";
  id: string;
}

export interface UpdateConsultantProject_updateConsultantProject_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface UpdateConsultantProject_updateConsultantProject_files {
  __typename: "File";
  id: string;
}

export interface UpdateConsultantProject_updateConsultantProject_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface UpdateConsultantProject_updateConsultantProject_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: UpdateConsultantProject_updateConsultantProject_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface UpdateConsultantProject_updateConsultantProject {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: UpdateConsultantProject_updateConsultantProject_invoiceInformation;
  status: UpdateConsultantProject_updateConsultantProject_status | null;
  users: UpdateConsultantProject_updateConsultantProject_users[];
  customer: UpdateConsultantProject_updateConsultantProject_customer | null;
  customerName: string | null;
  recruitment: UpdateConsultantProject_updateConsultantProject_recruitment | null;
  responsible: UpdateConsultantProject_updateConsultantProject_responsible | null;
  tags: UpdateConsultantProject_updateConsultantProject_tags[];
  comments: UpdateConsultantProject_updateConsultantProject_comments[];
  consultants: UpdateConsultantProject_updateConsultantProject_consultants[];
  files: UpdateConsultantProject_updateConsultantProject_files[];
  fieldExtensionValues: UpdateConsultantProject_updateConsultantProject_fieldExtensionValues[];
}

export interface UpdateConsultantProject {
  updateConsultantProject: UpdateConsultantProject_updateConsultantProject | null;
}

export interface UpdateConsultantProjectVariables {
  projectId: string;
  title?: string | null;
  description?: string | null;
  isActive?: boolean | null;
  note?: string | null;
  businessArea?: string | null;
  projectNumber?: string | null;
  connectResponsibleId?: string | null;
  disconnectResponsible?: boolean | null;
  statusId?: string | null;
  customerId?: string | null;
  activeFrom?: DateTime | null;
  activeTo?: DateTime | null;
  offerStatus?: OFFER_STATUS | null;
  startCost?: number | null;
  recruitmentCost?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateConsultantProjectRecruitment
// ====================================================

export interface UpdateConsultantProjectRecruitment_updateConsultantProjectRecruitment_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface UpdateConsultantProjectRecruitment_updateConsultantProjectRecruitment {
  __typename: "ConsultantProject";
  id: string;
  recruitment: UpdateConsultantProjectRecruitment_updateConsultantProjectRecruitment_recruitment | null;
}

export interface UpdateConsultantProjectRecruitment {
  updateConsultantProjectRecruitment: UpdateConsultantProjectRecruitment_updateConsultantProjectRecruitment | null;
}

export interface UpdateConsultantProjectRecruitmentVariables {
  projectId: string;
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateConsultantProjectRole
// ====================================================

export interface UpdateConsultantProjectRole_updateConsultantProjectRole {
  __typename: "ConsultantProjectRole";
  id: string;
}

export interface UpdateConsultantProjectRole {
  updateConsultantProjectRole: UpdateConsultantProjectRole_updateConsultantProjectRole | null;
}

export interface UpdateConsultantProjectRoleVariables {
  id: string;
  title?: string | null;
  hourlyRate?: number | null;
  hoursWorked?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateConsultantProjectUserPrivilegeLevel
// ====================================================

export interface UpdateConsultantProjectUserPrivilegeLevel_updateConsultantProjectUserPrivilegeLevel_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateConsultantProjectUserPrivilegeLevel_updateConsultantProjectUserPrivilegeLevel_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: UpdateConsultantProjectUserPrivilegeLevel_updateConsultantProjectUserPrivilegeLevel_user_profileImage | null;
}

export interface UpdateConsultantProjectUserPrivilegeLevel_updateConsultantProjectUserPrivilegeLevel_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface UpdateConsultantProjectUserPrivilegeLevel_updateConsultantProjectUserPrivilegeLevel {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: UpdateConsultantProjectUserPrivilegeLevel_updateConsultantProjectUserPrivilegeLevel_user;
  consultantProject: UpdateConsultantProjectUserPrivilegeLevel_updateConsultantProjectUserPrivilegeLevel_consultantProject;
}

export interface UpdateConsultantProjectUserPrivilegeLevel {
  updateConsultantProjectUserPrivilegeLevel: UpdateConsultantProjectUserPrivilegeLevel_updateConsultantProjectUserPrivilegeLevel | null;
}

export interface UpdateConsultantProjectUserPrivilegeLevelVariables {
  consultantProjectUserId: string;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateContactPerson
// ====================================================

export interface CreateContactPerson_createContactPerson {
  __typename: "ContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  role: string | null;
  description: string | null;
}

export interface CreateContactPerson {
  createContactPerson: CreateContactPerson_createContactPerson | null;
}

export interface CreateContactPersonVariables {
  customerId: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  role?: string | null;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteContactPerson
// ====================================================

export interface DeleteContactPerson_deleteContactPerson {
  __typename: "ContactPerson";
  id: string;
}

export interface DeleteContactPerson {
  deleteContactPerson: DeleteContactPerson_deleteContactPerson | null;
}

export interface DeleteContactPersonVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContactPerson
// ====================================================

export interface UpdateContactPerson_updateContactPerson {
  __typename: "ContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  role: string | null;
  description: string | null;
}

export interface UpdateContactPerson {
  updateContactPerson: UpdateContactPerson_updateContactPerson | null;
}

export interface UpdateContactPersonVariables {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  role?: string | null;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateContentBlock
// ====================================================

export interface GenerateContentBlock_generateContentBlock {
  __typename: "GeneratedBlock";
  title: string;
  text: string;
}

export interface GenerateContentBlock {
  generateContentBlock: GenerateContentBlock_generateContentBlock;
}

export interface GenerateContentBlockVariables {
  prompt: string;
  languageStyle: LANGUAGE_STYLE;
  approximatedLength: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateContentBlock
// ====================================================

export interface CreateContentBlock_createContentBlock_block {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
}

export interface CreateContentBlock_createContentBlock {
  __typename: "ConnectedContentBlock";
  id: string;
  block: CreateContentBlock_createContentBlock_block;
}

export interface CreateContentBlock {
  createContentBlock: CreateContentBlock_createContentBlock | null;
}

export interface CreateContentBlockVariables {
  companyId: string;
  type: CONTENT_BLOCK_TYPE;
  careerPageId?: string | null;
  adId?: string | null;
  pageTemplateId?: string | null;
  initialTitle?: string | null;
  initialText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CopyRecruitmentContactPersonToContentBlock
// ====================================================

export interface CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_coverImage_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_coverImage {
  __typename: "CoverImage";
  id: string;
  fullScreen: boolean | null;
  variant: COVER_IMAGE_VARIANT | null;
  fade: boolean | null;
  height: number | null;
  file: CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_coverImage_file | null;
}

export interface CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_contactPerson_profileImage {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
}

export interface CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_contactPerson {
  __typename: "AdContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  copyFromRecruitment: boolean | null;
  profileImage: CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_contactPerson_profileImage | null;
}

export interface CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_video {
  __typename: "Video";
  id: string;
  url: string | null;
  youtubeId: string | null;
  autoPlay: boolean | null;
  loop: boolean | null;
}

export interface CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
  title: string | null;
  text: string | null;
  url: string | null;
  copyShortPresentationFromCompany: boolean | null;
  file: CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_file | null;
  adMetaInfo: string[] | null;
  shareButtons: SOCIAL_MEDIA_SOURCE[] | null;
  coverImage: CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_coverImage | null;
  contactPerson: CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_contactPerson | null;
  video: CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock_video | null;
}

export interface CopyRecruitmentContactPersonToContentBlock {
  copyRecruitmentContactPersonToContentBlock: CopyRecruitmentContactPersonToContentBlock_copyRecruitmentContactPersonToContentBlock | null;
}

export interface CopyRecruitmentContactPersonToContentBlockVariables {
  contentBlockId: string;
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CopyUserToContentBlockContactPerson
// ====================================================

export interface CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_coverImage_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_coverImage {
  __typename: "CoverImage";
  id: string;
  fullScreen: boolean | null;
  variant: COVER_IMAGE_VARIANT | null;
  fade: boolean | null;
  height: number | null;
  file: CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_coverImage_file | null;
}

export interface CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_contactPerson_profileImage {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
}

export interface CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_contactPerson {
  __typename: "AdContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  copyFromRecruitment: boolean | null;
  profileImage: CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_contactPerson_profileImage | null;
}

export interface CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_video {
  __typename: "Video";
  id: string;
  url: string | null;
  youtubeId: string | null;
  autoPlay: boolean | null;
  loop: boolean | null;
}

export interface CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
  title: string | null;
  text: string | null;
  url: string | null;
  copyShortPresentationFromCompany: boolean | null;
  file: CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_file | null;
  adMetaInfo: string[] | null;
  shareButtons: SOCIAL_MEDIA_SOURCE[] | null;
  coverImage: CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_coverImage | null;
  contactPerson: CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_contactPerson | null;
  video: CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson_video | null;
}

export interface CopyUserToContentBlockContactPerson {
  copyUserToContentBlockContactPerson: CopyUserToContentBlockContactPerson_copyUserToContentBlockContactPerson | null;
}

export interface CopyUserToContentBlockContactPersonVariables {
  contentBlockId: string;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteContentBlock
// ====================================================

export interface DeleteContentBlock_deleteContentBlock {
  __typename: "ConnectedContentBlock";
  id: string;
}

export interface DeleteContentBlock {
  deleteContentBlock: DeleteContentBlock_deleteContentBlock | null;
}

export interface DeleteContentBlockVariables {
  id: string;
  careerPageId?: string | null;
  adId?: string | null;
  pageTemplateId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderContentBlocks
// ====================================================

export interface ReorderContentBlocks_reorderContentBlocks {
  __typename: "ConnectedContentBlock";
  id: string;
  listIndex: number;
}

export interface ReorderContentBlocks {
  reorderContentBlocks: ReorderContentBlocks_reorderContentBlocks[];
}

export interface ReorderContentBlocksVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContentBlock
// ====================================================

export interface UpdateContentBlock_updateContentBlock {
  __typename: "ContentBlock";
  id: string;
}

export interface UpdateContentBlock {
  updateContentBlock: UpdateContentBlock_updateContentBlock | null;
}

export interface UpdateContentBlockVariables {
  id: string;
  title?: string | null;
  text?: string | null;
  url?: string | null;
  videoUrl?: string | null;
  videoYoutubeId?: string | null;
  videoAutoPlay?: boolean | null;
  videoLoop?: boolean | null;
  coverImageFade?: boolean | null;
  coverImageHeight?: number | null;
  coverImageFullScreen?: boolean | null;
  coverImageVariant?: COVER_IMAGE_VARIANT | null;
  adMetaInfo?: string[] | null;
  copyShortPresentationFromCompany?: boolean | null;
  shareButtons?: SOCIAL_MEDIA_SOURCE[] | null;
  contactPerson?: AdContactPersonUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadContentBlockCoverImage
// ====================================================

export interface UploadContentBlockCoverImage_uploadContentBlockCoverImage {
  __typename: "ContentBlock";
  id: string;
}

export interface UploadContentBlockCoverImage {
  uploadContentBlockCoverImage: UploadContentBlockCoverImage_uploadContentBlockCoverImage | null;
}

export interface UploadContentBlockCoverImageVariables {
  id: string;
  file: Upload;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AuthorizeScriveAdmin
// ====================================================

export interface AuthorizeScriveAdmin {
  authorizeScriveAdmin: boolean;
}

export interface AuthorizeScriveAdminVariables {
  companyId: string;
  email: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ContractReminder
// ====================================================

export interface ContractReminder {
  contractReminder: boolean;
}

export interface ContractReminderVariables {
  id: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateContract
// ====================================================

export interface CreateContract_createContract {
  __typename: "Contract";
  id: string;
  title: string;
  createdAt: DateTime;
}

export interface CreateContract {
  createContract: CreateContract_createContract | null;
}

export interface CreateContractVariables {
  type: CONTRACT_TYPE;
  companyId: string;
  title: string;
  description?: string | null;
  language?: string | null;
  file: FileUploadInput;
  fileIsTemplate: boolean;
  applicantId?: string | null;
  applicationId?: string | null;
  customerId?: string | null;
  consultantId?: string | null;
  contactPersonIds?: string[] | null;
  customParties?: CustomContractPartyInput[] | null;
  userIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteContract
// ====================================================

export interface DeleteContract_deleteContract {
  __typename: "Contract";
  id: string;
}

export interface DeleteContract {
  deleteContract: DeleteContract_deleteContract | null;
}

export interface DeleteContractVariables {
  companyId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteScriveAuthAdmin
// ====================================================

export interface DeleteScriveAuthAdmin {
  deleteScriveAuthAdmin: boolean;
}

export interface DeleteScriveAuthAdminVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReauthorizeScriveAdmin
// ====================================================

export interface ReauthorizeScriveAdmin {
  reauthorizeScriveAdmin: boolean;
}

export interface ReauthorizeScriveAdminVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRecruitment
// ====================================================

export interface CreateRecruitment_createRecruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CreateRecruitment {
  createRecruitment: CreateRecruitment_createRecruitment | null;
}

export interface CreateRecruitmentVariables {
  companyId: string;
  title: string;
  adTitle?: string | null;
  identifiedNeedId?: string | null;
  consultantCustomerId?: string | null;
  useCustomerProfile?: boolean | null;
  recruitmentTemplateId?: string | null;
  recruitmentId?: string | null;
  internalJobDescription?: string | null;
  users?: AddedUserInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDepartment
// ====================================================

export interface CreateDepartment_createDepartment {
  __typename: "Department";
  id: string;
}

export interface CreateDepartment {
  createDepartment: CreateDepartment_createDepartment | null;
}

export interface CreateDepartmentVariables {
  companyId: string;
  name: string;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDepartment
// ====================================================

export interface DeleteDepartment_deleteDepartment {
  __typename: "Department";
  id: string;
}

export interface DeleteDepartment {
  deleteDepartment: DeleteDepartment_deleteDepartment | null;
}

export interface DeleteDepartmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDepartment
// ====================================================

export interface UpdateDepartment_updateDepartment {
  __typename: "Department";
  id: string;
  name: string;
  description: string | null;
}

export interface UpdateDepartment {
  updateDepartment: UpdateDepartment_updateDepartment | null;
}

export interface UpdateDepartmentVariables {
  id: string;
  name?: string | null;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveDocument
// ====================================================

export interface RemoveDocument_removeDocument {
  __typename: "Document";
  id: string;
}

export interface RemoveDocument {
  removeDocument: RemoveDocument_removeDocument | null;
}

export interface RemoveDocumentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadDocument
// ====================================================

export interface UploadDocument_uploadDocument {
  __typename: "Document";
  id: string;
}

export interface UploadDocument {
  uploadDocument: UploadDocument_uploadDocument | null;
}

export interface UploadDocumentVariables {
  applicantId?: string | null;
  applicationId?: string | null;
  consultantProjectId?: string | null;
  displayName: string;
  file: Upload;
  type: DOCUMENT_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveEventMatches
// ====================================================

export interface ApproveEventMatches_approveEventMatches {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
}

export interface ApproveEventMatches {
  approveEventMatches: ApproveEventMatches_approveEventMatches[];
}

export interface ApproveEventMatchesVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveOrRejectEventMatchesWithCertainMatchPercentage
// ====================================================

export interface ApproveOrRejectEventMatchesWithCertainMatchPercentage_approveOrRejectEventMatchesWithCertainMatchPercentage {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
}

export interface ApproveOrRejectEventMatchesWithCertainMatchPercentage {
  approveOrRejectEventMatchesWithCertainMatchPercentage: ApproveOrRejectEventMatchesWithCertainMatchPercentage_approveOrRejectEventMatchesWithCertainMatchPercentage[];
}

export interface ApproveOrRejectEventMatchesWithCertainMatchPercentageVariables {
  shouldApprove: boolean;
  talentProfileId: string;
  percentage: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateEventMatchesWithAI
// ====================================================

export interface CreateEventMatchesWithAI_createEventMatchesWithAI {
  __typename: "EventMatch";
  id: string;
}

export interface CreateEventMatchesWithAI {
  createEventMatchesWithAI: CreateEventMatchesWithAI_createEventMatchesWithAI[];
}

export interface CreateEventMatchesWithAIVariables {
  matchingEventId: string;
  talentProfileId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateEventMatchFromApplication
// ====================================================

export interface CreateEventMatchFromApplication_createEventMatchFromApplication {
  __typename: "EventMatch";
  id: string;
}

export interface CreateEventMatchFromApplication {
  createEventMatchFromApplication: CreateEventMatchFromApplication_createEventMatchFromApplication | null;
}

export interface CreateEventMatchFromApplicationVariables {
  matchingEventId: string;
  applicationId: string;
  talentProfileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EvaluateEventMatchBasedOnResume
// ====================================================

export interface EvaluateEventMatchBasedOnResume_evaluateEventMatchBasedOnResume {
  __typename: "EventMatch";
  id: string;
  matchPercentageFromChatGPT: number | null;
}

export interface EvaluateEventMatchBasedOnResume {
  evaluateEventMatchBasedOnResume: EvaluateEventMatchBasedOnResume_evaluateEventMatchBasedOnResume | null;
}

export interface EvaluateEventMatchBasedOnResumeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ParticipantApproveEventMatch
// ====================================================

export interface ParticipantApproveEventMatch_participantApproveEventMatch {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
}

export interface ParticipantApproveEventMatch {
  participantApproveEventMatch: ParticipantApproveEventMatch_participantApproveEventMatch | null;
}

export interface ParticipantApproveEventMatchVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ParticipantRejectEventMatch
// ====================================================

export interface ParticipantRejectEventMatch_participantRejectEventMatch {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
}

export interface ParticipantRejectEventMatch {
  participantRejectEventMatch: ParticipantRejectEventMatch_participantRejectEventMatch | null;
}

export interface ParticipantRejectEventMatchVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RegretRejectEventMatch
// ====================================================

export interface RegretRejectEventMatch_regretRejectEventMatch {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
}

export interface RegretRejectEventMatch {
  regretRejectEventMatch: RegretRejectEventMatch_regretRejectEventMatch | null;
}

export interface RegretRejectEventMatchVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RejectEventMatches
// ====================================================

export interface RejectEventMatches_rejectEventMatches {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
}

export interface RejectEventMatches {
  rejectEventMatches: RejectEventMatches_rejectEventMatches[];
}

export interface RejectEventMatchesVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveApprovedEventMatch
// ====================================================

export interface RemoveApprovedEventMatch_removeApprovedEventMatch {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
}

export interface RemoveApprovedEventMatch {
  removeApprovedEventMatch: RemoveApprovedEventMatch_removeApprovedEventMatch | null;
}

export interface RemoveApprovedEventMatchVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendEventMatchesInterviewBookingReminders
// ====================================================

export interface SendEventMatchesInterviewBookingReminders_sendEventMatchesInterviewBookingReminders {
  __typename: "EventMatch";
  id: string;
  interviewBookingReminders: DateTime[];
}

export interface SendEventMatchesInterviewBookingReminders {
  sendEventMatchesInterviewBookingReminders: SendEventMatchesInterviewBookingReminders_sendEventMatchesInterviewBookingReminders[];
}

export interface SendEventMatchesInterviewBookingRemindersVariables {
  eventMatchIds: string[];
  message: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendEventMatchInterviewBookingReminder
// ====================================================

export interface SendEventMatchInterviewBookingReminder_sendEventMatchInterviewBookingReminder {
  __typename: "EventMatch";
  id: string;
  interviewBookingReminders: DateTime[];
}

export interface SendEventMatchInterviewBookingReminder {
  sendEventMatchInterviewBookingReminder: SendEventMatchInterviewBookingReminder_sendEventMatchInterviewBookingReminder | null;
}

export interface SendEventMatchInterviewBookingReminderVariables {
  id: string;
  message: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFieldExtension
// ====================================================

export interface CreateFieldExtension_createFieldExtension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface CreateFieldExtension {
  createFieldExtension: CreateFieldExtension_createFieldExtension | null;
}

export interface CreateFieldExtensionVariables {
  title: string;
  description?: string | null;
  companyId: string;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFieldExtension
// ====================================================

export interface DeleteFieldExtension_deleteFieldExtension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface DeleteFieldExtension {
  deleteFieldExtension: DeleteFieldExtension_deleteFieldExtension | null;
}

export interface DeleteFieldExtensionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetFieldExtensionValue
// ====================================================

export interface SetFieldExtensionValue_setFieldExtensionValue_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface SetFieldExtensionValue_setFieldExtensionValue {
  __typename: "FieldExtensionValue";
  id: string;
  extension: SetFieldExtensionValue_setFieldExtensionValue_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface SetFieldExtensionValue {
  setFieldExtensionValue: SetFieldExtensionValue_setFieldExtensionValue | null;
}

export interface SetFieldExtensionValueVariables {
  extensionId: string;
  consultantProjectId?: string | null;
  consultantId?: string | null;
  text?: string | null;
  number?: number | null;
  array?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFieldExtension
// ====================================================

export interface UpdateFieldExtension_updateFieldExtension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface UpdateFieldExtension {
  updateFieldExtension: UpdateFieldExtension_updateFieldExtension | null;
}

export interface UpdateFieldExtensionVariables {
  id: string;
  title?: string | null;
  description?: string | null;
  valueType?: FIELD_EXTENSION_VALUE_TYPE | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFileTemplate
// ====================================================

export interface CreateFileTemplate_createFileTemplate_file {
  __typename: "File";
  id: string;
}

export interface CreateFileTemplate_createFileTemplate {
  __typename: "FileTemplate";
  id: string;
  name: string;
  description: string | null;
  file: CreateFileTemplate_createFileTemplate_file;
}

export interface CreateFileTemplate {
  createFileTemplate: CreateFileTemplate_createFileTemplate | null;
}

export interface CreateFileTemplateVariables {
  companyId: string;
  name: string;
  description?: string | null;
  file: FileUploadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFileTemplate
// ====================================================

export interface UpdateFileTemplate_updateFileTemplate_file {
  __typename: "File";
  id: string;
}

export interface UpdateFileTemplate_updateFileTemplate {
  __typename: "FileTemplate";
  id: string;
  name: string;
  description: string | null;
  file: UpdateFileTemplate_updateFileTemplate_file;
}

export interface UpdateFileTemplate {
  updateFileTemplate: UpdateFileTemplate_updateFileTemplate | null;
}

export interface UpdateFileTemplateVariables {
  id: string;
  name?: string | null;
  description?: string | null;
  file?: FileUploadInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFileTemplate
// ====================================================

export interface DeleteFileTemplate_deleteFileTemplate {
  __typename: "FileTemplate";
  id: string;
}

export interface DeleteFileTemplate {
  deleteFileTemplate: DeleteFileTemplate_deleteFileTemplate | null;
}

export interface DeleteFileTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddReferenceParticipant
// ====================================================

export interface AddReferenceParticipant_addReferenceParticipant {
  __typename: "FormParticipant";
  id: string;
}

export interface AddReferenceParticipant {
  addReferenceParticipant: AddReferenceParticipant_addReferenceParticipant | null;
}

export interface AddReferenceParticipantVariables {
  applicationId: string;
  formId: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  phoneNumber?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationForm
// ====================================================

export interface ApplicationForm_recruitment_form_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ApplicationForm_recruitment_form_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface ApplicationForm_recruitment_form_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: ApplicationForm_recruitment_form_participants_application_companyApplicant | null;
}

export interface ApplicationForm_recruitment_form_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: ApplicationForm_recruitment_form_participants_application | null;
}

export interface ApplicationForm_recruitment_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ApplicationForm_recruitment_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: ApplicationForm_recruitment_form_questions_matchingEventProfile | null;
}

export interface ApplicationForm_recruitment_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface ApplicationForm_recruitment_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface ApplicationForm_recruitment_form {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: ApplicationForm_recruitment_form_companiesWithAccess[];
  participants: ApplicationForm_recruitment_form_participants[];
  questions: ApplicationForm_recruitment_form_questions[];
  referenceForm: ApplicationForm_recruitment_form_referenceForm | null;
  screeningForm: ApplicationForm_recruitment_form_screeningForm | null;
}

export interface ApplicationForm_recruitment {
  __typename: "Recruitment";
  id: string;
  form: ApplicationForm_recruitment_form | null;
}

export interface ApplicationForm {
  recruitment: ApplicationForm_recruitment | null;
}

export interface ApplicationFormVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationFormQuestionAnswers
// ====================================================

export interface ApplicationFormQuestionAnswers_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface ApplicationFormQuestionAnswers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: ApplicationFormQuestionAnswers_application_applicationFormAnswers_question_matchingEventProfile | null;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerCompetences_competence;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers_application {
  __typename: "Application";
  id: string;
  recruitment: ApplicationFormQuestionAnswers_application_applicationFormAnswers_application_recruitment;
}

export interface ApplicationFormQuestionAnswers_application_applicationFormAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: ApplicationFormQuestionAnswers_application_applicationFormAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerFile | null;
  answerDocument: ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerDocument | null;
  answerDocuments: ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerEducations[] | null;
  answerWorkExperiences: ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerWorkExperiences[] | null;
  answerPersonalData: ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerPersonalData | null;
  answerCompetences: ApplicationFormQuestionAnswers_application_applicationFormAnswers_answerCompetences[] | null;
  application: ApplicationFormQuestionAnswers_application_applicationFormAnswers_application | null;
}

export interface ApplicationFormQuestionAnswers_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  companyApplicant: ApplicationFormQuestionAnswers_application_companyApplicant | null;
  recruitment: ApplicationFormQuestionAnswers_application_recruitment;
  applicationFormAnswers: ApplicationFormQuestionAnswers_application_applicationFormAnswers[];
}

export interface ApplicationFormQuestionAnswers {
  application: ApplicationFormQuestionAnswers_application | null;
}

export interface ApplicationFormQuestionAnswersVariables {
  applicationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeFormShareAccessStatus
// ====================================================

export interface ChangeFormShareAccessStatus_changeFormShareAccessStatus {
  __typename: "Form";
  id: string;
}

export interface ChangeFormShareAccessStatus {
  changeFormShareAccessStatus: ChangeFormShareAccessStatus_changeFormShareAccessStatus | null;
}

export interface ChangeFormShareAccessStatusVariables {
  formId: string;
  shareAccessStatus: TEMPLATE_SHARE_STATUS;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateForm
// ====================================================

export interface CreateForm_createForm {
  __typename: "Form";
  id: string;
}

export interface CreateForm {
  createForm: CreateForm_createForm | null;
}

export interface CreateFormVariables {
  companyId: string;
  title: string;
  description?: string | null;
  type: FORM_TYPE;
  recruitmentId?: string | null;
  status: FORM_STATUS;
  applications?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFormQuestion
// ====================================================

export interface CreateFormQuestion_createFormQuestion {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  description: string | null;
  language: string | null;
  required: boolean | null;
  type: FORM_QUESTION_TYPE;
}

export interface CreateFormQuestion {
  createFormQuestion: CreateFormQuestion_createFormQuestion | null;
}

export interface CreateFormQuestionVariables {
  formId: string;
  question?: string | null;
  type: FORM_QUESTION_TYPE;
  title?: string | null;
  language?: string | null;
  description?: string | null;
  required?: boolean | null;
  options?: string[] | null;
  answerSuffix?: string | null;
  matchingEventProfileId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateReferenceForm
// ====================================================

export interface CreateReferenceForm_createReferenceForm {
  __typename: "ReferenceForm";
  id: string;
}

export interface CreateReferenceForm {
  createReferenceForm: CreateReferenceForm_createReferenceForm;
}

export interface CreateReferenceFormVariables {
  companyId: string;
  title: string;
  externalTitle?: string | null;
  externalDescription?: string | null;
  recruitmentId?: string | null;
  formTemplateId?: string | null;
  applications?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteForm
// ====================================================

export interface DeleteForm_deleteForm {
  __typename: "Form";
  id: string;
}

export interface DeleteForm {
  deleteForm: DeleteForm_deleteForm | null;
}

export interface DeleteFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFormQuestion
// ====================================================

export interface DeleteFormQuestion_deleteFormQuestion {
  __typename: "FormQuestion";
  id: string;
}

export interface DeleteFormQuestion {
  deleteFormQuestion: DeleteFormQuestion_deleteFormQuestion | null;
}

export interface DeleteFormQuestionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteReferenceFormApplication
// ====================================================

export interface DeleteReferenceFormApplication_deleteReferenceFormApplication {
  __typename: "ReferenceForm";
  id: string;
}

export interface DeleteReferenceFormApplication {
  deleteReferenceFormApplication: DeleteReferenceFormApplication_deleteReferenceFormApplication | null;
}

export interface DeleteReferenceFormApplicationVariables {
  formId: string;
  applicationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteReferenceParticipant
// ====================================================

export interface DeleteReferenceParticipant_deleteReferenceParticipant {
  __typename: "FormParticipant";
  id: string;
}

export interface DeleteReferenceParticipant {
  deleteReferenceParticipant: DeleteReferenceParticipant_deleteReferenceParticipant | null;
}

export interface DeleteReferenceParticipantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EvaluateFormBasedOnText
// ====================================================

export interface EvaluateFormBasedOnText_evaluateFormBasedOnText {
  __typename: "Form";
  id: string;
}

export interface EvaluateFormBasedOnText {
  evaluateFormBasedOnText: EvaluateFormBasedOnText_evaluateFormBasedOnText | null;
}

export interface EvaluateFormBasedOnTextVariables {
  formId: string;
  formQuestionIds?: string[] | null;
  text: string;
  talentProfileId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoadFormTemplate
// ====================================================

export interface LoadFormTemplate_loadFormTemplate {
  __typename: "Form";
  id: string;
}

export interface LoadFormTemplate {
  loadFormTemplate: LoadFormTemplate_loadFormTemplate | null;
}

export interface LoadFormTemplateVariables {
  formId: string;
  templateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderFormQuestions
// ====================================================

export interface ReorderFormQuestions_reorderFormQuestions_questions {
  __typename: "FormQuestion";
  id: string;
  listIndex: number;
}

export interface ReorderFormQuestions_reorderFormQuestions {
  __typename: "Form";
  id: string;
  questions: ReorderFormQuestions_reorderFormQuestions_questions[];
}

export interface ReorderFormQuestions {
  reorderFormQuestions: ReorderFormQuestions_reorderFormQuestions | null;
}

export interface ReorderFormQuestionsVariables {
  formId: string;
  questionIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestReferences
// ====================================================

export interface RequestReferences_requestReferences {
  __typename: "AddReferencesInvitation";
  id: string;
}

export interface RequestReferences {
  requestReferences: RequestReferences_requestReferences[];
}

export interface RequestReferencesVariables {
  formId: string;
  applicationIds: string[];
  messageFromRecruiter?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveFormTemplate
// ====================================================

export interface SaveFormTemplate_saveFormTemplate {
  __typename: "Form";
  id: string;
}

export interface SaveFormTemplate {
  saveFormTemplate: SaveFormTemplate_saveFormTemplate | null;
}

export interface SaveFormTemplateVariables {
  companyId: string;
  formId: string;
  type: FORM_TYPE;
  title?: string | null;
  replaceFormId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendFormToParticipants
// ====================================================

export interface SendFormToParticipants_sendFormToParticipants {
  __typename: "FormParticipant";
  id: string;
  emailNotifiers: DateTime[];
  phoneNotifiers: DateTime[];
}

export interface SendFormToParticipants {
  sendFormToParticipants: SendFormToParticipants_sendFormToParticipants[];
}

export interface SendFormToParticipantsVariables {
  participantIds: string[];
  formId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShareFormWithCompanies
// ====================================================

export interface ShareFormWithCompanies_shareFormWithCompanies {
  __typename: "Form";
  id: string;
}

export interface ShareFormWithCompanies {
  shareFormWithCompanies: ShareFormWithCompanies_shareFormWithCompanies | null;
}

export interface ShareFormWithCompaniesVariables {
  formId: string;
  companyIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateForm
// ====================================================

export interface UpdateForm_updateForm_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface UpdateForm_updateForm_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface UpdateForm_updateForm_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: UpdateForm_updateForm_participants_application_companyApplicant | null;
}

export interface UpdateForm_updateForm_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: UpdateForm_updateForm_participants_application | null;
}

export interface UpdateForm_updateForm_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface UpdateForm_updateForm_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: UpdateForm_updateForm_questions_matchingEventProfile | null;
}

export interface UpdateForm_updateForm_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface UpdateForm_updateForm_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface UpdateForm_updateForm {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: UpdateForm_updateForm_companiesWithAccess[];
  participants: UpdateForm_updateForm_participants[];
  questions: UpdateForm_updateForm_questions[];
  referenceForm: UpdateForm_updateForm_referenceForm | null;
  screeningForm: UpdateForm_updateForm_screeningForm | null;
}

export interface UpdateForm {
  updateForm: UpdateForm_updateForm | null;
}

export interface UpdateFormVariables {
  id: string;
  title?: string | null;
  description?: string | null;
  submitButtonText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormQuestion
// ====================================================

export interface UpdateFormQuestion_updateFormQuestion {
  __typename: "FormQuestion";
  id: string;
}

export interface UpdateFormQuestion {
  updateFormQuestion: UpdateFormQuestion_updateFormQuestion | null;
}

export interface UpdateFormQuestionVariables {
  id: string;
  question?: string | null;
  title?: string | null;
  description?: string | null;
  type?: FORM_QUESTION_TYPE | null;
  required?: boolean | null;
  options?: string[] | null;
  answerSuffix?: string | null;
  matchingEventProfileId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateReferenceFormApplications
// ====================================================

export interface UpdateReferenceFormApplications_updateReferenceFormApplications {
  __typename: "ReferenceForm";
  id: string;
}

export interface UpdateReferenceFormApplications {
  updateReferenceFormApplications: UpdateReferenceFormApplications_updateReferenceFormApplications | null;
}

export interface UpdateReferenceFormApplicationsVariables {
  formId: string;
  addedApplications: string[];
  deletedApplications: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EvaluateFormQuestionAnswerForTalentProfile
// ====================================================

export interface EvaluateFormQuestionAnswerForTalentProfile_evaluateFormQuestionAnswerForTalentProfile {
  __typename: "FormQuestionAnswer";
  id: string;
}

export interface EvaluateFormQuestionAnswerForTalentProfile {
  evaluateFormQuestionAnswerForTalentProfile: EvaluateFormQuestionAnswerForTalentProfile_evaluateFormQuestionAnswerForTalentProfile | null;
}

export interface EvaluateFormQuestionAnswerForTalentProfileVariables {
  talentProfileId: string;
  questionId: string;
  answer: EditedFormQuestionAnswerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EvaluateFormQuestionAnswer
// ====================================================

export interface EvaluateFormQuestionAnswer_evaluateFormQuestionAnswer {
  __typename: "FormQuestionAnswer";
  id: string;
}

export interface EvaluateFormQuestionAnswer {
  evaluateFormQuestionAnswer: EvaluateFormQuestionAnswer_evaluateFormQuestionAnswer | null;
}

export interface EvaluateFormQuestionAnswerVariables {
  companyId: string;
  questionId: string;
  answer: EditedFormQuestionAnswerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormQuestionAnswerEvaluationsForForm
// ====================================================

export interface UpdateFormQuestionAnswerEvaluationsForForm_updateFormQuestionAnswerEvaluationsForForm_formQuestion {
  __typename: "FormQuestion";
  id: string;
}

export interface UpdateFormQuestionAnswerEvaluationsForForm_updateFormQuestionAnswerEvaluationsForForm {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  formQuestion: UpdateFormQuestionAnswerEvaluationsForForm_updateFormQuestionAnswerEvaluationsForForm_formQuestion | null;
}

export interface UpdateFormQuestionAnswerEvaluationsForForm {
  updateFormQuestionAnswerEvaluationsForForm: UpdateFormQuestionAnswerEvaluationsForForm_updateFormQuestionAnswerEvaluationsForForm | null;
}

export interface UpdateFormQuestionAnswerEvaluationsForFormVariables {
  formQuestionId: string;
  formId: string;
  significance: REQUIREMENT_SIGNIFICANCE;
  option?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormQuestionAnswerEvaluationsForTalentProfile
// ====================================================

export interface UpdateFormQuestionAnswerEvaluationsForTalentProfile_updateFormQuestionAnswerEvaluationsForTalentProfile_formQuestion {
  __typename: "FormQuestion";
  id: string;
}

export interface UpdateFormQuestionAnswerEvaluationsForTalentProfile_updateFormQuestionAnswerEvaluationsForTalentProfile {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  formQuestion: UpdateFormQuestionAnswerEvaluationsForTalentProfile_updateFormQuestionAnswerEvaluationsForTalentProfile_formQuestion | null;
}

export interface UpdateFormQuestionAnswerEvaluationsForTalentProfile {
  updateFormQuestionAnswerEvaluationsForTalentProfile: UpdateFormQuestionAnswerEvaluationsForTalentProfile_updateFormQuestionAnswerEvaluationsForTalentProfile | null;
}

export interface UpdateFormQuestionAnswerEvaluationsForTalentProfileVariables {
  formQuestionId: string;
  talentProfileId: string;
  significance: REQUIREMENT_SIGNIFICANCE;
  option?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddIdentifiedNeedVoter
// ====================================================

export interface AddIdentifiedNeedVoter_addIdentifiedNeedVoter {
  __typename: "IdentifiedNeed";
  id: string;
}

export interface AddIdentifiedNeedVoter {
  addIdentifiedNeedVoter: AddIdentifiedNeedVoter_addIdentifiedNeedVoter | null;
}

export interface AddIdentifiedNeedVoterVariables {
  identifiedNeedId: string;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteIdentifiedNeedVoter
// ====================================================

export interface DeleteIdentifiedNeedVoter_deleteIdentifiedNeedVoter {
  __typename: "IdentifiedNeedVote";
  id: string;
  approved: boolean | null;
}

export interface DeleteIdentifiedNeedVoter {
  deleteIdentifiedNeedVoter: DeleteIdentifiedNeedVoter_deleteIdentifiedNeedVoter | null;
}

export interface DeleteIdentifiedNeedVoterVariables {
  voteId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteIdentifiedNeed
// ====================================================

export interface DeleteIdentifiedNeed_deleteIdentifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
}

export interface DeleteIdentifiedNeed {
  deleteIdentifiedNeed: DeleteIdentifiedNeed_deleteIdentifiedNeed | null;
}

export interface DeleteIdentifiedNeedVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditIdentifiedNeed
// ====================================================

export interface EditIdentifiedNeed_editIdentifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
}

export interface EditIdentifiedNeed {
  editIdentifiedNeed: EditIdentifiedNeed_editIdentifiedNeed | null;
}

export interface EditIdentifiedNeedVariables {
  id: string;
  title?: string | null;
  description?: string | null;
  addedParticipants?: string[] | null;
  removedParticipants?: string[] | null;
  status?: IDENTIFIED_NEED_STATUS | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VoteIdentifiedNeed
// ====================================================

export interface VoteIdentifiedNeed_voteIdentifiedNeed {
  __typename: "IdentifiedNeedVote";
  id: string;
}

export interface VoteIdentifiedNeed {
  voteIdentifiedNeed: VoteIdentifiedNeed_voteIdentifiedNeed | null;
}

export interface VoteIdentifiedNeedVariables {
  id: string;
  approved?: boolean | null;
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateIdentifiedNeedForm
// ====================================================

export interface CreateIdentifiedNeedForm_createIdentifiedNeedForm {
  __typename: "IdentifiedNeedForm";
  id: string;
}

export interface CreateIdentifiedNeedForm {
  createIdentifiedNeedForm: CreateIdentifiedNeedForm_createIdentifiedNeedForm | null;
}

export interface CreateIdentifiedNeedFormVariables {
  companyId: string;
  title: string;
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
  identifiedNeedId?: string | null;
  formTemplateId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateIdentifiedNeed
// ====================================================

export interface CreateIdentifiedNeed_createIdentifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
}

export interface CreateIdentifiedNeed {
  createIdentifiedNeed: CreateIdentifiedNeed_createIdentifiedNeed | null;
}

export interface CreateIdentifiedNeedVariables {
  companyId: string;
  title: string;
  formTemplateId?: string | null;
  addedParticipants: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteIdentifiedNeedForm
// ====================================================

export interface DeleteIdentifiedNeedForm_deleteIdentifiedNeedForm {
  __typename: "IdentifiedNeedForm";
  id: string;
}

export interface DeleteIdentifiedNeedForm {
  deleteIdentifiedNeedForm: DeleteIdentifiedNeedForm_deleteIdentifiedNeedForm | null;
}

export interface DeleteIdentifiedNeedFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateIdentifiedNeedFormCompanyAnswers
// ====================================================

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_participants_application_companyApplicant | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_participants_application | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_questions_matchingEventProfile | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_companiesWithAccess[];
  participants: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_participants[];
  questions: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_questions[];
  referenceForm: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_referenceForm | null;
  screeningForm: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form_screeningForm | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_question_matchingEventProfile | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerCompetences_competence;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_application {
  __typename: "Application";
  id: string;
  recruitment: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_application_recruitment;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerFile | null;
  answerDocument: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerDocument | null;
  answerDocuments: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerEducations[] | null;
  answerWorkExperiences: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerWorkExperiences[] | null;
  answerPersonalData: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerPersonalData | null;
  answerCompetences: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_answerCompetences[] | null;
  application: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers_application | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_identifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
  canEdit: boolean | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers {
  __typename: "IdentifiedNeedForm";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  form: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_form;
  companyAnswers: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_companyAnswers[];
  identifiedNeed: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers_identifiedNeed | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswers {
  updateIdentifiedNeedFormCompanyAnswers: UpdateIdentifiedNeedFormCompanyAnswers_updateIdentifiedNeedFormCompanyAnswers | null;
}

export interface UpdateIdentifiedNeedFormCompanyAnswersVariables {
  id: string;
  answers: EditedFormQuestionAnswerInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateIdentifiedNeedForm
// ====================================================

export interface UpdateIdentifiedNeedForm_updateIdentifiedNeedForm_form {
  __typename: "Form";
  id: string;
  title: string | null;
  description: string | null;
}

export interface UpdateIdentifiedNeedForm_updateIdentifiedNeedForm {
  __typename: "IdentifiedNeedForm";
  id: string;
  title: string | null;
  form: UpdateIdentifiedNeedForm_updateIdentifiedNeedForm_form;
}

export interface UpdateIdentifiedNeedForm {
  updateIdentifiedNeedForm: UpdateIdentifiedNeedForm_updateIdentifiedNeedForm | null;
}

export interface UpdateIdentifiedNeedFormVariables {
  id: string;
  title?: string | null;
  externalTitle?: string | null;
  externalDescription?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInterview
// ====================================================

export interface CreateInterview_createInterview_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CreateInterview_createInterview_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CreateInterview_createInterview_users_profileImage | null;
}

export interface CreateInterview_createInterview_interviewGuide {
  __typename: "InterviewGuide";
  id: string;
}

export interface CreateInterview_createInterview {
  __typename: "Interview";
  id: string;
  title: string;
  description: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  users: CreateInterview_createInterview_users[];
  interviewGuide: CreateInterview_createInterview_interviewGuide | null;
}

export interface CreateInterview {
  createInterview: CreateInterview_createInterview | null;
}

export interface CreateInterviewVariables {
  title: string;
  applicantId?: string | null;
  applicationId?: string | null;
  interviewGuideTemplateId?: string | null;
  users?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInterview
// ====================================================

export interface DeleteInterview_deleteInterview {
  __typename: "Interview";
  id: string;
}

export interface DeleteInterview {
  deleteInterview: DeleteInterview_deleteInterview | null;
}

export interface DeleteInterviewVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInterviewInfo
// ====================================================

export interface UpdateInterviewInfo_updateInterviewInfo {
  __typename: "Interview";
  id: string;
}

export interface UpdateInterviewInfo {
  updateInterviewInfo: UpdateInterviewInfo_updateInterviewInfo | null;
}

export interface UpdateInterviewInfoVariables {
  id: string;
  title?: string | null;
  description?: string | null;
  addedUsers?: string[] | null;
  removedUsers?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInterviewBookingGroup
// ====================================================

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_application_recruitment;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_user_profileImage | null;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_eventMatch | null;
  application: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_application | null;
  user: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_recruitment | null;
  company: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_company;
  attendees: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_attendees[];
  interviewBookingGroup: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: CreateInterviewBookingGroup_createInterviewBookingGroup_users_profileImage | null;
}

export interface CreateInterviewBookingGroup_createInterviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string;
  defaultTitle: string | null;
  defaultDescription: string | null;
  defaultLocation: string | null;
  defaultUrl: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: CreateInterviewBookingGroup_createInterviewBookingGroup_companiesWithAccess[];
  company: CreateInterviewBookingGroup_createInterviewBookingGroup_company;
  recruitments: CreateInterviewBookingGroup_createInterviewBookingGroup_recruitments[];
  bookableInterviews: CreateInterviewBookingGroup_createInterviewBookingGroup_bookableInterviews[];
  users: CreateInterviewBookingGroup_createInterviewBookingGroup_users[];
}

export interface CreateInterviewBookingGroup {
  createInterviewBookingGroup: CreateInterviewBookingGroup_createInterviewBookingGroup | null;
}

export interface CreateInterviewBookingGroupVariables {
  companyId: string;
  title: string;
  description: string;
  defaultTitle?: string | null;
  defaultLocation?: string | null;
  defaultDescription?: string | null;
  defaultUrl?: string | null;
  userIds: string[];
  recruitmentIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInterviewBookingGroup
// ====================================================

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_application_recruitment;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_user_profileImage | null;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_eventMatch | null;
  application: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_application | null;
  user: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_recruitment | null;
  company: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_company;
  attendees: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_attendees[];
  interviewBookingGroup: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: UpdateInterviewBookingGroup_updateInterviewBookingGroup_users_profileImage | null;
}

export interface UpdateInterviewBookingGroup_updateInterviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string;
  defaultTitle: string | null;
  defaultDescription: string | null;
  defaultLocation: string | null;
  defaultUrl: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: UpdateInterviewBookingGroup_updateInterviewBookingGroup_companiesWithAccess[];
  company: UpdateInterviewBookingGroup_updateInterviewBookingGroup_company;
  recruitments: UpdateInterviewBookingGroup_updateInterviewBookingGroup_recruitments[];
  bookableInterviews: UpdateInterviewBookingGroup_updateInterviewBookingGroup_bookableInterviews[];
  users: UpdateInterviewBookingGroup_updateInterviewBookingGroup_users[];
}

export interface UpdateInterviewBookingGroup {
  updateInterviewBookingGroup: UpdateInterviewBookingGroup_updateInterviewBookingGroup | null;
}

export interface UpdateInterviewBookingGroupVariables {
  id: string;
  title?: string | null;
  description?: string | null;
  defaultTitle?: string | null;
  defaultLocation?: string | null;
  defaultDescription?: string | null;
  defaultUrl?: string | null;
  addedUserIds?: string[] | null;
  deletedUserIds?: string[] | null;
  addedRecruitmentIds?: string[] | null;
  deletedRecruitmentIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBookableInterviews
// ====================================================

export interface CreateBookableInterviews_createBookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CreateBookableInterviews_createBookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CreateBookableInterviews_createBookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CreateBookableInterviews_createBookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: CreateBookableInterviews_createBookableInterviews_attendees_eventMatch_talentProfile;
}

export interface CreateBookableInterviews_createBookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CreateBookableInterviews_createBookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: CreateBookableInterviews_createBookableInterviews_attendees_application_recruitment;
}

export interface CreateBookableInterviews_createBookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CreateBookableInterviews_createBookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CreateBookableInterviews_createBookableInterviews_attendees_user_profileImage | null;
}

export interface CreateBookableInterviews_createBookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: CreateBookableInterviews_createBookableInterviews_attendees_eventMatch | null;
  application: CreateBookableInterviews_createBookableInterviews_attendees_application | null;
  user: CreateBookableInterviews_createBookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface CreateBookableInterviews_createBookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface CreateBookableInterviews_createBookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CreateBookableInterviews_createBookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: CreateBookableInterviews_createBookableInterviews_recruitment | null;
  company: CreateBookableInterviews_createBookableInterviews_company;
  attendees: CreateBookableInterviews_createBookableInterviews_attendees[];
  interviewBookingGroup: CreateBookableInterviews_createBookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: CreateBookableInterviews_createBookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface CreateBookableInterviews {
  createBookableInterviews: CreateBookableInterviews_createBookableInterviews[];
}

export interface CreateBookableInterviewsVariables {
  companyId: string;
  bookingGroupId: string;
  times: CalendarEventTimeInput[];
  title?: string | null;
  description?: string | null;
  location?: string | null;
  url?: string | null;
  userIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteApplicationsToInterviewBookingGroup
// ====================================================

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_application_recruitment;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_user_profileImage | null;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_eventMatch | null;
  application: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_application | null;
  user: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_recruitment | null;
  company: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_company;
  attendees: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees[];
  interviewBookingGroup: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_users_profileImage | null;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string;
  defaultTitle: string | null;
  defaultDescription: string | null;
  defaultLocation: string | null;
  defaultUrl: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_companiesWithAccess[];
  company: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_company;
  recruitments: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_recruitments[];
  bookableInterviews: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_bookableInterviews[];
  users: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup_users[];
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_application_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_application_recruitment_company;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_application_companyApplicant | null;
  recruitment: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_application_recruitment;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_eventMatch_talentProfile;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_application_recruitment;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_user_profileImage | null;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_eventMatch | null;
  application: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_application | null;
  user: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_recruitment | null;
  company: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_company;
  attendees: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_attendees[];
  interviewBookingGroup: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_interviewBookingGroup | null;
  matchingEventTalentProfiles: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  createdAt: DateTime;
  interviewBookingGroup: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_interviewBookingGroup;
  application: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_application;
  bookedInterview: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup_bookedInterview | null;
  notifiers: DateTime[];
}

export interface InviteApplicationsToInterviewBookingGroup {
  inviteApplicationsToInterviewBookingGroup: InviteApplicationsToInterviewBookingGroup_inviteApplicationsToInterviewBookingGroup[];
}

export interface InviteApplicationsToInterviewBookingGroupVariables {
  applicationIds: string[];
  interviewBookingGroupId: string;
  sendInvite?: boolean | null;
  messageFromRecruiter?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInterviewBookingGroupApplication
// ====================================================

export interface DeleteInterviewBookingGroupApplication_deleteInterviewBookingGroupApplication {
  __typename: "InterviewBookingGroupApplication";
  id: string;
}

export interface DeleteInterviewBookingGroupApplication {
  deleteInterviewBookingGroupApplication: DeleteInterviewBookingGroupApplication_deleteInterviewBookingGroupApplication | null;
}

export interface DeleteInterviewBookingGroupApplicationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendInterviewBookingGroupInvitations
// ====================================================

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_application_recruitment;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_user_profileImage | null;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_eventMatch | null;
  application: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_application | null;
  user: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_recruitment | null;
  company: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_company;
  attendees: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_attendees[];
  interviewBookingGroup: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_users_profileImage | null;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string;
  defaultTitle: string | null;
  defaultDescription: string | null;
  defaultLocation: string | null;
  defaultUrl: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_companiesWithAccess[];
  company: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_company;
  recruitments: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_recruitments[];
  bookableInterviews: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_bookableInterviews[];
  users: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup_users[];
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_application_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_application_recruitment_company;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_application_companyApplicant | null;
  recruitment: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_application_recruitment;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_eventMatch_talentProfile;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_application_recruitment;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_user_profileImage | null;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_eventMatch | null;
  application: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_application | null;
  user: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_recruitment | null;
  company: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_company;
  attendees: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_attendees[];
  interviewBookingGroup: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_interviewBookingGroup | null;
  matchingEventTalentProfiles: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  createdAt: DateTime;
  interviewBookingGroup: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_interviewBookingGroup;
  application: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_application;
  bookedInterview: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations_bookedInterview | null;
  notifiers: DateTime[];
}

export interface SendInterviewBookingGroupInvitations {
  sendInterviewBookingGroupInvitations: SendInterviewBookingGroupInvitations_sendInterviewBookingGroupInvitations[];
}

export interface SendInterviewBookingGroupInvitationsVariables {
  interviewBookingGroupId: string;
  interviewBookingGroupApplicationIds: string[];
  messageFromRecruiter?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInterviewBookingGroup
// ====================================================

export interface DeleteInterviewBookingGroup_deleteInterviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
}

export interface DeleteInterviewBookingGroup {
  deleteInterviewBookingGroup: DeleteInterviewBookingGroup_deleteInterviewBookingGroup | null;
}

export interface DeleteInterviewBookingGroupVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BookApplicationInterview
// ====================================================

export interface BookApplicationInterview_bookApplicationInterview_bookedInterview {
  __typename: "CalendarEventExposed";
  id: string;
  title: string | null;
  startTime: DateTime;
  endTime: DateTime;
}

export interface BookApplicationInterview_bookApplicationInterview {
  __typename: "InterviewBookingGroupApplicationExposed";
  id: string;
  status: string;
  bookedInterview: BookApplicationInterview_bookApplicationInterview_bookedInterview | null;
}

export interface BookApplicationInterview {
  bookApplicationInterview: BookApplicationInterview_bookApplicationInterview | null;
}

export interface BookApplicationInterviewVariables {
  interviewBookingGroupApplicationId: string;
  calendarEventId: string;
  bookedByRecruiter?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeInterviewBookingGroupShareAccessStatus
// ====================================================

export interface ChangeInterviewBookingGroupShareAccessStatus_changeInterviewBookingGroupShareAccessStatus {
  __typename: "InterviewBookingGroup";
  id: string;
}

export interface ChangeInterviewBookingGroupShareAccessStatus {
  changeInterviewBookingGroupShareAccessStatus: ChangeInterviewBookingGroupShareAccessStatus_changeInterviewBookingGroupShareAccessStatus | null;
}

export interface ChangeInterviewBookingGroupShareAccessStatusVariables {
  interviewBookingGroupId: string;
  shareAccessStatus: TEMPLATE_SHARE_STATUS;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShareInterviewBookingGroupWithCompanies
// ====================================================

export interface ShareInterviewBookingGroupWithCompanies_shareInterviewBookingGroupWithCompanies {
  __typename: "InterviewBookingGroup";
  id: string;
}

export interface ShareInterviewBookingGroupWithCompanies {
  shareInterviewBookingGroupWithCompanies: ShareInterviewBookingGroupWithCompanies_shareInterviewBookingGroupWithCompanies | null;
}

export interface ShareInterviewBookingGroupWithCompaniesVariables {
  interviewBookingGroupId: string;
  companyIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddQuestionsFromCompetencesToInterviewGuide
// ====================================================

export interface AddQuestionsFromCompetencesToInterviewGuide_addQuestionsFromCompetencesToInterviewGuide {
  __typename: "InterviewGuide";
  id: string;
}

export interface AddQuestionsFromCompetencesToInterviewGuide {
  addQuestionsFromCompetencesToInterviewGuide: AddQuestionsFromCompetencesToInterviewGuide_addQuestionsFromCompetencesToInterviewGuide | null;
}

export interface AddQuestionsFromCompetencesToInterviewGuideVariables {
  interviewGuideId: string;
  competenceIds: string[];
  language: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignInterviewTemplate
// ====================================================

export interface AssignInterviewTemplate_assignInterviewTemplate {
  __typename: "InterviewGuide";
  id: string;
}

export interface AssignInterviewTemplate {
  assignInterviewTemplate: AssignInterviewTemplate_assignInterviewTemplate | null;
}

export interface AssignInterviewTemplateVariables {
  interviewTemplateId: string;
  interviewGuideId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeInterviewGuideShareAccessStatus
// ====================================================

export interface ChangeInterviewGuideShareAccessStatus_changeInterviewGuideShareAccessStatus {
  __typename: "InterviewGuide";
  id: string;
}

export interface ChangeInterviewGuideShareAccessStatus {
  changeInterviewGuideShareAccessStatus: ChangeInterviewGuideShareAccessStatus_changeInterviewGuideShareAccessStatus | null;
}

export interface ChangeInterviewGuideShareAccessStatusVariables {
  interviewGuideId: string;
  shareAccessStatus: TEMPLATE_SHARE_STATUS;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClearInterviewGuide
// ====================================================

export interface ClearInterviewGuide_clearInterviewGuide_interviewParts_questions_subQuestions {
  __typename: "InterviewQuestion";
  id: string;
  listIndex: number;
  title: string;
  answer: string | null;
}

export interface ClearInterviewGuide_clearInterviewGuide_interviewParts_questions {
  __typename: "InterviewQuestion";
  id: string;
  listIndex: number;
  title: string;
  category: string | null;
  answer: string | null;
  subQuestions: ClearInterviewGuide_clearInterviewGuide_interviewParts_questions_subQuestions[];
}

export interface ClearInterviewGuide_clearInterviewGuide_interviewParts {
  __typename: "InterviewPart";
  id: string;
  title: string;
  listIndex: number;
  description: string;
  questions: ClearInterviewGuide_clearInterviewGuide_interviewParts_questions[];
}

export interface ClearInterviewGuide_clearInterviewGuide {
  __typename: "InterviewGuide";
  id: string;
  interviewParts: ClearInterviewGuide_clearInterviewGuide_interviewParts[];
}

export interface ClearInterviewGuide {
  clearInterviewGuide: ClearInterviewGuide_clearInterviewGuide | null;
}

export interface ClearInterviewGuideVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInterviewGuide
// ====================================================

export interface CreateInterviewGuide_createInterviewGuide {
  __typename: "InterviewGuide";
  id: string;
}

export interface CreateInterviewGuide {
  createInterviewGuide: CreateInterviewGuide_createInterviewGuide | null;
}

export interface CreateInterviewGuideVariables {
  companyId: string;
  title?: string | null;
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
  interviewId?: string | null;
  templateId?: string | null;
  isTemplate?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInterviewPart
// ====================================================

export interface CreateInterviewPart_createInterviewPart {
  __typename: "InterviewGuide";
  id: string;
}

export interface CreateInterviewPart {
  createInterviewPart: CreateInterviewPart_createInterviewPart | null;
}

export interface CreateInterviewPartVariables {
  interviewGuideId: string;
  title: string;
  description?: string | null;
  competenceId?: string | null;
  language: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInterviewQuestions
// ====================================================

export interface CreateInterviewQuestions_createInterviewQuestions_questions_subQuestions {
  __typename: "InterviewQuestion";
  id: string;
  listIndex: number;
  title: string;
  answer: string | null;
}

export interface CreateInterviewQuestions_createInterviewQuestions_questions {
  __typename: "InterviewQuestion";
  id: string;
  listIndex: number;
  title: string;
  category: string | null;
  answer: string | null;
  subQuestions: CreateInterviewQuestions_createInterviewQuestions_questions_subQuestions[];
}

export interface CreateInterviewQuestions_createInterviewQuestions {
  __typename: "InterviewPart";
  id: string;
  questions: CreateInterviewQuestions_createInterviewQuestions_questions[];
}

export interface CreateInterviewQuestions {
  createInterviewQuestions: CreateInterviewQuestions_createInterviewQuestions | null;
}

export interface CreateInterviewQuestionsVariables {
  interviewPartId: string;
  questions: InterviewQuestionInput[];
  categories: string[];
  competenceId?: string | null;
  language?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInterviewSubQuestions
// ====================================================

export interface CreateInterviewSubQuestions_createInterviewSubQuestions_subQuestions {
  __typename: "InterviewQuestion";
  id: string;
  listIndex: number;
  title: string;
  answer: string | null;
}

export interface CreateInterviewSubQuestions_createInterviewSubQuestions {
  __typename: "InterviewQuestion";
  id: string;
  subQuestions: CreateInterviewSubQuestions_createInterviewSubQuestions_subQuestions[];
}

export interface CreateInterviewSubQuestions {
  createInterviewSubQuestions: CreateInterviewSubQuestions_createInterviewSubQuestions | null;
}

export interface CreateInterviewSubQuestionsVariables {
  interviewQuestionId: string;
  titles: string[];
  categories: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInterviewGuide
// ====================================================

export interface DeleteInterviewGuide_deleteInterviewGuide {
  __typename: "InterviewGuide";
  id: string;
}

export interface DeleteInterviewGuide {
  deleteInterviewGuide: DeleteInterviewGuide_deleteInterviewGuide | null;
}

export interface DeleteInterviewGuideVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInterviewPart
// ====================================================

export interface DeleteInterviewPart_deleteInterviewPart {
  __typename: "InterviewPart";
  id: string;
}

export interface DeleteInterviewPart {
  deleteInterviewPart: DeleteInterviewPart_deleteInterviewPart | null;
}

export interface DeleteInterviewPartVariables {
  interviewGuideId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInterviewQuestion
// ====================================================

export interface DeleteInterviewQuestion_deleteInterviewQuestion {
  __typename: "InterviewQuestion";
  id: string;
}

export interface DeleteInterviewQuestion {
  deleteInterviewQuestion: DeleteInterviewQuestion_deleteInterviewQuestion | null;
}

export interface DeleteInterviewQuestionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MoveInterviewQuestion
// ====================================================

export interface MoveInterviewQuestion {
  moveInterviewQuestion: boolean;
}

export interface MoveInterviewQuestionVariables {
  id: string;
  sourceParentId: string;
  destinationParentId: string;
  destinationIndex: number;
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveInterviewGuideAsTemplate
// ====================================================

export interface SaveInterviewGuideAsTemplate_saveInterviewGuideAsTemplate {
  __typename: "InterviewGuide";
  id: string;
}

export interface SaveInterviewGuideAsTemplate {
  saveInterviewGuideAsTemplate: SaveInterviewGuideAsTemplate_saveInterviewGuideAsTemplate | null;
}

export interface SaveInterviewGuideAsTemplateVariables {
  companyId: string;
  title: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetQuestionAnswer
// ====================================================

export interface SetQuestionAnswer_setQuestionAnswer {
  __typename: "InterviewQuestion";
  id: string;
}

export interface SetQuestionAnswer {
  setQuestionAnswer: SetQuestionAnswer_setQuestionAnswer | null;
}

export interface SetQuestionAnswerVariables {
  questionId: string;
  answer: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShareInterviewGuideWithCompanies
// ====================================================

export interface ShareInterviewGuideWithCompanies_shareInterviewGuideWithCompanies {
  __typename: "InterviewGuide";
  id: string;
}

export interface ShareInterviewGuideWithCompanies {
  shareInterviewGuideWithCompanies: ShareInterviewGuideWithCompanies_shareInterviewGuideWithCompanies | null;
}

export interface ShareInterviewGuideWithCompaniesVariables {
  interviewGuideId: string;
  companyIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInterviewGuide
// ====================================================

export interface UpdateInterviewGuide_updateInterviewGuide {
  __typename: "InterviewGuide";
  id: string;
  title: string | null;
}

export interface UpdateInterviewGuide {
  updateInterviewGuide: UpdateInterviewGuide_updateInterviewGuide | null;
}

export interface UpdateInterviewGuideVariables {
  id: string;
  title?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInterviewPart
// ====================================================

export interface UpdateInterviewPart_updateInterviewPart {
  __typename: "InterviewPart";
  id: string;
}

export interface UpdateInterviewPart {
  updateInterviewPart: UpdateInterviewPart_updateInterviewPart | null;
}

export interface UpdateInterviewPartVariables {
  id: string;
  title?: string | null;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCompetenceInterviewQuestions
// ====================================================

export interface CreateCompetenceInterviewQuestions_createCompetenceInterviewQuestions {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
}

export interface CreateCompetenceInterviewQuestions {
  createCompetenceInterviewQuestions: CreateCompetenceInterviewQuestions_createCompetenceInterviewQuestions[];
}

export interface CreateCompetenceInterviewQuestionsVariables {
  competenceId: string;
  questions: InterviewQuestionInput[];
  language: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetInterviewQuestionTitle
// ====================================================

export interface SetInterviewQuestionTitle_setInterviewQuestionTitle {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
}

export interface SetInterviewQuestionTitle {
  setInterviewQuestionTitle: SetInterviewQuestionTitle_setInterviewQuestionTitle | null;
}

export interface SetInterviewQuestionTitleVariables {
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CopyInvoiceInformationFromCustomer
// ====================================================

export interface CopyInvoiceInformationFromCustomer_copyInvoiceInformationFromCustomer {
  __typename: "InvoiceInformation";
  id: string;
}

export interface CopyInvoiceInformationFromCustomer {
  copyInvoiceInformationFromCustomer: CopyInvoiceInformationFromCustomer_copyInvoiceInformationFromCustomer | null;
}

export interface CopyInvoiceInformationFromCustomerVariables {
  companyId: string;
  invoiceInformationId: string;
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInvoiceInformation
// ====================================================

export interface UpdateInvoiceInformation_updateInvoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface UpdateInvoiceInformation {
  updateInvoiceInformation: UpdateInvoiceInformation_updateInvoiceInformation | null;
}

export interface UpdateInvoiceInformationVariables {
  companyId: string;
  id: string;
  legalName?: string | null;
  organizationNumber?: string | null;
  referenceName?: string | null;
  referenceNumber?: string | null;
  email?: string | null;
  city?: string | null;
  postalAddress?: string | null;
  postalCode?: string | null;
  costCenter?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateJobtipAd
// ====================================================

export interface CreateJobtipAd_createJobtipAd {
  __typename: "JobtipAd";
  id: string;
}

export interface CreateJobtipAd {
  createJobtipAd: CreateJobtipAd_createJobtipAd | null;
}

export interface CreateJobtipAdVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitJobtipAd
// ====================================================

export interface SubmitJobtipAd_submitJobtipAd {
  __typename: "JobtipAd";
  id: string;
}

export interface SubmitJobtipAd {
  submitJobtipAd: SubmitJobtipAd_submitJobtipAd | null;
}

export interface SubmitJobtipAdVariables {
  jobtipAdId: string;
  activeTo: DateTime;
  activeFrom: DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnfollowJobtipAd
// ====================================================

export interface UnfollowJobtipAd_unfollowJobtipAd {
  __typename: "JobtipAd";
  id: string;
}

export interface UnfollowJobtipAd {
  unfollowJobtipAd: UnfollowJobtipAd_unfollowJobtipAd | null;
}

export interface UnfollowJobtipAdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobtipAd
// ====================================================

export interface UpdateJobtipAd_updateJobtipAd {
  __typename: "JobtipAd";
  id: string;
  title: string | null;
  description: string | null;
  text: string | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  useStoryImage: boolean;
}

export interface UpdateJobtipAd {
  updateJobtipAd: UpdateJobtipAd_updateJobtipAd | null;
}

export interface UpdateJobtipAdVariables {
  jobtipAdId: string;
  activeFrom?: DateTime | null;
  activeTo?: DateTime | null;
  title?: string | null;
  description?: string | null;
  text?: string | null;
  useStoryImage?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectMiddleCompanyWithMatchingEvent
// ====================================================

export interface ConnectMiddleCompanyWithMatchingEvent_connectMiddleCompanyWithMatchingEvent_middleCompanies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ConnectMiddleCompanyWithMatchingEvent_connectMiddleCompanyWithMatchingEvent {
  __typename: "MatchingEvent";
  id: string;
  title: string;
  middleCompanies: ConnectMiddleCompanyWithMatchingEvent_connectMiddleCompanyWithMatchingEvent_middleCompanies[];
}

export interface ConnectMiddleCompanyWithMatchingEvent {
  connectMiddleCompanyWithMatchingEvent: ConnectMiddleCompanyWithMatchingEvent_connectMiddleCompanyWithMatchingEvent | null;
}

export interface ConnectMiddleCompanyWithMatchingEventVariables {
  companyId: string;
  matchingEventId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMatchingEvent
// ====================================================

export interface CreateMatchingEvent_createMatchingEvent {
  __typename: "MatchingEvent";
  id: string;
  title: string;
}

export interface CreateMatchingEvent {
  createMatchingEvent: CreateMatchingEvent_createMatchingEvent;
}

export interface CreateMatchingEventVariables {
  companyId: string;
  title: string;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectMiddleCompanyWithMatchingEvent
// ====================================================

export interface DisconnectMiddleCompanyWithMatchingEvent_disconnectMiddleCompanyWithMatchingEvent_middleCompanies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface DisconnectMiddleCompanyWithMatchingEvent_disconnectMiddleCompanyWithMatchingEvent {
  __typename: "MatchingEvent";
  id: string;
  title: string;
  middleCompanies: DisconnectMiddleCompanyWithMatchingEvent_disconnectMiddleCompanyWithMatchingEvent_middleCompanies[];
}

export interface DisconnectMiddleCompanyWithMatchingEvent {
  disconnectMiddleCompanyWithMatchingEvent: DisconnectMiddleCompanyWithMatchingEvent_disconnectMiddleCompanyWithMatchingEvent | null;
}

export interface DisconnectMiddleCompanyWithMatchingEventVariables {
  companyId: string;
  matchingEventId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendTrustcruitFeedbackRequestForMatchingEvent
// ====================================================

export interface SendTrustcruitFeedbackRequestForMatchingEvent_sendTrustcruitFeedbackRequestForMatchingEvent {
  __typename: "MatchingEvent";
  id: string;
}

export interface SendTrustcruitFeedbackRequestForMatchingEvent {
  sendTrustcruitFeedbackRequestForMatchingEvent: SendTrustcruitFeedbackRequestForMatchingEvent_sendTrustcruitFeedbackRequestForMatchingEvent | null;
}

export interface SendTrustcruitFeedbackRequestForMatchingEventVariables {
  matchingEventId: string;
  trustcruitCandidateLevel: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleProfileOnMatchingEvent
// ====================================================

export interface ToggleProfileOnMatchingEvent_toggleProfileOnMatchingEvent_profiles {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ToggleProfileOnMatchingEvent_toggleProfileOnMatchingEvent {
  __typename: "MatchingEvent";
  id: string;
  title: string;
  profiles: ToggleProfileOnMatchingEvent_toggleProfileOnMatchingEvent_profiles[];
}

export interface ToggleProfileOnMatchingEvent {
  toggleProfileOnMatchingEvent: ToggleProfileOnMatchingEvent_toggleProfileOnMatchingEvent | null;
}

export interface ToggleProfileOnMatchingEventVariables {
  matchingEventProfileId: string;
  matchingEventId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMatchingEvent
// ====================================================

export interface UpdateMatchingEvent_updateMatchingEvent_hostRecruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface UpdateMatchingEvent_updateMatchingEvent {
  __typename: "MatchingEvent";
  id: string;
  title: string;
  description: string | null;
  companiesCanAddInterviews: boolean | null;
  hostRecruitment: UpdateMatchingEvent_updateMatchingEvent_hostRecruitment;
  flowOrder: MATCHING_EVENT_FLOW_ORDER | null;
}

export interface UpdateMatchingEvent {
  updateMatchingEvent: UpdateMatchingEvent_updateMatchingEvent | null;
}

export interface UpdateMatchingEventVariables {
  id: string;
  title?: string | null;
  description?: string | null;
  descriptionApplication?: string | null;
  descriptionParticipant?: string | null;
  messageToApplicationOnMatch?: string | null;
  messageToParticipantOnMatch?: string | null;
  companiesCanAddInterviews?: boolean | null;
  enableTrustcruitFeedback?: boolean | null;
  flowOrder?: MATCHING_EVENT_FLOW_ORDER | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMatchingEventInterviewBookingStatus
// ====================================================

export interface UpdateMatchingEventInterviewBookingStatus_updateMatchingEventInterviewBookingStatus {
  __typename: "MatchingEvent";
  id: string;
  interviewBookingStatus: MATCHING_EVENT_INTERVIEW_BOOKING_STATUS | null;
}

export interface UpdateMatchingEventInterviewBookingStatus {
  updateMatchingEventInterviewBookingStatus: UpdateMatchingEventInterviewBookingStatus_updateMatchingEventInterviewBookingStatus | null;
}

export interface UpdateMatchingEventInterviewBookingStatusVariables {
  matchingEventId: string;
  notify?: boolean | null;
  message?: string | null;
  status: MATCHING_EVENT_INTERVIEW_BOOKING_STATUS;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RejectMatchingEventCompanyRequest
// ====================================================

export interface RejectMatchingEventCompanyRequest_rejectMatchingEventCompanyRequest {
  __typename: "MatchingEventCompanyRequest";
  id: string;
}

export interface RejectMatchingEventCompanyRequest {
  rejectMatchingEventCompanyRequest: RejectMatchingEventCompanyRequest_rejectMatchingEventCompanyRequest | null;
}

export interface RejectMatchingEventCompanyRequestVariables {
  matchingEventCompanyRequestId: string;
  matchingEventId: string;
  message?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompleteMatchingEventParticipantStep
// ====================================================

export interface CompleteMatchingEventParticipantStep_completeMatchingEventParticipantStep {
  __typename: "MatchingEventParticipant";
  id: string;
  completedWizardSteps: string[];
}

export interface CompleteMatchingEventParticipantStep {
  completeMatchingEventParticipantStep: CompleteMatchingEventParticipantStep_completeMatchingEventParticipantStep | null;
}

export interface CompleteMatchingEventParticipantStepVariables {
  matchingEventParticipantId: string;
  stepId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMatchingEventParticipantInterviewBooking
// ====================================================

export interface CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_attendees_application_recruitment;
}

export interface CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_attendees_user_profileImage | null;
}

export interface CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  application: CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_attendees_application | null;
  user: CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  childCompanyHasSelectedBookableInterview: boolean;
  recruitment: CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_recruitment | null;
  attendees: CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_attendees[];
  interviewBookingGroup: CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_interviewBookingGroup | null;
  matchingEventTalentProfiles: CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface CreateMatchingEventParticipantInterviewBooking {
  createMatchingEventParticipantInterviewBooking: (CreateMatchingEventParticipantInterviewBooking_createMatchingEventParticipantInterviewBooking | null)[] | null;
}

export interface CreateMatchingEventParticipantInterviewBookingVariables {
  companyId: string;
  participantId: string;
  title?: string | null;
  description?: string | null;
  url?: string | null;
  location?: string | null;
  times: CalendarEventTimeInput[];
  talentProfileIds: string[];
  userIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateParticipantFromMatchingEventCompanyRequest
// ====================================================

export interface CreateParticipantFromMatchingEventCompanyRequest_createParticipantFromMatchingEventCompanyRequest {
  __typename: "MatchingEventParticipant";
  id: string;
}

export interface CreateParticipantFromMatchingEventCompanyRequest {
  createParticipantFromMatchingEventCompanyRequest: CreateParticipantFromMatchingEventCompanyRequest_createParticipantFromMatchingEventCompanyRequest | null;
}

export interface CreateParticipantFromMatchingEventCompanyRequestVariables {
  matchingEventCompanyRequestId: string;
  matchingEventId: string;
  message?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMatchingEventParticipant
// ====================================================

export interface DeleteMatchingEventParticipant_deleteMatchingEventParticipant {
  __typename: "MatchingEventParticipant";
  id: string;
}

export interface DeleteMatchingEventParticipant {
  deleteMatchingEventParticipant: DeleteMatchingEventParticipant_deleteMatchingEventParticipant | null;
}

export interface DeleteMatchingEventParticipantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMatchingEventParticipantNote
// ====================================================

export interface UpdateMatchingEventParticipantNote_updateMatchingEventParticipantNote {
  __typename: "MatchingEventParticipant";
  id: string;
  note: string | null;
}

export interface UpdateMatchingEventParticipantNote {
  updateMatchingEventParticipantNote: UpdateMatchingEventParticipantNote_updateMatchingEventParticipantNote | null;
}

export interface UpdateMatchingEventParticipantNoteVariables {
  id: string;
  note: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMatchingEventProfile
// ====================================================

export interface CreateMatchingEventProfile_createMatchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
}

export interface CreateMatchingEventProfile {
  createMatchingEventProfile: CreateMatchingEventProfile_createMatchingEventProfile;
}

export interface CreateMatchingEventProfileVariables {
  companyId: string;
  title: string;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMatchingEventProfile
// ====================================================

export interface DeleteMatchingEventProfile_deleteMatchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
}

export interface DeleteMatchingEventProfile {
  deleteMatchingEventProfile: DeleteMatchingEventProfile_deleteMatchingEventProfile | null;
}

export interface DeleteMatchingEventProfileVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMatchingEventProfile
// ====================================================

export interface UpdateMatchingEventProfile_updateMatchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
  description: string | null;
}

export interface UpdateMatchingEventProfile {
  updateMatchingEventProfile: UpdateMatchingEventProfile_updateMatchingEventProfile | null;
}

export interface UpdateMatchingEventProfileVariables {
  id: string;
  title?: string | null;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMatchingEventTalentProfile
// ====================================================

export interface CreateMatchingEventTalentProfile_createMatchingEventTalentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  createdAt: DateTime;
  title: string;
}

export interface CreateMatchingEventTalentProfile {
  createMatchingEventTalentProfile: CreateMatchingEventTalentProfile_createMatchingEventTalentProfile | null;
}

export interface CreateMatchingEventTalentProfileVariables {
  companyId: string;
  title: string;
  participantId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMatchingEventTalentProfile
// ====================================================

export interface DeleteMatchingEventTalentProfile_deleteMatchingEventTalentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
}

export interface DeleteMatchingEventTalentProfile {
  deleteMatchingEventTalentProfile: DeleteMatchingEventTalentProfile_deleteMatchingEventTalentProfile | null;
}

export interface DeleteMatchingEventTalentProfileVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: NotifyCompanyOfEventMatchesDelivered
// ====================================================

export interface NotifyCompanyOfEventMatchesDelivered_notifyCompanyOfEventMatchesDelivered {
  __typename: "MatchingEventTalentProfile";
  id: string;
  participantNotifiedOfMatch: boolean | null;
}

export interface NotifyCompanyOfEventMatchesDelivered {
  notifyCompanyOfEventMatchesDelivered: NotifyCompanyOfEventMatchesDelivered_notifyCompanyOfEventMatchesDelivered | null;
}

export interface NotifyCompanyOfEventMatchesDeliveredVariables {
  matchingEventId: string;
  talentProfileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SelectProfileOnTalentProfile
// ====================================================

export interface SelectProfileOnTalentProfile_selectProfileOnTalentProfile_profiles {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface SelectProfileOnTalentProfile_selectProfileOnTalentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
  profiles: SelectProfileOnTalentProfile_selectProfileOnTalentProfile_profiles[];
}

export interface SelectProfileOnTalentProfile {
  selectProfileOnTalentProfile: SelectProfileOnTalentProfile_selectProfileOnTalentProfile | null;
}

export interface SelectProfileOnTalentProfileVariables {
  matchingEventProfileIds: string[];
  matchingEventTalentProfileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMatchingEventTalentProfile
// ====================================================

export interface UpdateMatchingEventTalentProfile_updateMatchingEventTalentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
  description: string | null;
}

export interface UpdateMatchingEventTalentProfile {
  updateMatchingEventTalentProfile: UpdateMatchingEventTalentProfile_updateMatchingEventTalentProfile | null;
}

export interface UpdateMatchingEventTalentProfileVariables {
  id: string;
  title?: string | null;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMediaElement
// ====================================================

export interface CreateMediaElement_createMediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface CreateMediaElement_createMediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CreateMediaElement_createMediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: CreateMediaElement_createMediaElement_createdBy_profileImage | null;
}

export interface CreateMediaElement_createMediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface CreateMediaElement_createMediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: CreateMediaElement_createMediaElement_thumbnail | null;
  createdBy: CreateMediaElement_createMediaElement_createdBy;
  tags: CreateMediaElement_createMediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface CreateMediaElement {
  createMediaElement: CreateMediaElement_createMediaElement;
}

export interface CreateMediaElementVariables {
  companyId: string;
  file: FileUploadInput;
  name?: string | null;
  description?: string | null;
  tags?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMediaElement
// ====================================================

export interface DeleteMediaElement_deleteMediaElement {
  __typename: "MediaElement";
  id: string;
}

export interface DeleteMediaElement {
  deleteMediaElement: DeleteMediaElement_deleteMediaElement | null;
}

export interface DeleteMediaElementVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMediaElement
// ====================================================

export interface UpdateMediaElement_updateMediaElement {
  __typename: "MediaElement";
  id: string;
}

export interface UpdateMediaElement {
  updateMediaElement: UpdateMediaElement_updateMediaElement | null;
}

export interface UpdateMediaElementVariables {
  id: string;
  name?: string | null;
  description?: string | null;
  tags?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMedia
// ====================================================

export interface CreateMedia_createMedia {
  __typename: "Media";
  id: string;
}

export interface CreateMedia {
  createMedia: CreateMedia_createMedia;
}

export interface CreateMediaVariables {
  mediaElementId: string;
  pageBlockId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMedia
// ====================================================

export interface UpdateMedia_updateMedia {
  __typename: "Media";
  id: string;
}

export interface UpdateMedia {
  updateMedia: UpdateMedia_updateMedia | null;
}

export interface UpdateMediaVariables {
  id: string;
  input: MediaUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePageBlockMedia
// ====================================================

export interface CreatePageBlockMedia_createPageBlockMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface CreatePageBlockMedia_createPageBlockMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CreatePageBlockMedia_createPageBlockMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: CreatePageBlockMedia_createPageBlockMedia_mediaElement_createdBy_profileImage | null;
}

export interface CreatePageBlockMedia_createPageBlockMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface CreatePageBlockMedia_createPageBlockMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: CreatePageBlockMedia_createPageBlockMedia_mediaElement_thumbnail | null;
  createdBy: CreatePageBlockMedia_createPageBlockMedia_mediaElement_createdBy;
  tags: CreatePageBlockMedia_createPageBlockMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface CreatePageBlockMedia_createPageBlockMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: CreatePageBlockMedia_createPageBlockMedia_mediaElement;
}

export interface CreatePageBlockMedia {
  createPageBlockMedia: CreatePageBlockMedia_createPageBlockMedia;
}

export interface CreatePageBlockMediaVariables {
  pageBlockId: string;
  mediaElementId: string;
  isInline?: boolean | null;
  isBackground?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMedia
// ====================================================

export interface DeleteMedia_deleteMedia {
  __typename: "Media";
  id: string;
}

export interface DeleteMedia {
  deleteMedia: DeleteMedia_deleteMedia | null;
}

export interface DeleteMediaVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeMessageTemplateShareAccessStatus
// ====================================================

export interface ChangeMessageTemplateShareAccessStatus_changeMessageTemplateShareAccessStatus {
  __typename: "MessageTemplate";
  id: string;
}

export interface ChangeMessageTemplateShareAccessStatus {
  changeMessageTemplateShareAccessStatus: ChangeMessageTemplateShareAccessStatus_changeMessageTemplateShareAccessStatus | null;
}

export interface ChangeMessageTemplateShareAccessStatusVariables {
  messageTemplateId: string;
  shareAccessStatus: TEMPLATE_SHARE_STATUS;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMessageTemplate
// ====================================================

export interface CreateMessageTemplate_createMessageTemplate {
  __typename: "MessageTemplate";
  id: string;
}

export interface CreateMessageTemplate {
  createMessageTemplate: CreateMessageTemplate_createMessageTemplate | null;
}

export interface CreateMessageTemplateVariables {
  companyId: string;
  templateName: string;
  templateDescription?: string | null;
  type: MESSAGE_TYPE;
  language?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMessageTemplate
// ====================================================

export interface DeleteMessageTemplate_deleteMessageTemplate {
  __typename: "MessageTemplate";
  id: string;
}

export interface DeleteMessageTemplate {
  deleteMessageTemplate: DeleteMessageTemplate_deleteMessageTemplate | null;
}

export interface DeleteMessageTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShareMessageTemplateWithCompanies
// ====================================================

export interface ShareMessageTemplateWithCompanies_shareMessageTemplateWithCompanies {
  __typename: "MessageTemplate";
  id: string;
}

export interface ShareMessageTemplateWithCompanies {
  shareMessageTemplateWithCompanies: ShareMessageTemplateWithCompanies_shareMessageTemplateWithCompanies | null;
}

export interface ShareMessageTemplateWithCompaniesVariables {
  messageTemplateId: string;
  companyIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMessageTemplate
// ====================================================

export interface UpdateMessageTemplate_updateMessageTemplate {
  __typename: "MessageTemplate";
  id: string;
  type: MESSAGE_TYPE;
  templateName: string | null;
  description: string | null;
  isDefault: boolean | null;
  header: string;
  content: string;
  buttonText: string | null;
  buttonLink: string | null;
  language: string | null;
}

export interface UpdateMessageTemplate {
  updateMessageTemplate: UpdateMessageTemplate_updateMessageTemplate | null;
}

export interface UpdateMessageTemplateVariables {
  id: string;
  templateName?: string | null;
  header?: string | null;
  content?: string | null;
  buttonText?: string | null;
  buttonLink?: string | null;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateNote
// ====================================================

export interface CreateNote_createNote {
  __typename: "Note";
  id: string;
  text: string | null;
  title: string;
}

export interface CreateNote {
  createNote: CreateNote_createNote | null;
}

export interface CreateNoteVariables {
  title: string;
  text?: string | null;
  applicantId?: string | null;
  applicationId?: string | null;
  consultantId?: string | null;
  consultantCustomerId?: string | null;
  consultantProjectId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateNote
// ====================================================

export interface UpdateNote_updateNote {
  __typename: "Note";
  id: string;
  text: string | null;
  title: string;
}

export interface UpdateNote {
  updateNote: UpdateNote_updateNote | null;
}

export interface UpdateNoteVariables {
  id: string;
  title?: string | null;
  text?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkAllNotificationsAsRead
// ====================================================

export interface MarkAllNotificationsAsRead_markAllNotificationsAsRead {
  __typename: "BatchPayload";
  count: number;
}

export interface MarkAllNotificationsAsRead {
  markAllNotificationsAsRead: MarkAllNotificationsAsRead_markAllNotificationsAsRead | null;
}

export interface MarkAllNotificationsAsReadVariables {
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: NotificationsRead
// ====================================================

export interface NotificationsRead_notificationsRead {
  __typename: "User";
  id: string;
}

export interface NotificationsRead {
  notificationsRead: NotificationsRead_notificationsRead | null;
}

export interface NotificationsReadVariables {
  type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleNotificationReadStatus
// ====================================================

export interface ToggleNotificationReadStatus_toggleNotificationReadStatus {
  __typename: "Notification";
  id: string;
  type: NOTIFICATION_TYPE;
  createdAt: DateTime;
  isRead: boolean;
}

export interface ToggleNotificationReadStatus {
  toggleNotificationReadStatus: ToggleNotificationReadStatus_toggleNotificationReadStatus | null;
}

export interface ToggleNotificationReadStatusVariables {
  id: string;
  isRead: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TogglePinNotification
// ====================================================

export interface TogglePinNotification_togglePinNotification_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TogglePinNotification_togglePinNotification_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface TogglePinNotification_togglePinNotification_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: TogglePinNotification_togglePinNotification_createdBy_profileImage | null;
}

export interface TogglePinNotification_togglePinNotification_messagingCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TogglePinNotification_togglePinNotification_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface TogglePinNotification_togglePinNotification_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface TogglePinNotification_togglePinNotification_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
}

export interface TogglePinNotification_togglePinNotification_consultant_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  company: TogglePinNotification_togglePinNotification_consultant_company;
}

export interface TogglePinNotification_togglePinNotification_consultantProject_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  title: string;
  company: TogglePinNotification_togglePinNotification_consultantProject_company;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_form_referenceForm_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_form_referenceForm_recruitment {
  __typename: "Recruitment";
  id: string;
  company: TogglePinNotification_togglePinNotification_formParticipant_form_referenceForm_recruitment_company;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
  recruitment: TogglePinNotification_togglePinNotification_formParticipant_form_referenceForm_recruitment | null;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_form_screeningForm_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_form_screeningForm_recruitment {
  __typename: "Recruitment";
  id: string;
  company: TogglePinNotification_togglePinNotification_formParticipant_form_screeningForm_recruitment_company;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
  recruitment: TogglePinNotification_togglePinNotification_formParticipant_form_screeningForm_recruitment | null;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_form {
  __typename: "Form";
  id: string;
  referenceForm: TogglePinNotification_togglePinNotification_formParticipant_form_referenceForm | null;
  screeningForm: TogglePinNotification_togglePinNotification_formParticipant_form_screeningForm | null;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_reference_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_reference_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: TogglePinNotification_togglePinNotification_formParticipant_reference_application_recruitment_company;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_reference_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: TogglePinNotification_togglePinNotification_formParticipant_reference_application_recruitment;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
  application: TogglePinNotification_togglePinNotification_formParticipant_reference_application | null;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: TogglePinNotification_togglePinNotification_formParticipant_application_recruitment_company;
}

export interface TogglePinNotification_togglePinNotification_formParticipant_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: TogglePinNotification_togglePinNotification_formParticipant_application_recruitment;
}

export interface TogglePinNotification_togglePinNotification_formParticipant {
  __typename: "FormParticipant";
  id: string;
  form: TogglePinNotification_togglePinNotification_formParticipant_form;
  reference: TogglePinNotification_togglePinNotification_formParticipant_reference | null;
  application: TogglePinNotification_togglePinNotification_formParticipant_application | null;
}

export interface TogglePinNotification_togglePinNotification_calendarEvent_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_calendarEvent {
  __typename: "CalendarEvent";
  id: string;
  type: CALENDAR_EVENT_TYPE;
  title: string | null;
  startTime: DateTime;
  endTime: DateTime;
  company: TogglePinNotification_togglePinNotification_calendarEvent_company;
}

export interface TogglePinNotification_togglePinNotification_comment_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_comment_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: TogglePinNotification_togglePinNotification_comment_application_recruitment_company;
}

export interface TogglePinNotification_togglePinNotification_comment_application {
  __typename: "Application";
  id: string;
  recruitment: TogglePinNotification_togglePinNotification_comment_application_recruitment;
}

export interface TogglePinNotification_togglePinNotification_comment_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_comment_recruitment {
  __typename: "Recruitment";
  id: string;
  company: TogglePinNotification_togglePinNotification_comment_recruitment_company;
}

export interface TogglePinNotification_togglePinNotification_comment_mentions {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TogglePinNotification_togglePinNotification_comment {
  __typename: "Comment";
  id: string;
  text: string;
  application: TogglePinNotification_togglePinNotification_comment_application | null;
  recruitment: TogglePinNotification_togglePinNotification_comment_recruitment | null;
  mentions: TogglePinNotification_togglePinNotification_comment_mentions[];
}

export interface TogglePinNotification_togglePinNotification_assignment_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_assignment {
  __typename: "Assignment";
  id: string;
  title: string;
  company: TogglePinNotification_togglePinNotification_assignment_company;
}

export interface TogglePinNotification_togglePinNotification_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: TogglePinNotification_togglePinNotification_recruitment_company;
}

export interface TogglePinNotification_togglePinNotification_interview_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface TogglePinNotification_togglePinNotification_interview_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_interview_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: TogglePinNotification_togglePinNotification_interview_application_recruitment_company;
}

export interface TogglePinNotification_togglePinNotification_interview_application {
  __typename: "Application";
  id: string;
  recruitment: TogglePinNotification_togglePinNotification_interview_application_recruitment;
}

export interface TogglePinNotification_togglePinNotification_interview {
  __typename: "Interview";
  id: string;
  title: string;
  applicant: TogglePinNotification_togglePinNotification_interview_applicant | null;
  application: TogglePinNotification_togglePinNotification_interview_application | null;
}

export interface TogglePinNotification_togglePinNotification_identifiedNeed_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_identifiedNeed_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: TogglePinNotification_togglePinNotification_identifiedNeed_recruitment_company;
}

export interface TogglePinNotification_togglePinNotification_identifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
  title: string;
  recruitment: TogglePinNotification_togglePinNotification_identifiedNeed_recruitment | null;
}

export interface TogglePinNotification_togglePinNotification_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface TogglePinNotification_togglePinNotification_interviewBookingGroupApplication_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface TogglePinNotification_togglePinNotification_interviewBookingGroupApplication {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  application: TogglePinNotification_togglePinNotification_interviewBookingGroupApplication_application;
}

export interface TogglePinNotification_togglePinNotification_applicantMessage_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_applicantMessage_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: TogglePinNotification_togglePinNotification_applicantMessage_application_recruitment_company;
}

export interface TogglePinNotification_togglePinNotification_applicantMessage_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: TogglePinNotification_togglePinNotification_applicantMessage_application_recruitment;
}

export interface TogglePinNotification_togglePinNotification_applicantMessage_applicant_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_applicantMessage_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: TogglePinNotification_togglePinNotification_applicantMessage_applicant_company;
}

export interface TogglePinNotification_togglePinNotification_applicantMessage {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  application: TogglePinNotification_togglePinNotification_applicantMessage_application | null;
  applicant: TogglePinNotification_togglePinNotification_applicantMessage_applicant | null;
}

export interface TogglePinNotification_togglePinNotification_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface TogglePinNotification_togglePinNotification_status {
  __typename: "Status";
  id: string;
  title: string;
}

export interface TogglePinNotification_togglePinNotification_applicant_company {
  __typename: "Company";
  id: string;
}

export interface TogglePinNotification_togglePinNotification_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: TogglePinNotification_togglePinNotification_applicant_company;
}

export interface TogglePinNotification_togglePinNotification {
  __typename: "Notification";
  id: string;
  type: NOTIFICATION_TYPE;
  createdAt: DateTime;
  isRead: boolean;
  isPinned: boolean | null;
  user: TogglePinNotification_togglePinNotification_user;
  createdBy: TogglePinNotification_togglePinNotification_createdBy | null;
  content: string | null;
  messagingCompany: TogglePinNotification_togglePinNotification_messagingCompany | null;
  screeningForm: TogglePinNotification_togglePinNotification_screeningForm | null;
  referenceForm: TogglePinNotification_togglePinNotification_referenceForm | null;
  reference: TogglePinNotification_togglePinNotification_reference | null;
  consultant: TogglePinNotification_togglePinNotification_consultant | null;
  consultantProject: TogglePinNotification_togglePinNotification_consultantProject | null;
  formParticipant: TogglePinNotification_togglePinNotification_formParticipant | null;
  calendarEvent: TogglePinNotification_togglePinNotification_calendarEvent | null;
  comment: TogglePinNotification_togglePinNotification_comment | null;
  assignment: TogglePinNotification_togglePinNotification_assignment | null;
  recruitment: TogglePinNotification_togglePinNotification_recruitment | null;
  interview: TogglePinNotification_togglePinNotification_interview | null;
  identifiedNeed: TogglePinNotification_togglePinNotification_identifiedNeed | null;
  interviewBookingGroup: TogglePinNotification_togglePinNotification_interviewBookingGroup | null;
  interviewBookingGroupApplication: TogglePinNotification_togglePinNotification_interviewBookingGroupApplication | null;
  applicantMessage: TogglePinNotification_togglePinNotification_applicantMessage | null;
  application: TogglePinNotification_togglePinNotification_application | null;
  status: TogglePinNotification_togglePinNotification_status | null;
  applicant: TogglePinNotification_togglePinNotification_applicant | null;
}

export interface TogglePinNotification {
  togglePinNotification: TogglePinNotification_togglePinNotification | null;
}

export interface TogglePinNotificationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSharedImage
// ====================================================

export interface UpdateSharedImage_updateSharedImage {
  __typename: "SharedImage";
  id: string;
}

export interface UpdateSharedImage {
  updateSharedImage: UpdateSharedImage_updateSharedImage | null;
}

export interface UpdateSharedImageVariables {
  id: string;
  input: SharedImageUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCareerPage
// ====================================================

export interface CreateCareerPage_createCareerPage {
  __typename: "Page";
  id: string;
}

export interface CreateCareerPage {
  createCareerPage: CreateCareerPage_createCareerPage | null;
}

export interface CreateCareerPageVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePageVersionPublishedStatus
// ====================================================

export interface UpdatePageVersionPublishedStatus_updatePageVersionPublishedStatus {
  __typename: "PageVersion";
  id: string;
}

export interface UpdatePageVersionPublishedStatus {
  updatePageVersionPublishedStatus: UpdatePageVersionPublishedStatus_updatePageVersionPublishedStatus | null;
}

export interface UpdatePageVersionPublishedStatusVariables {
  id: string;
  published: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePageVersion
// ====================================================

export interface UpdatePageVersion_updatePageVersion {
  __typename: "PageVersion";
  id: string;
}

export interface UpdatePageVersion {
  updatePageVersion: UpdatePageVersion_updatePageVersion | null;
}

export interface UpdatePageVersionVariables {
  id: string;
  customCss?: string | null;
  themeVariables?: PageThemeVariablesInput | null;
  metaInfo?: PageMetaInfoInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePageBlock
// ====================================================

export interface CreatePageBlock_createPageBlock {
  __typename: "PageBlock";
  id: string;
}

export interface CreatePageBlock {
  createPageBlock: CreatePageBlock_createPageBlock | null;
}

export interface CreatePageBlockVariables {
  pageVersionId: string;
  name?: string | null;
  blockType?: PAGE_BLOCK_TYPE | null;
  templatePageBlockId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderPageBlocks
// ====================================================

export interface ReorderPageBlocks_reorderPageBlocks {
  __typename: "PageVersion";
  id: string;
}

export interface ReorderPageBlocks {
  reorderPageBlocks: ReorderPageBlocks_reorderPageBlocks | null;
}

export interface ReorderPageBlocksVariables {
  pageVersionId: string;
  blockIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePageBlock
// ====================================================

export interface DeletePageBlock_deletePageBlock {
  __typename: "PageBlock";
  id: string;
}

export interface DeletePageBlock {
  deletePageBlock: DeletePageBlock_deletePageBlock | null;
}

export interface DeletePageBlockVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePageBlock
// ====================================================

export interface UpdatePageBlock_updatePageBlock {
  __typename: "PageBlock";
  id: string;
}

export interface UpdatePageBlock {
  updatePageBlock: UpdatePageBlock_updatePageBlock | null;
}

export interface UpdatePageBlockVariables {
  id: string;
  name?: string | null;
  content?: JSON | null;
  customCss?: string | null;
  themeVariables?: PageThemeVariablesInput | null;
  fixedHeight?: number | null;
  heightType?: PAGE_BLOCK_HEIGHT_TYPE | null;
  backgroundType?: PAGE_BLOCK_BACKGROUND_TYPE | null;
  backgroundOverlayOpacity?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderColumnPageBlocks
// ====================================================

export interface ReorderColumnPageBlocks_reorderColumnPageBlocks {
  __typename: "PageBlock";
  id: string;
}

export interface ReorderColumnPageBlocks {
  reorderColumnPageBlocks: ReorderColumnPageBlocks_reorderColumnPageBlocks | null;
}

export interface ReorderColumnPageBlocksVariables {
  pageBlockId: string;
  blockIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateColumnPageBlock
// ====================================================

export interface CreateColumnPageBlock_createColumnPageBlock {
  __typename: "PageBlock";
  id: string;
}

export interface CreateColumnPageBlock {
  createColumnPageBlock: CreateColumnPageBlock_createColumnPageBlock | null;
}

export interface CreateColumnPageBlockVariables {
  pageBlockId: string;
  name?: string | null;
  blockType: PAGE_BLOCK_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTemplatePageBlock
// ====================================================

export interface CreateTemplatePageBlock_createTemplatePageBlock {
  __typename: "PageBlock";
  id: string;
}

export interface CreateTemplatePageBlock {
  createTemplatePageBlock: CreateTemplatePageBlock_createTemplatePageBlock;
}

export interface CreateTemplatePageBlockVariables {
  pageBlockId: string;
  name: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePageContent
// ====================================================

export interface UpdatePageContent_updatePageContent {
  __typename: "PageContent";
  id: string;
}

export interface UpdatePageContent {
  updatePageContent: UpdatePageContent_updatePageContent | null;
}

export interface UpdatePageContentVariables {
  id: string;
  content?: JSON | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePageContentMedia
// ====================================================

export interface CreatePageContentMedia_createPageContentMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface CreatePageContentMedia_createPageContentMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CreatePageContentMedia_createPageContentMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: CreatePageContentMedia_createPageContentMedia_mediaElement_createdBy_profileImage | null;
}

export interface CreatePageContentMedia_createPageContentMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface CreatePageContentMedia_createPageContentMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: CreatePageContentMedia_createPageContentMedia_mediaElement_thumbnail | null;
  createdBy: CreatePageContentMedia_createPageContentMedia_mediaElement_createdBy;
  tags: CreatePageContentMedia_createPageContentMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface CreatePageContentMedia_createPageContentMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: CreatePageContentMedia_createPageContentMedia_mediaElement;
}

export interface CreatePageContentMedia {
  createPageContentMedia: CreatePageContentMedia_createPageContentMedia;
}

export interface CreatePageContentMediaVariables {
  pageContentId: string;
  mediaElementId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckPageTemplateName
// ====================================================

export interface CheckPageTemplateName {
  checkPageTemplateName: boolean;
}

export interface CheckPageTemplateNameVariables {
  companyId: string;
  name: string;
  type: PAGE_TEMPLATE_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePageTempate
// ====================================================

export interface CreatePageTempate_createPageTemplate {
  __typename: "PageTemplate";
  id: string;
}

export interface CreatePageTempate {
  createPageTemplate: CreatePageTempate_createPageTemplate | null;
}

export interface CreatePageTempateVariables {
  companyId: string;
  name: string;
  type: PAGE_TEMPLATE_TYPE;
  contentBlockIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePageTemplate
// ====================================================

export interface DeletePageTemplate_deletePageTemplate {
  __typename: "PageTemplate";
  id: string;
}

export interface DeletePageTemplate {
  deletePageTemplate: DeletePageTemplate_deletePageTemplate | null;
}

export interface DeletePageTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoadPageTemplate
// ====================================================

export interface LoadPageTemplate_loadPageTemplate {
  __typename: "ConnectedContentBlock";
  id: string;
}

export interface LoadPageTemplate {
  loadPageTemplate: LoadPageTemplate_loadPageTemplate[];
}

export interface LoadPageTemplateVariables {
  companyId: string;
  templateId: string;
  adId?: string | null;
  careerPageId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDefaultPageTemplate
// ====================================================

export interface SetDefaultPageTemplate_setDefaultPageTemplate {
  __typename: "PageTemplate";
  id: string;
  isDefault: boolean | null;
}

export interface SetDefaultPageTemplate {
  setDefaultPageTemplate: SetDefaultPageTemplate_setDefaultPageTemplate | null;
}

export interface SetDefaultPageTemplateVariables {
  id: string;
  type: PAGE_TEMPLATE_TYPE;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TogglePinRecruitment
// ====================================================

export interface TogglePinRecruitment {
  togglePinRecruitment: boolean | null;
}

export interface TogglePinRecruitmentVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompleteRecruitmentStep
// ====================================================

export interface CompleteRecruitmentStep_completeRecruitmentStep {
  __typename: "Recruitment";
  id: string;
  completedWizardSteps: string[];
}

export interface CompleteRecruitmentStep {
  completeRecruitmentStep: CompleteRecruitmentStep_completeRecruitmentStep | null;
}

export interface CompleteRecruitmentStepVariables {
  recruitmentId: string;
  stepId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSpontaniousRecruitment
// ====================================================

export interface CreateSpontaniousRecruitment_createSpontaniousRecruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CreateSpontaniousRecruitment {
  createSpontaniousRecruitment: CreateSpontaniousRecruitment_createSpontaniousRecruitment | null;
}

export interface CreateSpontaniousRecruitmentVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteRecruitment
// ====================================================

export interface DeleteRecruitment_deleteRecruitment {
  __typename: "Recruitment";
  id: string;
}

export interface DeleteRecruitment {
  deleteRecruitment: DeleteRecruitment_deleteRecruitment | null;
}

export interface DeleteRecruitmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetRecruitmentAnonymized
// ====================================================

export interface SetRecruitmentAnonymized_setRecruitmentAnonymized {
  __typename: "Recruitment";
  id: string;
  isAnonymized: boolean;
}

export interface SetRecruitmentAnonymized {
  setRecruitmentAnonymized: SetRecruitmentAnonymized_setRecruitmentAnonymized | null;
}

export interface SetRecruitmentAnonymizedVariables {
  recruitmentId: string;
  isAnonymized: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetRecruitmentPrivate
// ====================================================

export interface SetRecruitmentPrivate_setRecruitmentPrivate {
  __typename: "Recruitment";
  id: string;
  isPrivate: boolean | null;
}

export interface SetRecruitmentPrivate {
  setRecruitmentPrivate: SetRecruitmentPrivate_setRecruitmentPrivate | null;
}

export interface SetRecruitmentPrivateVariables {
  recruitmentId: string;
  isPrivate: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRecruitmentDepartment
// ====================================================

export interface UpdateRecruitmentDepartment_updateRecruitmentDepartment_department_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface UpdateRecruitmentDepartment_updateRecruitmentDepartment_department {
  __typename: "Department";
  id: string;
  name: string;
  description: string | null;
  recruitments: UpdateRecruitmentDepartment_updateRecruitmentDepartment_department_recruitments[];
}

export interface UpdateRecruitmentDepartment_updateRecruitmentDepartment {
  __typename: "Recruitment";
  id: string;
  department: UpdateRecruitmentDepartment_updateRecruitmentDepartment_department | null;
}

export interface UpdateRecruitmentDepartment {
  updateRecruitmentDepartment: UpdateRecruitmentDepartment_updateRecruitmentDepartment | null;
}

export interface UpdateRecruitmentDepartmentVariables {
  recruitmentId: string;
  departmentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRecruitment
// ====================================================

export interface UpdateRecruitment_updateRecruitment {
  __typename: "Recruitment";
  id: string;
  language: string | null;
  type: string | null;
  numberOfEmployments: number | null;
  salaryType: SALARY_TYPE | null;
  salaryDescription: string | null;
  enabled: boolean | null;
  title: string;
  role: string | null;
  professionType: PROFESSION_TYPE | null;
  professionLocaleFieldID: string | null;
  professionLocaleCode: string | null;
  professionOccupationNameID: string | null;
  municipalityID: string | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  location: string | null;
  extent: number | null;
  countryCode: string | null;
  employmentType: EMPLOYMENT_TYPE | null;
  employmentTemporaryType: EMPLOYMENT_TEMPORARY_TYPE | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  internalJobDescription: string;
  useCustomerProfile: boolean | null;
  messageToLateAdVisitors: string | null;
  useCompanyAddress: boolean | null;
  postalCode: string | null;
  postalAddress: string | null;
  municipality: string | null;
  useCustomCompleteApplicationPage: boolean | null;
  customCompleteApplicationPageTitle: string | null;
  customCompleteApplicationPageDescription: string | null;
}

export interface UpdateRecruitment {
  updateRecruitment: UpdateRecruitment_updateRecruitment | null;
}

export interface UpdateRecruitmentVariables {
  recruitmentId: string;
  data: RecruitmentUpdate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRecruitmentProfession
// ====================================================

export interface UpdateRecruitmentProfession_updateRecruitmentProfession {
  __typename: "Recruitment";
  id: string;
}

export interface UpdateRecruitmentProfession {
  updateRecruitmentProfession: UpdateRecruitmentProfession_updateRecruitmentProfession | null;
}

export interface UpdateRecruitmentProfessionVariables {
  recruitmentId: string;
  professionLocaleFieldID: string;
  professionLocaleCode: string;
  professionOccupationNameID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRecruitmentRoles
// ====================================================

export interface UpdateRecruitmentRoles_updateRecruitmentRoles_recruitingManager {
  __typename: "User";
  id: string;
}

export interface UpdateRecruitmentRoles_updateRecruitmentRoles_contactPerson {
  __typename: "User";
  id: string;
}

export interface UpdateRecruitmentRoles_updateRecruitmentRoles_responsibleRecruiter {
  __typename: "User";
  id: string;
}

export interface UpdateRecruitmentRoles_updateRecruitmentRoles {
  __typename: "Recruitment";
  id: string;
  recruitingManager: UpdateRecruitmentRoles_updateRecruitmentRoles_recruitingManager | null;
  contactPerson: UpdateRecruitmentRoles_updateRecruitmentRoles_contactPerson | null;
  responsibleRecruiter: UpdateRecruitmentRoles_updateRecruitmentRoles_responsibleRecruiter | null;
}

export interface UpdateRecruitmentRoles {
  updateRecruitmentRoles: UpdateRecruitmentRoles_updateRecruitmentRoles | null;
}

export interface UpdateRecruitmentRolesVariables {
  recruitmentId: string;
  recruitingManagerId?: string | null;
  contactPersonId?: string | null;
  responsibleRecruiterId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRecruitmentStatus
// ====================================================

export interface UpdateRecruitmentStatus_updateRecruitmentStatus_status {
  __typename: "Status";
  id: string;
}

export interface UpdateRecruitmentStatus_updateRecruitmentStatus {
  __typename: "Recruitment";
  id: string;
  status: UpdateRecruitmentStatus_updateRecruitmentStatus_status | null;
}

export interface UpdateRecruitmentStatus {
  updateRecruitmentStatus: UpdateRecruitmentStatus_updateRecruitmentStatus | null;
}

export interface UpdateRecruitmentStatusVariables {
  recruitmentId: string;
  statusId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRecruitmentCompetence
// ====================================================

export interface CreateRecruitmentCompetence_createRecruitmentCompetence {
  __typename: "RecruitmentCompetence";
  id: string;
}

export interface CreateRecruitmentCompetence {
  createRecruitmentCompetence: CreateRecruitmentCompetence_createRecruitmentCompetence | null;
}

export interface CreateRecruitmentCompetenceVariables {
  companyId: string;
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
  name: string;
  required: boolean;
  type: COMPETENCE_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteRecruitmentCompetence
// ====================================================

export interface DeleteRecruitmentCompetence_deleteRecruitmentCompetence {
  __typename: "RecruitmentCompetence";
  id: string;
}

export interface DeleteRecruitmentCompetence {
  deleteRecruitmentCompetence: DeleteRecruitmentCompetence_deleteRecruitmentCompetence | null;
}

export interface DeleteRecruitmentCompetenceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderRecruitmentCompetences
// ====================================================

export interface ReorderRecruitmentCompetences_reorderRecruitmentCompetences {
  __typename: "RecruitmentCompetence";
  id: string;
  listIndex: number | null;
}

export interface ReorderRecruitmentCompetences {
  reorderRecruitmentCompetences: ReorderRecruitmentCompetences_reorderRecruitmentCompetences[];
}

export interface ReorderRecruitmentCompetencesVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRecruitmentCompetence
// ====================================================

export interface UpdateRecruitmentCompetence_updateRecruitmentCompetence_competence {
  __typename: "Competence";
  id: string;
  name: string;
}

export interface UpdateRecruitmentCompetence_updateRecruitmentCompetence {
  __typename: "RecruitmentCompetence";
  id: string;
  competence: UpdateRecruitmentCompetence_updateRecruitmentCompetence_competence;
}

export interface UpdateRecruitmentCompetence {
  updateRecruitmentCompetence: UpdateRecruitmentCompetence_updateRecruitmentCompetence | null;
}

export interface UpdateRecruitmentCompetenceVariables {
  id: string;
  name: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRecruitmentTeam
// ====================================================

export interface CreateRecruitmentTeam_createRecruitmentTeam_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CreateRecruitmentTeam_createRecruitmentTeam_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  subscribedMailNotificationTypes: NOTIFICATION_TYPE[];
  subscribedToNotificationsStatus: SUBSCRIBED_TO_NOTIFICATIONS_STATUS | null;
  profileImage: CreateRecruitmentTeam_createRecruitmentTeam_users_user_profileImage | null;
}

export interface CreateRecruitmentTeam_createRecruitmentTeam_users {
  __typename: "UserRecruitment";
  id: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
  user: CreateRecruitmentTeam_createRecruitmentTeam_users_user;
}

export interface CreateRecruitmentTeam_createRecruitmentTeam {
  __typename: "RecruitmentTeam";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  users: CreateRecruitmentTeam_createRecruitmentTeam_users[];
}

export interface CreateRecruitmentTeam {
  createRecruitmentTeam: CreateRecruitmentTeam_createRecruitmentTeam;
}

export interface CreateRecruitmentTeamVariables {
  companyId: string;
  title: string;
  description?: string | null;
  users: UserRecruitmentInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRecruitmentTeam
// ====================================================

export interface UpdateRecruitmentTeam_updateRecruitmentTeam_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateRecruitmentTeam_updateRecruitmentTeam_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  subscribedMailNotificationTypes: NOTIFICATION_TYPE[];
  subscribedToNotificationsStatus: SUBSCRIBED_TO_NOTIFICATIONS_STATUS | null;
  profileImage: UpdateRecruitmentTeam_updateRecruitmentTeam_users_user_profileImage | null;
}

export interface UpdateRecruitmentTeam_updateRecruitmentTeam_users {
  __typename: "UserRecruitment";
  id: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
  user: UpdateRecruitmentTeam_updateRecruitmentTeam_users_user;
}

export interface UpdateRecruitmentTeam_updateRecruitmentTeam {
  __typename: "RecruitmentTeam";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  users: UpdateRecruitmentTeam_updateRecruitmentTeam_users[];
}

export interface UpdateRecruitmentTeam {
  updateRecruitmentTeam: UpdateRecruitmentTeam_updateRecruitmentTeam;
}

export interface UpdateRecruitmentTeamVariables {
  id: string;
  title: string;
  description?: string | null;
  users?: UserRecruitmentInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteRecruitmentTeam
// ====================================================

export interface DeleteRecruitmentTeam_deleteRecruitmentTeam {
  __typename: "RecruitmentTeam";
  id: string;
}

export interface DeleteRecruitmentTeam {
  deleteRecruitmentTeam: DeleteRecruitmentTeam_deleteRecruitmentTeam | null;
}

export interface DeleteRecruitmentTeamVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeRecruitmentTemplateShareAccessStatus
// ====================================================

export interface ChangeRecruitmentTemplateShareAccessStatus_changeRecruitmentTemplateShareAccessStatus {
  __typename: "RecruitmentTemplate";
  id: string;
}

export interface ChangeRecruitmentTemplateShareAccessStatus {
  changeRecruitmentTemplateShareAccessStatus: ChangeRecruitmentTemplateShareAccessStatus_changeRecruitmentTemplateShareAccessStatus | null;
}

export interface ChangeRecruitmentTemplateShareAccessStatusVariables {
  recruitmentTemplateId: string;
  shareAccessStatus: TEMPLATE_SHARE_STATUS;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CopyRecruitmentTemplate
// ====================================================

export interface CopyRecruitmentTemplate_copyRecruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
}

export interface CopyRecruitmentTemplate {
  copyRecruitmentTemplate: CopyRecruitmentTemplate_copyRecruitmentTemplate | null;
}

export interface CopyRecruitmentTemplateVariables {
  newCompanyId: string;
  name: string;
  recruitmentTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRecruitmentTemplate
// ====================================================

export interface CreateRecruitmentTemplate_createRecruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
}

export interface CreateRecruitmentTemplate {
  createRecruitmentTemplate: CreateRecruitmentTemplate_createRecruitmentTemplate | null;
}

export interface CreateRecruitmentTemplateVariables {
  companyId: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteRecruitmentTemplate
// ====================================================

export interface DeleteRecruitmentTemplate_deleteRecruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
}

export interface DeleteRecruitmentTemplate {
  deleteRecruitmentTemplate: DeleteRecruitmentTemplate_deleteRecruitmentTemplate | null;
}

export interface DeleteRecruitmentTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MoveRecruitmentTemplate
// ====================================================

export interface MoveRecruitmentTemplate_moveRecruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
}

export interface MoveRecruitmentTemplate {
  moveRecruitmentTemplate: MoveRecruitmentTemplate_moveRecruitmentTemplate | null;
}

export interface MoveRecruitmentTemplateVariables {
  recruitmentTemplateId: string;
  newCompanyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveRecruitmentTemplateFromRecruitment
// ====================================================

export interface SaveRecruitmentTemplateFromRecruitment_saveRecruitmentTemplateFromRecruitment {
  __typename: "RecruitmentTemplate";
  id: string;
}

export interface SaveRecruitmentTemplateFromRecruitment {
  saveRecruitmentTemplateFromRecruitment: SaveRecruitmentTemplateFromRecruitment_saveRecruitmentTemplateFromRecruitment | null;
}

export interface SaveRecruitmentTemplateFromRecruitmentVariables {
  companyId: string;
  name: string;
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDefaultRecruitmentTemplate
// ====================================================

export interface SetDefaultRecruitmentTemplate_setDefaultRecruitmentTemplate_companiesUsingThisAsDefault {
  __typename: "Company";
  id: string;
}

export interface SetDefaultRecruitmentTemplate_setDefaultRecruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
  companiesUsingThisAsDefault: SetDefaultRecruitmentTemplate_setDefaultRecruitmentTemplate_companiesUsingThisAsDefault[];
}

export interface SetDefaultRecruitmentTemplate {
  setDefaultRecruitmentTemplate: SetDefaultRecruitmentTemplate_setDefaultRecruitmentTemplate | null;
}

export interface SetDefaultRecruitmentTemplateVariables {
  companyIds: string[];
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShareRecruitmentTemplateWithCompanies
// ====================================================

export interface ShareRecruitmentTemplateWithCompanies_shareRecruitmentTemplateWithCompanies {
  __typename: "RecruitmentTemplate";
  id: string;
}

export interface ShareRecruitmentTemplateWithCompanies {
  shareRecruitmentTemplateWithCompanies: ShareRecruitmentTemplateWithCompanies_shareRecruitmentTemplateWithCompanies | null;
}

export interface ShareRecruitmentTemplateWithCompaniesVariables {
  recruitmentTemplateId: string;
  companyIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRecruitmentTemplate
// ====================================================

export interface UpdateRecruitmentTemplate_updateRecruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
}

export interface UpdateRecruitmentTemplate {
  updateRecruitmentTemplate: UpdateRecruitmentTemplate_updateRecruitmentTemplate | null;
}

export interface UpdateRecruitmentTemplateVariables {
  id: string;
  companyId: string;
  name?: string | null;
  language?: string | null;
  numberOfEmployments?: number | null;
  salaryType?: SALARY_TYPE | null;
  salaryDescription?: string | null;
  professionType?: PROFESSION_TYPE | null;
  professionLocaleFieldID?: string | null;
  professionLocaleCode?: string | null;
  professionOccupationNameID?: string | null;
  municipalityID?: string | null;
  dateSpanEnabled?: boolean | null;
  location?: string | null;
  extent?: number | null;
  employmentType?: EMPLOYMENT_TYPE | null;
  employmentTemporaryType?: EMPLOYMENT_TEMPORARY_TYPE | null;
  internalJobDescription?: string | null;
  messageToLateAdVisitors?: string | null;
  useCompanyAddress?: boolean | null;
  postalCode?: string | null;
  postalAddress?: string | null;
  municipality?: string | null;
  countryCode?: string | null;
  useCustomCompleteApplicationPage?: boolean | null;
  customCompleteApplicationPageTitle?: string | null;
  customCompleteApplicationPageDescription?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRecruitmentVisitorAccess
// ====================================================

export interface CreateRecruitmentVisitorAccess_createRecruitmentVisitorAccess {
  __typename: "RecruitmentVisitorAccess";
  id: string;
}

export interface CreateRecruitmentVisitorAccess {
  createRecruitmentVisitorAccess: CreateRecruitmentVisitorAccess_createRecruitmentVisitorAccess | null;
}

export interface CreateRecruitmentVisitorAccessVariables {
  recruitmentId: string;
  input: RecruitmentVisitorUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRecruitmentVisitorAccess
// ====================================================

export interface UpdateRecruitmentVisitorAccess_updateRecruitmentVisitorAccess {
  __typename: "RecruitmentVisitorAccess";
  id: string;
}

export interface UpdateRecruitmentVisitorAccess {
  updateRecruitmentVisitorAccess: UpdateRecruitmentVisitorAccess_updateRecruitmentVisitorAccess | null;
}

export interface UpdateRecruitmentVisitorAccessVariables {
  id: string;
  input: RecruitmentVisitorUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteRecruitmentVisitorAccess
// ====================================================

export interface DeleteRecruitmentVisitorAccess_deleteRecruitmentVisitorAccess {
  __typename: "RecruitmentVisitorAccess";
  id: string;
}

export interface DeleteRecruitmentVisitorAccess {
  deleteRecruitmentVisitorAccess: DeleteRecruitmentVisitorAccess_deleteRecruitmentVisitorAccess | null;
}

export interface DeleteRecruitmentVisitorAccessVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateReference
// ====================================================

export interface UpdateReference_updateReference {
  __typename: "FormParticipant";
  id: string;
}

export interface UpdateReference {
  updateReference: UpdateReference_updateReference | null;
}

export interface UpdateReferenceVariables {
  id: string;
  input: ReferenceUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddQuestionsFromCompetencesToReferenceForm
// ====================================================

export interface AddQuestionsFromCompetencesToReferenceForm_addQuestionsFromCompetencesToReferenceForm_form_questions {
  __typename: "FormQuestion";
  id: string;
}

export interface AddQuestionsFromCompetencesToReferenceForm_addQuestionsFromCompetencesToReferenceForm_form {
  __typename: "Form";
  id: string;
  questions: AddQuestionsFromCompetencesToReferenceForm_addQuestionsFromCompetencesToReferenceForm_form_questions[];
}

export interface AddQuestionsFromCompetencesToReferenceForm_addQuestionsFromCompetencesToReferenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
  form: AddQuestionsFromCompetencesToReferenceForm_addQuestionsFromCompetencesToReferenceForm_form;
}

export interface AddQuestionsFromCompetencesToReferenceForm {
  addQuestionsFromCompetencesToReferenceForm: AddQuestionsFromCompetencesToReferenceForm_addQuestionsFromCompetencesToReferenceForm | null;
}

export interface AddQuestionsFromCompetencesToReferenceFormVariables {
  referenceFormId: string;
  competenceIds: string[];
  language: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateReferenceForm
// ====================================================

export interface UpdateReferenceForm_updateReferenceForm_form {
  __typename: "Form";
  id: string;
  title: string | null;
  description: string | null;
}

export interface UpdateReferenceForm_updateReferenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
  form: UpdateReferenceForm_updateReferenceForm_form;
}

export interface UpdateReferenceForm {
  updateReferenceForm: UpdateReferenceForm_updateReferenceForm | null;
}

export interface UpdateReferenceFormVariables {
  id: string;
  title?: string | null;
  externalTitle?: string | null;
  externalDescription?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserFromRecruitment
// ====================================================

export interface RemoveUserFromRecruitment_removeUserFromRecruitment {
  __typename: "BatchPayload";
  count: number;
}

export interface RemoveUserFromRecruitment {
  removeUserFromRecruitment: RemoveUserFromRecruitment_removeUserFromRecruitment | null;
}

export interface RemoveUserFromRecruitmentVariables {
  userId: string;
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateScoringMatrixQuestions
// ====================================================

export interface GenerateScoringMatrixQuestions_generateScoringMatrixQuestions_questions {
  __typename: "ScoringMatrixQuestion";
  id: string;
  question: string;
  index: number;
}

export interface GenerateScoringMatrixQuestions_generateScoringMatrixQuestions {
  __typename: "ScoringMatrix";
  id: string;
  questions: GenerateScoringMatrixQuestions_generateScoringMatrixQuestions_questions[];
}

export interface GenerateScoringMatrixQuestions {
  generateScoringMatrixQuestions: GenerateScoringMatrixQuestions_generateScoringMatrixQuestions | null;
}

export interface GenerateScoringMatrixQuestionsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderScoringMatrix
// ====================================================

export interface ReorderScoringMatrix_reorderScoringMatrix_questions {
  __typename: "ScoringMatrixQuestion";
  id: string;
  index: number;
}

export interface ReorderScoringMatrix_reorderScoringMatrix {
  __typename: "ScoringMatrix";
  id: string;
  questions: ReorderScoringMatrix_reorderScoringMatrix_questions[];
}

export interface ReorderScoringMatrix {
  reorderScoringMatrix: ReorderScoringMatrix_reorderScoringMatrix | null;
}

export interface ReorderScoringMatrixVariables {
  questionIds: string[];
  scoringMatrixId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetScoringMatrixAnswer
// ====================================================

export interface SetScoringMatrixAnswer_setScoringMatrixAnswer_question {
  __typename: "ScoringMatrixQuestion";
  id: string;
}

export interface SetScoringMatrixAnswer_setScoringMatrixAnswer_interview {
  __typename: "Interview";
  id: string;
}

export interface SetScoringMatrixAnswer_setScoringMatrixAnswer {
  __typename: "ScoringMatrixAnswer";
  id: string;
  grade: number;
  question: SetScoringMatrixAnswer_setScoringMatrixAnswer_question;
  interview: SetScoringMatrixAnswer_setScoringMatrixAnswer_interview;
}

export interface SetScoringMatrixAnswer {
  setScoringMatrixAnswer: SetScoringMatrixAnswer_setScoringMatrixAnswer | null;
}

export interface SetScoringMatrixAnswerVariables {
  interviewId: string;
  questionId: string;
  grade: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateScoringMatrixQuestion
// ====================================================

export interface CreateScoringMatrixQuestion_createScoringMatrixQuestion {
  __typename: "ScoringMatrixQuestion";
  id: string;
  index: number;
  question: string;
  description: string | null;
  descriptionLabels: string[];
  type: ScoringMatrixQuestionType | null;
}

export interface CreateScoringMatrixQuestion {
  createScoringMatrixQuestion: CreateScoringMatrixQuestion_createScoringMatrixQuestion | null;
}

export interface CreateScoringMatrixQuestionVariables {
  id: string;
  question: string;
  type: ScoringMatrixQuestionType;
  description?: string | null;
  descriptionLabels?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteScoringMatrixQuestion
// ====================================================

export interface DeleteScoringMatrixQuestion_deleteScoringMatrixQuestion {
  __typename: "ScoringMatrixQuestion";
  id: string;
}

export interface DeleteScoringMatrixQuestion {
  deleteScoringMatrixQuestion: DeleteScoringMatrixQuestion_deleteScoringMatrixQuestion | null;
}

export interface DeleteScoringMatrixQuestionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditScoringMatrixQuestion
// ====================================================

export interface EditScoringMatrixQuestion_editScoringMatrixQuestion_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface EditScoringMatrixQuestion_editScoringMatrixQuestion {
  __typename: "ScoringMatrixQuestion";
  id: string;
  index: number;
  question: string;
  description: string | null;
  descriptionLabels: string[];
  type: ScoringMatrixQuestionType | null;
  recruitment: EditScoringMatrixQuestion_editScoringMatrixQuestion_recruitment | null;
}

export interface EditScoringMatrixQuestion {
  editScoringMatrixQuestion: EditScoringMatrixQuestion_editScoringMatrixQuestion | null;
}

export interface EditScoringMatrixQuestionVariables {
  id: string;
  question?: string | null;
  type?: ScoringMatrixQuestionType | null;
  description?: string | null;
  descriptionLabels?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateScreeningForm
// ====================================================

export interface CreateScreeningForm_createScreeningForm {
  __typename: "ScreeningForm";
  id: string;
}

export interface CreateScreeningForm {
  createScreeningForm: CreateScreeningForm_createScreeningForm | null;
}

export interface CreateScreeningFormVariables {
  companyId: string;
  title: string;
  externalTitle?: string | null;
  externalDescription?: string | null;
  recruitmentId?: string | null;
  formTemplateId?: string | null;
  applications?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteScreeningForm
// ====================================================

export interface DeleteScreeningForm_deleteScreeningForm {
  __typename: "ScreeningForm";
  id: string;
}

export interface DeleteScreeningForm {
  deleteScreeningForm: DeleteScreeningForm_deleteScreeningForm | null;
}

export interface DeleteScreeningFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateScreeningForm
// ====================================================

export interface UpdateScreeningForm_updateScreeningForm_form {
  __typename: "Form";
  id: string;
  title: string | null;
  description: string | null;
}

export interface UpdateScreeningForm_updateScreeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
  form: UpdateScreeningForm_updateScreeningForm_form;
}

export interface UpdateScreeningForm {
  updateScreeningForm: UpdateScreeningForm_updateScreeningForm | null;
}

export interface UpdateScreeningFormVariables {
  id: string;
  title?: string | null;
  externalTitle?: string | null;
  externalDescription?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateScreeningFormParticipants
// ====================================================

export interface UpdateScreeningFormParticipants_updateScreeningFormParticipants {
  __typename: "ScreeningForm";
  id: string;
}

export interface UpdateScreeningFormParticipants {
  updateScreeningFormParticipants: UpdateScreeningFormParticipants_updateScreeningFormParticipants | null;
}

export interface UpdateScreeningFormParticipantsVariables {
  formId: string;
  addedApplications: string[];
  deletedParticipants: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserPrivilegeLevel
// ====================================================

export interface SetUserPrivilegeLevel_setUserPrivilegeLevel {
  __typename: "BatchPayload";
  count: number;
}

export interface SetUserPrivilegeLevel {
  setUserPrivilegeLevel: SetUserPrivilegeLevel_setUserPrivilegeLevel | null;
}

export interface SetUserPrivilegeLevelVariables {
  userId: string;
  recruitmentId: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateStatus
// ====================================================

export interface CreateStatus_createStatus_company {
  __typename: "Company";
  id: string;
}

export interface CreateStatus_createStatus {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
  description: string | null;
  company: CreateStatus_createStatus_company;
}

export interface CreateStatus {
  createStatus: CreateStatus_createStatus | null;
}

export interface CreateStatusVariables {
  companyId: string;
  title: string;
  description?: string | null;
  color?: string | null;
  type: STATUS_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteStatus
// ====================================================

export interface DeleteStatus_deleteStatus {
  __typename: "Status";
  id: string;
}

export interface DeleteStatus {
  deleteStatus: DeleteStatus_deleteStatus | null;
}

export interface DeleteStatusVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderStatuses
// ====================================================

export interface ReorderStatuses_reorderStatuses_company {
  __typename: "Company";
  id: string;
}

export interface ReorderStatuses_reorderStatuses {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
  description: string | null;
  company: ReorderStatuses_reorderStatuses_company;
}

export interface ReorderStatuses {
  reorderStatuses: ReorderStatuses_reorderStatuses[];
}

export interface ReorderStatusesVariables {
  companyId: string;
  ids: string[];
  type: STATUS_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStatus
// ====================================================

export interface UpdateStatus_updateStatus_company {
  __typename: "Company";
  id: string;
}

export interface UpdateStatus_updateStatus {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
  description: string | null;
  company: UpdateStatus_updateStatus_company;
}

export interface UpdateStatus {
  updateStatus: UpdateStatus_updateStatus | null;
}

export interface UpdateStatusVariables {
  id: string;
  title?: string | null;
  description?: string | null;
  color?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStatusDeadline
// ====================================================

export interface UpdateStatusDeadline_updateStatusDeadline {
  __typename: "StatusDeadline";
  id: string;
}

export interface UpdateStatusDeadline {
  updateStatusDeadline: UpdateStatusDeadline_updateStatusDeadline | null;
}

export interface UpdateStatusDeadlineVariables {
  statusId: string;
  recruitmentId: string;
  deadline: DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteStatusDeadline
// ====================================================

export interface DeleteStatusDeadline_deleteStatusDeadline {
  __typename: "StatusDeadline";
  id: string;
}

export interface DeleteStatusDeadline {
  deleteStatusDeadline: DeleteStatusDeadline_deleteStatusDeadline | null;
}

export interface DeleteStatusDeadlineVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTagLabel
// ====================================================

export interface CreateTagLabel_createTagLabel {
  __typename: "TagLabel";
  id: string;
}

export interface CreateTagLabel {
  createTagLabel: CreateTagLabel_createTagLabel | null;
}

export interface CreateTagLabelVariables {
  companyId: string;
  groupCompanyApplicantId?: string | null;
  applicationId?: string | null;
  applicantId?: string | null;
  recruitmentId?: string | null;
  consultantProjectId?: string | null;
  consultantId?: string | null;
  recruitmentTemplateId?: string | null;
  tag: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTagLabel
// ====================================================

export interface DeleteTagLabel_deleteTagLabel {
  __typename: "TagLabel";
  id: string;
}

export interface DeleteTagLabel {
  deleteTagLabel: DeleteTagLabel_deleteTagLabel | null;
}

export interface DeleteTagLabelVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteBaseTag
// ====================================================

export interface DeleteBaseTag_deleteBaseTag {
  __typename: "BaseTag";
  id: string;
}

export interface DeleteBaseTag {
  deleteBaseTag: DeleteBaseTag_deleteBaseTag | null;
}

export interface DeleteBaseTagVariables {
  id: string;
  companyId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRecruitmentInternalJobDescription
// ====================================================

export interface UpdateRecruitmentInternalJobDescription_updateRecruitmentInternalJobDescription {
  __typename: "Recruitment";
  id: string;
  internalJobDescription: string;
}

export interface UpdateRecruitmentInternalJobDescription {
  updateRecruitmentInternalJobDescription: UpdateRecruitmentInternalJobDescription_updateRecruitmentInternalJobDescription | null;
}

export interface UpdateRecruitmentInternalJobDescriptionVariables {
  recruitmentId: string;
  internalJobDescription: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadUserProfileImage
// ====================================================

export interface UploadUserProfileImage_uploadUserProfileImage_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UploadUserProfileImage_uploadUserProfileImage {
  __typename: "User";
  id: string;
  profileImage: UploadUserProfileImage_uploadUserProfileImage_profileImage | null;
}

export interface UploadUserProfileImage {
  uploadUserProfileImage: UploadUserProfileImage_uploadUserProfileImage | null;
}

export interface UploadUserProfileImageVariables {
  userId: string;
  file: Upload;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserAgent
// ====================================================

export interface UpdateUserAgent {
  updateUserAgent: boolean | null;
}

export interface UpdateUserAgentVariables {
  userAgent: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeMyLanguage
// ====================================================

export interface ChangeMyLanguage_changeMyLanguage {
  __typename: "User";
  id: string;
  language: string | null;
}

export interface ChangeMyLanguage {
  changeMyLanguage: ChangeMyLanguage_changeMyLanguage | null;
}

export interface ChangeMyLanguageVariables {
  language: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePasswordChangeTicket
// ====================================================

export interface CreatePasswordChangeTicket {
  createPasswordChangeTicket: string | null;
}

export interface CreatePasswordChangeTicketVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUser
// ====================================================

export interface DeleteUser_deleteUser {
  __typename: "User";
  id: string;
}

export interface DeleteUser {
  deleteUser: DeleteUser_deleteUser | null;
}

export interface DeleteUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteUser
// ====================================================

export interface InviteUser_inviteUser {
  __typename: "UserInvitation";
  id: string;
}

export interface InviteUser {
  inviteUser: InviteUser_inviteUser | null;
}

export interface InviteUserVariables {
  companyId: string;
  email: string;
  firstName: string;
  lastName: string;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RevokeInvitation
// ====================================================

export interface RevokeInvitation_revokeInvitation {
  __typename: "UserInvitation";
  id: string;
}

export interface RevokeInvitation {
  revokeInvitation: RevokeInvitation_revokeInvitation | null;
}

export interface RevokeInvitationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserCompanyPrivilegeLevel
// ====================================================

export interface SetUserCompanyPrivilegeLevel_setUserCompanyPrivilegeLevel {
  __typename: "User";
  id: string;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
}

export interface SetUserCompanyPrivilegeLevel {
  setUserCompanyPrivilegeLevel: SetUserCompanyPrivilegeLevel_setUserCompanyPrivilegeLevel | null;
}

export interface SetUserCompanyPrivilegeLevelVariables {
  userId: string;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserInvitationPrivilegeLevel
// ====================================================

export interface SetUserInvitationPrivilegeLevel_setUserInvitationPrivilegeLevel {
  __typename: "UserInvitation";
  id: string;
  privilegeLevel: USER_PRIVILEGE_LEVEL | null;
}

export interface SetUserInvitationPrivilegeLevel {
  setUserInvitationPrivilegeLevel: SetUserInvitationPrivilegeLevel_setUserInvitationPrivilegeLevel | null;
}

export interface SetUserInvitationPrivilegeLevelVariables {
  companyId: string;
  userInvitationId: string;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSubscribedMailNotifications
// ====================================================

export interface UpdateSubscribedMailNotifications_updateSubscribedMailNotifications {
  __typename: "User";
  id: string;
  subscribedMailNotificationTypes: NOTIFICATION_TYPE[];
  subscribedToNotificationsStatus: SUBSCRIBED_TO_NOTIFICATIONS_STATUS | null;
}

export interface UpdateSubscribedMailNotifications {
  updateSubscribedMailNotifications: UpdateSubscribedMailNotifications_updateSubscribedMailNotifications | null;
}

export interface UpdateSubscribedMailNotificationsVariables {
  mailSubscriptions?: NOTIFICATION_TYPE[] | null;
  subscribedToNotificationsStatus?: SUBSCRIBED_TO_NOTIFICATIONS_STATUS | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UpdateUser_updateUser {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: UpdateUser_updateUser_profileImage | null;
}

export interface UpdateUser {
  updateUser: UpdateUser_updateUser | null;
}

export interface UpdateUserVariables {
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  jobTitle?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendFeedback
// ====================================================

export interface SendFeedback {
  sendFeedback: boolean | null;
}

export interface SendFeedbackVariables {
  message: string;
  screenshotUrl?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeVariableShareAccessStatus
// ====================================================

export interface ChangeVariableShareAccessStatus_changeVariableShareAccessStatus {
  __typename: "Variable";
  id: string;
}

export interface ChangeVariableShareAccessStatus {
  changeVariableShareAccessStatus: ChangeVariableShareAccessStatus_changeVariableShareAccessStatus | null;
}

export interface ChangeVariableShareAccessStatusVariables {
  variableId: string;
  shareAccessStatus: TEMPLATE_SHARE_STATUS;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateVariable
// ====================================================

export interface CreateVariable_createVariable {
  __typename: "Variable";
  id: string;
}

export interface CreateVariable {
  createVariable: CreateVariable_createVariable | null;
}

export interface CreateVariableVariables {
  key: string;
  value: string;
  description?: string | null;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteVariable
// ====================================================

export interface DeleteVariable_deleteVariable {
  __typename: "Variable";
  id: string;
}

export interface DeleteVariable {
  deleteVariable: DeleteVariable_deleteVariable | null;
}

export interface DeleteVariableVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShareVariableWithCompanies
// ====================================================

export interface ShareVariableWithCompanies_shareVariableWithCompanies {
  __typename: "Variable";
  id: string;
}

export interface ShareVariableWithCompanies {
  shareVariableWithCompanies: ShareVariableWithCompanies_shareVariableWithCompanies | null;
}

export interface ShareVariableWithCompaniesVariables {
  variableId: string;
  companyIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateVariableValue
// ====================================================

export interface UpdateVariableValue_updateVariableValue {
  __typename: "Variable";
  id: string;
  value: string;
}

export interface UpdateVariableValue {
  updateVariableValue: UpdateVariableValue_updateVariableValue | null;
}

export interface UpdateVariableValueVariables {
  key: string;
  value: string;
  description?: string | null;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWidget
// ====================================================

export interface CreateWidget_createWidget {
  __typename: "Widget";
  id: string;
  name: string;
  description: string | null;
  type: WIDGET_TYPE;
  customCss: boolean | null;
  css: string | null;
  allowSorting: boolean | null;
  showCompanyLogoOnAds: boolean | null;
  showCompanyNameOnAds: boolean | null;
  showExpiredAds: boolean | null;
  primaryColor: string | null;
  secondaryColor: string | null;
}

export interface CreateWidget {
  createWidget: CreateWidget_createWidget | null;
}

export interface CreateWidgetVariables {
  companyId: string;
  type: WIDGET_TYPE;
  name: string;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteWidget
// ====================================================

export interface DeleteWidget_deleteWidget {
  __typename: "Widget";
  id: string;
  name: string;
  description: string | null;
  type: WIDGET_TYPE;
  customCss: boolean | null;
  css: string | null;
  allowSorting: boolean | null;
  showCompanyLogoOnAds: boolean | null;
  showCompanyNameOnAds: boolean | null;
  showExpiredAds: boolean | null;
  primaryColor: string | null;
  secondaryColor: string | null;
}

export interface DeleteWidget {
  deleteWidget: DeleteWidget_deleteWidget | null;
}

export interface DeleteWidgetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWidget
// ====================================================

export interface UpdateWidget_updateWidget {
  __typename: "Widget";
  id: string;
  name: string;
  description: string | null;
  type: WIDGET_TYPE;
  customCss: boolean | null;
  css: string | null;
  allowSorting: boolean | null;
  showCompanyLogoOnAds: boolean | null;
  showCompanyNameOnAds: boolean | null;
  showExpiredAds: boolean | null;
  primaryColor: string | null;
  secondaryColor: string | null;
}

export interface UpdateWidget {
  updateWidget: UpdateWidget_updateWidget | null;
}

export interface UpdateWidgetVariables {
  id: string;
  data: WidgetUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActivityFeed
// ====================================================

export interface ActivityFeed_activityFeed_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ActivityFeed_activityFeed_edges_node_comment_mentions {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ActivityFeed_activityFeed_edges_node_comment_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_comment_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: ActivityFeed_activityFeed_edges_node_comment_application_recruitment_company;
}

export interface ActivityFeed_activityFeed_edges_node_comment_application {
  __typename: "Application";
  id: string;
  recruitment: ActivityFeed_activityFeed_edges_node_comment_application_recruitment;
}

export interface ActivityFeed_activityFeed_edges_node_comment_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_comment_recruitment {
  __typename: "Recruitment";
  id: string;
  company: ActivityFeed_activityFeed_edges_node_comment_recruitment_company;
}

export interface ActivityFeed_activityFeed_edges_node_comment_applicant_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_comment_applicant {
  __typename: "CompanyApplicant";
  id: string;
  company: ActivityFeed_activityFeed_edges_node_comment_applicant_company;
}

export interface ActivityFeed_activityFeed_edges_node_comment_assignment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_comment_assignment {
  __typename: "Assignment";
  id: string;
  company: ActivityFeed_activityFeed_edges_node_comment_assignment_company;
}

export interface ActivityFeed_activityFeed_edges_node_comment_consultantProject_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_comment_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  company: ActivityFeed_activityFeed_edges_node_comment_consultantProject_company;
}

export interface ActivityFeed_activityFeed_edges_node_comment {
  __typename: "Comment";
  id: string;
  text: string;
  mentions: ActivityFeed_activityFeed_edges_node_comment_mentions[];
  application: ActivityFeed_activityFeed_edges_node_comment_application | null;
  recruitment: ActivityFeed_activityFeed_edges_node_comment_recruitment | null;
  applicant: ActivityFeed_activityFeed_edges_node_comment_applicant | null;
  assignment: ActivityFeed_activityFeed_edges_node_comment_assignment | null;
  consultantProject: ActivityFeed_activityFeed_edges_node_comment_consultantProject | null;
}

export interface ActivityFeed_activityFeed_edges_node_consultant_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  company: ActivityFeed_activityFeed_edges_node_consultant_company;
}

export interface ActivityFeed_activityFeed_edges_node_applicantMessage_applicant_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_applicantMessage_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: ActivityFeed_activityFeed_edges_node_applicantMessage_applicant_company;
}

export interface ActivityFeed_activityFeed_edges_node_applicantMessage_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_applicantMessage_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: ActivityFeed_activityFeed_edges_node_applicantMessage_application_recruitment_company;
}

export interface ActivityFeed_activityFeed_edges_node_applicantMessage_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: ActivityFeed_activityFeed_edges_node_applicantMessage_application_recruitment;
}

export interface ActivityFeed_activityFeed_edges_node_applicantMessage {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  applicant: ActivityFeed_activityFeed_edges_node_applicantMessage_applicant | null;
  application: ActivityFeed_activityFeed_edges_node_applicantMessage_application | null;
}

export interface ActivityFeed_activityFeed_edges_node_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: ActivityFeed_activityFeed_edges_node_recruitment_company;
}

export interface ActivityFeed_activityFeed_edges_node_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ActivityFeed_activityFeed_edges_node_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: ActivityFeed_activityFeed_edges_node_application_recruitment_company;
}

export interface ActivityFeed_activityFeed_edges_node_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: ActivityFeed_activityFeed_edges_node_application_companyApplicant | null;
  recruitment: ActivityFeed_activityFeed_edges_node_application_recruitment;
}

export interface ActivityFeed_activityFeed_edges_node_calendarEvent_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_calendarEvent {
  __typename: "CalendarEvent";
  id: string;
  startTime: DateTime;
  title: string | null;
  company: ActivityFeed_activityFeed_edges_node_calendarEvent_company;
}

export interface ActivityFeed_activityFeed_edges_node_applicationStatus_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_applicationStatus_recruitment {
  __typename: "Recruitment";
  id: string;
  company: ActivityFeed_activityFeed_edges_node_applicationStatus_recruitment_company;
}

export interface ActivityFeed_activityFeed_edges_node_applicationStatus_recruitmentTemplate_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_applicationStatus_recruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
  company: ActivityFeed_activityFeed_edges_node_applicationStatus_recruitmentTemplate_company;
}

export interface ActivityFeed_activityFeed_edges_node_applicationStatus {
  __typename: "ApplicationStatus";
  id: string;
  type: APPLICATION_STATUS_TYPE;
  title: string | null;
  listIndex: number | null;
  color: string | null;
  recruitment: ActivityFeed_activityFeed_edges_node_applicationStatus_recruitment | null;
  recruitmentTemplate: ActivityFeed_activityFeed_edges_node_applicationStatus_recruitmentTemplate | null;
}

export interface ActivityFeed_activityFeed_edges_node_applicant_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: ActivityFeed_activityFeed_edges_node_applicant_company;
}

export interface ActivityFeed_activityFeed_edges_node_consultantProject_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  title: string;
  company: ActivityFeed_activityFeed_edges_node_consultantProject_company;
}

export interface ActivityFeed_activityFeed_edges_node_consultantCustomer_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_consultantCustomer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
  company: ActivityFeed_activityFeed_edges_node_consultantCustomer_company;
}

export interface ActivityFeed_activityFeed_edges_node_connectedStatus_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_connectedStatus {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
  company: ActivityFeed_activityFeed_edges_node_connectedStatus_company;
}

export interface ActivityFeed_activityFeed_edges_node_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ActivityFeed_activityFeed_edges_node_user_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: ActivityFeed_activityFeed_edges_node_user_profileImage | null;
  company: ActivityFeed_activityFeed_edges_node_user_company;
}

export interface ActivityFeed_activityFeed_edges_node_assignment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_assignment_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_assignment_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_assignment_application {
  __typename: "Application";
  id: string;
  recruitment: ActivityFeed_activityFeed_edges_node_assignment_application_recruitment;
}

export interface ActivityFeed_activityFeed_edges_node_assignment {
  __typename: "Assignment";
  id: string;
  title: string;
  company: ActivityFeed_activityFeed_edges_node_assignment_company;
  recruitment: ActivityFeed_activityFeed_edges_node_assignment_recruitment | null;
  application: ActivityFeed_activityFeed_edges_node_assignment_application | null;
}

export interface ActivityFeed_activityFeed_edges_node_contract_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ActivityFeed_activityFeed_edges_node_contract_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_contract_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: ActivityFeed_activityFeed_edges_node_contract_application_recruitment;
}

export interface ActivityFeed_activityFeed_edges_node_contract_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ActivityFeed_activityFeed_edges_node_contract_customer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
}

export interface ActivityFeed_activityFeed_edges_node_contract_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_contract {
  __typename: "Contract";
  id: string;
  title: string;
  type: CONTRACT_TYPE | null;
  applicant: ActivityFeed_activityFeed_edges_node_contract_applicant | null;
  application: ActivityFeed_activityFeed_edges_node_contract_application | null;
  consultant: ActivityFeed_activityFeed_edges_node_contract_consultant | null;
  customer: ActivityFeed_activityFeed_edges_node_contract_customer | null;
  company: ActivityFeed_activityFeed_edges_node_contract_company | null;
}

export interface ActivityFeed_activityFeed_edges_node_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
  company: ActivityFeed_activityFeed_edges_node_interviewBookingGroup_company;
}

export interface ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  startTime: DateTime;
  endTime: DateTime;
}

export interface ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_application_recruitment;
}

export interface ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  company: ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_interviewBookingGroup_company;
}

export interface ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  bookedInterview: ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_bookedInterview | null;
  application: ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_application;
  interviewBookingGroup: ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_interviewBookingGroup;
}

export interface ActivityFeed_activityFeed_edges_node_referenceForm_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_referenceForm_form {
  __typename: "Form";
  id: string;
  title: string | null;
}

export interface ActivityFeed_activityFeed_edges_node_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
  company: ActivityFeed_activityFeed_edges_node_referenceForm_company;
  form: ActivityFeed_activityFeed_edges_node_referenceForm_form;
}

export interface ActivityFeed_activityFeed_edges_node_screeningForm_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_screeningForm_form {
  __typename: "Form";
  id: string;
  title: string | null;
}

export interface ActivityFeed_activityFeed_edges_node_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
  company: ActivityFeed_activityFeed_edges_node_screeningForm_company;
  form: ActivityFeed_activityFeed_edges_node_screeningForm_form;
}

export interface ActivityFeed_activityFeed_edges_node_formParticipant_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityFeed_activityFeed_edges_node_formParticipant_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: ActivityFeed_activityFeed_edges_node_formParticipant_application_recruitment_company;
}

export interface ActivityFeed_activityFeed_edges_node_formParticipant_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: ActivityFeed_activityFeed_edges_node_formParticipant_application_recruitment;
}

export interface ActivityFeed_activityFeed_edges_node_formParticipant_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ActivityFeed_activityFeed_edges_node_formParticipant {
  __typename: "FormParticipant";
  id: string;
  application: ActivityFeed_activityFeed_edges_node_formParticipant_application | null;
  reference: ActivityFeed_activityFeed_edges_node_formParticipant_reference | null;
}

export interface ActivityFeed_activityFeed_edges_node {
  __typename: "Activity";
  id: string;
  type: ActivityType;
  createdAt: DateTime;
  grade: number | null;
  status: STATUS | null;
  comment: ActivityFeed_activityFeed_edges_node_comment | null;
  consultant: ActivityFeed_activityFeed_edges_node_consultant | null;
  applicantMessage: ActivityFeed_activityFeed_edges_node_applicantMessage | null;
  recruitment: ActivityFeed_activityFeed_edges_node_recruitment | null;
  application: ActivityFeed_activityFeed_edges_node_application | null;
  calendarEvent: ActivityFeed_activityFeed_edges_node_calendarEvent | null;
  applicationStatus: ActivityFeed_activityFeed_edges_node_applicationStatus | null;
  applicant: ActivityFeed_activityFeed_edges_node_applicant | null;
  consultantProject: ActivityFeed_activityFeed_edges_node_consultantProject | null;
  consultantCustomer: ActivityFeed_activityFeed_edges_node_consultantCustomer | null;
  connectedStatus: ActivityFeed_activityFeed_edges_node_connectedStatus | null;
  user: ActivityFeed_activityFeed_edges_node_user | null;
  assignment: ActivityFeed_activityFeed_edges_node_assignment | null;
  contract: ActivityFeed_activityFeed_edges_node_contract | null;
  interviewBookingGroup: ActivityFeed_activityFeed_edges_node_interviewBookingGroup | null;
  interviewBookingGroupApplication: ActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication | null;
  referenceForm: ActivityFeed_activityFeed_edges_node_referenceForm | null;
  screeningForm: ActivityFeed_activityFeed_edges_node_screeningForm | null;
  formParticipant: ActivityFeed_activityFeed_edges_node_formParticipant | null;
  assignmentMove: ASSIGNMENT_STATUS | null;
}

export interface ActivityFeed_activityFeed_edges {
  __typename: "ActivityEdge";
  node: ActivityFeed_activityFeed_edges_node;
}

export interface ActivityFeed_activityFeed {
  __typename: "ActivityConnection";
  pageInfo: ActivityFeed_activityFeed_pageInfo;
  edges: (ActivityFeed_activityFeed_edges | null)[];
}

export interface ActivityFeed {
  activityFeed: ActivityFeed_activityFeed;
}

export interface ActivityFeedVariables {
  applicationId?: string | null;
  applicantId?: string | null;
  recruitmentId?: string | null;
  consultantId?: string | null;
  consultantProjectId?: string | null;
  consultantCustomerId?: string | null;
  interviewBookingGroupId?: string | null;
  referenceFormId?: string | null;
  screeningFormId?: string | null;
  after?: string | null;
  limit?: number | null;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssignmentActivityFeed
// ====================================================

export interface AssignmentActivityFeed_activityFeed_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_mentions {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_comment_application_recruitment_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_application {
  __typename: "Application";
  id: string;
  recruitment: AssignmentActivityFeed_activityFeed_edges_node_comment_application_recruitment;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_recruitment {
  __typename: "Recruitment";
  id: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_comment_recruitment_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_applicant_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_applicant {
  __typename: "CompanyApplicant";
  id: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_comment_applicant_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_assignment_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_assignment {
  __typename: "Assignment";
  id: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_comment_assignment_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_consultantProject_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_comment_consultantProject_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_comment {
  __typename: "Comment";
  id: string;
  text: string;
  mentions: AssignmentActivityFeed_activityFeed_edges_node_comment_mentions[];
  application: AssignmentActivityFeed_activityFeed_edges_node_comment_application | null;
  recruitment: AssignmentActivityFeed_activityFeed_edges_node_comment_recruitment | null;
  applicant: AssignmentActivityFeed_activityFeed_edges_node_comment_applicant | null;
  assignment: AssignmentActivityFeed_activityFeed_edges_node_comment_assignment | null;
  consultantProject: AssignmentActivityFeed_activityFeed_edges_node_comment_consultantProject | null;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_consultant_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_consultant_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicantMessage_applicant_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicantMessage_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_applicantMessage_applicant_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicantMessage_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicantMessage_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_applicantMessage_application_recruitment_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicantMessage_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: AssignmentActivityFeed_activityFeed_edges_node_applicantMessage_application_recruitment;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicantMessage {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  applicant: AssignmentActivityFeed_activityFeed_edges_node_applicantMessage_applicant | null;
  application: AssignmentActivityFeed_activityFeed_edges_node_applicantMessage_application | null;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_recruitment_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_application_recruitment_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: AssignmentActivityFeed_activityFeed_edges_node_application_companyApplicant | null;
  recruitment: AssignmentActivityFeed_activityFeed_edges_node_application_recruitment;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_calendarEvent_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_calendarEvent {
  __typename: "CalendarEvent";
  id: string;
  startTime: DateTime;
  title: string | null;
  company: AssignmentActivityFeed_activityFeed_edges_node_calendarEvent_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicationStatus_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicationStatus_recruitment {
  __typename: "Recruitment";
  id: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_applicationStatus_recruitment_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicationStatus_recruitmentTemplate_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicationStatus_recruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_applicationStatus_recruitmentTemplate_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicationStatus {
  __typename: "ApplicationStatus";
  id: string;
  type: APPLICATION_STATUS_TYPE;
  title: string | null;
  listIndex: number | null;
  color: string | null;
  recruitment: AssignmentActivityFeed_activityFeed_edges_node_applicationStatus_recruitment | null;
  recruitmentTemplate: AssignmentActivityFeed_activityFeed_edges_node_applicationStatus_recruitmentTemplate | null;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicant_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_applicant_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_consultantProject_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  title: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_consultantProject_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_consultantCustomer_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_consultantCustomer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_consultantCustomer_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_connectedStatus_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_connectedStatus {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
  company: AssignmentActivityFeed_activityFeed_edges_node_connectedStatus_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_user_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: AssignmentActivityFeed_activityFeed_edges_node_user_profileImage | null;
  company: AssignmentActivityFeed_activityFeed_edges_node_user_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_assignment_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_assignment_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_assignment_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_assignment_application {
  __typename: "Application";
  id: string;
  recruitment: AssignmentActivityFeed_activityFeed_edges_node_assignment_application_recruitment;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_assignment {
  __typename: "Assignment";
  id: string;
  title: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_assignment_company;
  recruitment: AssignmentActivityFeed_activityFeed_edges_node_assignment_recruitment | null;
  application: AssignmentActivityFeed_activityFeed_edges_node_assignment_application | null;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_contract_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_contract_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_contract_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: AssignmentActivityFeed_activityFeed_edges_node_contract_application_recruitment;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_contract_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_contract_customer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_contract_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_contract {
  __typename: "Contract";
  id: string;
  title: string;
  type: CONTRACT_TYPE | null;
  applicant: AssignmentActivityFeed_activityFeed_edges_node_contract_applicant | null;
  application: AssignmentActivityFeed_activityFeed_edges_node_contract_application | null;
  consultant: AssignmentActivityFeed_activityFeed_edges_node_contract_consultant | null;
  customer: AssignmentActivityFeed_activityFeed_edges_node_contract_customer | null;
  company: AssignmentActivityFeed_activityFeed_edges_node_contract_company | null;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroup_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  startTime: DateTime;
  endTime: DateTime;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_application_recruitment;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_interviewBookingGroup_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  bookedInterview: AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_bookedInterview | null;
  application: AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_application;
  interviewBookingGroup: AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication_interviewBookingGroup;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_referenceForm_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_referenceForm_form {
  __typename: "Form";
  id: string;
  title: string | null;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
  company: AssignmentActivityFeed_activityFeed_edges_node_referenceForm_company;
  form: AssignmentActivityFeed_activityFeed_edges_node_referenceForm_form;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_screeningForm_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_screeningForm_form {
  __typename: "Form";
  id: string;
  title: string | null;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
  company: AssignmentActivityFeed_activityFeed_edges_node_screeningForm_company;
  form: AssignmentActivityFeed_activityFeed_edges_node_screeningForm_form;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_formParticipant_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_formParticipant_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: AssignmentActivityFeed_activityFeed_edges_node_formParticipant_application_recruitment_company;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_formParticipant_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: AssignmentActivityFeed_activityFeed_edges_node_formParticipant_application_recruitment;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_formParticipant_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
}

export interface AssignmentActivityFeed_activityFeed_edges_node_formParticipant {
  __typename: "FormParticipant";
  id: string;
  application: AssignmentActivityFeed_activityFeed_edges_node_formParticipant_application | null;
  reference: AssignmentActivityFeed_activityFeed_edges_node_formParticipant_reference | null;
}

export interface AssignmentActivityFeed_activityFeed_edges_node {
  __typename: "Activity";
  id: string;
  type: ActivityType;
  createdAt: DateTime;
  grade: number | null;
  status: STATUS | null;
  comment: AssignmentActivityFeed_activityFeed_edges_node_comment | null;
  consultant: AssignmentActivityFeed_activityFeed_edges_node_consultant | null;
  applicantMessage: AssignmentActivityFeed_activityFeed_edges_node_applicantMessage | null;
  recruitment: AssignmentActivityFeed_activityFeed_edges_node_recruitment | null;
  application: AssignmentActivityFeed_activityFeed_edges_node_application | null;
  calendarEvent: AssignmentActivityFeed_activityFeed_edges_node_calendarEvent | null;
  applicationStatus: AssignmentActivityFeed_activityFeed_edges_node_applicationStatus | null;
  applicant: AssignmentActivityFeed_activityFeed_edges_node_applicant | null;
  consultantProject: AssignmentActivityFeed_activityFeed_edges_node_consultantProject | null;
  consultantCustomer: AssignmentActivityFeed_activityFeed_edges_node_consultantCustomer | null;
  connectedStatus: AssignmentActivityFeed_activityFeed_edges_node_connectedStatus | null;
  user: AssignmentActivityFeed_activityFeed_edges_node_user | null;
  assignment: AssignmentActivityFeed_activityFeed_edges_node_assignment | null;
  contract: AssignmentActivityFeed_activityFeed_edges_node_contract | null;
  interviewBookingGroup: AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroup | null;
  interviewBookingGroupApplication: AssignmentActivityFeed_activityFeed_edges_node_interviewBookingGroupApplication | null;
  referenceForm: AssignmentActivityFeed_activityFeed_edges_node_referenceForm | null;
  screeningForm: AssignmentActivityFeed_activityFeed_edges_node_screeningForm | null;
  formParticipant: AssignmentActivityFeed_activityFeed_edges_node_formParticipant | null;
  assignmentMove: ASSIGNMENT_STATUS | null;
}

export interface AssignmentActivityFeed_activityFeed_edges {
  __typename: "ActivityEdge";
  node: AssignmentActivityFeed_activityFeed_edges_node;
}

export interface AssignmentActivityFeed_activityFeed {
  __typename: "ActivityConnection";
  pageInfo: AssignmentActivityFeed_activityFeed_pageInfo;
  edges: (AssignmentActivityFeed_activityFeed_edges | null)[];
}

export interface AssignmentActivityFeed {
  activityFeed: AssignmentActivityFeed_activityFeed;
}

export interface AssignmentActivityFeedVariables {
  companyId: string;
  assignmentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Ad
// ====================================================

export interface Ad_ad_coverImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Ad_ad_blocks_block_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Ad_ad_blocks_block_coverImage_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Ad_ad_blocks_block_coverImage {
  __typename: "CoverImage";
  id: string;
  fullScreen: boolean | null;
  variant: COVER_IMAGE_VARIANT | null;
  fade: boolean | null;
  height: number | null;
  file: Ad_ad_blocks_block_coverImage_file | null;
}

export interface Ad_ad_blocks_block_contactPerson_profileImage {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
}

export interface Ad_ad_blocks_block_contactPerson {
  __typename: "AdContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  copyFromRecruitment: boolean | null;
  profileImage: Ad_ad_blocks_block_contactPerson_profileImage | null;
}

export interface Ad_ad_blocks_block_video {
  __typename: "Video";
  id: string;
  url: string | null;
  youtubeId: string | null;
  autoPlay: boolean | null;
  loop: boolean | null;
}

export interface Ad_ad_blocks_block {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
  title: string | null;
  text: string | null;
  url: string | null;
  copyShortPresentationFromCompany: boolean | null;
  file: Ad_ad_blocks_block_file | null;
  adMetaInfo: string[] | null;
  shareButtons: SOCIAL_MEDIA_SOURCE[] | null;
  coverImage: Ad_ad_blocks_block_coverImage | null;
  contactPerson: Ad_ad_blocks_block_contactPerson | null;
  video: Ad_ad_blocks_block_video | null;
}

export interface Ad_ad_blocks {
  __typename: "ConnectedContentBlock";
  id: string;
  listIndex: number;
  block: Ad_ad_blocks_block;
}

export interface Ad_ad {
  __typename: "Ad";
  id: string;
  title: string | null;
  design: string | null;
  content: string[];
  titleFont: string | null;
  textFont: string | null;
  applyButtonText: string | null;
  applyButtonLink: string | null;
  useCustomOgImage: boolean | null;
  ogDescription: string | null;
  coverImage: Ad_ad_coverImage | null;
  blocks: Ad_ad_blocks[];
}

export interface Ad {
  ad: Ad_ad | null;
}

export interface AdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdDistributionNeedsUpdate
// ====================================================

export interface AdDistributionNeedsUpdate {
  adDistributionNeedsUpdate: boolean;
}

export interface AdDistributionNeedsUpdateVariables {
  recruitmentId: string;
  inputData: RecruitmentUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlvaLabsCandidate
// ====================================================

export interface AlvaLabsCandidate_alvaLabsCandidate_user_personalityTestResult {
  __typename: "AlvaJobsPersonalityTestResult";
  completedAt: DateTime | null;
  a: number | null;
  c: number | null;
  e: number | null;
  es: number | null;
  o: number | null;
  ai: number | null;
  aii: number | null;
  aiii: number | null;
  ci: number | null;
  cii: number | null;
  ciii: number | null;
  ei: number | null;
  eii: number | null;
  eiii: number | null;
  esi: number | null;
  esii: number | null;
  esiii: number | null;
  oi: number | null;
  oii: number | null;
  oiii: number | null;
}

export interface AlvaLabsCandidate_alvaLabsCandidate_user_logicTestResult {
  __typename: "AlvaJobsLogicTestResult";
  completedAt: DateTime | null;
  mu: number | null;
  sigma: number | null;
}

export interface AlvaLabsCandidate_alvaLabsCandidate_user {
  __typename: "AlvaLabsUser";
  id: string | null;
  personalityTestResult: AlvaLabsCandidate_alvaLabsCandidate_user_personalityTestResult | null;
  logicTestResult: AlvaLabsCandidate_alvaLabsCandidate_user_logicTestResult | null;
}

export interface AlvaLabsCandidate_alvaLabsCandidate_roleFit {
  __typename: "AlvaLabsRoleFit";
  score: number | null;
  label: string | null;
  isCompleted: boolean | null;
}

export interface AlvaLabsCandidate_alvaLabsCandidate {
  __typename: "AlvaLabsCandidate";
  id: string;
  candidatePageUrl: string | null;
  user: AlvaLabsCandidate_alvaLabsCandidate_user | null;
  roleFit: AlvaLabsCandidate_alvaLabsCandidate_roleFit | null;
}

export interface AlvaLabsCandidate {
  alvaLabsCandidate: AlvaLabsCandidate_alvaLabsCandidate | null;
}

export interface AlvaLabsCandidateVariables {
  applicationId: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlvaLabsJob
// ====================================================

export interface AlvaLabsJob_alvaLabsJob {
  __typename: "AlvaLabsJob";
  id: string | null;
  testProfileId: string | null;
  requireLogicTest: boolean | null;
  requirePersonalityTest: boolean | null;
}

export interface AlvaLabsJob {
  alvaLabsJob: AlvaLabsJob_alvaLabsJob | null;
}

export interface AlvaLabsJobVariables {
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
  companyId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlvaLabsTestProfiles
// ====================================================

export interface AlvaLabsTestProfiles_alvaLabsTestProfiles {
  __typename: "AlvaLabsTestProfile";
  id: string;
  name: string;
}

export interface AlvaLabsTestProfiles {
  alvaLabsTestProfiles: AlvaLabsTestProfiles_alvaLabsTestProfiles[];
}

export interface AlvaLabsTestProfilesVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminApplicant
// ====================================================

export interface AdminApplicant_adminApplicant_competences_competence {
  __typename: "Competence";
  id: string;
  name: string;
}

export interface AdminApplicant_adminApplicant_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: AdminApplicant_adminApplicant_competences_competence;
}

export interface AdminApplicant_adminApplicant_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  filename: string;
}

export interface AdminApplicant_adminApplicant_documents {
  __typename: "Document";
  id: string;
  file: AdminApplicant_adminApplicant_documents_file;
}

export interface AdminApplicant_adminApplicant_companies {
  __typename: "CompanyApplicant";
  id: string;
}

export interface AdminApplicant_adminApplicant {
  __typename: "Applicant";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
  createdAt: DateTime;
  competences: AdminApplicant_adminApplicant_competences[];
  documents: AdminApplicant_adminApplicant_documents[];
  companies: AdminApplicant_adminApplicant_companies[];
}

export interface AdminApplicant {
  adminApplicant: AdminApplicant_adminApplicant | null;
}

export interface AdminApplicantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicantsAdmin
// ====================================================

export interface ApplicantsAdmin_applicantsAdmin_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ApplicantsAdmin_applicantsAdmin_edges_node_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface ApplicantsAdmin_applicantsAdmin_edges_node_competences_competence {
  __typename: "Competence";
  id: string;
  name: string;
}

export interface ApplicantsAdmin_applicantsAdmin_edges_node_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: ApplicantsAdmin_applicantsAdmin_edges_node_competences_competence;
}

export interface ApplicantsAdmin_applicantsAdmin_edges_node {
  __typename: "Applicant";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
  tags: ApplicantsAdmin_applicantsAdmin_edges_node_tags[];
  competences: ApplicantsAdmin_applicantsAdmin_edges_node_competences[];
}

export interface ApplicantsAdmin_applicantsAdmin_edges {
  __typename: "ApplicantEdge";
  cursor: string;
  node: ApplicantsAdmin_applicantsAdmin_edges_node;
}

export interface ApplicantsAdmin_applicantsAdmin {
  __typename: "ApplicantConnection";
  pageInfo: ApplicantsAdmin_applicantsAdmin_pageInfo;
  edges: (ApplicantsAdmin_applicantsAdmin_edges | null)[];
}

export interface ApplicantsAdmin {
  applicantsAdmin: ApplicantsAdmin_applicantsAdmin;
}

export interface ApplicantsAdminVariables {
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicantsFilterData
// ====================================================

export interface ApplicantsFilterData_applicantsFilterData_tagFilters {
  __typename: "TagFilterData";
  tag: string;
  id: string;
  count: number;
}

export interface ApplicantsFilterData_applicantsFilterData_educationFilters {
  __typename: "EducationFilterData";
  id: string;
  education: string;
  count: number;
}

export interface ApplicantsFilterData_applicantsFilterData_gradeFilters {
  __typename: "GradeFilterData";
  grade: number;
  count: number;
}

export interface ApplicantsFilterData_applicantsFilterData_recruitmentFilters {
  __typename: "RecruitmentFilterData";
  id: string;
  title: string;
  count: number;
}

export interface ApplicantsFilterData_applicantsFilterData_professionFieldFilters {
  __typename: "ProfessionFieldFilterData";
  id: string;
  title: string;
  count: number;
}

export interface ApplicantsFilterData_applicantsFilterData {
  __typename: "ApplicantsFilterData";
  tagFilters: ApplicantsFilterData_applicantsFilterData_tagFilters[];
  educationFilters: ApplicantsFilterData_applicantsFilterData_educationFilters[];
  gradeFilters: ApplicantsFilterData_applicantsFilterData_gradeFilters[];
  recruitmentFilters: ApplicantsFilterData_applicantsFilterData_recruitmentFilters[];
  professionFieldFilters: ApplicantsFilterData_applicantsFilterData_professionFieldFilters[];
}

export interface ApplicantsFilterData {
  applicantsFilterData: ApplicantsFilterData_applicantsFilterData;
}

export interface ApplicantsFilterDataVariables {
  companyId: string;
  showResultsFromChildCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicantGrades
// ====================================================

export interface ApplicantGrades_applicantGrades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ApplicantGrades_applicantGrades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ApplicantGrades_applicantGrades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: ApplicantGrades_applicantGrades_visitor | null;
  setBy: ApplicantGrades_applicantGrades_setBy | null;
}

export interface ApplicantGrades {
  applicantGrades: ApplicantGrades_applicantGrades[];
}

export interface ApplicantGradesVariables {
  applicantId?: string | null;
  applicationId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicantMessages
// ====================================================

export interface ApplicantMessages_applicantMessages_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface ApplicantMessages_applicantMessages_edges_node_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ApplicantMessages_applicantMessages_edges_node_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: ApplicantMessages_applicantMessages_edges_node_documents_file;
}

export interface ApplicantMessages_applicantMessages_edges_node_applicant {
  __typename: "CompanyApplicant";
  id: string;
}

export interface ApplicantMessages_applicantMessages_edges_node_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ApplicantMessages_applicantMessages_edges_node_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: ApplicantMessages_applicantMessages_edges_node_user_profileImage | null;
}

export interface ApplicantMessages_applicantMessages_edges_node {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  createdAt: DateTime;
  sentByApplicant: boolean;
  sentVia: MESSAGE_CHANNEL[];
  status: MESSAGE_STATUS;
  openedAt: DateTime | null;
  documents: ApplicantMessages_applicantMessages_edges_node_documents[];
  applicant: ApplicantMessages_applicantMessages_edges_node_applicant | null;
  user: ApplicantMessages_applicantMessages_edges_node_user | null;
}

export interface ApplicantMessages_applicantMessages_edges {
  __typename: "ApplicantMessageEdge";
  node: ApplicantMessages_applicantMessages_edges_node;
}

export interface ApplicantMessages_applicantMessages {
  __typename: "ApplicantMessageConnection";
  pageInfo: ApplicantMessages_applicantMessages_pageInfo;
  edges: (ApplicantMessages_applicantMessages_edges | null)[];
}

export interface ApplicantMessages {
  applicantMessages: ApplicantMessages_applicantMessages;
}

export interface ApplicantMessagesVariables {
  applicationId?: string | null;
  applicantId?: string | null;
  childCompanyId?: string | null;
  limit: number;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicantMessagePreview
// ====================================================

export interface ApplicantMessagePreview_applicantMessagePreview {
  __typename: "MessageContent";
  subject: string | null;
  message: string | null;
}

export interface ApplicantMessagePreview {
  applicantMessagePreview: ApplicantMessagePreview_applicantMessagePreview;
}

export interface ApplicantMessagePreviewVariables {
  subject?: string | null;
  companyId: string;
  recruitmentId?: string | null;
  message?: string | null;
  applicationId?: string | null;
  applicantId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationMessagesRead
// ====================================================

export interface ApplicationMessagesRead_application {
  __typename: "Application";
  id: string;
  messagesRead: boolean | null;
  latestMessageTime: DateTime | null;
}

export interface ApplicationMessagesRead {
  application: ApplicationMessagesRead_application | null;
}

export interface ApplicationMessagesReadVariables {
  applicationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentApplicantMessages
// ====================================================

export interface RecruitmentApplicantMessages_recruitmentApplicantMessages_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node_messages_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node_messages_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node_messages_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node_messages_user_profileImage | null;
}

export interface RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node_messages {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  sentVia: MESSAGE_CHANNEL[];
  sentByApplicant: boolean;
  createdAt: DateTime;
  application: RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node_messages_application | null;
  user: RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node_messages_user | null;
}

export interface RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  messagesRead: boolean | null;
  companyApplicant: RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node_companyApplicant | null;
  latestMessageTime: DateTime | null;
  messages: RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node_messages[];
}

export interface RecruitmentApplicantMessages_recruitmentApplicantMessages_edges {
  __typename: "ApplicationEdge";
  node: RecruitmentApplicantMessages_recruitmentApplicantMessages_edges_node;
}

export interface RecruitmentApplicantMessages_recruitmentApplicantMessages {
  __typename: "ApplicationConnection";
  pageInfo: RecruitmentApplicantMessages_recruitmentApplicantMessages_pageInfo;
  edges: (RecruitmentApplicantMessages_recruitmentApplicantMessages_edges | null)[];
}

export interface RecruitmentApplicantMessages {
  recruitmentApplicantMessages: RecruitmentApplicantMessages_recruitmentApplicantMessages;
}

export interface RecruitmentApplicantMessagesVariables {
  recruitmentId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UnreadApplicantMessagesCount
// ====================================================

export interface UnreadApplicantMessagesCount {
  unreadApplicantMessagesCount: number;
}

export interface UnreadApplicantMessagesCountVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminApplications
// ====================================================

export interface AdminApplications_adminApplications_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface AdminApplications_adminApplications_edges_node_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  education: string | null;
  endDate: DateTime | null;
}

export interface AdminApplications_adminApplications_edges_node_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  createdAt: DateTime;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
}

export interface AdminApplications_adminApplications_edges_node_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface AdminApplications_adminApplications_edges_node_applicant {
  __typename: "Applicant";
  id: string;
  email: string;
}

export interface AdminApplications_adminApplications_edges_node {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  educations: AdminApplications_adminApplications_edges_node_educations[];
  status: AdminApplications_adminApplications_edges_node_status | null;
  tags: AdminApplications_adminApplications_edges_node_tags[];
  createdAt: DateTime;
  averageGrade: number;
  needsVerification: boolean | null;
  note: string | null;
  computedMatchRanking: number | null;
  computedMatchScore: number;
  applicant: AdminApplications_adminApplications_edges_node_applicant;
}

export interface AdminApplications_adminApplications_edges {
  __typename: "ApplicationEdge";
  cursor: string;
  node: AdminApplications_adminApplications_edges_node;
}

export interface AdminApplications_adminApplications {
  __typename: "ApplicationConnection";
  pageInfo: AdminApplications_adminApplications_pageInfo;
  edges: (AdminApplications_adminApplications_edges | null)[];
}

export interface AdminApplications {
  adminApplications: AdminApplications_adminApplications;
}

export interface AdminApplicationsVariables {
  recruitmentId?: string | null;
  limit: number;
  after?: string | null;
  searchText?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  gradeFilter?: (number | null)[] | null;
  statusFilter?: (string | null)[] | null;
  competenceFilter?: (string | null)[] | null;
  tagFilter?: (string | null)[] | null;
  educationFilter?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllApplications
// ====================================================

export interface AllApplications_allApplications_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
  email: string;
  firstName: string;
  lastName: string;
}

export interface AllApplications_allApplications_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  education: string | null;
  endDate: DateTime | null;
}

export interface AllApplications_allApplications_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  createdAt: DateTime;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
}

export interface AllApplications_allApplications_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface AllApplications_allApplications_applicationFormAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface AllApplications_allApplications_applicationFormAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: AllApplications_allApplications_applicationFormAnswers_question_matchingEventProfile | null;
}

export interface AllApplications_allApplications_applicationFormAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
  question: AllApplications_allApplications_applicationFormAnswers_question;
}

export interface AllApplications_allApplications {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  listIndex: number | null;
  note: string | null;
  companyApplicant: AllApplications_allApplications_companyApplicant | null;
  educations: AllApplications_allApplications_educations[];
  status: AllApplications_allApplications_status | null;
  tags: AllApplications_allApplications_tags[];
  averageGrade: number;
  applicationFormAnswers: AllApplications_allApplications_applicationFormAnswers[];
}

export interface AllApplications {
  allApplications: AllApplications_allApplications[];
}

export interface AllApplicationsVariables {
  recruitmentId: string;
  searchText?: string | null;
  gradeFilter?: number[] | null;
  statusFilter?: string[] | null;
  competenceFilter?: string[] | null;
  tagFilter?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicantsApplications
// ====================================================

export interface ApplicantsApplications_applicantsApplications_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ApplicantsApplications_applicantsApplications_edges_node_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  createdAt: DateTime;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
}

export interface ApplicantsApplications_applicantsApplications_edges_node_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ApplicantsApplications_applicantsApplications_edges_node_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  setBy: ApplicantsApplications_applicantsApplications_edges_node_grades_setBy | null;
}

export interface ApplicantsApplications_applicantsApplications_edges_node_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ApplicantsApplications_applicantsApplications_edges_node_recruitment_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ApplicantsApplications_applicantsApplications_edges_node_recruitment_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: ApplicantsApplications_applicantsApplications_edges_node_recruitment_users_user_profileImage | null;
}

export interface ApplicantsApplications_applicantsApplications_edges_node_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: ApplicantsApplications_applicantsApplications_edges_node_recruitment_users_user;
}

export interface ApplicantsApplications_applicantsApplications_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: ApplicantsApplications_applicantsApplications_edges_node_recruitment_company;
  users: ApplicantsApplications_applicantsApplications_edges_node_recruitment_users[];
}

export interface ApplicantsApplications_applicantsApplications_edges_node_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
}

export interface ApplicantsApplications_applicantsApplications_edges_node {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  status: ApplicantsApplications_applicantsApplications_edges_node_status | null;
  averageGrade: number;
  note: string | null;
  grades: ApplicantsApplications_applicantsApplications_edges_node_grades[];
  recruitment: ApplicantsApplications_applicantsApplications_edges_node_recruitment;
  companyApplicant: ApplicantsApplications_applicantsApplications_edges_node_companyApplicant | null;
}

export interface ApplicantsApplications_applicantsApplications_edges {
  __typename: "ApplicationEdge";
  cursor: string;
  node: ApplicantsApplications_applicantsApplications_edges_node;
}

export interface ApplicantsApplications_applicantsApplications {
  __typename: "ApplicationConnection";
  pageInfo: ApplicantsApplications_applicantsApplications_pageInfo;
  edges: (ApplicantsApplications_applicantsApplications_edges | null)[];
}

export interface ApplicantsApplications {
  applicantsApplications: ApplicantsApplications_applicantsApplications;
}

export interface ApplicantsApplicationsVariables {
  companyApplicantId: string;
  limit: number;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationNextAndPrev
// ====================================================

export interface ApplicationNextAndPrev_applicationNextAndPrev_next {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ApplicationNextAndPrev_applicationNextAndPrev_previous {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ApplicationNextAndPrev_applicationNextAndPrev {
  __typename: "ApplicationNextAndPrev";
  next: ApplicationNextAndPrev_applicationNextAndPrev_next | null;
  previous: ApplicationNextAndPrev_applicationNextAndPrev_previous | null;
}

export interface ApplicationNextAndPrev {
  applicationNextAndPrev: ApplicationNextAndPrev_applicationNextAndPrev | null;
}

export interface ApplicationNextAndPrevVariables {
  applicationId: string;
  companyId: string;
  recruitmentId?: string | null;
  searchText?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  gradeFilter?: number[] | null;
  statusFilter?: string[] | null;
  competenceFilter?: string[] | null;
  tagFilter?: string[] | null;
  educationFilter?: string[] | null;
  questionFilter?: FormQuestionFilter[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationNote
// ====================================================

export interface ApplicationNote_application {
  __typename: "Application";
  id: string;
  note: string | null;
}

export interface ApplicationNote {
  application: ApplicationNote_application | null;
}

export interface ApplicationNoteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Application
// ====================================================

export interface Application_application_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  listIndex: number | null;
  createdAt: DateTime;
  type: APPLICATION_STATUS_TYPE;
}

export interface Application_application_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Application_application_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Application_application_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: Application_application_grades_visitor | null;
  setBy: Application_application_grades_setBy | null;
}

export interface Application_application_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Application_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  company: Application_application_recruitment_company;
}

export interface Application_application_companyApplicant_applications {
  __typename: "Application";
  id: string;
}

export interface Application_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
  email: string;
  applications: Application_application_companyApplicant_applications[];
}

export interface Application_application_interviewBookingGroups_application {
  __typename: "Application";
  id: string;
}

export interface Application_application_interviewBookingGroups {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  application: Application_application_interviewBookingGroups_application;
}

export interface Application_application_video {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Application_application_competences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface Application_application_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: Application_application_competences_competence;
}

export interface Application_application_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface Application_application_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: Application_application_documents_file;
  type: DOCUMENT_TYPE;
}

export interface Application_application_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface Application_application_workExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface Application_application {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  approvedForInterview: boolean;
  offeredEmployment: boolean | null;
  latestMessageTime: DateTime | null;
  messagesRead: boolean | null;
  anonymizedWords: (string | null)[];
  isAnonymized: boolean;
  status: Application_application_status | null;
  averageGrade: number;
  grades: Application_application_grades[];
  recruitment: Application_application_recruitment;
  companyApplicant: Application_application_companyApplicant | null;
  interviewBookingGroups: Application_application_interviewBookingGroups[];
  video: Application_application_video | null;
  competences: Application_application_competences[];
  documents: Application_application_documents[];
  freeText: string | null;
  educations: Application_application_educations[];
  workExperiences: Application_application_workExperiences[];
}

export interface Application {
  application: Application_application | null;
}

export interface ApplicationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationsCount
// ====================================================

export interface ApplicationsCount {
  applicationsCount: number;
}

export interface ApplicationsCountVariables {
  companyId?: string | null;
  recruitmentId?: string | null;
  statusType?: APPLICATION_STATUS_TYPE | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Applications
// ====================================================

export interface Applications_applications_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface Applications_applications_edges_node_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Applications_applications_edges_node_recruitment_users_user {
  __typename: "User";
  id: string;
}

export interface Applications_applications_edges_node_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: Applications_applications_edges_node_recruitment_users_user;
}

export interface Applications_applications_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  company: Applications_applications_edges_node_recruitment_company;
  users: Applications_applications_edges_node_recruitment_users[];
}

export interface Applications_applications_edges_node_tengaiInterview {
  __typename: "TengaiInterview";
  id: string;
  status: TENGAI_INTERVIEW_STATUS;
  totalPersonalityIndicatorScore: number | null;
}

export interface Applications_applications_edges_node_applicationFormAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface Applications_applications_edges_node_applicationFormAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: Applications_applications_edges_node_applicationFormAnswers_question_matchingEventProfile | null;
}

export interface Applications_applications_edges_node_applicationFormAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: Applications_applications_edges_node_applicationFormAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface Applications_applications_edges_node_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
}

export interface Applications_applications_edges_node_grades_setBy {
  __typename: "User";
  id: string;
}

export interface Applications_applications_edges_node_grades {
  __typename: "ApplicantGrade";
  id: string;
  setBy: Applications_applications_edges_node_grades_setBy | null;
}

export interface Applications_applications_edges_node_addedByUser_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Applications_applications_edges_node_addedByUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: Applications_applications_edges_node_addedByUser_profileImage | null;
}

export interface Applications_applications_edges_node_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  education: string | null;
  endDate: DateTime | null;
}

export interface Applications_applications_edges_node_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  createdAt: DateTime;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
}

export interface Applications_applications_edges_node_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface Applications_applications_edges_node_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface Applications_applications_edges_node_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: Applications_applications_edges_node_documents_file;
  type: DOCUMENT_TYPE;
}

export interface Applications_applications_edges_node {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  createdAt: DateTime;
  averageGrade: number;
  needsVerification: boolean | null;
  note: string | null;
  computedMatchRanking: number | null;
  computedMatchScore: number;
  matchPercentageNeedToHave: number;
  matchPercentageNiceToHave: number;
  alvaLabsTestScore: number;
  alvaLabsCandidateCreatedAt: DateTime | null;
  recruitment: Applications_applications_edges_node_recruitment;
  tengaiInterview: Applications_applications_edges_node_tengaiInterview | null;
  applicationFormAnswers: Applications_applications_edges_node_applicationFormAnswers[];
  companyApplicant: Applications_applications_edges_node_companyApplicant | null;
  grades: Applications_applications_edges_node_grades[];
  addedByUser: Applications_applications_edges_node_addedByUser | null;
  educations: Applications_applications_edges_node_educations[];
  status: Applications_applications_edges_node_status | null;
  tags: Applications_applications_edges_node_tags[];
  documents: Applications_applications_edges_node_documents[];
}

export interface Applications_applications_edges {
  __typename: "ApplicationEdge";
  cursor: string;
  node: Applications_applications_edges_node;
}

export interface Applications_applications {
  __typename: "ApplicationConnection";
  pageInfo: Applications_applications_pageInfo;
  edges: (Applications_applications_edges | null)[];
  totalCount: number;
}

export interface Applications {
  applications: Applications_applications;
}

export interface ApplicationsVariables {
  companyId: string;
  recruitmentFilter?: string[] | null;
  limit: number;
  after?: string | null;
  searchText?: string | null;
  searchTextOptions?: string[] | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  gradeFilter?: number[] | null;
  statusFilter?: string[] | null;
  statusNameFilter?: string[] | null;
  competenceFilter?: string[] | null;
  tagFilter?: string[] | null;
  educationFilter?: string[] | null;
  questionFilter?: FormQuestionFilter[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationWithStatus
// ====================================================

export interface ApplicationWithStatus_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface ApplicationWithStatus_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ApplicationWithStatus_application_status {
  __typename: "ApplicationStatus";
  id: string;
  type: APPLICATION_STATUS_TYPE;
  title: string | null;
  color: string | null;
  listIndex: number | null;
}

export interface ApplicationWithStatus_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: ApplicationWithStatus_application_recruitment;
  companyApplicant: ApplicationWithStatus_application_companyApplicant | null;
  status: ApplicationWithStatus_application_status | null;
}

export interface ApplicationWithStatus {
  application: ApplicationWithStatus_application | null;
}

export interface ApplicationWithStatusVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MergedApplication
// ====================================================

export interface MergedApplication_mergedApplication_competences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface MergedApplication_mergedApplication_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: MergedApplication_mergedApplication_competences_competence;
}

export interface MergedApplication_mergedApplication_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface MergedApplication_mergedApplication_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface MergedApplication_mergedApplication_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: MergedApplication_mergedApplication_documents_file;
  type: DOCUMENT_TYPE;
}

export interface MergedApplication_mergedApplication_workExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface MergedApplication_mergedApplication {
  __typename: "MergedApplication";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  competences: MergedApplication_mergedApplication_competences[];
  educations: MergedApplication_mergedApplication_educations[];
  documents: MergedApplication_mergedApplication_documents[];
  workExperiences: MergedApplication_mergedApplication_workExperiences[];
}

export interface MergedApplication {
  mergedApplication: MergedApplication_mergedApplication | null;
}

export interface MergedApplicationVariables {
  companyApplicantId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatedApplications
// ====================================================

export interface PaginatedApplications_paginatedApplications_items_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PaginatedApplications_paginatedApplications_items_recruitment_users_user {
  __typename: "User";
  id: string;
}

export interface PaginatedApplications_paginatedApplications_items_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: PaginatedApplications_paginatedApplications_items_recruitment_users_user;
}

export interface PaginatedApplications_paginatedApplications_items_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  company: PaginatedApplications_paginatedApplications_items_recruitment_company;
  users: PaginatedApplications_paginatedApplications_items_recruitment_users[];
}

export interface PaginatedApplications_paginatedApplications_items_tengaiInterview {
  __typename: "TengaiInterview";
  id: string;
  status: TENGAI_INTERVIEW_STATUS;
  totalPersonalityIndicatorScore: number | null;
}

export interface PaginatedApplications_paginatedApplications_items_applicationFormAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface PaginatedApplications_paginatedApplications_items_applicationFormAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: PaginatedApplications_paginatedApplications_items_applicationFormAnswers_question_matchingEventProfile | null;
}

export interface PaginatedApplications_paginatedApplications_items_applicationFormAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: PaginatedApplications_paginatedApplications_items_applicationFormAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface PaginatedApplications_paginatedApplications_items_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
}

export interface PaginatedApplications_paginatedApplications_items_grades_setBy {
  __typename: "User";
  id: string;
}

export interface PaginatedApplications_paginatedApplications_items_grades {
  __typename: "ApplicantGrade";
  id: string;
  setBy: PaginatedApplications_paginatedApplications_items_grades_setBy | null;
}

export interface PaginatedApplications_paginatedApplications_items_addedByUser_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PaginatedApplications_paginatedApplications_items_addedByUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: PaginatedApplications_paginatedApplications_items_addedByUser_profileImage | null;
}

export interface PaginatedApplications_paginatedApplications_items_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  education: string | null;
  endDate: DateTime | null;
}

export interface PaginatedApplications_paginatedApplications_items_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  createdAt: DateTime;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
}

export interface PaginatedApplications_paginatedApplications_items_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface PaginatedApplications_paginatedApplications_items_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface PaginatedApplications_paginatedApplications_items_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: PaginatedApplications_paginatedApplications_items_documents_file;
  type: DOCUMENT_TYPE;
}

export interface PaginatedApplications_paginatedApplications_items {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  createdAt: DateTime;
  averageGrade: number;
  needsVerification: boolean | null;
  note: string | null;
  computedMatchRanking: number | null;
  computedMatchScore: number;
  matchPercentageNeedToHave: number;
  matchPercentageNiceToHave: number;
  alvaLabsTestScore: number;
  alvaLabsCandidateCreatedAt: DateTime | null;
  recruitment: PaginatedApplications_paginatedApplications_items_recruitment;
  tengaiInterview: PaginatedApplications_paginatedApplications_items_tengaiInterview | null;
  applicationFormAnswers: PaginatedApplications_paginatedApplications_items_applicationFormAnswers[];
  companyApplicant: PaginatedApplications_paginatedApplications_items_companyApplicant | null;
  grades: PaginatedApplications_paginatedApplications_items_grades[];
  addedByUser: PaginatedApplications_paginatedApplications_items_addedByUser | null;
  educations: PaginatedApplications_paginatedApplications_items_educations[];
  status: PaginatedApplications_paginatedApplications_items_status | null;
  tags: PaginatedApplications_paginatedApplications_items_tags[];
  documents: PaginatedApplications_paginatedApplications_items_documents[];
}

export interface PaginatedApplications_paginatedApplications {
  __typename: "PaginatedApplications";
  totalCount: number;
  items: PaginatedApplications_paginatedApplications_items[];
}

export interface PaginatedApplications {
  paginatedApplications: PaginatedApplications_paginatedApplications;
}

export interface PaginatedApplicationsVariables {
  companyId: string;
  getChildCompaniesApplications?: boolean | null;
  getNotOwnedApplications?: boolean | null;
  recruitmentFilter?: string[] | null;
  page?: number | null;
  pageSize?: number | null;
  searchText?: string | null;
  searchTextOptions?: string[] | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  gradeFilter?: number[] | null;
  statusFilter?: string[] | null;
  companyFilter?: string[] | null;
  statusNameFilter?: string[] | null;
  competenceFilter?: string[] | null;
  studentOptionsFilter?: string[] | null;
  tagFilter?: string[] | null;
  educationFilter?: string[] | null;
  questionFilter?: FormQuestionFilter[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StartPageApplications
// ====================================================

export interface StartPageApplications_applications_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface StartPageApplications_applications_edges_node_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  createdAt: DateTime;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
}

export interface StartPageApplications_applications_edges_node_recruitment_users_user {
  __typename: "User";
  id: string;
}

export interface StartPageApplications_applications_edges_node_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: StartPageApplications_applications_edges_node_recruitment_users_user;
}

export interface StartPageApplications_applications_edges_node_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface StartPageApplications_applications_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  users: StartPageApplications_applications_edges_node_recruitment_users[];
  company: StartPageApplications_applications_edges_node_recruitment_company;
}

export interface StartPageApplications_applications_edges_node {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  averageGrade: number;
  createdAt: DateTime;
  status: StartPageApplications_applications_edges_node_status | null;
  recruitment: StartPageApplications_applications_edges_node_recruitment;
}

export interface StartPageApplications_applications_edges {
  __typename: "ApplicationEdge";
  cursor: string;
  node: StartPageApplications_applications_edges_node;
}

export interface StartPageApplications_applications {
  __typename: "ApplicationConnection";
  pageInfo: StartPageApplications_applications_pageInfo;
  edges: (StartPageApplications_applications_edges | null)[];
}

export interface StartPageApplications {
  applications: StartPageApplications_applications;
}

export interface StartPageApplicationsVariables {
  companyId: string;
  recruitmentFilter?: string[] | null;
  limit: number;
  after?: string | null;
  searchText?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  gradeFilter?: number[] | null;
  statusFilter?: string[] | null;
  competenceFilter?: string[] | null;
  tagFilter?: string[] | null;
  educationFilter?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationsCompaniesFilter
// ====================================================

export interface ApplicationsCompaniesFilter_applicationsCompaniesFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface ApplicationsCompaniesFilter_applicationsCompaniesFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: ApplicationsCompaniesFilter_applicationsCompaniesFilter_edges_node;
}

export interface ApplicationsCompaniesFilter_applicationsCompaniesFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface ApplicationsCompaniesFilter_applicationsCompaniesFilter {
  __typename: "FilterItemConnection";
  edges: (ApplicationsCompaniesFilter_applicationsCompaniesFilter_edges | null)[];
  pageInfo: ApplicationsCompaniesFilter_applicationsCompaniesFilter_pageInfo;
}

export interface ApplicationsCompaniesFilter {
  applicationsCompaniesFilter: ApplicationsCompaniesFilter_applicationsCompaniesFilter;
}

export interface ApplicationsCompaniesFilterVariables {
  filters?: ApplicationFilterInput | null;
  after?: string | null;
  limit: number;
  groupCompanyId: string;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationsFilterData
// ====================================================

export interface ApplicationsFilterData_applicationsFilterData_statusFilters {
  __typename: "ApplicationStatusFilterData";
  id: string;
  type: APPLICATION_STATUS_TYPE;
  title: string | null;
  listIndex: number | null;
  count: number;
}

export interface ApplicationsFilterData_applicationsFilterData_competenceFilters {
  __typename: "CompetenceFilterData";
  id: string;
  name: string;
  count: number;
}

export interface ApplicationsFilterData_applicationsFilterData_gradeFilters {
  __typename: "GradeFilterData";
  grade: number;
  count: number;
}

export interface ApplicationsFilterData_applicationsFilterData_tagFilters {
  __typename: "TagFilterData";
  tag: string;
  id: string;
  count: number;
}

export interface ApplicationsFilterData_applicationsFilterData_educationFilters {
  __typename: "EducationFilterData";
  education: string;
  id: string;
  count: number;
}

export interface ApplicationsFilterData_applicationsFilterData_questionFilters {
  __typename: "FormQuestionFilterData";
  id: string;
  question: string | null;
  type: FORM_QUESTION_TYPE | null;
  options: string[];
}

export interface ApplicationsFilterData_applicationsFilterData {
  __typename: "ApplicationsFilterData";
  statusFilters: ApplicationsFilterData_applicationsFilterData_statusFilters[];
  competenceFilters: ApplicationsFilterData_applicationsFilterData_competenceFilters[];
  gradeFilters: ApplicationsFilterData_applicationsFilterData_gradeFilters[];
  tagFilters: ApplicationsFilterData_applicationsFilterData_tagFilters[];
  educationFilters: ApplicationsFilterData_applicationsFilterData_educationFilters[];
  questionFilters: ApplicationsFilterData_applicationsFilterData_questionFilters[];
}

export interface ApplicationsFilterData {
  applicationsFilterData: ApplicationsFilterData_applicationsFilterData | null;
}

export interface ApplicationsFilterDataVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationsGradesFilter
// ====================================================

export interface ApplicationsGradesFilter_applicationsGradesFilter {
  __typename: "FilterItem";
  id: string;
  label: string;
  count: number | null;
}

export interface ApplicationsGradesFilter {
  applicationsGradesFilter: ApplicationsGradesFilter_applicationsGradesFilter[];
}

export interface ApplicationsGradesFilterVariables {
  filters?: ApplicationFilterInput | null;
  companyId?: string | null;
  groupCompanyId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationRecruitmentsFilter
// ====================================================

export interface ApplicationRecruitmentsFilter_applicationsRecruitmentsFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface ApplicationRecruitmentsFilter_applicationsRecruitmentsFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: ApplicationRecruitmentsFilter_applicationsRecruitmentsFilter_edges_node;
}

export interface ApplicationRecruitmentsFilter_applicationsRecruitmentsFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ApplicationRecruitmentsFilter_applicationsRecruitmentsFilter {
  __typename: "FilterItemConnection";
  edges: (ApplicationRecruitmentsFilter_applicationsRecruitmentsFilter_edges | null)[];
  pageInfo: ApplicationRecruitmentsFilter_applicationsRecruitmentsFilter_pageInfo;
}

export interface ApplicationRecruitmentsFilter {
  applicationsRecruitmentsFilter: ApplicationRecruitmentsFilter_applicationsRecruitmentsFilter;
}

export interface ApplicationRecruitmentsFilterVariables {
  filters?: ApplicationFilterInput | null;
  after?: string | null;
  limit: number;
  companyId?: string | null;
  groupCompanyId?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationStatusesFilter
// ====================================================

export interface ApplicationStatusesFilter_applicationStatusesFilter {
  __typename: "FilterItem";
  id: string;
  label: string;
  count: number | null;
}

export interface ApplicationStatusesFilter {
  applicationStatusesFilter: ApplicationStatusesFilter_applicationStatusesFilter[];
}

export interface ApplicationStatusesFilterVariables {
  companyId?: string | null;
  groupCompanyId?: string | null;
  filters?: ApplicationFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationStudentOptionsFilter
// ====================================================

export interface ApplicationStudentOptionsFilter_applicationStudentOptionsFilter {
  __typename: "FilterItem";
  id: string;
  label: string;
  count: number | null;
}

export interface ApplicationStudentOptionsFilter {
  applicationStudentOptionsFilter: ApplicationStudentOptionsFilter_applicationStudentOptionsFilter[];
}

export interface ApplicationStudentOptionsFilterVariables {
  companyId?: string | null;
  groupCompanyId?: string | null;
  filters?: ApplicationFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationStatuses
// ====================================================

export interface ApplicationStatuses_applicationStatuses_messageTemplate_company {
  __typename: "Company";
  id: string;
}

export interface ApplicationStatuses_applicationStatuses_messageTemplate {
  __typename: "MessageTemplate";
  id: string;
  templateName: string | null;
  header: string;
  content: string;
  company: ApplicationStatuses_applicationStatuses_messageTemplate_company | null;
}

export interface ApplicationStatuses_applicationStatuses_eventTriggers {
  __typename: "EventTrigger";
  id: string;
  actionType: EVENT_TRIGGER_ACTION_TYPE;
  screeningFormId: string | null;
  referenceFormId: string | null;
}

export interface ApplicationStatuses_applicationStatuses {
  __typename: "ApplicationStatus";
  id: string;
  type: APPLICATION_STATUS_TYPE;
  listIndex: number | null;
  title: string | null;
  description: string | null;
  color: string | null;
  applicationCount: number;
  messageTemplate: ApplicationStatuses_applicationStatuses_messageTemplate | null;
  eventTriggers: ApplicationStatuses_applicationStatuses_eventTriggers[] | null;
}

export interface ApplicationStatuses {
  applicationStatuses: ApplicationStatuses_applicationStatuses[];
}

export interface ApplicationStatusesVariables {
  recruitmentIds?: string[] | null;
  recruitmentTemplateId?: string | null;
  companyIds?: string[] | null;
  groupCompanyId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationStatusSummaries
// ====================================================

export interface ApplicationStatusSummaries_applicationStatusSummaries_status {
  __typename: "ApplicationStatus";
  id: string;
  title: string | null;
  type: APPLICATION_STATUS_TYPE;
  listIndex: number | null;
  color: string | null;
}

export interface ApplicationStatusSummaries_applicationStatusSummaries {
  __typename: "ApplicationStatusSummary";
  status: ApplicationStatusSummaries_applicationStatusSummaries_status;
  applicationCount: number;
}

export interface ApplicationStatusSummaries {
  applicationStatusSummaries: ApplicationStatusSummaries_applicationStatusSummaries[];
}

export interface ApplicationStatusSummariesVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Assignment
// ====================================================

export interface Assignment_assignment_column {
  __typename: "AssignmentColumn";
  id: string;
  title: string;
}

export interface Assignment_assignment_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Assignment_assignment_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: Assignment_assignment_createdBy_profileImage | null;
}

export interface Assignment_assignment_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Assignment_assignment_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  profileImage: Assignment_assignment_users_profileImage | null;
}

export interface Assignment_assignment_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Assignment_assignment_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: Assignment_assignment_application_companyApplicant | null;
}

export interface Assignment_assignment_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Assignment_assignment_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Assignment_assignment_comments {
  __typename: "Comment";
  id: string;
}

export interface Assignment_assignment {
  __typename: "Assignment";
  id: string;
  status: ASSIGNMENT_STATUS | null;
  title: string;
  description: string | null;
  order: number;
  column: Assignment_assignment_column | null;
  createdBy: Assignment_assignment_createdBy | null;
  users: Assignment_assignment_users[];
  application: Assignment_assignment_application | null;
  applicant: Assignment_assignment_applicant | null;
  recruitment: Assignment_assignment_recruitment | null;
  comments: Assignment_assignment_comments[];
}

export interface Assignment {
  assignment: Assignment_assignment | null;
}

export interface AssignmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Assignments
// ====================================================

export interface Assignments_assignments_column {
  __typename: "AssignmentColumn";
  id: string;
  title: string;
}

export interface Assignments_assignments_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Assignments_assignments_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: Assignments_assignments_createdBy_profileImage | null;
}

export interface Assignments_assignments_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Assignments_assignments_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  profileImage: Assignments_assignments_users_profileImage | null;
}

export interface Assignments_assignments_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Assignments_assignments_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: Assignments_assignments_application_companyApplicant | null;
}

export interface Assignments_assignments_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Assignments_assignments_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Assignments_assignments_comments {
  __typename: "Comment";
  id: string;
}

export interface Assignments_assignments {
  __typename: "Assignment";
  id: string;
  status: ASSIGNMENT_STATUS | null;
  title: string;
  description: string | null;
  order: number;
  column: Assignments_assignments_column | null;
  createdBy: Assignments_assignments_createdBy | null;
  users: Assignments_assignments_users[];
  application: Assignments_assignments_application | null;
  applicant: Assignments_assignments_applicant | null;
  recruitment: Assignments_assignments_recruitment | null;
  comments: Assignments_assignments_comments[];
}

export interface Assignments {
  assignments: Assignments_assignments[];
}

export interface AssignmentsVariables {
  companyId: string;
  boardId: string;
  recruitments?: string[] | null;
  applications?: string[] | null;
  users?: string[] | null;
  applicants?: string[] | null;
  types?: string[] | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssignmentBoard
// ====================================================

export interface AssignmentBoard_assignmentBoard_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AssignmentBoard_assignmentBoard_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: AssignmentBoard_assignmentBoard_users_profileImage | null;
}

export interface AssignmentBoard_assignmentBoard_recruitment_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface AssignmentBoard_assignmentBoard_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface AssignmentBoard_assignmentBoard_recruitment_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AssignmentBoard_assignmentBoard_recruitment_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: AssignmentBoard_assignmentBoard_recruitment_users_user_profileImage | null;
}

export interface AssignmentBoard_assignmentBoard_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: AssignmentBoard_assignmentBoard_recruitment_users_user;
}

export interface AssignmentBoard_assignmentBoard_recruitment {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  status: AssignmentBoard_assignmentBoard_recruitment_status | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  countryCode: string | null;
  isPinned: boolean | null;
  isAnonymized: boolean;
  company: AssignmentBoard_assignmentBoard_recruitment_company;
  users: AssignmentBoard_assignmentBoard_recruitment_users[];
}

export interface AssignmentBoard_assignmentBoard_consultantProject_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: AssignmentBoard_assignmentBoard_consultantProject_users_user_profileImage | null;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: AssignmentBoard_assignmentBoard_consultantProject_users_user;
  consultantProject: AssignmentBoard_assignmentBoard_consultantProject_users_consultantProject;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: AssignmentBoard_assignmentBoard_consultantProject_customer_customerCompany_profileImage | null;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: AssignmentBoard_assignmentBoard_consultantProject_customer_invoiceInformation | null;
  status: AssignmentBoard_assignmentBoard_consultantProject_customer_status | null;
  companiesWithAccess: AssignmentBoard_assignmentBoard_consultantProject_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: AssignmentBoard_assignmentBoard_consultantProject_customer_customerCompany | null;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: AssignmentBoard_assignmentBoard_consultantProject_responsible_profileImage | null;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_tags {
  __typename: "TagLabel";
  id: string;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_comments {
  __typename: "Comment";
  id: string;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_files {
  __typename: "File";
  id: string;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface AssignmentBoard_assignmentBoard_consultantProject_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: AssignmentBoard_assignmentBoard_consultantProject_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface AssignmentBoard_assignmentBoard_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: AssignmentBoard_assignmentBoard_consultantProject_invoiceInformation;
  status: AssignmentBoard_assignmentBoard_consultantProject_status | null;
  users: AssignmentBoard_assignmentBoard_consultantProject_users[];
  customer: AssignmentBoard_assignmentBoard_consultantProject_customer | null;
  customerName: string | null;
  recruitment: AssignmentBoard_assignmentBoard_consultantProject_recruitment | null;
  responsible: AssignmentBoard_assignmentBoard_consultantProject_responsible | null;
  tags: AssignmentBoard_assignmentBoard_consultantProject_tags[];
  comments: AssignmentBoard_assignmentBoard_consultantProject_comments[];
  consultants: AssignmentBoard_assignmentBoard_consultantProject_consultants[];
  files: AssignmentBoard_assignmentBoard_consultantProject_files[];
  fieldExtensionValues: AssignmentBoard_assignmentBoard_consultantProject_fieldExtensionValues[];
}

export interface AssignmentBoard_assignmentBoard {
  __typename: "AssignmentBoard";
  id: string;
  title: string | null;
  createdAt: DateTime;
  users: AssignmentBoard_assignmentBoard_users[];
  recruitment: AssignmentBoard_assignmentBoard_recruitment | null;
  consultantProject: AssignmentBoard_assignmentBoard_consultantProject | null;
}

export interface AssignmentBoard {
  assignmentBoard: AssignmentBoard_assignmentBoard | null;
}

export interface AssignmentBoardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssignmentBoards
// ====================================================

export interface AssignmentBoards_assignmentBoards_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface AssignmentBoards_assignmentBoards_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface AssignmentBoards_assignmentBoards_edges_node_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  title: string;
}

export interface AssignmentBoards_assignmentBoards_edges_node_columns_assignments {
  __typename: "Assignment";
  id: string;
}

export interface AssignmentBoards_assignmentBoards_edges_node_columns {
  __typename: "AssignmentColumn";
  id: string;
  assignments: AssignmentBoards_assignmentBoards_edges_node_columns_assignments[];
}

export interface AssignmentBoards_assignmentBoards_edges_node_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AssignmentBoards_assignmentBoards_edges_node_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: AssignmentBoards_assignmentBoards_edges_node_users_profileImage | null;
}

export interface AssignmentBoards_assignmentBoards_edges_node {
  __typename: "AssignmentBoard";
  id: string;
  createdAt: DateTime;
  title: string | null;
  active: boolean;
  recruitment: AssignmentBoards_assignmentBoards_edges_node_recruitment | null;
  consultantProject: AssignmentBoards_assignmentBoards_edges_node_consultantProject | null;
  columns: AssignmentBoards_assignmentBoards_edges_node_columns[];
  users: AssignmentBoards_assignmentBoards_edges_node_users[];
}

export interface AssignmentBoards_assignmentBoards_edges {
  __typename: "AssignmentBoardEdge";
  cursor: string;
  node: AssignmentBoards_assignmentBoards_edges_node;
}

export interface AssignmentBoards_assignmentBoards {
  __typename: "AssignmentBoardConnection";
  pageInfo: AssignmentBoards_assignmentBoards_pageInfo;
  edges: (AssignmentBoards_assignmentBoards_edges | null)[];
}

export interface AssignmentBoards {
  assignmentBoards: AssignmentBoards_assignmentBoards;
}

export interface AssignmentBoardsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  searchText?: string | null;
  recruitmentIds?: string[] | null;
  consultantProjectIds?: string[] | null;
  archived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssignmentColumn
// ====================================================

export interface AssignmentColumn_assignmentColumn {
  __typename: "AssignmentColumn";
  id: string;
  createdAt: DateTime;
  title: string;
}

export interface AssignmentColumn {
  assignmentColumn: AssignmentColumn_assignmentColumn | null;
}

export interface AssignmentColumnVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssignmentColumns
// ====================================================

export interface AssignmentColumns_assignmentColumns {
  __typename: "AssignmentColumn";
  id: string;
  createdAt: DateTime;
  title: string;
  listIndex: number;
}

export interface AssignmentColumns {
  assignmentColumns: AssignmentColumns_assignmentColumns[];
}

export interface AssignmentColumnsVariables {
  boardId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatedBaseTags
// ====================================================

export interface PaginatedBaseTags_paginatedBaseTags_items_companies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PaginatedBaseTags_paginatedBaseTags_items_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface PaginatedBaseTags_paginatedBaseTags_items_companyApplicants {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface PaginatedBaseTags_paginatedBaseTags_items_recruitments {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
}

export interface PaginatedBaseTags_paginatedBaseTags_items_recruitmentTemplates {
  __typename: "RecruitmentTemplate";
  id: string;
  name: string;
  createdAt: DateTime;
}

export interface PaginatedBaseTags_paginatedBaseTags_items_groupCompanyApplicants {
  __typename: "GroupCompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface PaginatedBaseTags_paginatedBaseTags_items {
  __typename: "BaseTag";
  id: string;
  tag: string;
  companies: PaginatedBaseTags_paginatedBaseTags_items_companies[];
  applications: PaginatedBaseTags_paginatedBaseTags_items_applications[];
  companyApplicants: PaginatedBaseTags_paginatedBaseTags_items_companyApplicants[];
  recruitments: PaginatedBaseTags_paginatedBaseTags_items_recruitments[];
  recruitmentTemplates: PaginatedBaseTags_paginatedBaseTags_items_recruitmentTemplates[];
  groupCompanyApplicants: PaginatedBaseTags_paginatedBaseTags_items_groupCompanyApplicants[];
  applicationCount: number;
  companyApplicantCount: number;
  recruitmentCount: number;
  recruitmentTemplateCount: number;
  groupCompanyApplicantCount: number;
  companyCount: number;
}

export interface PaginatedBaseTags_paginatedBaseTags {
  __typename: "PaginatedBaseTags";
  items: PaginatedBaseTags_paginatedBaseTags_items[];
  totalCount: number;
}

export interface PaginatedBaseTags {
  paginatedBaseTags: PaginatedBaseTags_paginatedBaseTags;
}

export interface PaginatedBaseTagsVariables {
  companyId?: string | null;
  page?: number | null;
  pageSize?: number | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  filters?: BaseTagFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BaseTag
// ====================================================

export interface BaseTag_baseTag_companies {
  __typename: "Company";
  id: string;
  name: string;
  createdAt: DateTime;
}

export interface BaseTag_baseTag_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface BaseTag_baseTag_applications_recruitment_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface BaseTag_baseTag_applications_recruitment_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: BaseTag_baseTag_applications_recruitment_users_user_profileImage | null;
}

export interface BaseTag_baseTag_applications_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: BaseTag_baseTag_applications_recruitment_users_user;
}

export interface BaseTag_baseTag_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: BaseTag_baseTag_applications_recruitment_company;
  users: BaseTag_baseTag_applications_recruitment_users[];
}

export interface BaseTag_baseTag_applications_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface BaseTag_baseTag_applications {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  recruitment: BaseTag_baseTag_applications_recruitment;
  companyApplicant: BaseTag_baseTag_applications_companyApplicant | null;
}

export interface BaseTag_baseTag_companyApplicants_company {
  __typename: "Company";
  id: string;
}

export interface BaseTag_baseTag_companyApplicants {
  __typename: "CompanyApplicant";
  id: string;
  createdAt: DateTime | null;
  firstName: string;
  lastName: string;
  email: string;
  company: BaseTag_baseTag_companyApplicants_company;
}

export interface BaseTag_baseTag_recruitments_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface BaseTag_baseTag_recruitments_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface BaseTag_baseTag_recruitments_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: BaseTag_baseTag_recruitments_users_user_profileImage | null;
}

export interface BaseTag_baseTag_recruitments_users {
  __typename: "UserRecruitment";
  id: string;
  user: BaseTag_baseTag_recruitments_users_user;
}

export interface BaseTag_baseTag_recruitments {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  company: BaseTag_baseTag_recruitments_company;
  users: BaseTag_baseTag_recruitments_users[];
}

export interface BaseTag_baseTag_recruitmentTemplates_company {
  __typename: "Company";
  id: string;
}

export interface BaseTag_baseTag_recruitmentTemplates {
  __typename: "RecruitmentTemplate";
  id: string;
  createdAt: DateTime;
  name: string;
  company: BaseTag_baseTag_recruitmentTemplates_company;
}

export interface BaseTag_baseTag_groupCompanyApplicants {
  __typename: "GroupCompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface BaseTag_baseTag {
  __typename: "BaseTag";
  id: string;
  tag: string;
  companies: BaseTag_baseTag_companies[];
  applications: BaseTag_baseTag_applications[];
  companyApplicants: BaseTag_baseTag_companyApplicants[];
  recruitments: BaseTag_baseTag_recruitments[];
  recruitmentTemplates: BaseTag_baseTag_recruitmentTemplates[];
  groupCompanyApplicants: BaseTag_baseTag_groupCompanyApplicants[];
}

export interface BaseTag {
  baseTag: BaseTag_baseTag | null;
}

export interface BaseTagVariables {
  id: string;
  companyId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BaseTagsCompaniesFilter
// ====================================================

export interface BaseTagsCompaniesFilter_baseTagsCompaniesFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface BaseTagsCompaniesFilter_baseTagsCompaniesFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: BaseTagsCompaniesFilter_baseTagsCompaniesFilter_edges_node;
}

export interface BaseTagsCompaniesFilter_baseTagsCompaniesFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface BaseTagsCompaniesFilter_baseTagsCompaniesFilter {
  __typename: "FilterItemConnection";
  edges: (BaseTagsCompaniesFilter_baseTagsCompaniesFilter_edges | null)[];
  pageInfo: BaseTagsCompaniesFilter_baseTagsCompaniesFilter_pageInfo;
}

export interface BaseTagsCompaniesFilter {
  baseTagsCompaniesFilter: BaseTagsCompaniesFilter_baseTagsCompaniesFilter;
}

export interface BaseTagsCompaniesFilterVariables {
  filters?: BaseTagFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BaseTagsApplicationsFilter
// ====================================================

export interface BaseTagsApplicationsFilter_baseTagsApplicationsFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface BaseTagsApplicationsFilter_baseTagsApplicationsFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: BaseTagsApplicationsFilter_baseTagsApplicationsFilter_edges_node;
}

export interface BaseTagsApplicationsFilter_baseTagsApplicationsFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface BaseTagsApplicationsFilter_baseTagsApplicationsFilter {
  __typename: "FilterItemConnection";
  edges: (BaseTagsApplicationsFilter_baseTagsApplicationsFilter_edges | null)[];
  pageInfo: BaseTagsApplicationsFilter_baseTagsApplicationsFilter_pageInfo;
}

export interface BaseTagsApplicationsFilter {
  baseTagsApplicationsFilter: BaseTagsApplicationsFilter_baseTagsApplicationsFilter;
}

export interface BaseTagsApplicationsFilterVariables {
  filters?: BaseTagFilterInput | null;
  companyId?: string | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BaseTagsRecruitmentsFilter
// ====================================================

export interface BaseTagsRecruitmentsFilter_baseTagsRecruitmentsFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface BaseTagsRecruitmentsFilter_baseTagsRecruitmentsFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: BaseTagsRecruitmentsFilter_baseTagsRecruitmentsFilter_edges_node;
}

export interface BaseTagsRecruitmentsFilter_baseTagsRecruitmentsFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface BaseTagsRecruitmentsFilter_baseTagsRecruitmentsFilter {
  __typename: "FilterItemConnection";
  edges: (BaseTagsRecruitmentsFilter_baseTagsRecruitmentsFilter_edges | null)[];
  pageInfo: BaseTagsRecruitmentsFilter_baseTagsRecruitmentsFilter_pageInfo;
}

export interface BaseTagsRecruitmentsFilter {
  baseTagsRecruitmentsFilter: BaseTagsRecruitmentsFilter_baseTagsRecruitmentsFilter;
}

export interface BaseTagsRecruitmentsFilterVariables {
  filters?: BaseTagFilterInput | null;
  companyId?: string | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BaseTagsCompanyApplicantsFilter
// ====================================================

export interface BaseTagsCompanyApplicantsFilter_baseTagsCompanyApplicantsFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface BaseTagsCompanyApplicantsFilter_baseTagsCompanyApplicantsFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: BaseTagsCompanyApplicantsFilter_baseTagsCompanyApplicantsFilter_edges_node;
}

export interface BaseTagsCompanyApplicantsFilter_baseTagsCompanyApplicantsFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface BaseTagsCompanyApplicantsFilter_baseTagsCompanyApplicantsFilter {
  __typename: "FilterItemConnection";
  edges: (BaseTagsCompanyApplicantsFilter_baseTagsCompanyApplicantsFilter_edges | null)[];
  pageInfo: BaseTagsCompanyApplicantsFilter_baseTagsCompanyApplicantsFilter_pageInfo;
}

export interface BaseTagsCompanyApplicantsFilter {
  baseTagsCompanyApplicantsFilter: BaseTagsCompanyApplicantsFilter_baseTagsCompanyApplicantsFilter;
}

export interface BaseTagsCompanyApplicantsFilterVariables {
  filters?: BaseTagFilterInput | null;
  companyId?: string | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BaseTagsRecruitmentTemplatesFilter
// ====================================================

export interface BaseTagsRecruitmentTemplatesFilter_baseTagsRecruitmentTemplatesFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface BaseTagsRecruitmentTemplatesFilter_baseTagsRecruitmentTemplatesFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: BaseTagsRecruitmentTemplatesFilter_baseTagsRecruitmentTemplatesFilter_edges_node;
}

export interface BaseTagsRecruitmentTemplatesFilter_baseTagsRecruitmentTemplatesFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface BaseTagsRecruitmentTemplatesFilter_baseTagsRecruitmentTemplatesFilter {
  __typename: "FilterItemConnection";
  edges: (BaseTagsRecruitmentTemplatesFilter_baseTagsRecruitmentTemplatesFilter_edges | null)[];
  pageInfo: BaseTagsRecruitmentTemplatesFilter_baseTagsRecruitmentTemplatesFilter_pageInfo;
}

export interface BaseTagsRecruitmentTemplatesFilter {
  baseTagsRecruitmentTemplatesFilter: BaseTagsRecruitmentTemplatesFilter_baseTagsRecruitmentTemplatesFilter;
}

export interface BaseTagsRecruitmentTemplatesFilterVariables {
  filters?: BaseTagFilterInput | null;
  companyId?: string | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BaseTagsGroupCompanyApplicantsFilter
// ====================================================

export interface BaseTagsGroupCompanyApplicantsFilter_baseTagsGroupCompanyApplicantsFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface BaseTagsGroupCompanyApplicantsFilter_baseTagsGroupCompanyApplicantsFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: BaseTagsGroupCompanyApplicantsFilter_baseTagsGroupCompanyApplicantsFilter_edges_node;
}

export interface BaseTagsGroupCompanyApplicantsFilter_baseTagsGroupCompanyApplicantsFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface BaseTagsGroupCompanyApplicantsFilter_baseTagsGroupCompanyApplicantsFilter {
  __typename: "FilterItemConnection";
  edges: (BaseTagsGroupCompanyApplicantsFilter_baseTagsGroupCompanyApplicantsFilter_edges | null)[];
  pageInfo: BaseTagsGroupCompanyApplicantsFilter_baseTagsGroupCompanyApplicantsFilter_pageInfo;
}

export interface BaseTagsGroupCompanyApplicantsFilter {
  baseTagsGroupCompanyApplicantsFilter: BaseTagsGroupCompanyApplicantsFilter_baseTagsGroupCompanyApplicantsFilter;
}

export interface BaseTagsGroupCompanyApplicantsFilterVariables {
  filters?: BaseTagFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BaseVariables
// ====================================================

export interface BaseVariables_baseVariables_items_companies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface BaseVariables_baseVariables_items_variables_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface BaseVariables_baseVariables_items_variables {
  __typename: "Variable";
  id: string;
  key: string;
  value: string;
  company: BaseVariables_baseVariables_items_variables_company;
}

export interface BaseVariables_baseVariables_items {
  __typename: "BaseVariable";
  id: string;
  key: string;
  companies: BaseVariables_baseVariables_items_companies[];
  variables: BaseVariables_baseVariables_items_variables[];
}

export interface BaseVariables_baseVariables {
  __typename: "PaginatedBaseVariables";
  totalCount: number;
  items: BaseVariables_baseVariables_items[];
}

export interface BaseVariables {
  baseVariables: BaseVariables_baseVariables;
}

export interface BaseVariablesVariables {
  page?: number | null;
  pageSize?: number | null;
  searchText?: string | null;
  searchTextOptions?: string[] | null;
  sortBy?: string | null;
  sortOrder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BaseVariable
// ====================================================

export interface BaseVariable_baseVariable_companies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface BaseVariable_baseVariable_variables_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface BaseVariable_baseVariable_variables {
  __typename: "Variable";
  id: string;
  key: string;
  value: string;
  company: BaseVariable_baseVariable_variables_company;
}

export interface BaseVariable_baseVariable {
  __typename: "BaseVariable";
  id: string;
  key: string;
  companies: BaseVariable_baseVariable_companies[];
  variables: BaseVariable_baseVariable_variables[];
}

export interface BaseVariable {
  baseVariable: BaseVariable_baseVariable | null;
}

export interface BaseVariableVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTotalCalculatedMonthlyIncome
// ====================================================

export interface GetTotalCalculatedMonthlyIncome {
  getTotalCalculatedMonthlyIncome: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbRecruitment
// ====================================================

export interface BreadcrumbRecruitment_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface BreadcrumbRecruitment {
  recruitment: BreadcrumbRecruitment_recruitment | null;
}

export interface BreadcrumbRecruitmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbApplication
// ====================================================

export interface BreadcrumbApplication_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface BreadcrumbApplication {
  application: BreadcrumbApplication_application | null;
}

export interface BreadcrumbApplicationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbCompanyApplicant
// ====================================================

export interface BreadcrumbCompanyApplicant_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface BreadcrumbCompanyApplicant {
  companyApplicant: BreadcrumbCompanyApplicant_companyApplicant | null;
}

export interface BreadcrumbCompanyApplicantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbIdentifiedNeed
// ====================================================

export interface BreadcrumbIdentifiedNeed_identifiedNeed_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface BreadcrumbIdentifiedNeed_identifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
  title: string;
  recruitment: BreadcrumbIdentifiedNeed_identifiedNeed_recruitment | null;
}

export interface BreadcrumbIdentifiedNeed {
  identifiedNeed: BreadcrumbIdentifiedNeed_identifiedNeed | null;
}

export interface BreadcrumbIdentifiedNeedVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbCompany
// ====================================================

export interface BreadcrumbCompany_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface BreadcrumbCompany {
  company: BreadcrumbCompany_company | null;
}

export interface BreadcrumbCompanyVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbInterview
// ====================================================

export interface BreadcrumbInterview_interview {
  __typename: "Interview";
  id: string;
  title: string;
}

export interface BreadcrumbInterview {
  interview: BreadcrumbInterview_interview | null;
}

export interface BreadcrumbInterviewVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbPageTemplate
// ====================================================

export interface BreadcrumbPageTemplate_pageTemplate {
  __typename: "PageTemplate";
  id: string;
  name: string;
}

export interface BreadcrumbPageTemplate {
  pageTemplate: BreadcrumbPageTemplate_pageTemplate | null;
}

export interface BreadcrumbPageTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbForm
// ====================================================

export interface BreadcrumbForm_form {
  __typename: "Form";
  id: string;
  title: string | null;
}

export interface BreadcrumbForm {
  form: BreadcrumbForm_form | null;
}

export interface BreadcrumbFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbAssignmentBoard
// ====================================================

export interface BreadcrumbAssignmentBoard_assignmentBoard {
  __typename: "AssignmentBoard";
  id: string;
  title: string | null;
}

export interface BreadcrumbAssignmentBoard {
  assignmentBoard: BreadcrumbAssignmentBoard_assignmentBoard | null;
}

export interface BreadcrumbAssignmentBoardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbConsultantProject
// ====================================================

export interface BreadcrumbConsultantProject_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  title: string;
}

export interface BreadcrumbConsultantProject {
  consultantProject: BreadcrumbConsultantProject_consultantProject | null;
}

export interface BreadcrumbConsultantProjectVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbConsultantCustomer
// ====================================================

export interface BreadcrumbConsultantCustomer_consultantCustomer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
}

export interface BreadcrumbConsultantCustomer {
  consultantCustomer: BreadcrumbConsultantCustomer_consultantCustomer | null;
}

export interface BreadcrumbConsultantCustomerVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbConsultant
// ====================================================

export interface BreadcrumbConsultant_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface BreadcrumbConsultant {
  consultant: BreadcrumbConsultant_consultant | null;
}

export interface BreadcrumbConsultantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbInterviewGuide
// ====================================================

export interface BreadcrumbInterviewGuide_interviewGuide {
  __typename: "InterviewGuide";
  id: string;
  title: string | null;
}

export interface BreadcrumbInterviewGuide {
  interviewGuide: BreadcrumbInterviewGuide_interviewGuide | null;
}

export interface BreadcrumbInterviewGuideVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbRecruitmentTemplate
// ====================================================

export interface BreadcrumbRecruitmentTemplate_recruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
  name: string;
}

export interface BreadcrumbRecruitmentTemplate {
  recruitmentTemplate: BreadcrumbRecruitmentTemplate_recruitmentTemplate | null;
}

export interface BreadcrumbRecruitmentTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbMessageTemplate
// ====================================================

export interface BreadcrumbMessageTemplate_messageTemplate {
  __typename: "MessageTemplateWithHtml";
  id: string;
  templateName: string | null;
}

export interface BreadcrumbMessageTemplate {
  messageTemplate: BreadcrumbMessageTemplate_messageTemplate | null;
}

export interface BreadcrumbMessageTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbIdentifiedNeedForm
// ====================================================

export interface BreadcrumbIdentifiedNeedForm_identifiedNeedForm {
  __typename: "IdentifiedNeedForm";
  id: string;
  title: string | null;
}

export interface BreadcrumbIdentifiedNeedForm {
  identifiedNeedForm: BreadcrumbIdentifiedNeedForm_identifiedNeedForm | null;
}

export interface BreadcrumbIdentifiedNeedFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbReferenceForm
// ====================================================

export interface BreadcrumbReferenceForm_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface BreadcrumbReferenceForm {
  referenceForm: BreadcrumbReferenceForm_referenceForm | null;
}

export interface BreadcrumbReferenceFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbJobTipAd
// ====================================================

export interface BreadcrumbJobTipAd_jobtipAd {
  __typename: "JobtipAd";
  id: string;
  title: string | null;
}

export interface BreadcrumbJobTipAd {
  jobtipAd: BreadcrumbJobTipAd_jobtipAd;
}

export interface BreadcrumbJobTipAdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbCompetence
// ====================================================

export interface BreadcrumbCompetence_competence {
  __typename: "Competence";
  id: string;
  name: string;
}

export interface BreadcrumbCompetence {
  competence: BreadcrumbCompetence_competence | null;
}

export interface BreadcrumbCompetenceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbInterviewBookingGroup
// ====================================================

export interface BreadcrumbInterviewBookingGroup_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface BreadcrumbInterviewBookingGroup {
  interviewBookingGroup: BreadcrumbInterviewBookingGroup_interviewBookingGroup | null;
}

export interface BreadcrumbInterviewBookingGroupVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbScreeningForm
// ====================================================

export interface BreadcrumbScreeningForm_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface BreadcrumbScreeningForm {
  screeningForm: BreadcrumbScreeningForm_screeningForm | null;
}

export interface BreadcrumbScreeningFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbGroupCompanyApplicant
// ====================================================

export interface BreadcrumbGroupCompanyApplicant_groupCompanyApplicant {
  __typename: "GroupCompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface BreadcrumbGroupCompanyApplicant {
  groupCompanyApplicant: BreadcrumbGroupCompanyApplicant_groupCompanyApplicant;
}

export interface BreadcrumbGroupCompanyApplicantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbMatchingEventProfile
// ====================================================

export interface BreadcrumbMatchingEventProfile_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface BreadcrumbMatchingEventProfile {
  matchingEventProfile: BreadcrumbMatchingEventProfile_matchingEventProfile | null;
}

export interface BreadcrumbMatchingEventProfileVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbMatchingEvent
// ====================================================

export interface BreadcrumbMatchingEvent_matchingEvent {
  __typename: "MatchingEvent";
  id: string;
  title: string;
}

export interface BreadcrumbMatchingEvent {
  matchingEvent: BreadcrumbMatchingEvent_matchingEvent | null;
}

export interface BreadcrumbMatchingEventVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbMatchingEventParticipant
// ====================================================

export interface BreadcrumbMatchingEventParticipant_matchingEventParticipant_event {
  __typename: "MatchingEvent";
  id: string;
  title: string;
}

export interface BreadcrumbMatchingEventParticipant_matchingEventParticipant {
  __typename: "MatchingEventParticipant";
  id: string;
  companyName: string;
  event: BreadcrumbMatchingEventParticipant_matchingEventParticipant_event;
}

export interface BreadcrumbMatchingEventParticipant {
  matchingEventParticipant: BreadcrumbMatchingEventParticipant_matchingEventParticipant | null;
}

export interface BreadcrumbMatchingEventParticipantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbMatchingEventTalentProfile
// ====================================================

export interface BreadcrumbMatchingEventTalentProfile_matchingEventTalentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface BreadcrumbMatchingEventTalentProfile {
  matchingEventTalentProfile: BreadcrumbMatchingEventTalentProfile_matchingEventTalentProfile | null;
}

export interface BreadcrumbMatchingEventTalentProfileVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbEventMatch
// ====================================================

export interface BreadcrumbEventMatch_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
}

export interface BreadcrumbEventMatch {
  eventMatch: BreadcrumbEventMatch_eventMatch | null;
}

export interface BreadcrumbEventMatchVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbBaseVariable
// ====================================================

export interface BreadcrumbBaseVariable_baseVariable {
  __typename: "BaseVariable";
  id: string;
  key: string;
}

export interface BreadcrumbBaseVariable {
  baseVariable: BreadcrumbBaseVariable_baseVariable | null;
}

export interface BreadcrumbBaseVariableVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbRecruitments
// ====================================================

export interface BreadcrumbRecruitments_recruitmentsPage_edges_node {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface BreadcrumbRecruitments_recruitmentsPage_edges {
  __typename: "RecruitmentEdge";
  cursor: string;
  node: BreadcrumbRecruitments_recruitmentsPage_edges_node;
}

export interface BreadcrumbRecruitments_recruitmentsPage_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbRecruitments_recruitmentsPage {
  __typename: "RecruitmentConnection";
  edges: (BreadcrumbRecruitments_recruitmentsPage_edges | null)[];
  pageInfo: BreadcrumbRecruitments_recruitmentsPage_pageInfo;
}

export interface BreadcrumbRecruitments {
  recruitmentsPage: BreadcrumbRecruitments_recruitmentsPage;
}

export interface BreadcrumbRecruitmentsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbGroupCompanyApplicants
// ====================================================

export interface BreadcrumbGroupCompanyApplicants_cursorBasedGroupCompanyApplicants_edges_node {
  __typename: "GroupCompanyApplicant";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
}

export interface BreadcrumbGroupCompanyApplicants_cursorBasedGroupCompanyApplicants_edges {
  __typename: "GroupCompanyApplicantEdge";
  cursor: string;
  node: BreadcrumbGroupCompanyApplicants_cursorBasedGroupCompanyApplicants_edges_node;
}

export interface BreadcrumbGroupCompanyApplicants_cursorBasedGroupCompanyApplicants_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbGroupCompanyApplicants_cursorBasedGroupCompanyApplicants {
  __typename: "GroupCompanyApplicantConnection";
  edges: (BreadcrumbGroupCompanyApplicants_cursorBasedGroupCompanyApplicants_edges | null)[];
  pageInfo: BreadcrumbGroupCompanyApplicants_cursorBasedGroupCompanyApplicants_pageInfo;
}

export interface BreadcrumbGroupCompanyApplicants {
  cursorBasedGroupCompanyApplicants: BreadcrumbGroupCompanyApplicants_cursorBasedGroupCompanyApplicants;
}

export interface BreadcrumbGroupCompanyApplicantsVariables {
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbBaseVariables
// ====================================================

export interface BreadcrumbBaseVariables_baseVariablesCursorBased_edges_node {
  __typename: "BaseVariable";
  id: string;
  key: string;
}

export interface BreadcrumbBaseVariables_baseVariablesCursorBased_edges {
  __typename: "BaseVariableEdge";
  cursor: string;
  node: BreadcrumbBaseVariables_baseVariablesCursorBased_edges_node;
}

export interface BreadcrumbBaseVariables_baseVariablesCursorBased_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbBaseVariables_baseVariablesCursorBased {
  __typename: "BaseVariableConnection";
  edges: (BreadcrumbBaseVariables_baseVariablesCursorBased_edges | null)[];
  pageInfo: BreadcrumbBaseVariables_baseVariablesCursorBased_pageInfo;
}

export interface BreadcrumbBaseVariables {
  baseVariablesCursorBased: BreadcrumbBaseVariables_baseVariablesCursorBased;
}

export interface BreadcrumbBaseVariablesVariables {
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbApplications
// ====================================================

export interface BreadcrumbApplications_applications_edges_node {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
}

export interface BreadcrumbApplications_applications_edges {
  __typename: "ApplicationEdge";
  cursor: string;
  node: BreadcrumbApplications_applications_edges_node;
}

export interface BreadcrumbApplications_applications_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbApplications_applications {
  __typename: "ApplicationConnection";
  edges: (BreadcrumbApplications_applications_edges | null)[];
  pageInfo: BreadcrumbApplications_applications_pageInfo;
}

export interface BreadcrumbApplications {
  applications: BreadcrumbApplications_applications;
}

export interface BreadcrumbApplicationsVariables {
  companyId: string;
  getChildCompaniesApplications?: boolean | null;
  recruitmentFilter?: string[] | null;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbRecruitmentTemplates
// ====================================================

export interface BreadcrumbRecruitmentTemplates_recruitmentTemplates_edges_node {
  __typename: "RecruitmentTemplate";
  id: string;
  name: string;
}

export interface BreadcrumbRecruitmentTemplates_recruitmentTemplates_edges {
  __typename: "RecruitmentTemplateEdge";
  cursor: string;
  node: BreadcrumbRecruitmentTemplates_recruitmentTemplates_edges_node;
}

export interface BreadcrumbRecruitmentTemplates_recruitmentTemplates_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbRecruitmentTemplates_recruitmentTemplates {
  __typename: "RecruitmentTemplateConnection";
  edges: (BreadcrumbRecruitmentTemplates_recruitmentTemplates_edges | null)[];
  pageInfo: BreadcrumbRecruitmentTemplates_recruitmentTemplates_pageInfo;
}

export interface BreadcrumbRecruitmentTemplates {
  recruitmentTemplates: BreadcrumbRecruitmentTemplates_recruitmentTemplates;
}

export interface BreadcrumbRecruitmentTemplatesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  showTemplatesFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbAdTemplates
// ====================================================

export interface BreadcrumbAdTemplates_pageTemplates_edges_node {
  __typename: "PageTemplate";
  id: string;
  name: string;
}

export interface BreadcrumbAdTemplates_pageTemplates_edges {
  __typename: "PageTemplateEdge";
  cursor: string;
  node: BreadcrumbAdTemplates_pageTemplates_edges_node;
}

export interface BreadcrumbAdTemplates_pageTemplates_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbAdTemplates_pageTemplates {
  __typename: "PageTemplateConnection";
  edges: (BreadcrumbAdTemplates_pageTemplates_edges | null)[];
  pageInfo: BreadcrumbAdTemplates_pageTemplates_pageInfo;
}

export interface BreadcrumbAdTemplates {
  pageTemplates: BreadcrumbAdTemplates_pageTemplates;
}

export interface BreadcrumbAdTemplatesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbMessageTemplates
// ====================================================

export interface BreadcrumbMessageTemplates_messageTemplates_edges_node {
  __typename: "MessageTemplate";
  id: string;
  templateName: string | null;
  language: string | null;
  type: MESSAGE_TYPE;
}

export interface BreadcrumbMessageTemplates_messageTemplates_edges {
  __typename: "MessageTemplateEdge";
  cursor: string;
  node: BreadcrumbMessageTemplates_messageTemplates_edges_node;
}

export interface BreadcrumbMessageTemplates_messageTemplates_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbMessageTemplates_messageTemplates {
  __typename: "MessageTemplateConnection";
  edges: (BreadcrumbMessageTemplates_messageTemplates_edges | null)[];
  pageInfo: BreadcrumbMessageTemplates_messageTemplates_pageInfo;
}

export interface BreadcrumbMessageTemplates {
  messageTemplates: BreadcrumbMessageTemplates_messageTemplates;
}

export interface BreadcrumbMessageTemplatesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbMessageTemplatesCustom
// ====================================================

export interface BreadcrumbMessageTemplatesCustom_messageTemplatesCustom_edges_node {
  __typename: "MessageTemplate";
  id: string;
  templateName: string | null;
  language: string | null;
  type: MESSAGE_TYPE;
}

export interface BreadcrumbMessageTemplatesCustom_messageTemplatesCustom_edges {
  __typename: "MessageTemplateEdge";
  cursor: string;
  node: BreadcrumbMessageTemplatesCustom_messageTemplatesCustom_edges_node;
}

export interface BreadcrumbMessageTemplatesCustom_messageTemplatesCustom_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbMessageTemplatesCustom_messageTemplatesCustom {
  __typename: "MessageTemplateConnection";
  edges: (BreadcrumbMessageTemplatesCustom_messageTemplatesCustom_edges | null)[];
  pageInfo: BreadcrumbMessageTemplatesCustom_messageTemplatesCustom_pageInfo;
}

export interface BreadcrumbMessageTemplatesCustom {
  messageTemplatesCustom: BreadcrumbMessageTemplatesCustom_messageTemplatesCustom;
}

export interface BreadcrumbMessageTemplatesCustomVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  showResultsFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbInterviewGuideTemplates
// ====================================================

export interface BreadcrumbInterviewGuideTemplates_interviewGuideTemplates_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
}

export interface BreadcrumbInterviewGuideTemplates_interviewGuideTemplates_edges_node {
  __typename: "InterviewGuide";
  id: string;
  title: string | null;
}

export interface BreadcrumbInterviewGuideTemplates_interviewGuideTemplates_edges {
  __typename: "InterviewGuideEdge";
  cursor: string;
  node: BreadcrumbInterviewGuideTemplates_interviewGuideTemplates_edges_node;
}

export interface BreadcrumbInterviewGuideTemplates_interviewGuideTemplates {
  __typename: "InterviewGuideConnection";
  pageInfo: BreadcrumbInterviewGuideTemplates_interviewGuideTemplates_pageInfo;
  edges: (BreadcrumbInterviewGuideTemplates_interviewGuideTemplates_edges | null)[];
}

export interface BreadcrumbInterviewGuideTemplates {
  interviewGuideTemplates: BreadcrumbInterviewGuideTemplates_interviewGuideTemplates;
}

export interface BreadcrumbInterviewGuideTemplatesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
  showResultsFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbFormTemplates
// ====================================================

export interface BreadcrumbFormTemplates_forms_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface BreadcrumbFormTemplates_forms_edges_node {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
}

export interface BreadcrumbFormTemplates_forms_edges {
  __typename: "FormEdge";
  cursor: string;
  node: BreadcrumbFormTemplates_forms_edges_node;
}

export interface BreadcrumbFormTemplates_forms {
  __typename: "FormConnection";
  pageInfo: BreadcrumbFormTemplates_forms_pageInfo;
  edges: (BreadcrumbFormTemplates_forms_edges | null)[];
}

export interface BreadcrumbFormTemplates {
  forms: BreadcrumbFormTemplates_forms;
}

export interface BreadcrumbFormTemplatesVariables {
  companyId: string;
  after?: string | null;
  limit: number;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  types?: FORM_TYPE[] | null;
  statuses?: FORM_STATUS[] | null;
  recruitments?: string[] | null;
  applications?: string[] | null;
  showResultsFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbCompanyCompetences
// ====================================================

export interface BreadcrumbCompanyCompetences_companyCompetences_edges_node {
  __typename: "Competence";
  id: string;
  name: string;
  type: COMPETENCE_TYPE | null;
}

export interface BreadcrumbCompanyCompetences_companyCompetences_edges {
  __typename: "CompetenceEdge";
  cursor: string;
  node: BreadcrumbCompanyCompetences_companyCompetences_edges_node;
}

export interface BreadcrumbCompanyCompetences_companyCompetences_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbCompanyCompetences_companyCompetences {
  __typename: "CompetenceConnection";
  edges: (BreadcrumbCompanyCompetences_companyCompetences_edges | null)[];
  pageInfo: BreadcrumbCompanyCompetences_companyCompetences_pageInfo;
}

export interface BreadcrumbCompanyCompetences {
  companyCompetences: BreadcrumbCompanyCompetences_companyCompetences;
}

export interface BreadcrumbCompanyCompetencesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbInterviewBookingGroups
// ====================================================

export interface BreadcrumbInterviewBookingGroups_interviewBookingGroups_edges_node {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface BreadcrumbInterviewBookingGroups_interviewBookingGroups_edges {
  __typename: "InterviewBookingGroupEdge";
  cursor: string;
  node: BreadcrumbInterviewBookingGroups_interviewBookingGroups_edges_node;
}

export interface BreadcrumbInterviewBookingGroups_interviewBookingGroups_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbInterviewBookingGroups_interviewBookingGroups {
  __typename: "InterviewBookingGroupConnection";
  edges: (BreadcrumbInterviewBookingGroups_interviewBookingGroups_edges | null)[];
  pageInfo: BreadcrumbInterviewBookingGroups_interviewBookingGroups_pageInfo;
}

export interface BreadcrumbInterviewBookingGroups {
  interviewBookingGroups: BreadcrumbInterviewBookingGroups_interviewBookingGroups;
}

export interface BreadcrumbInterviewBookingGroupsVariables {
  companyId: string;
  recruitmentId?: string | null;
  applicationId?: string | null;
  after?: string | null;
  limit?: number | null;
  sortBy?: string | null;
  searchText?: string | null;
  sortOrder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbCompanyApplicants
// ====================================================

export interface BreadcrumbCompanyApplicants_companyApplicants_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
}

export interface BreadcrumbCompanyApplicants_companyApplicants_edges_node {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface BreadcrumbCompanyApplicants_companyApplicants_edges {
  __typename: "CompanyApplicantEdge";
  cursor: string;
  node: BreadcrumbCompanyApplicants_companyApplicants_edges_node;
}

export interface BreadcrumbCompanyApplicants_companyApplicants {
  __typename: "CompanyApplicantConnection";
  pageInfo: BreadcrumbCompanyApplicants_companyApplicants_pageInfo;
  edges: (BreadcrumbCompanyApplicants_companyApplicants_edges | null)[];
}

export interface BreadcrumbCompanyApplicants {
  companyApplicants: BreadcrumbCompanyApplicants_companyApplicants;
}

export interface BreadcrumbCompanyApplicantsVariables {
  companyId?: string | null;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbScreeningForms
// ====================================================

export interface BreadcrumbScreeningForms_screeningForms_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
}

export interface BreadcrumbScreeningForms_screeningForms_edges_node {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface BreadcrumbScreeningForms_screeningForms_edges {
  __typename: "ScreeningFormEdge";
  cursor: string;
  node: BreadcrumbScreeningForms_screeningForms_edges_node;
}

export interface BreadcrumbScreeningForms_screeningForms {
  __typename: "ScreeningFormConnection";
  pageInfo: BreadcrumbScreeningForms_screeningForms_pageInfo;
  edges: (BreadcrumbScreeningForms_screeningForms_edges | null)[];
}

export interface BreadcrumbScreeningForms {
  screeningForms: BreadcrumbScreeningForms_screeningForms;
}

export interface BreadcrumbScreeningFormsVariables {
  companyId: string;
  after?: string | null;
  limit: number;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  recruitments?: string[] | null;
  excludeFormWithApplicationId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbReferenceForms
// ====================================================

export interface BreadcrumbReferenceForms_referenceForms_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
}

export interface BreadcrumbReferenceForms_referenceForms_edges_node {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface BreadcrumbReferenceForms_referenceForms_edges {
  __typename: "ReferenceFormEdge";
  cursor: string;
  node: BreadcrumbReferenceForms_referenceForms_edges_node;
}

export interface BreadcrumbReferenceForms_referenceForms {
  __typename: "ReferenceFormConnection";
  pageInfo: BreadcrumbReferenceForms_referenceForms_pageInfo;
  edges: (BreadcrumbReferenceForms_referenceForms_edges | null)[];
}

export interface BreadcrumbReferenceForms {
  referenceForms: BreadcrumbReferenceForms_referenceForms;
}

export interface BreadcrumbReferenceFormsVariables {
  companyId: string;
  after?: string | null;
  limit: number;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  recruitments?: string[] | null;
  excludeFormWithApplicationId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbAssignmentBoards
// ====================================================

export interface BreadcrumbAssignmentBoards_assignmentBoards_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
}

export interface BreadcrumbAssignmentBoards_assignmentBoards_edges_node {
  __typename: "AssignmentBoard";
  id: string;
  title: string | null;
}

export interface BreadcrumbAssignmentBoards_assignmentBoards_edges {
  __typename: "AssignmentBoardEdge";
  cursor: string;
  node: BreadcrumbAssignmentBoards_assignmentBoards_edges_node;
}

export interface BreadcrumbAssignmentBoards_assignmentBoards {
  __typename: "AssignmentBoardConnection";
  pageInfo: BreadcrumbAssignmentBoards_assignmentBoards_pageInfo;
  edges: (BreadcrumbAssignmentBoards_assignmentBoards_edges | null)[];
}

export interface BreadcrumbAssignmentBoards {
  assignmentBoards: BreadcrumbAssignmentBoards_assignmentBoards;
}

export interface BreadcrumbAssignmentBoardsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  searchText?: string | null;
  recruitmentIds?: string[] | null;
  consultantProjectIds?: string[] | null;
  archived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbConsultantProjects
// ====================================================

export interface BreadcrumbConsultantProjects_consultantProjects_edges_node {
  __typename: "ConsultantProject";
  id: string;
  title: string;
}

export interface BreadcrumbConsultantProjects_consultantProjects_edges {
  __typename: "ConsultantProjectEdge";
  cursor: string;
  node: BreadcrumbConsultantProjects_consultantProjects_edges_node;
}

export interface BreadcrumbConsultantProjects_consultantProjects_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbConsultantProjects_consultantProjects {
  __typename: "ConsultantProjectConnection";
  edges: (BreadcrumbConsultantProjects_consultantProjects_edges | null)[];
  pageInfo: BreadcrumbConsultantProjects_consultantProjects_pageInfo;
}

export interface BreadcrumbConsultantProjects {
  consultantProjects: BreadcrumbConsultantProjects_consultantProjects;
}

export interface BreadcrumbConsultantProjectsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  customerId?: string | null;
  consultantId?: string | null;
  searchText?: string | null;
  tagFilter?: string[] | null;
  statusFilter?: string[] | null;
  activeFilter?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbConsultantCustomers
// ====================================================

export interface BreadcrumbConsultantCustomers_consultantCustomers_edges_node {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
}

export interface BreadcrumbConsultantCustomers_consultantCustomers_edges {
  __typename: "ConsultantCustomerEdge";
  cursor: string;
  node: BreadcrumbConsultantCustomers_consultantCustomers_edges_node;
}

export interface BreadcrumbConsultantCustomers_consultantCustomers_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbConsultantCustomers_consultantCustomers {
  __typename: "ConsultantCustomerConnection";
  edges: (BreadcrumbConsultantCustomers_consultantCustomers_edges | null)[];
  pageInfo: BreadcrumbConsultantCustomers_consultantCustomers_pageInfo;
}

export interface BreadcrumbConsultantCustomers {
  consultantCustomers: BreadcrumbConsultantCustomers_consultantCustomers;
}

export interface BreadcrumbConsultantCustomersVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  statusFilter?: string[] | null;
  showTemplatesFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbConsultants
// ====================================================

export interface BreadcrumbConsultants_consultants_edges_node {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface BreadcrumbConsultants_consultants_edges {
  __typename: "ConsultantEdge";
  cursor: string;
  node: BreadcrumbConsultants_consultants_edges_node;
}

export interface BreadcrumbConsultants_consultants_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbConsultants_consultants {
  __typename: "ConsultantConnection";
  edges: (BreadcrumbConsultants_consultants_edges | null)[];
  pageInfo: BreadcrumbConsultants_consultants_pageInfo;
}

export interface BreadcrumbConsultants {
  consultants: BreadcrumbConsultants_consultants;
}

export interface BreadcrumbConsultantsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  searchText?: string | null;
  consultantProjectId?: string | null;
  statusFilter?: string[] | null;
  questionFilter?: FormQuestionFilter[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbIdentifiedNeedForms
// ====================================================

export interface BreadcrumbIdentifiedNeedForms_identifiedNeedForms_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
}

export interface BreadcrumbIdentifiedNeedForms_identifiedNeedForms_edges_node {
  __typename: "IdentifiedNeedForm";
  id: string;
  title: string | null;
}

export interface BreadcrumbIdentifiedNeedForms_identifiedNeedForms_edges {
  __typename: "IdentifiedNeedFormEdge";
  cursor: string;
  node: BreadcrumbIdentifiedNeedForms_identifiedNeedForms_edges_node;
}

export interface BreadcrumbIdentifiedNeedForms_identifiedNeedForms {
  __typename: "IdentifiedNeedFormConnection";
  pageInfo: BreadcrumbIdentifiedNeedForms_identifiedNeedForms_pageInfo;
  edges: (BreadcrumbIdentifiedNeedForms_identifiedNeedForms_edges | null)[];
}

export interface BreadcrumbIdentifiedNeedForms {
  identifiedNeedForms: BreadcrumbIdentifiedNeedForms_identifiedNeedForms;
}

export interface BreadcrumbIdentifiedNeedFormsVariables {
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
  identifiedNeedId?: string | null;
  after?: string | null;
  limit: number;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BreadcrumbInterviews
// ====================================================

export interface BreadcrumbInterviews_interviews_edges_node {
  __typename: "Interview";
  id: string;
  title: string;
}

export interface BreadcrumbInterviews_interviews_edges {
  __typename: "InterviewEdge";
  cursor: string;
  node: BreadcrumbInterviews_interviews_edges_node;
}

export interface BreadcrumbInterviews_interviews_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface BreadcrumbInterviews_interviews {
  __typename: "InterviewConnection";
  edges: (BreadcrumbInterviews_interviews_edges | null)[];
  pageInfo: BreadcrumbInterviews_interviews_pageInfo;
}

export interface BreadcrumbInterviews {
  interviews: BreadcrumbInterviews_interviews;
}

export interface BreadcrumbInterviewsVariables {
  applicantId?: string | null;
  applicationId?: string | null;
  recruitmentId?: string | null;
  searchText?: string | null;
  after?: string | null;
  limit: number;
  sortBy?: string | null;
  sortOrder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CalendarFilters
// ====================================================

export interface CalendarFilters_recruitments {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  publishingStatus: RECRUITMENT_STATUS | null;
}

export interface CalendarFilters_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CalendarFilters_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CalendarFilters_users_profileImage | null;
}

export interface CalendarFilters {
  recruitments: CalendarFilters_recruitments[];
  users: CalendarFilters_users[];
}

export interface CalendarFiltersVariables {
  companyId: string;
  recruitmentSearchText?: string | null;
  usersSearchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CalendarEvent
// ====================================================

export interface CalendarEvent_calendarEvent_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface CalendarEvent_calendarEvent_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface CalendarEvent_calendarEvent_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CalendarEvent_calendarEvent_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  profileImage: CalendarEvent_calendarEvent_attendees_user_profileImage | null;
}

export interface CalendarEvent_calendarEvent_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
}

export interface CalendarEvent_calendarEvent_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface CalendarEvent_calendarEvent_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: CalendarEvent_calendarEvent_attendees_application_recruitment;
}

export interface CalendarEvent_calendarEvent_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
  user: CalendarEvent_calendarEvent_attendees_user | null;
  eventMatch: CalendarEvent_calendarEvent_attendees_eventMatch | null;
  application: CalendarEvent_calendarEvent_attendees_application | null;
}

export interface CalendarEvent_calendarEvent {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  startTime: DateTime;
  endTime: DateTime;
  type: CALENDAR_EVENT_TYPE;
  location: string | null;
  url: string | null;
  recruitment: CalendarEvent_calendarEvent_recruitment | null;
  interviewBookingGroup: CalendarEvent_calendarEvent_interviewBookingGroup | null;
  attendees: CalendarEvent_calendarEvent_attendees[];
}

export interface CalendarEvent {
  calendarEvent: CalendarEvent_calendarEvent | null;
}

export interface CalendarEventVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CalendarEvents
// ====================================================

export interface CalendarEvents_calendarEvents_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CalendarEvents_calendarEvents_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CalendarEvents_calendarEvents_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CalendarEvents_calendarEvents_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: CalendarEvents_calendarEvents_attendees_eventMatch_talentProfile;
}

export interface CalendarEvents_calendarEvents_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CalendarEvents_calendarEvents_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: CalendarEvents_calendarEvents_attendees_application_recruitment;
}

export interface CalendarEvents_calendarEvents_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CalendarEvents_calendarEvents_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CalendarEvents_calendarEvents_attendees_user_profileImage | null;
}

export interface CalendarEvents_calendarEvents_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: CalendarEvents_calendarEvents_attendees_eventMatch | null;
  application: CalendarEvents_calendarEvents_attendees_application | null;
  user: CalendarEvents_calendarEvents_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface CalendarEvents_calendarEvents_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface CalendarEvents_calendarEvents_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CalendarEvents_calendarEvents {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: CalendarEvents_calendarEvents_recruitment | null;
  company: CalendarEvents_calendarEvents_company;
  attendees: CalendarEvents_calendarEvents_attendees[];
  interviewBookingGroup: CalendarEvents_calendarEvents_interviewBookingGroup | null;
  matchingEventTalentProfiles: CalendarEvents_calendarEvents_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface CalendarEvents {
  calendarEvents: CalendarEvents_calendarEvents[];
}

export interface CalendarEventsVariables {
  companyId: string;
  recruitmentIds?: string[] | null;
  userIds?: string[] | null;
  from?: DateTime | null;
  to?: DateTime | null;
  type?: CALENDAR_EVENT_TYPE | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CalendarEventsByIds
// ====================================================

export interface CalendarEventsByIds_calendarEventsByIds {
  __typename: "CalendarEventExposed";
  id: string;
  title: string | null;
  description: string | null;
  location: string | null;
  url: string | null;
  startTime: DateTime;
  endTime: DateTime;
}

export interface CalendarEventsByIds {
  calendarEventsByIds: CalendarEventsByIds_calendarEventsByIds[];
}

export interface CalendarEventsByIdsVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CalendarEventsList
// ====================================================

export interface CalendarEventsList_calendarEventsList_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CalendarEventsList_calendarEventsList_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CalendarEventsList_calendarEventsList_edges_node_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CalendarEventsList_calendarEventsList_edges_node_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: CalendarEventsList_calendarEventsList_edges_node_attendees_eventMatch_talentProfile;
}

export interface CalendarEventsList_calendarEventsList_edges_node_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CalendarEventsList_calendarEventsList_edges_node_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: CalendarEventsList_calendarEventsList_edges_node_attendees_application_recruitment;
}

export interface CalendarEventsList_calendarEventsList_edges_node_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CalendarEventsList_calendarEventsList_edges_node_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CalendarEventsList_calendarEventsList_edges_node_attendees_user_profileImage | null;
}

export interface CalendarEventsList_calendarEventsList_edges_node_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: CalendarEventsList_calendarEventsList_edges_node_attendees_eventMatch | null;
  application: CalendarEventsList_calendarEventsList_edges_node_attendees_application | null;
  user: CalendarEventsList_calendarEventsList_edges_node_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface CalendarEventsList_calendarEventsList_edges_node_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface CalendarEventsList_calendarEventsList_edges_node_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CalendarEventsList_calendarEventsList_edges_node {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: CalendarEventsList_calendarEventsList_edges_node_recruitment | null;
  company: CalendarEventsList_calendarEventsList_edges_node_company;
  attendees: CalendarEventsList_calendarEventsList_edges_node_attendees[];
  interviewBookingGroup: CalendarEventsList_calendarEventsList_edges_node_interviewBookingGroup | null;
  matchingEventTalentProfiles: CalendarEventsList_calendarEventsList_edges_node_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface CalendarEventsList_calendarEventsList_edges {
  __typename: "CalendarEventEdge";
  cursor: string;
  node: CalendarEventsList_calendarEventsList_edges_node;
}

export interface CalendarEventsList_calendarEventsList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface CalendarEventsList_calendarEventsList {
  __typename: "CalendarEventConnection";
  edges: (CalendarEventsList_calendarEventsList_edges | null)[];
  pageInfo: CalendarEventsList_calendarEventsList_pageInfo;
}

export interface CalendarEventsList {
  calendarEventsList: CalendarEventsList_calendarEventsList;
}

export interface CalendarEventsListVariables {
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  recruitmentId?: string | null;
  applicationId?: string | null;
  matchingEventId?: string | null;
  matchingEventParticipantId?: string | null;
  onlyUpcoming?: boolean | null;
  onlyMyInterviews?: boolean | null;
  type?: CALENDAR_EVENT_TYPE | null;
  bookingGroupId?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventBookableInterview
// ====================================================

export interface MatchingEventBookableInterview_calendarEvent_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventBookableInterview_calendarEvent_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventBookableInterview_calendarEvent_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: MatchingEventBookableInterview_calendarEvent_attendees_application_recruitment;
}

export interface MatchingEventBookableInterview_calendarEvent_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MatchingEventBookableInterview_calendarEvent_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: MatchingEventBookableInterview_calendarEvent_attendees_user_profileImage | null;
}

export interface MatchingEventBookableInterview_calendarEvent_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  application: MatchingEventBookableInterview_calendarEvent_attendees_application | null;
  user: MatchingEventBookableInterview_calendarEvent_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface MatchingEventBookableInterview_calendarEvent_matchingEventCalendarEvents_matchingEventParticipant_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventBookableInterview_calendarEvent_matchingEventCalendarEvents_matchingEventParticipant {
  __typename: "MatchingEventParticipant";
  id: string;
  company: MatchingEventBookableInterview_calendarEvent_matchingEventCalendarEvents_matchingEventParticipant_company;
}

export interface MatchingEventBookableInterview_calendarEvent_matchingEventCalendarEvents_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
}

export interface MatchingEventBookableInterview_calendarEvent_matchingEventCalendarEvents_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: MatchingEventBookableInterview_calendarEvent_matchingEventCalendarEvents_attendees_eventMatch | null;
}

export interface MatchingEventBookableInterview_calendarEvent_matchingEventCalendarEvents {
  __typename: "CalendarEvent";
  id: string;
  startTime: DateTime;
  endTime: DateTime;
  matchingEventParticipant: MatchingEventBookableInterview_calendarEvent_matchingEventCalendarEvents_matchingEventParticipant | null;
  attendees: MatchingEventBookableInterview_calendarEvent_matchingEventCalendarEvents_attendees[];
}

export interface MatchingEventBookableInterview_calendarEvent {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: MatchingEventBookableInterview_calendarEvent_recruitment | null;
  attendees: MatchingEventBookableInterview_calendarEvent_attendees[];
  matchingEventCalendarEvents: MatchingEventBookableInterview_calendarEvent_matchingEventCalendarEvents[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface MatchingEventBookableInterview {
  calendarEvent: MatchingEventBookableInterview_calendarEvent | null;
}

export interface MatchingEventBookableInterviewVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventBookableInterviewsList
// ====================================================

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: MatchingEventBookableInterviewsList_calendarEventsList_edges_node_attendees_application_recruitment;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: MatchingEventBookableInterviewsList_calendarEventsList_edges_node_attendees_user_profileImage | null;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  application: MatchingEventBookableInterviewsList_calendarEventsList_edges_node_attendees_application | null;
  user: MatchingEventBookableInterviewsList_calendarEventsList_edges_node_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node_matchingEventCalendarEvents_matchingEventParticipant_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node_matchingEventCalendarEvents_matchingEventParticipant {
  __typename: "MatchingEventParticipant";
  id: string;
  company: MatchingEventBookableInterviewsList_calendarEventsList_edges_node_matchingEventCalendarEvents_matchingEventParticipant_company;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node_matchingEventCalendarEvents_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node_matchingEventCalendarEvents_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: MatchingEventBookableInterviewsList_calendarEventsList_edges_node_matchingEventCalendarEvents_attendees_eventMatch | null;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node_matchingEventCalendarEvents {
  __typename: "CalendarEvent";
  id: string;
  startTime: DateTime;
  endTime: DateTime;
  matchingEventParticipant: MatchingEventBookableInterviewsList_calendarEventsList_edges_node_matchingEventCalendarEvents_matchingEventParticipant | null;
  attendees: MatchingEventBookableInterviewsList_calendarEventsList_edges_node_matchingEventCalendarEvents_attendees[];
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges_node {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: MatchingEventBookableInterviewsList_calendarEventsList_edges_node_recruitment | null;
  attendees: MatchingEventBookableInterviewsList_calendarEventsList_edges_node_attendees[];
  matchingEventCalendarEvents: MatchingEventBookableInterviewsList_calendarEventsList_edges_node_matchingEventCalendarEvents[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_edges {
  __typename: "CalendarEventEdge";
  cursor: string;
  node: MatchingEventBookableInterviewsList_calendarEventsList_edges_node;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface MatchingEventBookableInterviewsList_calendarEventsList {
  __typename: "CalendarEventConnection";
  edges: (MatchingEventBookableInterviewsList_calendarEventsList_edges | null)[];
  pageInfo: MatchingEventBookableInterviewsList_calendarEventsList_pageInfo;
}

export interface MatchingEventBookableInterviewsList {
  calendarEventsList: MatchingEventBookableInterviewsList_calendarEventsList;
}

export interface MatchingEventBookableInterviewsListVariables {
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  matchingEventId?: string | null;
  matchingEventParticipantId?: string | null;
  onlyUpcoming?: boolean | null;
  type?: CALENDAR_EVENT_TYPE | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventChildCompanyAvailiableInterviews
// ====================================================

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_attendees_application_recruitment;
}

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_attendees_user_profileImage | null;
}

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  application: MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_attendees_application | null;
  user: MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  childCompanyHasSelectedBookableInterview: boolean;
  recruitment: MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_recruitment | null;
  attendees: MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_attendees[];
  interviewBookingGroup: MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_interviewBookingGroup | null;
  matchingEventTalentProfiles: MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges {
  __typename: "CalendarEventEdge";
  cursor: string;
  node: MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges_node;
}

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface MatchingEventChildCompanyAvailiableInterviews_calendarEventsList {
  __typename: "CalendarEventConnection";
  edges: (MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_edges | null)[];
  pageInfo: MatchingEventChildCompanyAvailiableInterviews_calendarEventsList_pageInfo;
}

export interface MatchingEventChildCompanyAvailiableInterviews {
  calendarEventsList: MatchingEventChildCompanyAvailiableInterviews_calendarEventsList;
}

export interface MatchingEventChildCompanyAvailiableInterviewsVariables {
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  matchingEventId?: string | null;
  onlyUpcoming?: boolean | null;
  onlyMyInterviews?: boolean | null;
  type?: CALENDAR_EVENT_TYPE | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Comments
// ====================================================

export interface Comments_comments_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface Comments_comments_edges_node_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Comments_comments_edges_node_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: Comments_comments_edges_node_application_recruitment;
}

export interface Comments_comments_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Comments_comments_edges_node_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Comments_comments_edges_node_mentions {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Comments_comments_edges_node_sentBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Comments_comments_edges_node_sentBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: Comments_comments_edges_node_sentBy_profileImage | null;
}

export interface Comments_comments_edges_node_likes {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Comments_comments_edges_node_replies_mentions {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Comments_comments_edges_node_replies_sentBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Comments_comments_edges_node_replies_sentBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: Comments_comments_edges_node_replies_sentBy_profileImage | null;
}

export interface Comments_comments_edges_node_replies_likes {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Comments_comments_edges_node_replies {
  __typename: "Comment";
  id: string;
  text: string;
  createdAt: DateTime;
  mentions: Comments_comments_edges_node_replies_mentions[];
  sentBy: Comments_comments_edges_node_replies_sentBy | null;
  likes: Comments_comments_edges_node_replies_likes[];
}

export interface Comments_comments_edges_node {
  __typename: "Comment";
  id: string;
  text: string;
  createdAt: DateTime;
  application: Comments_comments_edges_node_application | null;
  recruitment: Comments_comments_edges_node_recruitment | null;
  applicant: Comments_comments_edges_node_applicant | null;
  mentions: Comments_comments_edges_node_mentions[];
  sentBy: Comments_comments_edges_node_sentBy | null;
  likes: Comments_comments_edges_node_likes[];
  replies: Comments_comments_edges_node_replies[];
}

export interface Comments_comments_edges {
  __typename: "CommentEdge";
  node: Comments_comments_edges_node;
}

export interface Comments_comments {
  __typename: "CommentConnection";
  pageInfo: Comments_comments_pageInfo;
  edges: (Comments_comments_edges | null)[];
}

export interface Comments {
  comments: Comments_comments;
}

export interface CommentsVariables {
  companyId: string;
  applicationId?: string | null;
  recruitmentId?: string | null;
  applicantId?: string | null;
  identifiedNeedId?: string | null;
  consultantProjectId?: string | null;
  consultantCustomerId?: string | null;
  consultantId?: string | null;
  limit: number;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminCompanies
// ====================================================

export interface AdminCompanies_adminCompanies_items_applicantMessages {
  __typename: "ApplicantMessage";
  id: string;
  createdAt: DateTime;
  sentVia: MESSAGE_CHANNEL[];
}

export interface AdminCompanies_adminCompanies_items_users {
  __typename: "User";
  id: string;
}

export interface AdminCompanies_adminCompanies_items_recruitments {
  __typename: "Recruitment";
  id: string;
}

export interface AdminCompanies_adminCompanies_items {
  __typename: "Company";
  id: string;
  createdAt: DateTime;
  name: string;
  type: COMPANY_TYPE | null;
  paymentType: COMPANY_PAYMENT_TYPE | null;
  agreementExpirationDate: DateTime | null;
  applicantMessages: AdminCompanies_adminCompanies_items_applicantMessages[];
  users: AdminCompanies_adminCompanies_items_users[];
  averageMonthlyCost: number;
  isPayingCustomer: boolean | null;
  recruitments: AdminCompanies_adminCompanies_items_recruitments[];
}

export interface AdminCompanies_adminCompanies {
  __typename: "PaginatedCompanies";
  items: AdminCompanies_adminCompanies_items[];
  totalCount: number;
}

export interface AdminCompanies {
  adminCompanies: AdminCompanies_adminCompanies;
}

export interface AdminCompaniesVariables {
  page?: number | null;
  pageSize?: number | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  filters?: AdminCompaniesFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllMyCompanies
// ====================================================

export interface AllMyCompanies_allMyCompanies {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  shortPresentation: string | null;
}

export interface AllMyCompanies {
  allMyCompanies: AllMyCompanies_allMyCompanies[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChildCompanies
// ====================================================

export interface ChildCompanies_childCompanies_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ChildCompanies_childCompanies_childCompanies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ChildCompanies_childCompanies {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  type: COMPANY_TYPE | null;
  profileImage: ChildCompanies_childCompanies_profileImage | null;
  childCompanies: ChildCompanies_childCompanies_childCompanies[];
}

export interface ChildCompanies {
  childCompanies: ChildCompanies_childCompanies[];
}

export interface ChildCompaniesVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyAdmin
// ====================================================

export interface CompanyAdmin_companyAdmin_enabledFeatures {
  __typename: "EnabledFeatures";
  id: string;
  assignments: boolean | null;
  calendar: boolean | null;
  consultantProjects: boolean | null;
  contracts: boolean | null;
  screeningForms: boolean | null;
  referenceForms: boolean | null;
  trustcruit: boolean | null;
  applicationsRankedWithAI: boolean | null;
  interviewBooking: boolean | null;
  interviewBookingGroups: boolean | null;
  alvaLabs: boolean | null;
  tengai: boolean | null;
  allApplicationsPage: boolean | null;
  aiGeneration: boolean | null;
  careerPage: boolean | null;
  mediaLibrary: boolean | null;
  jobtip: boolean | null;
  evaluationForm: boolean | null;
  statusDeadlines: boolean | null;
  videoInApplications: boolean | null;
}

export interface CompanyAdmin_companyAdmin_billingInformation {
  __typename: "BillingInformation";
  id: string;
  description: string | null;
  annualCost: number;
  monthlyCost: number;
  monthlyCostPerUser: number;
  costPerRecruitment: number;
}

export interface CompanyAdmin_companyAdmin {
  __typename: "Company";
  id: string;
  name: string;
  createdAt: DateTime;
  type: COMPANY_TYPE | null;
  paymentType: COMPANY_PAYMENT_TYPE | null;
  isPayingCustomer: boolean | null;
  scriveEmail: string | null;
  agreementExpirationDate: DateTime | null;
  trustcruitCustomerSprancherId: string | null;
  preciserCustomerId: string | null;
  tengaiInterviewUrl: string | null;
  orgNumber: string | null;
  arbetsformedlingenSenderId: string | null;
  enabledFeatures: CompanyAdmin_companyAdmin_enabledFeatures | null;
  averageMonthlyCost: number;
  billingInformation: CompanyAdmin_companyAdmin_billingInformation | null;
}

export interface CompanyAdmin {
  companyAdmin: CompanyAdmin_companyAdmin | null;
}

export interface CompanyAdminVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Company
// ====================================================

export interface Company_company_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Company_company_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: Company_company_users_profileImage | null;
}

export interface Company_company_parentCompany_parentCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Company_company_parentCompany {
  __typename: "Company";
  id: string;
  name: string;
  parentCompany: Company_company_parentCompany_parentCompany | null;
}

export interface Company_company_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Company_company_companyFont {
  __typename: "File";
  id: string;
  url: string | null;
  filename: string;
}

export interface Company_company_childCompanies {
  __typename: "Company";
  id: string;
}

export interface Company_company_defaultRecruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
}

export interface Company_company_enabledFeatures {
  __typename: "EnabledFeatures";
  id: string;
  assignments: boolean | null;
  calendar: boolean | null;
  consultantProjects: boolean | null;
  contracts: boolean | null;
  screeningForms: boolean | null;
  referenceForms: boolean | null;
  trustcruit: boolean | null;
  applicationsRankedWithAI: boolean | null;
  interviewBooking: boolean | null;
  interviewBookingGroups: boolean | null;
  alvaLabs: boolean | null;
  tengai: boolean | null;
  allApplicationsPage: boolean | null;
  aiGeneration: boolean | null;
  careerPage: boolean | null;
  mediaLibrary: boolean | null;
  jobtip: boolean | null;
  evaluationForm: boolean | null;
  statusDeadlines: boolean | null;
  videoInApplications: boolean | null;
}

export interface Company_company {
  __typename: "Company";
  id: string;
  createdAt: DateTime;
  name: string;
  slug: string | null;
  subdomain: string | null;
  orgNumber: string | null;
  primaryColor: string | null;
  language: string | null;
  shortPresentation: string | null;
  postalCode: string | null;
  postalAddress: string | null;
  municipality: string | null;
  websiteUrl: string | null;
  type: COMPANY_TYPE | null;
  applicationCount: number;
  users: Company_company_users[];
  parentCompany: Company_company_parentCompany | null;
  profileImage: Company_company_profileImage | null;
  companyFont: Company_company_companyFont | null;
  companyWebFont: string | null;
  titleFont: string | null;
  textFont: string | null;
  apiKey: string | null;
  childCompanies: Company_company_childCompanies[];
  defaultRecruitmentTemplate: Company_company_defaultRecruitmentTemplate | null;
  enabledFeatures: Company_company_enabledFeatures | null;
}

export interface Company {
  company: Company_company | null;
}

export interface CompanyVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupCompanyCompanies
// ====================================================

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: GroupCompanyCompanies_groupCompanyCompanies_edges_node_users_profileImage | null;
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node_parentCompany_parentCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node_parentCompany {
  __typename: "Company";
  id: string;
  name: string;
  parentCompany: GroupCompanyCompanies_groupCompanyCompanies_edges_node_parentCompany_parentCompany | null;
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node_companyFont {
  __typename: "File";
  id: string;
  url: string | null;
  filename: string;
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node_childCompanies_childCompanies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node_childCompanies {
  __typename: "Company";
  id: string;
  name: string;
  childCompanies: GroupCompanyCompanies_groupCompanyCompanies_edges_node_childCompanies_childCompanies[];
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node_defaultRecruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node_enabledFeatures {
  __typename: "EnabledFeatures";
  id: string;
  assignments: boolean | null;
  calendar: boolean | null;
  consultantProjects: boolean | null;
  contracts: boolean | null;
  screeningForms: boolean | null;
  referenceForms: boolean | null;
  trustcruit: boolean | null;
  applicationsRankedWithAI: boolean | null;
  interviewBooking: boolean | null;
  interviewBookingGroups: boolean | null;
  alvaLabs: boolean | null;
  tengai: boolean | null;
  allApplicationsPage: boolean | null;
  aiGeneration: boolean | null;
  careerPage: boolean | null;
  mediaLibrary: boolean | null;
  jobtip: boolean | null;
  evaluationForm: boolean | null;
  statusDeadlines: boolean | null;
  videoInApplications: boolean | null;
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges_node {
  __typename: "Company";
  id: string;
  createdAt: DateTime;
  name: string;
  slug: string | null;
  subdomain: string | null;
  orgNumber: string | null;
  primaryColor: string | null;
  language: string | null;
  shortPresentation: string | null;
  postalCode: string | null;
  postalAddress: string | null;
  municipality: string | null;
  websiteUrl: string | null;
  type: COMPANY_TYPE | null;
  applicationCount: number;
  users: GroupCompanyCompanies_groupCompanyCompanies_edges_node_users[];
  parentCompany: GroupCompanyCompanies_groupCompanyCompanies_edges_node_parentCompany | null;
  profileImage: GroupCompanyCompanies_groupCompanyCompanies_edges_node_profileImage | null;
  companyFont: GroupCompanyCompanies_groupCompanyCompanies_edges_node_companyFont | null;
  companyWebFont: string | null;
  titleFont: string | null;
  textFont: string | null;
  apiKey: string | null;
  childCompanies: GroupCompanyCompanies_groupCompanyCompanies_edges_node_childCompanies[];
  defaultRecruitmentTemplate: GroupCompanyCompanies_groupCompanyCompanies_edges_node_defaultRecruitmentTemplate | null;
  enabledFeatures: GroupCompanyCompanies_groupCompanyCompanies_edges_node_enabledFeatures | null;
  recruitments: GroupCompanyCompanies_groupCompanyCompanies_edges_node_recruitments[];
}

export interface GroupCompanyCompanies_groupCompanyCompanies_edges {
  __typename: "CompanyEdge";
  cursor: string;
  node: GroupCompanyCompanies_groupCompanyCompanies_edges_node;
}

export interface GroupCompanyCompanies_groupCompanyCompanies_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface GroupCompanyCompanies_groupCompanyCompanies {
  __typename: "CompanyConnection";
  edges: (GroupCompanyCompanies_groupCompanyCompanies_edges | null)[];
  pageInfo: GroupCompanyCompanies_groupCompanyCompanies_pageInfo;
}

export interface GroupCompanyCompanies {
  groupCompanyCompanies: GroupCompanyCompanies_groupCompanyCompanies;
}

export interface GroupCompanyCompaniesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  tagFilter?: (string | null)[] | null;
  includeItself?: boolean | null;
  hasAccessToRecruitmentTemplateId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupCompanyStatistics
// ====================================================

export interface GroupCompanyStatistics_groupCompanyStatistics {
  __typename: "GroupCompanyStatistics";
  applicantCount: number | null;
  companyCount: number | null;
  recruitmentCount: number | null;
  eventCount: number | null;
}

export interface GroupCompanyStatistics {
  groupCompanyStatistics: GroupCompanyStatistics_groupCompanyStatistics | null;
}

export interface GroupCompanyStatisticsVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HasCompanyParent
// ====================================================

export interface HasCompanyParent {
  hasCompanyParent: boolean;
}

export interface HasCompanyParentVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicantsCount
// ====================================================

export interface ApplicantsCount {
  applicantsCount: number;
}

export interface ApplicantsCountVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicantMessages
// ====================================================

export interface CompanyApplicantMessages_companyApplicantApplications_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface CompanyApplicantMessages_companyApplicantApplications_edges_node_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface CompanyApplicantMessages_companyApplicantApplications_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: CompanyApplicantMessages_companyApplicantApplications_edges_node_recruitment_company;
}

export interface CompanyApplicantMessages_companyApplicantApplications_edges_node_messages_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface CompanyApplicantMessages_companyApplicantApplications_edges_node_messages_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CompanyApplicantMessages_companyApplicantApplications_edges_node_messages_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CompanyApplicantMessages_companyApplicantApplications_edges_node_messages_user_profileImage | null;
}

export interface CompanyApplicantMessages_companyApplicantApplications_edges_node_messages {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  sentVia: MESSAGE_CHANNEL[];
  sentByApplicant: boolean;
  createdAt: DateTime;
  application: CompanyApplicantMessages_companyApplicantApplications_edges_node_messages_application | null;
  user: CompanyApplicantMessages_companyApplicantApplications_edges_node_messages_user | null;
}

export interface CompanyApplicantMessages_companyApplicantApplications_edges_node {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  latestMessageTime: DateTime | null;
  messagesRead: boolean | null;
  recruitment: CompanyApplicantMessages_companyApplicantApplications_edges_node_recruitment;
  messages: CompanyApplicantMessages_companyApplicantApplications_edges_node_messages[];
}

export interface CompanyApplicantMessages_companyApplicantApplications_edges {
  __typename: "ApplicationEdge";
  cursor: string;
  node: CompanyApplicantMessages_companyApplicantApplications_edges_node;
}

export interface CompanyApplicantMessages_companyApplicantApplications {
  __typename: "ApplicationConnection";
  pageInfo: CompanyApplicantMessages_companyApplicantApplications_pageInfo;
  edges: (CompanyApplicantMessages_companyApplicantApplications_edges | null)[];
}

export interface CompanyApplicantMessages {
  companyApplicantApplications: CompanyApplicantMessages_companyApplicantApplications;
}

export interface CompanyApplicantMessagesVariables {
  id: string;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicant
// ====================================================

export interface CompanyApplicant_companyApplicant_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CompanyApplicant_companyApplicant_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: CompanyApplicant_companyApplicant_applications_recruitment_company;
}

export interface CompanyApplicant_companyApplicant_applications_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface CompanyApplicant_companyApplicant_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  recruitment: CompanyApplicant_companyApplicant_applications_recruitment;
  educations: CompanyApplicant_companyApplicant_applications_educations[];
}

export interface CompanyApplicant_companyApplicant_messages_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CompanyApplicant_companyApplicant_messages_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CompanyApplicant_companyApplicant_messages_user_profileImage | null;
}

export interface CompanyApplicant_companyApplicant_messages {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  sentVia: MESSAGE_CHANNEL[];
  sentByApplicant: boolean;
  createdAt: DateTime;
  user: CompanyApplicant_companyApplicant_messages_user | null;
}

export interface CompanyApplicant_companyApplicant_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface CompanyApplicant_companyApplicant_workExperiences {
  __typename: "WorkExperience";
  id: string;
  position: string | null;
  companyName: string | null;
}

export interface CompanyApplicant_companyApplicant_competences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface CompanyApplicant_companyApplicant_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: CompanyApplicant_companyApplicant_competences_competence;
}

export interface CompanyApplicant_companyApplicant_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface CompanyApplicant_companyApplicant_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface CompanyApplicant_companyApplicant_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: CompanyApplicant_companyApplicant_grades_visitor | null;
  setBy: CompanyApplicant_companyApplicant_grades_setBy | null;
}

export interface CompanyApplicant_companyApplicant_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface CompanyApplicant_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  createdAt: DateTime | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  averageGrade: number;
  isAnonymized: boolean;
  applications: CompanyApplicant_companyApplicant_applications[];
  messagesRead: boolean | null;
  latestMessageTime: DateTime | null;
  messages: CompanyApplicant_companyApplicant_messages[];
  educations: CompanyApplicant_companyApplicant_educations[];
  workExperiences: CompanyApplicant_companyApplicant_workExperiences[];
  competences: CompanyApplicant_companyApplicant_competences[];
  grades: CompanyApplicant_companyApplicant_grades[];
  tags: CompanyApplicant_companyApplicant_tags[];
}

export interface CompanyApplicant {
  companyApplicant: CompanyApplicant_companyApplicant | null;
}

export interface CompanyApplicantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicantQuestionAnswers
// ====================================================

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_question_matchingEventProfile | null;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerCompetences_competence;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_application {
  __typename: "Application";
  id: string;
  recruitment: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_application_recruitment;
}

export interface CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerFile | null;
  answerDocument: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerDocument | null;
  answerDocuments: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerEducations[] | null;
  answerWorkExperiences: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerWorkExperiences[] | null;
  answerPersonalData: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerPersonalData | null;
  answerCompetences: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_answerCompetences[] | null;
  application: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers_application | null;
}

export interface CompanyApplicantQuestionAnswers {
  companyApplicantQuestionAnswers: CompanyApplicantQuestionAnswers_companyApplicantQuestionAnswers[];
}

export interface CompanyApplicantQuestionAnswersVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicants
// ====================================================

export interface CompanyApplicants_companyApplicants_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface CompanyApplicants_companyApplicants_edges_node_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CompanyApplicants_companyApplicants_edges_node_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: CompanyApplicants_companyApplicants_edges_node_applications_recruitment_company;
}

export interface CompanyApplicants_companyApplicants_edges_node_applications_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface CompanyApplicants_companyApplicants_edges_node_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  recruitment: CompanyApplicants_companyApplicants_edges_node_applications_recruitment;
  educations: CompanyApplicants_companyApplicants_edges_node_applications_educations[];
}

export interface CompanyApplicants_companyApplicants_edges_node_messages_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CompanyApplicants_companyApplicants_edges_node_messages_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CompanyApplicants_companyApplicants_edges_node_messages_user_profileImage | null;
}

export interface CompanyApplicants_companyApplicants_edges_node_messages {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  sentVia: MESSAGE_CHANNEL[];
  sentByApplicant: boolean;
  createdAt: DateTime;
  user: CompanyApplicants_companyApplicants_edges_node_messages_user | null;
}

export interface CompanyApplicants_companyApplicants_edges_node_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface CompanyApplicants_companyApplicants_edges_node_workExperiences {
  __typename: "WorkExperience";
  id: string;
  position: string | null;
  companyName: string | null;
}

export interface CompanyApplicants_companyApplicants_edges_node_competences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface CompanyApplicants_companyApplicants_edges_node_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: CompanyApplicants_companyApplicants_edges_node_competences_competence;
}

export interface CompanyApplicants_companyApplicants_edges_node_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface CompanyApplicants_companyApplicants_edges_node_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface CompanyApplicants_companyApplicants_edges_node_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: CompanyApplicants_companyApplicants_edges_node_grades_visitor | null;
  setBy: CompanyApplicants_companyApplicants_edges_node_grades_setBy | null;
}

export interface CompanyApplicants_companyApplicants_edges_node_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface CompanyApplicants_companyApplicants_edges_node {
  __typename: "CompanyApplicant";
  id: string;
  createdAt: DateTime | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  averageGrade: number;
  isAnonymized: boolean;
  applications: CompanyApplicants_companyApplicants_edges_node_applications[];
  messagesRead: boolean | null;
  latestMessageTime: DateTime | null;
  messages: CompanyApplicants_companyApplicants_edges_node_messages[];
  educations: CompanyApplicants_companyApplicants_edges_node_educations[];
  workExperiences: CompanyApplicants_companyApplicants_edges_node_workExperiences[];
  competences: CompanyApplicants_companyApplicants_edges_node_competences[];
  grades: CompanyApplicants_companyApplicants_edges_node_grades[];
  tags: CompanyApplicants_companyApplicants_edges_node_tags[];
}

export interface CompanyApplicants_companyApplicants_edges {
  __typename: "CompanyApplicantEdge";
  cursor: string;
  node: CompanyApplicants_companyApplicants_edges_node;
}

export interface CompanyApplicants_companyApplicants {
  __typename: "CompanyApplicantConnection";
  pageInfo: CompanyApplicants_companyApplicants_pageInfo;
  edges: (CompanyApplicants_companyApplicants_edges | null)[];
}

export interface CompanyApplicants {
  companyApplicants: CompanyApplicants_companyApplicants;
}

export interface CompanyApplicantsVariables {
  companyId?: string | null;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  searchText?: string | null;
  searchTextOptions?: string[] | null;
  tagFilter?: (string | null)[] | null;
  educationFilter?: (string | null)[] | null;
  gradeFilter?: (number | null)[] | null;
  recruitmentFilter?: string[] | null;
  professionFieldFilter?: string[] | null;
  excludedRecruitment?: string | null;
  onlyNonConsultants?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicantsSimple
// ====================================================

export interface CompanyApplicantsSimple_companyApplicantsSimple_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface CompanyApplicantsSimple_companyApplicantsSimple_edges_node {
  __typename: "CompanyApplicant";
  id: string;
  createdAt: DateTime | null;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
}

export interface CompanyApplicantsSimple_companyApplicantsSimple_edges {
  __typename: "CompanyApplicantEdge";
  cursor: string;
  node: CompanyApplicantsSimple_companyApplicantsSimple_edges_node;
}

export interface CompanyApplicantsSimple_companyApplicantsSimple {
  __typename: "CompanyApplicantConnection";
  pageInfo: CompanyApplicantsSimple_companyApplicantsSimple_pageInfo;
  edges: (CompanyApplicantsSimple_companyApplicantsSimple_edges | null)[];
}

export interface CompanyApplicantsSimple {
  companyApplicantsSimple: CompanyApplicantsSimple_companyApplicantsSimple;
}

export interface CompanyApplicantsSimpleVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  searchText?: string | null;
  onlyNonConsultants?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupCompanyApplicants
// ====================================================

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: GroupCompanyApplicants_groupCompanyApplicants_edges_node_applications_recruitment_company;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_applications_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  recruitment: GroupCompanyApplicants_groupCompanyApplicants_edges_node_applications_recruitment;
  educations: GroupCompanyApplicants_groupCompanyApplicants_edges_node_applications_educations[];
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_messages_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_messages_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: GroupCompanyApplicants_groupCompanyApplicants_edges_node_messages_user_profileImage | null;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_messages {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  sentVia: MESSAGE_CHANNEL[];
  sentByApplicant: boolean;
  createdAt: DateTime;
  user: GroupCompanyApplicants_groupCompanyApplicants_edges_node_messages_user | null;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_workExperiences {
  __typename: "WorkExperience";
  id: string;
  position: string | null;
  companyName: string | null;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_competences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: GroupCompanyApplicants_groupCompanyApplicants_edges_node_competences_competence;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: GroupCompanyApplicants_groupCompanyApplicants_edges_node_grades_visitor | null;
  setBy: GroupCompanyApplicants_groupCompanyApplicants_edges_node_grades_setBy | null;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges_node {
  __typename: "CompanyApplicant";
  id: string;
  createdAt: DateTime | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  averageGrade: number;
  company: GroupCompanyApplicants_groupCompanyApplicants_edges_node_company;
  applications: GroupCompanyApplicants_groupCompanyApplicants_edges_node_applications[];
  messagesRead: boolean | null;
  latestMessageTime: DateTime | null;
  messages: GroupCompanyApplicants_groupCompanyApplicants_edges_node_messages[];
  educations: GroupCompanyApplicants_groupCompanyApplicants_edges_node_educations[];
  workExperiences: GroupCompanyApplicants_groupCompanyApplicants_edges_node_workExperiences[];
  competences: GroupCompanyApplicants_groupCompanyApplicants_edges_node_competences[];
  grades: GroupCompanyApplicants_groupCompanyApplicants_edges_node_grades[];
  tags: GroupCompanyApplicants_groupCompanyApplicants_edges_node_tags[];
}

export interface GroupCompanyApplicants_groupCompanyApplicants_edges {
  __typename: "CompanyApplicantEdge";
  cursor: string;
  node: GroupCompanyApplicants_groupCompanyApplicants_edges_node;
}

export interface GroupCompanyApplicants_groupCompanyApplicants_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface GroupCompanyApplicants_groupCompanyApplicants {
  __typename: "CompanyApplicantConnection";
  edges: (GroupCompanyApplicants_groupCompanyApplicants_edges | null)[];
  pageInfo: GroupCompanyApplicants_groupCompanyApplicants_pageInfo;
}

export interface GroupCompanyApplicants {
  groupCompanyApplicants: GroupCompanyApplicants_groupCompanyApplicants;
}

export interface GroupCompanyApplicantsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  tagFilter?: string[] | null;
  educationFilter?: string[] | null;
  gradeFilter?: number[] | null;
  recruitmentFilter?: string[] | null;
  professionFieldFilter?: string[] | null;
  excludedRecruitment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatedCompanyApplicants
// ====================================================

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_applications_recruitment_company;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_applications_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  recruitment: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_applications_recruitment;
  educations: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_applications_educations[];
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_messages_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_messages_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_messages_user_profileImage | null;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_messages {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  sentVia: MESSAGE_CHANNEL[];
  sentByApplicant: boolean;
  createdAt: DateTime;
  user: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_messages_user | null;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_workExperiences {
  __typename: "WorkExperience";
  id: string;
  position: string | null;
  companyName: string | null;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_competences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_competences_competence;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_grades_visitor | null;
  setBy: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_grades_setBy | null;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants_items {
  __typename: "CompanyApplicant";
  id: string;
  createdAt: DateTime | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  averageGrade: number;
  company: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_company;
  applications: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_applications[];
  messagesRead: boolean | null;
  latestMessageTime: DateTime | null;
  messages: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_messages[];
  educations: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_educations[];
  workExperiences: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_workExperiences[];
  competences: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_competences[];
  grades: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_grades[];
  tags: PaginatedCompanyApplicants_paginatedCompanyApplicants_items_tags[];
}

export interface PaginatedCompanyApplicants_paginatedCompanyApplicants {
  __typename: "PaginatedCompanyApplicants";
  totalCount: number;
  items: PaginatedCompanyApplicants_paginatedCompanyApplicants_items[];
}

export interface PaginatedCompanyApplicants {
  paginatedCompanyApplicants: PaginatedCompanyApplicants_paginatedCompanyApplicants;
}

export interface PaginatedCompanyApplicantsVariables {
  companyId: string;
  page?: number | null;
  pageSize?: number | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  filters?: CompanyApplicantFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicantsBaseEducationFilter
// ====================================================

export interface CompanyApplicantsBaseEducationFilter_companyApplicantsBaseEducationFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface CompanyApplicantsBaseEducationFilter_companyApplicantsBaseEducationFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: CompanyApplicantsBaseEducationFilter_companyApplicantsBaseEducationFilter_edges_node;
}

export interface CompanyApplicantsBaseEducationFilter_companyApplicantsBaseEducationFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface CompanyApplicantsBaseEducationFilter_companyApplicantsBaseEducationFilter {
  __typename: "FilterItemConnection";
  edges: (CompanyApplicantsBaseEducationFilter_companyApplicantsBaseEducationFilter_edges | null)[];
  pageInfo: CompanyApplicantsBaseEducationFilter_companyApplicantsBaseEducationFilter_pageInfo;
}

export interface CompanyApplicantsBaseEducationFilter {
  companyApplicantsBaseEducationFilter: CompanyApplicantsBaseEducationFilter_companyApplicantsBaseEducationFilter;
}

export interface CompanyApplicantsBaseEducationFilterVariables {
  filters?: CompanyApplicantFilterInput | null;
  after?: string | null;
  limit: number;
  companyId?: string | null;
  groupCompanyId?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicantsCompaniesFilter
// ====================================================

export interface CompanyApplicantsCompaniesFilter_companyApplicantsCompaniesFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface CompanyApplicantsCompaniesFilter_companyApplicantsCompaniesFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: CompanyApplicantsCompaniesFilter_companyApplicantsCompaniesFilter_edges_node;
}

export interface CompanyApplicantsCompaniesFilter_companyApplicantsCompaniesFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface CompanyApplicantsCompaniesFilter_companyApplicantsCompaniesFilter {
  __typename: "FilterItemConnection";
  edges: (CompanyApplicantsCompaniesFilter_companyApplicantsCompaniesFilter_edges | null)[];
  pageInfo: CompanyApplicantsCompaniesFilter_companyApplicantsCompaniesFilter_pageInfo;
}

export interface CompanyApplicantsCompaniesFilter {
  companyApplicantsCompaniesFilter: CompanyApplicantsCompaniesFilter_companyApplicantsCompaniesFilter;
}

export interface CompanyApplicantsCompaniesFilterVariables {
  filters?: CompanyApplicantFilterInput | null;
  after?: string | null;
  limit: number;
  companyId: string;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicantsEducationFilter
// ====================================================

export interface CompanyApplicantsEducationFilter_companyApplicantsEducationFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface CompanyApplicantsEducationFilter_companyApplicantsEducationFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: CompanyApplicantsEducationFilter_companyApplicantsEducationFilter_edges_node;
}

export interface CompanyApplicantsEducationFilter_companyApplicantsEducationFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface CompanyApplicantsEducationFilter_companyApplicantsEducationFilter {
  __typename: "FilterItemConnection";
  edges: (CompanyApplicantsEducationFilter_companyApplicantsEducationFilter_edges | null)[];
  pageInfo: CompanyApplicantsEducationFilter_companyApplicantsEducationFilter_pageInfo;
}

export interface CompanyApplicantsEducationFilter {
  companyApplicantsEducationFilter: CompanyApplicantsEducationFilter_companyApplicantsEducationFilter;
}

export interface CompanyApplicantsEducationFilterVariables {
  filters?: CompanyApplicantFilterInput | null;
  after?: string | null;
  limit: number;
  companyId?: string | null;
  groupCompanyId?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicantsGradeFilter
// ====================================================

export interface CompanyApplicantsGradeFilter_companyApplicantsGradeFilter {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface CompanyApplicantsGradeFilter {
  companyApplicantsGradeFilter: CompanyApplicantsGradeFilter_companyApplicantsGradeFilter[];
}

export interface CompanyApplicantsGradeFilterVariables {
  filters?: CompanyApplicantFilterInput | null;
  companyId?: string | null;
  groupCompanyId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicantsProfessionFieldFilter
// ====================================================

export interface CompanyApplicantsProfessionFieldFilter_companyApplicantsProfessionFieldFilter {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface CompanyApplicantsProfessionFieldFilter {
  companyApplicantsProfessionFieldFilter: CompanyApplicantsProfessionFieldFilter_companyApplicantsProfessionFieldFilter[];
}

export interface CompanyApplicantsProfessionFieldFilterVariables {
  filters?: CompanyApplicantFilterInput | null;
  companyId?: string | null;
  groupCompanyId?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicantsRecruitmentsFilter
// ====================================================

export interface CompanyApplicantsRecruitmentsFilter_companyApplicantsRecruitmentsFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface CompanyApplicantsRecruitmentsFilter_companyApplicantsRecruitmentsFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: CompanyApplicantsRecruitmentsFilter_companyApplicantsRecruitmentsFilter_edges_node;
}

export interface CompanyApplicantsRecruitmentsFilter_companyApplicantsRecruitmentsFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface CompanyApplicantsRecruitmentsFilter_companyApplicantsRecruitmentsFilter {
  __typename: "FilterItemConnection";
  edges: (CompanyApplicantsRecruitmentsFilter_companyApplicantsRecruitmentsFilter_edges | null)[];
  pageInfo: CompanyApplicantsRecruitmentsFilter_companyApplicantsRecruitmentsFilter_pageInfo;
}

export interface CompanyApplicantsRecruitmentsFilter {
  companyApplicantsRecruitmentsFilter: CompanyApplicantsRecruitmentsFilter_companyApplicantsRecruitmentsFilter;
}

export interface CompanyApplicantsRecruitmentsFilterVariables {
  filters?: CompanyApplicantFilterInput | null;
  after?: string | null;
  limit: number;
  companyId?: string | null;
  groupCompanyId?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyApplicantsTagFilter
// ====================================================

export interface CompanyApplicantsTagFilter_companyApplicantsTagFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface CompanyApplicantsTagFilter_companyApplicantsTagFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: CompanyApplicantsTagFilter_companyApplicantsTagFilter_edges_node;
}

export interface CompanyApplicantsTagFilter_companyApplicantsTagFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface CompanyApplicantsTagFilter_companyApplicantsTagFilter {
  __typename: "FilterItemConnection";
  edges: (CompanyApplicantsTagFilter_companyApplicantsTagFilter_edges | null)[];
  pageInfo: CompanyApplicantsTagFilter_companyApplicantsTagFilter_pageInfo;
}

export interface CompanyApplicantsTagFilter {
  companyApplicantsTagFilter: CompanyApplicantsTagFilter_companyApplicantsTagFilter;
}

export interface CompanyApplicantsTagFilterVariables {
  filters?: CompanyApplicantFilterInput | null;
  after?: string | null;
  limit: number;
  companyId?: string | null;
  groupCompanyId?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CareerPage
// ====================================================

export interface CareerPage_careerPage_webIcon {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CareerPage_careerPage_blocks_block_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CareerPage_careerPage_blocks_block_coverImage_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CareerPage_careerPage_blocks_block_coverImage {
  __typename: "CoverImage";
  id: string;
  fullScreen: boolean | null;
  variant: COVER_IMAGE_VARIANT | null;
  fade: boolean | null;
  height: number | null;
  file: CareerPage_careerPage_blocks_block_coverImage_file | null;
}

export interface CareerPage_careerPage_blocks_block_contactPerson_profileImage {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
}

export interface CareerPage_careerPage_blocks_block_contactPerson {
  __typename: "AdContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  copyFromRecruitment: boolean | null;
  profileImage: CareerPage_careerPage_blocks_block_contactPerson_profileImage | null;
}

export interface CareerPage_careerPage_blocks_block_video {
  __typename: "Video";
  id: string;
  url: string | null;
  youtubeId: string | null;
  autoPlay: boolean | null;
  loop: boolean | null;
}

export interface CareerPage_careerPage_blocks_block {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
  title: string | null;
  text: string | null;
  url: string | null;
  copyShortPresentationFromCompany: boolean | null;
  file: CareerPage_careerPage_blocks_block_file | null;
  adMetaInfo: string[] | null;
  shareButtons: SOCIAL_MEDIA_SOURCE[] | null;
  coverImage: CareerPage_careerPage_blocks_block_coverImage | null;
  contactPerson: CareerPage_careerPage_blocks_block_contactPerson | null;
  video: CareerPage_careerPage_blocks_block_video | null;
}

export interface CareerPage_careerPage_blocks {
  __typename: "ConnectedContentBlock";
  id: string;
  listIndex: number;
  block: CareerPage_careerPage_blocks_block;
}

export interface CareerPage_careerPage {
  __typename: "CompanyCareerPage";
  id: string;
  published: boolean;
  webTitle: string | null;
  webIcon: CareerPage_careerPage_webIcon | null;
  blocks: CareerPage_careerPage_blocks[];
}

export interface CareerPage {
  careerPage: CareerPage_careerPage | null;
}

export interface CareerPageVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyLogo
// ====================================================

export interface CompanyLogo_companyLogo_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface CompanyLogo_companyLogo_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CompanyLogo_companyLogo_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: CompanyLogo_companyLogo_mediaElement_createdBy_profileImage | null;
}

export interface CompanyLogo_companyLogo_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface CompanyLogo_companyLogo_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: CompanyLogo_companyLogo_mediaElement_thumbnail | null;
  createdBy: CompanyLogo_companyLogo_mediaElement_createdBy;
  tags: CompanyLogo_companyLogo_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface CompanyLogo_companyLogo {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: CompanyLogo_companyLogo_mediaElement;
}

export interface CompanyLogo {
  companyLogo: CompanyLogo_companyLogo | null;
}

export interface CompanyLogoVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyCompetences
// ====================================================

export interface CompanyCompetences_companyCompetences_edges_node_interviewQuestions_subQuestions {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
}

export interface CompanyCompetences_companyCompetences_edges_node_interviewQuestions {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
  language: string | null;
  subQuestions: CompanyCompetences_companyCompetences_edges_node_interviewQuestions_subQuestions[];
}

export interface CompanyCompetences_companyCompetences_edges_node {
  __typename: "Competence";
  id: string;
  createdAt: DateTime;
  name: string;
  type: COMPETENCE_TYPE | null;
  answerType: COMPETENCE_ANSWER_TYPE | null;
  interviewQuestions: CompanyCompetences_companyCompetences_edges_node_interviewQuestions[];
}

export interface CompanyCompetences_companyCompetences_edges {
  __typename: "CompetenceEdge";
  cursor: string;
  node: CompanyCompetences_companyCompetences_edges_node;
}

export interface CompanyCompetences_companyCompetences_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface CompanyCompetences_companyCompetences {
  __typename: "CompetenceConnection";
  edges: (CompanyCompetences_companyCompetences_edges | null)[];
  pageInfo: CompanyCompetences_companyCompetences_pageInfo;
}

export interface CompanyCompetences {
  companyCompetences: CompanyCompetences_companyCompetences;
}

export interface CompanyCompetencesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  searchText?: string | null;
  typeFilter?: string[] | null;
  answerTypeFilter?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyCompetencesSearch
// ====================================================

export interface CompanyCompetencesSearch_companyCompetencesSearch {
  __typename: "Competence";
  id: string;
  name: string;
  type: COMPETENCE_TYPE | null;
}

export interface CompanyCompetencesSearch {
  companyCompetencesSearch: CompanyCompetencesSearch_companyCompetencesSearch[];
}

export interface CompanyCompetencesSearchVariables {
  companyId: string;
  searchText?: string | null;
  type?: COMPETENCE_TYPE | null;
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompetencesFilterData
// ====================================================

export interface CompetencesFilterData_competencesFilterData_typeFilter {
  __typename: "CompetenceTypeFilterData";
  value: string;
  id: string;
  count: number | null;
}

export interface CompetencesFilterData_competencesFilterData_answerTypeFilter {
  __typename: "CompetenceAnswerTypeFilterData";
  value: string;
  id: string;
  count: number | null;
}

export interface CompetencesFilterData_competencesFilterData {
  __typename: "CompetencesFilterData";
  typeFilter: CompetencesFilterData_competencesFilterData_typeFilter[];
  answerTypeFilter: CompetencesFilterData_competencesFilterData_answerTypeFilter[];
}

export interface CompetencesFilterData {
  competencesFilterData: CompetencesFilterData_competencesFilterData | null;
}

export interface CompetencesFilterDataVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Competence
// ====================================================

export interface Competence_competence_referenceQuestionsForm {
  __typename: "Form";
  id: string;
  type: FORM_TYPE;
  title: string | null;
}

export interface Competence_competence {
  __typename: "Competence";
  id: string;
  name: string;
  description: string | null;
  type: COMPETENCE_TYPE | null;
  answerType: COMPETENCE_ANSWER_TYPE | null;
  referenceQuestionsForm: Competence_competence_referenceQuestionsForm | null;
}

export interface Competence {
  competence: Competence_competence | null;
}

export interface CompetenceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompetenceWithReferenceFormQuestions
// ====================================================

export interface CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_participants_application_companyApplicant | null;
}

export interface CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_participants_application | null;
}

export interface CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_questions_matchingEventProfile | null;
}

export interface CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_companiesWithAccess[];
  participants: CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_participants[];
  questions: CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_questions[];
  referenceForm: CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_referenceForm | null;
  screeningForm: CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm_screeningForm | null;
}

export interface CompetenceWithReferenceFormQuestions_competence {
  __typename: "Competence";
  id: string;
  name: string;
  description: string | null;
  type: COMPETENCE_TYPE | null;
  answerType: COMPETENCE_ANSWER_TYPE | null;
  referenceQuestionsForm: CompetenceWithReferenceFormQuestions_competence_referenceQuestionsForm | null;
}

export interface CompetenceWithReferenceFormQuestions {
  competence: CompetenceWithReferenceFormQuestions_competence | null;
}

export interface CompetenceWithReferenceFormQuestionsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantProfileAnswers
// ====================================================

export interface ConsultantProfileAnswers_consultantProfileForm_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ConsultantProfileAnswers_consultantProfileForm_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface ConsultantProfileAnswers_consultantProfileForm_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: ConsultantProfileAnswers_consultantProfileForm_participants_application_companyApplicant | null;
}

export interface ConsultantProfileAnswers_consultantProfileForm_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: ConsultantProfileAnswers_consultantProfileForm_participants_application | null;
}

export interface ConsultantProfileAnswers_consultantProfileForm_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ConsultantProfileAnswers_consultantProfileForm_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: ConsultantProfileAnswers_consultantProfileForm_questions_matchingEventProfile | null;
}

export interface ConsultantProfileAnswers_consultantProfileForm_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface ConsultantProfileAnswers_consultantProfileForm_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface ConsultantProfileAnswers_consultantProfileForm {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: ConsultantProfileAnswers_consultantProfileForm_companiesWithAccess[];
  participants: ConsultantProfileAnswers_consultantProfileForm_participants[];
  questions: ConsultantProfileAnswers_consultantProfileForm_questions[];
  referenceForm: ConsultantProfileAnswers_consultantProfileForm_referenceForm | null;
  screeningForm: ConsultantProfileAnswers_consultantProfileForm_screeningForm | null;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: ConsultantProfileAnswers_consultantProfileAnswers_question_matchingEventProfile | null;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: ConsultantProfileAnswers_consultantProfileAnswers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: ConsultantProfileAnswers_consultantProfileAnswers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: ConsultantProfileAnswers_consultantProfileAnswers_answerCompetences_competence;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers_application {
  __typename: "Application";
  id: string;
  recruitment: ConsultantProfileAnswers_consultantProfileAnswers_application_recruitment;
}

export interface ConsultantProfileAnswers_consultantProfileAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: ConsultantProfileAnswers_consultantProfileAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: ConsultantProfileAnswers_consultantProfileAnswers_answerFile | null;
  answerDocument: ConsultantProfileAnswers_consultantProfileAnswers_answerDocument | null;
  answerDocuments: ConsultantProfileAnswers_consultantProfileAnswers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: ConsultantProfileAnswers_consultantProfileAnswers_answerEducations[] | null;
  answerWorkExperiences: ConsultantProfileAnswers_consultantProfileAnswers_answerWorkExperiences[] | null;
  answerPersonalData: ConsultantProfileAnswers_consultantProfileAnswers_answerPersonalData | null;
  answerCompetences: ConsultantProfileAnswers_consultantProfileAnswers_answerCompetences[] | null;
  application: ConsultantProfileAnswers_consultantProfileAnswers_application | null;
}

export interface ConsultantProfileAnswers {
  consultantProfileForm: ConsultantProfileAnswers_consultantProfileForm | null;
  consultantProfileAnswers: ConsultantProfileAnswers_consultantProfileAnswers[];
}

export interface ConsultantProfileAnswersVariables {
  consultantId: string;
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantProfileForm
// ====================================================

export interface ConsultantProfileForm_consultantProfileForm_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ConsultantProfileForm_consultantProfileForm_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface ConsultantProfileForm_consultantProfileForm_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: ConsultantProfileForm_consultantProfileForm_participants_application_companyApplicant | null;
}

export interface ConsultantProfileForm_consultantProfileForm_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: ConsultantProfileForm_consultantProfileForm_participants_application | null;
}

export interface ConsultantProfileForm_consultantProfileForm_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ConsultantProfileForm_consultantProfileForm_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: ConsultantProfileForm_consultantProfileForm_questions_matchingEventProfile | null;
}

export interface ConsultantProfileForm_consultantProfileForm_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface ConsultantProfileForm_consultantProfileForm_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface ConsultantProfileForm_consultantProfileForm {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: ConsultantProfileForm_consultantProfileForm_companiesWithAccess[];
  participants: ConsultantProfileForm_consultantProfileForm_participants[];
  questions: ConsultantProfileForm_consultantProfileForm_questions[];
  referenceForm: ConsultantProfileForm_consultantProfileForm_referenceForm | null;
  screeningForm: ConsultantProfileForm_consultantProfileForm_screeningForm | null;
}

export interface ConsultantProfileForm {
  consultantProfileForm: ConsultantProfileForm_consultantProfileForm | null;
}

export interface ConsultantProfileFormVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Consultant
// ====================================================

export interface Consultant_consultant_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Consultant_consultant_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Consultant_consultant_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: Consultant_consultant_grades_visitor | null;
  setBy: Consultant_consultant_grades_setBy | null;
}

export interface Consultant_consultant_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface Consultant_consultant_formAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface Consultant_consultant_formAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: Consultant_consultant_formAnswers_question_matchingEventProfile | null;
}

export interface Consultant_consultant_formAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: Consultant_consultant_formAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface Consultant_consultant_projects_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface Consultant_consultant_projects_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface Consultant_consultant_projects_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Consultant_consultant_projects_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: Consultant_consultant_projects_users_user_profileImage | null;
}

export interface Consultant_consultant_projects_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface Consultant_consultant_projects_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: Consultant_consultant_projects_users_user;
  consultantProject: Consultant_consultant_projects_users_consultantProject;
}

export interface Consultant_consultant_projects_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface Consultant_consultant_projects_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface Consultant_consultant_projects_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Consultant_consultant_projects_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Consultant_consultant_projects_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: Consultant_consultant_projects_customer_customerCompany_profileImage | null;
}

export interface Consultant_consultant_projects_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: Consultant_consultant_projects_customer_invoiceInformation | null;
  status: Consultant_consultant_projects_customer_status | null;
  companiesWithAccess: Consultant_consultant_projects_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: Consultant_consultant_projects_customer_customerCompany | null;
}

export interface Consultant_consultant_projects_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Consultant_consultant_projects_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Consultant_consultant_projects_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: Consultant_consultant_projects_responsible_profileImage | null;
}

export interface Consultant_consultant_projects_tags {
  __typename: "TagLabel";
  id: string;
}

export interface Consultant_consultant_projects_comments {
  __typename: "Comment";
  id: string;
}

export interface Consultant_consultant_projects_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Consultant_consultant_projects_files {
  __typename: "File";
  id: string;
}

export interface Consultant_consultant_projects_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface Consultant_consultant_projects_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: Consultant_consultant_projects_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface Consultant_consultant_projects {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: Consultant_consultant_projects_invoiceInformation;
  status: Consultant_consultant_projects_status | null;
  users: Consultant_consultant_projects_users[];
  customer: Consultant_consultant_projects_customer | null;
  customerName: string | null;
  recruitment: Consultant_consultant_projects_recruitment | null;
  responsible: Consultant_consultant_projects_responsible | null;
  tags: Consultant_consultant_projects_tags[];
  comments: Consultant_consultant_projects_comments[];
  consultants: Consultant_consultant_projects_consultants[];
  files: Consultant_consultant_projects_files[];
  fieldExtensionValues: Consultant_consultant_projects_fieldExtensionValues[];
}

export interface Consultant_consultant_applicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
}

export interface Consultant_consultant_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface Consultant_consultant_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: Consultant_consultant_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface Consultant_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  averageGrade: number;
  createdAt: DateTime;
  note: string | null;
  grades: Consultant_consultant_grades[];
  status: Consultant_consultant_status | null;
  formAnswers: Consultant_consultant_formAnswers[];
  projects: Consultant_consultant_projects[];
  applicant: Consultant_consultant_applicant;
  fieldExtensionValues: Consultant_consultant_fieldExtensionValues[];
}

export interface Consultant {
  consultant: Consultant_consultant | null;
}

export interface ConsultantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantsFilterData
// ====================================================

export interface ConsultantsFilterData_consultantsFilterData_questionFilters {
  __typename: "FormQuestionFilterData";
  id: string;
  question: string | null;
  type: FORM_QUESTION_TYPE | null;
  options: string[];
}

export interface ConsultantsFilterData_consultantsFilterData {
  __typename: "ConsultantsFilterData";
  questionFilters: ConsultantsFilterData_consultantsFilterData_questionFilters[];
}

export interface ConsultantsFilterData {
  consultantsFilterData: ConsultantsFilterData_consultantsFilterData;
}

export interface ConsultantsFilterDataVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Consultants
// ====================================================

export interface Consultants_consultants_edges_node_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Consultants_consultants_edges_node_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Consultants_consultants_edges_node_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: Consultants_consultants_edges_node_grades_visitor | null;
  setBy: Consultants_consultants_edges_node_grades_setBy | null;
}

export interface Consultants_consultants_edges_node_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface Consultants_consultants_edges_node_formAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface Consultants_consultants_edges_node_formAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: Consultants_consultants_edges_node_formAnswers_question_matchingEventProfile | null;
}

export interface Consultants_consultants_edges_node_formAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: Consultants_consultants_edges_node_formAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface Consultants_consultants_edges_node_projects_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface Consultants_consultants_edges_node_projects_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface Consultants_consultants_edges_node_projects_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Consultants_consultants_edges_node_projects_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: Consultants_consultants_edges_node_projects_users_user_profileImage | null;
}

export interface Consultants_consultants_edges_node_projects_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface Consultants_consultants_edges_node_projects_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: Consultants_consultants_edges_node_projects_users_user;
  consultantProject: Consultants_consultants_edges_node_projects_users_consultantProject;
}

export interface Consultants_consultants_edges_node_projects_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface Consultants_consultants_edges_node_projects_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface Consultants_consultants_edges_node_projects_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Consultants_consultants_edges_node_projects_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Consultants_consultants_edges_node_projects_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: Consultants_consultants_edges_node_projects_customer_customerCompany_profileImage | null;
}

export interface Consultants_consultants_edges_node_projects_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: Consultants_consultants_edges_node_projects_customer_invoiceInformation | null;
  status: Consultants_consultants_edges_node_projects_customer_status | null;
  companiesWithAccess: Consultants_consultants_edges_node_projects_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: Consultants_consultants_edges_node_projects_customer_customerCompany | null;
}

export interface Consultants_consultants_edges_node_projects_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Consultants_consultants_edges_node_projects_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Consultants_consultants_edges_node_projects_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: Consultants_consultants_edges_node_projects_responsible_profileImage | null;
}

export interface Consultants_consultants_edges_node_projects_tags {
  __typename: "TagLabel";
  id: string;
}

export interface Consultants_consultants_edges_node_projects_comments {
  __typename: "Comment";
  id: string;
}

export interface Consultants_consultants_edges_node_projects_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Consultants_consultants_edges_node_projects_files {
  __typename: "File";
  id: string;
}

export interface Consultants_consultants_edges_node_projects_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface Consultants_consultants_edges_node_projects_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: Consultants_consultants_edges_node_projects_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface Consultants_consultants_edges_node_projects {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: Consultants_consultants_edges_node_projects_invoiceInformation;
  status: Consultants_consultants_edges_node_projects_status | null;
  users: Consultants_consultants_edges_node_projects_users[];
  customer: Consultants_consultants_edges_node_projects_customer | null;
  customerName: string | null;
  recruitment: Consultants_consultants_edges_node_projects_recruitment | null;
  responsible: Consultants_consultants_edges_node_projects_responsible | null;
  tags: Consultants_consultants_edges_node_projects_tags[];
  comments: Consultants_consultants_edges_node_projects_comments[];
  consultants: Consultants_consultants_edges_node_projects_consultants[];
  files: Consultants_consultants_edges_node_projects_files[];
  fieldExtensionValues: Consultants_consultants_edges_node_projects_fieldExtensionValues[];
}

export interface Consultants_consultants_edges_node_applicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
}

export interface Consultants_consultants_edges_node_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface Consultants_consultants_edges_node_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: Consultants_consultants_edges_node_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface Consultants_consultants_edges_node {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  averageGrade: number;
  createdAt: DateTime;
  note: string | null;
  grades: Consultants_consultants_edges_node_grades[];
  status: Consultants_consultants_edges_node_status | null;
  formAnswers: Consultants_consultants_edges_node_formAnswers[];
  projects: Consultants_consultants_edges_node_projects[];
  applicant: Consultants_consultants_edges_node_applicant;
  fieldExtensionValues: Consultants_consultants_edges_node_fieldExtensionValues[];
}

export interface Consultants_consultants_edges {
  __typename: "ConsultantEdge";
  cursor: string;
  node: Consultants_consultants_edges_node;
}

export interface Consultants_consultants_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface Consultants_consultants {
  __typename: "ConsultantConnection";
  edges: (Consultants_consultants_edges | null)[];
  pageInfo: Consultants_consultants_pageInfo;
}

export interface Consultants {
  consultants: Consultants_consultants;
}

export interface ConsultantsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  searchText?: string | null;
  consultantProjectId?: string | null;
  statusFilter?: string[] | null;
  questionFilter?: FormQuestionFilter[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantCustomerCompany
// ====================================================

export interface ConsultantCustomerCompany_consultantCustomerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantCustomerCompany_consultantCustomerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  shortPresentation: string | null;
  profileImage: ConsultantCustomerCompany_consultantCustomerCompany_profileImage | null;
  websiteUrl: string | null;
  titleFont: string | null;
  textFont: string | null;
}

export interface ConsultantCustomerCompany {
  consultantCustomerCompany: ConsultantCustomerCompany_consultantCustomerCompany | null;
}

export interface ConsultantCustomerCompanyVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantCustomer
// ====================================================

export interface ConsultantCustomer_consultantCustomer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConsultantCustomer_consultantCustomer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ConsultantCustomer_consultantCustomer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ConsultantCustomer_consultantCustomer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantCustomer_consultantCustomer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: ConsultantCustomer_consultantCustomer_customerCompany_profileImage | null;
}

export interface ConsultantCustomer_consultantCustomer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: ConsultantCustomer_consultantCustomer_invoiceInformation | null;
  status: ConsultantCustomer_consultantCustomer_status | null;
  companiesWithAccess: ConsultantCustomer_consultantCustomer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: ConsultantCustomer_consultantCustomer_customerCompany | null;
}

export interface ConsultantCustomer {
  consultantCustomer: ConsultantCustomer_consultantCustomer | null;
}

export interface ConsultantCustomerVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantCustomers
// ====================================================

export interface ConsultantCustomers_consultantCustomers_edges_node_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConsultantCustomers_consultantCustomers_edges_node_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ConsultantCustomers_consultantCustomers_edges_node_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ConsultantCustomers_consultantCustomers_edges_node_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantCustomers_consultantCustomers_edges_node_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: ConsultantCustomers_consultantCustomers_edges_node_customerCompany_profileImage | null;
}

export interface ConsultantCustomers_consultantCustomers_edges_node {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: ConsultantCustomers_consultantCustomers_edges_node_invoiceInformation | null;
  status: ConsultantCustomers_consultantCustomers_edges_node_status | null;
  companiesWithAccess: ConsultantCustomers_consultantCustomers_edges_node_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: ConsultantCustomers_consultantCustomers_edges_node_customerCompany | null;
}

export interface ConsultantCustomers_consultantCustomers_edges {
  __typename: "ConsultantCustomerEdge";
  cursor: string;
  node: ConsultantCustomers_consultantCustomers_edges_node;
}

export interface ConsultantCustomers_consultantCustomers_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ConsultantCustomers_consultantCustomers {
  __typename: "ConsultantCustomerConnection";
  edges: (ConsultantCustomers_consultantCustomers_edges | null)[];
  pageInfo: ConsultantCustomers_consultantCustomers_pageInfo;
}

export interface ConsultantCustomers {
  consultantCustomers: ConsultantCustomers_consultantCustomers;
}

export interface ConsultantCustomersVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  statusFilter?: string[] | null;
  showTemplatesFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantProjectCosts
// ====================================================

export interface ConsultantProjectCosts_consultantProjectCosts_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ConsultantProjectCosts_consultantProjectCosts_edges_node_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProjectCosts_consultantProjectCosts_edges_node_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: ConsultantProjectCosts_consultantProjectCosts_edges_node_createdBy_profileImage | null;
}

export interface ConsultantProjectCosts_consultantProjectCosts_edges_node_role {
  __typename: "ConsultantProjectRole";
  id: string;
  title: string | null;
}

export interface ConsultantProjectCosts_consultantProjectCosts_edges_node {
  __typename: "ConsultantProjectCost";
  id: string;
  createdAt: DateTime;
  title: string | null;
  createdBy: ConsultantProjectCosts_consultantProjectCosts_edges_node_createdBy | null;
  type: COST_TYPE;
  role: ConsultantProjectCosts_consultantProjectCosts_edges_node_role | null;
  hourlyRate: number;
  hoursWorked: number;
  fixedCost: number;
  quantity: number;
}

export interface ConsultantProjectCosts_consultantProjectCosts_edges {
  __typename: "ConsultantProjectCostEdge";
  cursor: string;
  node: ConsultantProjectCosts_consultantProjectCosts_edges_node;
}

export interface ConsultantProjectCosts_consultantProjectCosts {
  __typename: "ConsultantProjectCostConnection";
  pageInfo: ConsultantProjectCosts_consultantProjectCosts_pageInfo;
  edges: (ConsultantProjectCosts_consultantProjectCosts_edges | null)[];
}

export interface ConsultantProjectCosts {
  consultantProjectCosts: ConsultantProjectCosts_consultantProjectCosts;
}

export interface ConsultantProjectCostsVariables {
  projectId: string;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  types?: COST_TYPE[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantProject
// ====================================================

export interface ConsultantProject_consultantProject_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConsultantProject_consultantProject_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface ConsultantProject_consultantProject_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProject_consultantProject_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConsultantProject_consultantProject_users_user_profileImage | null;
}

export interface ConsultantProject_consultantProject_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface ConsultantProject_consultantProject_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: ConsultantProject_consultantProject_users_user;
  consultantProject: ConsultantProject_consultantProject_users_consultantProject;
}

export interface ConsultantProject_consultantProject_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConsultantProject_consultantProject_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ConsultantProject_consultantProject_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ConsultantProject_consultantProject_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProject_consultantProject_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: ConsultantProject_consultantProject_customer_customerCompany_profileImage | null;
}

export interface ConsultantProject_consultantProject_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: ConsultantProject_consultantProject_customer_invoiceInformation | null;
  status: ConsultantProject_consultantProject_customer_status | null;
  companiesWithAccess: ConsultantProject_consultantProject_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: ConsultantProject_consultantProject_customer_customerCompany | null;
}

export interface ConsultantProject_consultantProject_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ConsultantProject_consultantProject_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProject_consultantProject_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConsultantProject_consultantProject_responsible_profileImage | null;
}

export interface ConsultantProject_consultantProject_tags {
  __typename: "TagLabel";
  id: string;
}

export interface ConsultantProject_consultantProject_comments {
  __typename: "Comment";
  id: string;
}

export interface ConsultantProject_consultantProject_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ConsultantProject_consultantProject_files {
  __typename: "File";
  id: string;
}

export interface ConsultantProject_consultantProject_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface ConsultantProject_consultantProject_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: ConsultantProject_consultantProject_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface ConsultantProject_consultantProject_fieldExtensions {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface ConsultantProject_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: ConsultantProject_consultantProject_invoiceInformation;
  status: ConsultantProject_consultantProject_status | null;
  users: ConsultantProject_consultantProject_users[];
  customer: ConsultantProject_consultantProject_customer | null;
  customerName: string | null;
  recruitment: ConsultantProject_consultantProject_recruitment | null;
  responsible: ConsultantProject_consultantProject_responsible | null;
  tags: ConsultantProject_consultantProject_tags[];
  comments: ConsultantProject_consultantProject_comments[];
  consultants: ConsultantProject_consultantProject_consultants[];
  files: ConsultantProject_consultantProject_files[];
  fieldExtensionValues: ConsultantProject_consultantProject_fieldExtensionValues[];
  fieldExtensions: ConsultantProject_consultantProject_fieldExtensions[];
}

export interface ConsultantProject {
  consultantProject: ConsultantProject_consultantProject | null;
}

export interface ConsultantProjectVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantProjectRoles
// ====================================================

export interface ConsultantProjectRoles_consultantProjectRoles_edges_node {
  __typename: "ConsultantProjectRole";
  id: string;
  title: string | null;
  hourlyRate: number;
  hoursWorked: number;
}

export interface ConsultantProjectRoles_consultantProjectRoles_edges {
  __typename: "ConsultantProjectRoleEdge";
  cursor: string;
  node: ConsultantProjectRoles_consultantProjectRoles_edges_node;
}

export interface ConsultantProjectRoles_consultantProjectRoles {
  __typename: "ConsultantProjectRoleConnection";
  edges: (ConsultantProjectRoles_consultantProjectRoles_edges | null)[];
}

export interface ConsultantProjectRoles {
  consultantProjectRoles: ConsultantProjectRoles_consultantProjectRoles;
}

export interface ConsultantProjectRolesVariables {
  projectId: string;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantProjectsFilterData
// ====================================================

export interface ConsultantProjectsFilterData_consultantProjectsFilterData_statusFilters {
  __typename: "ConsultantProjectStatusFilterData";
  id: string;
  title: string | null;
  listIndex: number | null;
  count: number;
}

export interface ConsultantProjectsFilterData_consultantProjectsFilterData_tagFilters {
  __typename: "TagFilterData";
  tag: string;
  id: string;
  count: number;
}

export interface ConsultantProjectsFilterData_consultantProjectsFilterData_activeFilters {
  __typename: "ConsultantProjectActiveFilterData";
  id: string;
  title: string;
  count: number;
}

export interface ConsultantProjectsFilterData_consultantProjectsFilterData {
  __typename: "ConsultantProjectsFilterData";
  statusFilters: ConsultantProjectsFilterData_consultantProjectsFilterData_statusFilters[];
  tagFilters: ConsultantProjectsFilterData_consultantProjectsFilterData_tagFilters[];
  activeFilters: ConsultantProjectsFilterData_consultantProjectsFilterData_activeFilters[];
}

export interface ConsultantProjectsFilterData {
  consultantProjectsFilterData: ConsultantProjectsFilterData_consultantProjectsFilterData | null;
}

export interface ConsultantProjectsFilterDataVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantProjects
// ====================================================

export interface ConsultantProjects_consultantProjects_edges_node_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConsultantProjects_consultantProjects_edges_node_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface ConsultantProjects_consultantProjects_edges_node_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProjects_consultantProjects_edges_node_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConsultantProjects_consultantProjects_edges_node_users_user_profileImage | null;
}

export interface ConsultantProjects_consultantProjects_edges_node_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface ConsultantProjects_consultantProjects_edges_node_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: ConsultantProjects_consultantProjects_edges_node_users_user;
  consultantProject: ConsultantProjects_consultantProjects_edges_node_users_consultantProject;
}

export interface ConsultantProjects_consultantProjects_edges_node_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConsultantProjects_consultantProjects_edges_node_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ConsultantProjects_consultantProjects_edges_node_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ConsultantProjects_consultantProjects_edges_node_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProjects_consultantProjects_edges_node_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: ConsultantProjects_consultantProjects_edges_node_customer_customerCompany_profileImage | null;
}

export interface ConsultantProjects_consultantProjects_edges_node_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: ConsultantProjects_consultantProjects_edges_node_customer_invoiceInformation | null;
  status: ConsultantProjects_consultantProjects_edges_node_customer_status | null;
  companiesWithAccess: ConsultantProjects_consultantProjects_edges_node_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: ConsultantProjects_consultantProjects_edges_node_customer_customerCompany | null;
}

export interface ConsultantProjects_consultantProjects_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ConsultantProjects_consultantProjects_edges_node_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProjects_consultantProjects_edges_node_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConsultantProjects_consultantProjects_edges_node_responsible_profileImage | null;
}

export interface ConsultantProjects_consultantProjects_edges_node_tags {
  __typename: "TagLabel";
  id: string;
}

export interface ConsultantProjects_consultantProjects_edges_node_comments {
  __typename: "Comment";
  id: string;
}

export interface ConsultantProjects_consultantProjects_edges_node_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ConsultantProjects_consultantProjects_edges_node_files {
  __typename: "File";
  id: string;
}

export interface ConsultantProjects_consultantProjects_edges_node_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface ConsultantProjects_consultantProjects_edges_node_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: ConsultantProjects_consultantProjects_edges_node_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface ConsultantProjects_consultantProjects_edges_node {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: ConsultantProjects_consultantProjects_edges_node_invoiceInformation;
  status: ConsultantProjects_consultantProjects_edges_node_status | null;
  users: ConsultantProjects_consultantProjects_edges_node_users[];
  customer: ConsultantProjects_consultantProjects_edges_node_customer | null;
  customerName: string | null;
  recruitment: ConsultantProjects_consultantProjects_edges_node_recruitment | null;
  responsible: ConsultantProjects_consultantProjects_edges_node_responsible | null;
  tags: ConsultantProjects_consultantProjects_edges_node_tags[];
  comments: ConsultantProjects_consultantProjects_edges_node_comments[];
  consultants: ConsultantProjects_consultantProjects_edges_node_consultants[];
  files: ConsultantProjects_consultantProjects_edges_node_files[];
  fieldExtensionValues: ConsultantProjects_consultantProjects_edges_node_fieldExtensionValues[];
}

export interface ConsultantProjects_consultantProjects_edges {
  __typename: "ConsultantProjectEdge";
  cursor: string;
  node: ConsultantProjects_consultantProjects_edges_node;
}

export interface ConsultantProjects_consultantProjects_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ConsultantProjects_consultantProjects {
  __typename: "ConsultantProjectConnection";
  edges: (ConsultantProjects_consultantProjects_edges | null)[];
  pageInfo: ConsultantProjects_consultantProjects_pageInfo;
}

export interface ConsultantProjects {
  consultantProjects: ConsultantProjects_consultantProjects;
}

export interface ConsultantProjectsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  customerId?: string | null;
  consultantId?: string | null;
  searchText?: string | null;
  tagFilter?: string[] | null;
  statusFilter?: string[] | null;
  activeFilter?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConsultantProjectUsers
// ====================================================

export interface ConsultantProjectUsers_consultantProjectUsers_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProjectUsers_consultantProjectUsers_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConsultantProjectUsers_consultantProjectUsers_user_profileImage | null;
}

export interface ConsultantProjectUsers_consultantProjectUsers_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface ConsultantProjectUsers_consultantProjectUsers {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: ConsultantProjectUsers_consultantProjectUsers_user;
  consultantProject: ConsultantProjectUsers_consultantProjectUsers_consultantProject;
}

export interface ConsultantProjectUsers {
  consultantProjectUsers: ConsultantProjectUsers_consultantProjectUsers[];
}

export interface ConsultantProjectUsersVariables {
  projectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactPerson
// ====================================================

export interface ContactPerson_contactPerson {
  __typename: "ContactPerson";
  id: string;
  createdAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  role: string | null;
  description: string | null;
}

export interface ContactPerson {
  contactPerson: ContactPerson_contactPerson | null;
}

export interface ContactPersonVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactPersons
// ====================================================

export interface ContactPersons_contactPersons_edges_node {
  __typename: "ContactPerson";
  id: string;
  createdAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  role: string | null;
  description: string | null;
}

export interface ContactPersons_contactPersons_edges {
  __typename: "ContactPersonEdge";
  cursor: string;
  node: ContactPersons_contactPersons_edges_node;
}

export interface ContactPersons_contactPersons_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ContactPersons_contactPersons {
  __typename: "ContactPersonConnection";
  edges: (ContactPersons_contactPersons_edges | null)[];
  pageInfo: ContactPersons_contactPersons_pageInfo;
}

export interface ContactPersons {
  contactPersons: ContactPersons_contactPersons;
}

export interface ContactPersonsVariables {
  companyId: string;
  customerId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContractFile
// ====================================================

export interface ContractFile {
  contractFile: string;
}

export interface ContractFileVariables {
  contractId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Contract
// ====================================================

export interface Contract_contract_scriveContract_file {
  __typename: "ScriveFile";
  id: string;
  name: string;
}

export interface Contract_contract_scriveContract_parties_fields {
  __typename: "ScrivePartyField";
  type: string;
  order: number | null;
  value: string;
}

export interface Contract_contract_scriveContract_parties {
  __typename: "ScriveParty";
  id: string;
  sign_time: string | null;
  rejected_time: string | null;
  rejection_reason: string | null;
  email_delivery_status: string | null;
  signatory_role: string | null;
  fields: Contract_contract_scriveContract_parties_fields[];
}

export interface Contract_contract_scriveContract {
  __typename: "ScriveContract";
  id: string;
  title: string;
  status: string | null;
  file: Contract_contract_scriveContract_file | null;
  parties: Contract_contract_scriveContract_parties[];
}

export interface Contract_contract_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Contract_contract_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface Contract_contract_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Contract_contract_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: Contract_contract_application_companyApplicant | null;
  recruitment: Contract_contract_application_recruitment;
}

export interface Contract_contract_consultant_applicant {
  __typename: "CompanyApplicant";
  id: string;
}

export interface Contract_contract_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  applicant: Contract_contract_consultant_applicant;
}

export interface Contract_contract_customer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
}

export interface Contract_contract_contactPersons {
  __typename: "ContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface Contract_contract_customParties {
  __typename: "CustomContractParty";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Contract_contract_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Contract_contract_users {
  __typename: "User";
  id: string;
  profileImage: Contract_contract_users_profileImage | null;
  firstName: string | null;
  lastName: string | null;
}

export interface Contract_contract {
  __typename: "ContractWithScrive";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  scriveId: string;
  type: CONTRACT_TYPE | null;
  scriveContract: Contract_contract_scriveContract | null;
  applicant: Contract_contract_applicant | null;
  reminders: DateTime[];
  application: Contract_contract_application | null;
  consultant: Contract_contract_consultant | null;
  customer: Contract_contract_customer | null;
  contactPersons: Contract_contract_contactPersons[] | null;
  customParties: Contract_contract_customParties[] | null;
  users: Contract_contract_users[];
}

export interface Contract {
  contract: Contract_contract | null;
}

export interface ContractVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Contracts
// ====================================================

export interface Contracts_contracts_edges_node_scriveContract_file {
  __typename: "ScriveFile";
  id: string;
  name: string;
}

export interface Contracts_contracts_edges_node_scriveContract_parties_fields {
  __typename: "ScrivePartyField";
  type: string;
  order: number | null;
  value: string;
}

export interface Contracts_contracts_edges_node_scriveContract_parties {
  __typename: "ScriveParty";
  id: string;
  sign_time: string | null;
  rejected_time: string | null;
  rejection_reason: string | null;
  email_delivery_status: string | null;
  signatory_role: string | null;
  fields: Contracts_contracts_edges_node_scriveContract_parties_fields[];
}

export interface Contracts_contracts_edges_node_scriveContract {
  __typename: "ScriveContract";
  id: string;
  title: string;
  status: string | null;
  file: Contracts_contracts_edges_node_scriveContract_file | null;
  parties: Contracts_contracts_edges_node_scriveContract_parties[];
}

export interface Contracts_contracts_edges_node_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Contracts_contracts_edges_node_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface Contracts_contracts_edges_node_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Contracts_contracts_edges_node_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: Contracts_contracts_edges_node_application_companyApplicant | null;
  recruitment: Contracts_contracts_edges_node_application_recruitment;
}

export interface Contracts_contracts_edges_node_consultant_applicant {
  __typename: "CompanyApplicant";
  id: string;
}

export interface Contracts_contracts_edges_node_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  applicant: Contracts_contracts_edges_node_consultant_applicant;
}

export interface Contracts_contracts_edges_node_customer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
}

export interface Contracts_contracts_edges_node_contactPersons {
  __typename: "ContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface Contracts_contracts_edges_node_customParties {
  __typename: "CustomContractParty";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Contracts_contracts_edges_node_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Contracts_contracts_edges_node_users {
  __typename: "User";
  id: string;
  profileImage: Contracts_contracts_edges_node_users_profileImage | null;
  firstName: string | null;
  lastName: string | null;
}

export interface Contracts_contracts_edges_node {
  __typename: "ContractWithScrive";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  scriveId: string;
  type: CONTRACT_TYPE | null;
  scriveContract: Contracts_contracts_edges_node_scriveContract | null;
  applicant: Contracts_contracts_edges_node_applicant | null;
  reminders: DateTime[];
  application: Contracts_contracts_edges_node_application | null;
  consultant: Contracts_contracts_edges_node_consultant | null;
  customer: Contracts_contracts_edges_node_customer | null;
  contactPersons: Contracts_contracts_edges_node_contactPersons[] | null;
  customParties: Contracts_contracts_edges_node_customParties[] | null;
  users: Contracts_contracts_edges_node_users[];
}

export interface Contracts_contracts_edges {
  __typename: "ContractWithScriveEdge";
  cursor: string;
  node: Contracts_contracts_edges_node;
}

export interface Contracts_contracts_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface Contracts_contracts {
  __typename: "ContractWithScriveConnection";
  edges: (Contracts_contracts_edges | null)[];
  pageInfo: Contracts_contracts_pageInfo;
}

export interface Contracts {
  contracts: Contracts_contracts;
}

export interface ContractsVariables {
  type?: CONTRACT_TYPE | null;
  companyId: string;
  recruitmentId?: string | null;
  applicationId?: string | null;
  applicantId?: string | null;
  customerId?: string | null;
  consultantId?: string | null;
  after?: string | null;
  limit?: number | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SealedContractFile
// ====================================================

export interface SealedContractFile {
  sealedContractFile: string;
}

export interface SealedContractFileVariables {
  contractId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Departments
// ====================================================

export interface Departments_departments_edges_node_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Departments_departments_edges_node {
  __typename: "Department";
  id: string;
  name: string;
  description: string | null;
  recruitments: Departments_departments_edges_node_recruitments[];
}

export interface Departments_departments_edges {
  __typename: "DepartmentEdge";
  cursor: string;
  node: Departments_departments_edges_node;
}

export interface Departments_departments_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface Departments_departments {
  __typename: "DepartmentConnection";
  edges: (Departments_departments_edges | null)[];
  pageInfo: Departments_departments_pageInfo;
}

export interface Departments {
  departments: Departments_departments;
}

export interface DepartmentsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Department
// ====================================================

export interface Department_department_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Department_department {
  __typename: "Department";
  id: string;
  name: string;
  description: string | null;
  recruitments: Department_department_recruitments[];
}

export interface Department {
  department: Department_department | null;
}

export interface DepartmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentDepartment
// ====================================================

export interface RecruitmentDepartment_recruitmentDepartment_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface RecruitmentDepartment_recruitmentDepartment {
  __typename: "Department";
  id: string;
  name: string;
  description: string | null;
  recruitments: RecruitmentDepartment_recruitmentDepartment_recruitments[];
}

export interface RecruitmentDepartment {
  recruitmentDepartment: RecruitmentDepartment_recruitmentDepartment | null;
}

export interface RecruitmentDepartmentVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Document
// ====================================================

export interface Document_document_file {
  __typename: "File";
  id: string;
  url: string | null;
  filename: string;
  mimetype: string;
  downloadUrl: string | null;
}

export interface Document_document_originalFile {
  __typename: "File";
  id: string;
  url: string | null;
  filename: string;
  mimetype: string;
  downloadUrl: string | null;
}

export interface Document_document_dimensions {
  __typename: "Vec2";
  id: string;
  x: number;
  y: number;
}

export interface Document_document {
  __typename: "Document";
  id: string;
  createdAt: DateTime;
  displayName: string;
  type: DOCUMENT_TYPE;
  file: Document_document_file;
  originalFile: Document_document_originalFile | null;
  words: string | null;
  dimensions: Document_document_dimensions | null;
  fullText: string | null;
}

export interface Document {
  document: Document_document | null;
}

export interface DocumentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DocumentLanguages
// ====================================================

export interface DocumentLanguages {
  documentLanguages: string[];
}

export interface DocumentLanguagesVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Documents
// ====================================================

export interface Documents_documents_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface Documents_documents_edges_node_file {
  __typename: "File";
  id: string;
  url: string | null;
  filename: string;
  mimetype: string;
  downloadUrl: string | null;
}

export interface Documents_documents_edges_node_originalFile {
  __typename: "File";
  id: string;
  url: string | null;
  filename: string;
  mimetype: string;
  downloadUrl: string | null;
}

export interface Documents_documents_edges_node_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Documents_documents_edges_node {
  __typename: "Document";
  id: string;
  createdAt: DateTime;
  language: string | null;
  type: DOCUMENT_TYPE;
  displayName: string;
  file: Documents_documents_edges_node_file;
  originalFile: Documents_documents_edges_node_originalFile | null;
  application: Documents_documents_edges_node_application | null;
}

export interface Documents_documents_edges {
  __typename: "DocumentEdge";
  cursor: string;
  node: Documents_documents_edges_node;
}

export interface Documents_documents {
  __typename: "DocumentConnection";
  pageInfo: Documents_documents_pageInfo;
  edges: (Documents_documents_edges | null)[];
}

export interface Documents {
  documents: Documents_documents;
}

export interface DocumentsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  searchText?: string | null;
  applicantId?: string | null;
  applicationId?: string | null;
  recruitmentId?: string | null;
  consultantProjectId?: string | null;
  types?: DOCUMENT_TYPE[] | null;
  languages?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Errors
// ====================================================

export interface Errors_errors_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface Errors_errors_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Errors_errors_edges_node_user {
  __typename: "User";
  id: string;
}

export interface Errors_errors_edges_node_applicant {
  __typename: "Applicant";
  id: string;
}

export interface Errors_errors_edges_node {
  __typename: "Error";
  id: string;
  createdAt: DateTime;
  type: ERROR_TYPE;
  description: string;
  company: Errors_errors_edges_node_company | null;
  user: Errors_errors_edges_node_user | null;
  applicant: Errors_errors_edges_node_applicant | null;
}

export interface Errors_errors_edges {
  __typename: "ErrorEdge";
  cursor: string;
  node: Errors_errors_edges_node;
}

export interface Errors_errors {
  __typename: "ErrorConnection";
  pageInfo: Errors_errors_pageInfo;
  edges: (Errors_errors_edges | null)[];
}

export interface Errors {
  errors: Errors_errors;
}

export interface ErrorsVariables {
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  type?: ERROR_TYPE | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EventMatchesFilterData
// ====================================================

export interface EventMatchesFilterData_eventMatchesFilterData_talentProfileFilters {
  __typename: "TalentProfileFilterData";
  title: string;
  id: string;
  count: number;
}

export interface EventMatchesFilterData_eventMatchesFilterData {
  __typename: "EventMatchesFilterData";
  talentProfileFilters: EventMatchesFilterData_eventMatchesFilterData_talentProfileFilters[];
}

export interface EventMatchesFilterData {
  eventMatchesFilterData: EventMatchesFilterData_eventMatchesFilterData;
}

export interface EventMatchesFilterDataVariables {
  matchingEventId: string;
  participantId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EventMatch
// ====================================================

export interface EventMatch_eventMatch_event {
  __typename: "MatchingEvent";
  id: string;
  title: string;
  flowOrder: MATCHING_EVENT_FLOW_ORDER | null;
}

export interface EventMatch_eventMatch_talentProfile_matchingEventParticipants_company {
  __typename: "Company";
  id: string;
}

export interface EventMatch_eventMatch_talentProfile_matchingEventParticipants {
  __typename: "MatchingEventParticipant";
  id: string;
  company: EventMatch_eventMatch_talentProfile_matchingEventParticipants_company;
}

export interface EventMatch_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
  matchingEventParticipants: EventMatch_eventMatch_talentProfile_matchingEventParticipants[];
}

export interface EventMatch_eventMatch_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface EventMatch_eventMatch_application_applicationFormAnswers_question {
  __typename: "FormQuestion";
  id: string;
  question: string | null;
}

export interface EventMatch_eventMatch_application_applicationFormAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  answerText: string | null;
  question: EventMatch_eventMatch_application_applicationFormAnswers_question;
}

export interface EventMatch_eventMatch_application_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface EventMatch_eventMatch_application_documents {
  __typename: "Document";
  id: string;
  type: DOCUMENT_TYPE;
  displayName: string;
  file: EventMatch_eventMatch_application_documents_file;
}

export interface EventMatch_eventMatch_application {
  __typename: "Application";
  id: string;
  freeText: string | null;
  phoneNumber: string | null;
  applicationFormAnswers: EventMatch_eventMatch_application_applicationFormAnswers[];
  documents: EventMatch_eventMatch_application_documents[];
}

export interface EventMatch_eventMatch {
  __typename: "EventMatch";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  email: string;
  status: EVENT_MATCH_STATUS;
  matchRank: number | null;
  matchPercentageNeedToHave: number;
  matchPercentageNiceToHave: number;
  interviewBookingReminders: DateTime[];
  createdManually: boolean | null;
  event: EventMatch_eventMatch_event;
  talentProfile: EventMatch_eventMatch_talentProfile;
  bookedInterview: EventMatch_eventMatch_bookedInterview | null;
  application: EventMatch_eventMatch_application;
}

export interface EventMatch {
  eventMatch: EventMatch_eventMatch | null;
}

export interface EventMatchVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HostsEventMatches
// ====================================================

export interface HostsEventMatches_hostsEventMatches_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface HostsEventMatches_hostsEventMatches_edges_node_talentProfile_matchingEventParticipants_company {
  __typename: "Company";
  id: string;
}

export interface HostsEventMatches_hostsEventMatches_edges_node_talentProfile_matchingEventParticipants {
  __typename: "MatchingEventParticipant";
  id: string;
  company: HostsEventMatches_hostsEventMatches_edges_node_talentProfile_matchingEventParticipants_company;
}

export interface HostsEventMatches_hostsEventMatches_edges_node_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
  matchingEventParticipants: HostsEventMatches_hostsEventMatches_edges_node_talentProfile_matchingEventParticipants[];
}

export interface HostsEventMatches_hostsEventMatches_edges_node_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface HostsEventMatches_hostsEventMatches_edges_node_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
}

export interface HostsEventMatches_hostsEventMatches_edges_node_application {
  __typename: "Application";
  id: string;
  companyApplicant: HostsEventMatches_hostsEventMatches_edges_node_application_companyApplicant | null;
}

export interface HostsEventMatches_hostsEventMatches_edges_node {
  __typename: "EventMatch";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  email: string;
  talentProfile: HostsEventMatches_hostsEventMatches_edges_node_talentProfile;
  interviewBookingReminders: DateTime[];
  bookedInterview: HostsEventMatches_hostsEventMatches_edges_node_bookedInterview | null;
  application: HostsEventMatches_hostsEventMatches_edges_node_application;
  companyName: string;
  status: EVENT_MATCH_STATUS;
}

export interface HostsEventMatches_hostsEventMatches_edges {
  __typename: "EventMatchEdge";
  cursor: string;
  node: HostsEventMatches_hostsEventMatches_edges_node;
}

export interface HostsEventMatches_hostsEventMatches {
  __typename: "EventMatchConnection";
  pageInfo: HostsEventMatches_hostsEventMatches_pageInfo;
  edges: (HostsEventMatches_hostsEventMatches_edges | null)[];
}

export interface HostsEventMatches {
  hostsEventMatches: HostsEventMatches_hostsEventMatches;
}

export interface HostsEventMatchesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  statuses?: EVENT_MATCH_STATUS[] | null;
  eventId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ParticipantsEventMatches
// ====================================================

export interface ParticipantsEventMatches_participantsEventMatches_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ParticipantsEventMatches_participantsEventMatches_edges_node_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface ParticipantsEventMatches_participantsEventMatches_edges_node_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface ParticipantsEventMatches_participantsEventMatches_edges_node_application {
  __typename: "Application";
  id: string;
}

export interface ParticipantsEventMatches_participantsEventMatches_edges_node {
  __typename: "EventMatch";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  createdManually: boolean | null;
  status: EVENT_MATCH_STATUS;
  matchRank: number | null;
  matchPercentageNeedToHave: number;
  matchPercentageNiceToHave: number;
  interviewBookingReminders: DateTime[];
  bookedInterview: ParticipantsEventMatches_participantsEventMatches_edges_node_bookedInterview | null;
  talentProfile: ParticipantsEventMatches_participantsEventMatches_edges_node_talentProfile;
  application: ParticipantsEventMatches_participantsEventMatches_edges_node_application;
}

export interface ParticipantsEventMatches_participantsEventMatches_edges {
  __typename: "EventMatchEdge";
  cursor: string;
  node: ParticipantsEventMatches_participantsEventMatches_edges_node;
}

export interface ParticipantsEventMatches_participantsEventMatches {
  __typename: "EventMatchConnection";
  pageInfo: ParticipantsEventMatches_participantsEventMatches_pageInfo;
  edges: (ParticipantsEventMatches_participantsEventMatches_edges | null)[];
}

export interface ParticipantsEventMatches {
  participantsEventMatches: ParticipantsEventMatches_participantsEventMatches;
}

export interface ParticipantsEventMatchesVariables {
  status?: EVENT_MATCH_STATUS | null;
  participantId: string;
  talentProfiles?: string[] | null;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EventTriggerTypes
// ====================================================

export interface EventTriggerTypes {
  eventTriggerTypes: EVENT_TRIGGER_ACTION_TYPE[];
}

export interface EventTriggerTypesVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FieldExtensions
// ====================================================

export interface FieldExtensions_fieldExtensions {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface FieldExtensions {
  fieldExtensions: FieldExtensions_fieldExtensions[];
}

export interface FieldExtensionsVariables {
  companyId: string;
  type: FIELD_EXTENSION_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FieldExtensionValues
// ====================================================

export interface FieldExtensionValues_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface FieldExtensionValues_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: FieldExtensionValues_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface FieldExtensionValues {
  fieldExtensionValues: FieldExtensionValues_fieldExtensionValues[];
}

export interface FieldExtensionValuesVariables {
  consultantProjectId?: string | null;
  consultantId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FieldExtension
// ====================================================

export interface FieldExtension_fieldExtension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface FieldExtension {
  fieldExtension: FieldExtension_fieldExtension | null;
}

export interface FieldExtensionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FileTemplate
// ====================================================

export interface FileTemplate_fileTemplate_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface FileTemplate_fileTemplate_file {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
  mimetype: string;
}

export interface FileTemplate_fileTemplate {
  __typename: "FileTemplate";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  company: FileTemplate_fileTemplate_company;
  file: FileTemplate_fileTemplate_file;
}

export interface FileTemplate {
  fileTemplate: FileTemplate_fileTemplate | null;
}

export interface FileTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FileTemplates
// ====================================================

export interface FileTemplates_fileTemplates_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface FileTemplates_fileTemplates_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface FileTemplates_fileTemplates_edges_node_file {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
  mimetype: string;
}

export interface FileTemplates_fileTemplates_edges_node {
  __typename: "FileTemplate";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  company: FileTemplates_fileTemplates_edges_node_company;
  file: FileTemplates_fileTemplates_edges_node_file;
}

export interface FileTemplates_fileTemplates_edges {
  __typename: "FileTemplateEdge";
  cursor: string;
  node: FileTemplates_fileTemplates_edges_node;
}

export interface FileTemplates_fileTemplates {
  __typename: "FileTemplateConnection";
  pageInfo: FileTemplates_fileTemplates_pageInfo;
  edges: (FileTemplates_fileTemplates_edges | null)[];
}

export interface FileTemplates {
  fileTemplates: FileTemplates_fileTemplates;
}

export interface FileTemplatesVariables {
  companyId: string;
  sortBy?: string | null;
  after?: string | null;
  limit?: number | null;
  sortOrder?: string | null;
  searchText?: string | null;
  showResultsFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationReferenceForms
// ====================================================

export interface ApplicationReferenceForms_referenceForms_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ApplicationReferenceForms_referenceForms_edges_node_applications {
  __typename: "Application";
  id: string;
}

export interface ApplicationReferenceForms_referenceForms_edges_node_form_questions {
  __typename: "FormQuestion";
  id: string;
}

export interface ApplicationReferenceForms_referenceForms_edges_node_form_participants_answeredByUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ApplicationReferenceForms_referenceForms_edges_node_form_participants_reference_application {
  __typename: "Application";
  id: string;
}

export interface ApplicationReferenceForms_referenceForms_edges_node_form_participants_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phoneNumber: string | null;
  application: ApplicationReferenceForms_referenceForms_edges_node_form_participants_reference_application | null;
}

export interface ApplicationReferenceForms_referenceForms_edges_node_form_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  answeredByUser: ApplicationReferenceForms_referenceForms_edges_node_form_participants_answeredByUser | null;
  answeredAt: DateTime | null;
  status: FORM_SUBMISSION_STATUS;
  emailNotifiers: DateTime[];
  phoneNotifiers: DateTime[];
  reference: ApplicationReferenceForms_referenceForms_edges_node_form_participants_reference | null;
}

export interface ApplicationReferenceForms_referenceForms_edges_node_form {
  __typename: "Form";
  id: string;
  title: string | null;
  description: string | null;
  status: FORM_STATUS | null;
  questions: ApplicationReferenceForms_referenceForms_edges_node_form_questions[];
  participants: ApplicationReferenceForms_referenceForms_edges_node_form_participants[];
}

export interface ApplicationReferenceForms_referenceForms_edges_node {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
  createdAt: DateTime;
  applications: ApplicationReferenceForms_referenceForms_edges_node_applications[] | null;
  form: ApplicationReferenceForms_referenceForms_edges_node_form;
}

export interface ApplicationReferenceForms_referenceForms_edges {
  __typename: "ReferenceFormEdge";
  cursor: string;
  node: ApplicationReferenceForms_referenceForms_edges_node;
}

export interface ApplicationReferenceForms_referenceForms {
  __typename: "ReferenceFormConnection";
  pageInfo: ApplicationReferenceForms_referenceForms_pageInfo;
  edges: (ApplicationReferenceForms_referenceForms_edges | null)[];
}

export interface ApplicationReferenceForms {
  referenceForms: ApplicationReferenceForms_referenceForms;
}

export interface ApplicationReferenceFormsVariables {
  companyId: string;
  after?: string | null;
  limit: number;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  recruitments?: string[] | null;
  applications?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationScreeningFormParticipations
// ====================================================

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_question_matchingEventProfile | null;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerCompetences_competence;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_application {
  __typename: "Application";
  id: string;
  recruitment: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_application_recruitment;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerFile | null;
  answerDocument: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerDocument | null;
  answerDocuments: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerEducations[] | null;
  answerWorkExperiences: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerWorkExperiences[] | null;
  answerPersonalData: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerPersonalData | null;
  answerCompetences: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_answerCompetences[] | null;
  application: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers_application | null;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_form {
  __typename: "Form";
  id: string;
  title: string | null;
  screeningForm: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_form_screeningForm | null;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  answeredAt: DateTime | null;
  status: FORM_SUBMISSION_STATUS;
  answers: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_answers[];
  emailNotifiers: DateTime[];
  phoneNotifiers: DateTime[];
  form: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_form;
  application: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_application | null;
  reference: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node_reference | null;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges {
  __typename: "FormParticipantEdge";
  cursor: string;
  node: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges_node;
}

export interface ApplicationScreeningFormParticipations_applicationScreeningFormParticipations {
  __typename: "FormParticipantConnection";
  pageInfo: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_pageInfo;
  edges: (ApplicationScreeningFormParticipations_applicationScreeningFormParticipations_edges | null)[];
}

export interface ApplicationScreeningFormParticipations {
  applicationScreeningFormParticipations: ApplicationScreeningFormParticipations_applicationScreeningFormParticipations;
}

export interface ApplicationScreeningFormParticipationsVariables {
  applicationId: string;
  after?: string | null;
  limit: number;
  sortOrder?: string | null;
  sortBy?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormParticipant
// ====================================================

export interface FormParticipant_formParticipant_answers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface FormParticipant_formParticipant_answers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: FormParticipant_formParticipant_answers_question_matchingEventProfile | null;
}

export interface FormParticipant_formParticipant_answers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface FormParticipant_formParticipant_answers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface FormParticipant_formParticipant_answers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: FormParticipant_formParticipant_answers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface FormParticipant_formParticipant_answers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface FormParticipant_formParticipant_answers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: FormParticipant_formParticipant_answers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface FormParticipant_formParticipant_answers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface FormParticipant_formParticipant_answers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface FormParticipant_formParticipant_answers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface FormParticipant_formParticipant_answers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface FormParticipant_formParticipant_answers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: FormParticipant_formParticipant_answers_answerCompetences_competence;
}

export interface FormParticipant_formParticipant_answers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface FormParticipant_formParticipant_answers_application {
  __typename: "Application";
  id: string;
  recruitment: FormParticipant_formParticipant_answers_application_recruitment;
}

export interface FormParticipant_formParticipant_answers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: FormParticipant_formParticipant_answers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: FormParticipant_formParticipant_answers_answerFile | null;
  answerDocument: FormParticipant_formParticipant_answers_answerDocument | null;
  answerDocuments: FormParticipant_formParticipant_answers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: FormParticipant_formParticipant_answers_answerEducations[] | null;
  answerWorkExperiences: FormParticipant_formParticipant_answers_answerWorkExperiences[] | null;
  answerPersonalData: FormParticipant_formParticipant_answers_answerPersonalData | null;
  answerCompetences: FormParticipant_formParticipant_answers_answerCompetences[] | null;
  application: FormParticipant_formParticipant_answers_application | null;
}

export interface FormParticipant_formParticipant_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface FormParticipant_formParticipant_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: FormParticipant_formParticipant_form_questions_matchingEventProfile | null;
}

export interface FormParticipant_formParticipant_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface FormParticipant_formParticipant_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface FormParticipant_formParticipant_form {
  __typename: "Form";
  id: string;
  type: FORM_TYPE;
  title: string | null;
  questions: FormParticipant_formParticipant_form_questions[];
  referenceForm: FormParticipant_formParticipant_form_referenceForm | null;
  screeningForm: FormParticipant_formParticipant_form_screeningForm | null;
}

export interface FormParticipant_formParticipant_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface FormParticipant_formParticipant_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: FormParticipant_formParticipant_application_recruitment;
}

export interface FormParticipant_formParticipant_reference_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface FormParticipant_formParticipant_reference_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: FormParticipant_formParticipant_reference_application_recruitment;
}

export interface FormParticipant_formParticipant_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phoneNumber: string | null;
  application: FormParticipant_formParticipant_reference_application | null;
}

export interface FormParticipant_formParticipant {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  answeredAt: DateTime | null;
  status: FORM_SUBMISSION_STATUS;
  answers: FormParticipant_formParticipant_answers[];
  emailNotifiers: DateTime[];
  phoneNotifiers: DateTime[];
  form: FormParticipant_formParticipant_form;
  application: FormParticipant_formParticipant_application | null;
  reference: FormParticipant_formParticipant_reference | null;
}

export interface FormParticipant {
  formParticipant: FormParticipant_formParticipant | null;
}

export interface FormParticipantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormParticipants
// ====================================================

export interface FormParticipants_formParticipants_answers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface FormParticipants_formParticipants_answers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: FormParticipants_formParticipants_answers_question_matchingEventProfile | null;
}

export interface FormParticipants_formParticipants_answers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface FormParticipants_formParticipants_answers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface FormParticipants_formParticipants_answers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: FormParticipants_formParticipants_answers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface FormParticipants_formParticipants_answers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface FormParticipants_formParticipants_answers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: FormParticipants_formParticipants_answers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface FormParticipants_formParticipants_answers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface FormParticipants_formParticipants_answers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface FormParticipants_formParticipants_answers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface FormParticipants_formParticipants_answers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface FormParticipants_formParticipants_answers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: FormParticipants_formParticipants_answers_answerCompetences_competence;
}

export interface FormParticipants_formParticipants_answers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface FormParticipants_formParticipants_answers_application {
  __typename: "Application";
  id: string;
  recruitment: FormParticipants_formParticipants_answers_application_recruitment;
}

export interface FormParticipants_formParticipants_answers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: FormParticipants_formParticipants_answers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: FormParticipants_formParticipants_answers_answerFile | null;
  answerDocument: FormParticipants_formParticipants_answers_answerDocument | null;
  answerDocuments: FormParticipants_formParticipants_answers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: FormParticipants_formParticipants_answers_answerEducations[] | null;
  answerWorkExperiences: FormParticipants_formParticipants_answers_answerWorkExperiences[] | null;
  answerPersonalData: FormParticipants_formParticipants_answers_answerPersonalData | null;
  answerCompetences: FormParticipants_formParticipants_answers_answerCompetences[] | null;
  application: FormParticipants_formParticipants_answers_application | null;
}

export interface FormParticipants_formParticipants_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface FormParticipants_formParticipants_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: FormParticipants_formParticipants_form_questions_matchingEventProfile | null;
}

export interface FormParticipants_formParticipants_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface FormParticipants_formParticipants_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface FormParticipants_formParticipants_form {
  __typename: "Form";
  id: string;
  type: FORM_TYPE;
  title: string | null;
  questions: FormParticipants_formParticipants_form_questions[];
  referenceForm: FormParticipants_formParticipants_form_referenceForm | null;
  screeningForm: FormParticipants_formParticipants_form_screeningForm | null;
}

export interface FormParticipants_formParticipants_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface FormParticipants_formParticipants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: FormParticipants_formParticipants_application_recruitment;
}

export interface FormParticipants_formParticipants_reference_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface FormParticipants_formParticipants_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
  application: FormParticipants_formParticipants_reference_application | null;
}

export interface FormParticipants_formParticipants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  answeredAt: DateTime | null;
  status: FORM_SUBMISSION_STATUS;
  answers: FormParticipants_formParticipants_answers[];
  emailNotifiers: DateTime[];
  phoneNotifiers: DateTime[];
  form: FormParticipants_formParticipants_form;
  application: FormParticipants_formParticipants_application | null;
  reference: FormParticipants_formParticipants_reference | null;
}

export interface FormParticipants {
  formParticipants: FormParticipants_formParticipants[];
}

export interface FormParticipantsVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Form
// ====================================================

export interface Form_form_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Form_form_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface Form_form_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: Form_form_participants_application_companyApplicant | null;
}

export interface Form_form_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: Form_form_participants_application | null;
}

export interface Form_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface Form_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: Form_form_questions_matchingEventProfile | null;
}

export interface Form_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface Form_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface Form_form {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: Form_form_companiesWithAccess[];
  participants: Form_form_participants[];
  questions: Form_form_questions[];
  referenceForm: Form_form_referenceForm | null;
  screeningForm: Form_form_screeningForm | null;
}

export interface Form {
  form: Form_form | null;
}

export interface FormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Forms
// ====================================================

export interface Forms_forms_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface Forms_forms_edges_node_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Forms_forms_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Forms_forms_edges_node_questions {
  __typename: "FormQuestion";
  id: string;
  type: FORM_QUESTION_TYPE;
}

export interface Forms_forms_edges_node_participants {
  __typename: "FormParticipant";
  id: string;
}

export interface Forms_forms_edges_node {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: Forms_forms_edges_node_companiesWithAccess[];
  company: Forms_forms_edges_node_company | null;
  questions: Forms_forms_edges_node_questions[];
  participants: Forms_forms_edges_node_participants[];
  description: string | null;
}

export interface Forms_forms_edges {
  __typename: "FormEdge";
  cursor: string;
  node: Forms_forms_edges_node;
}

export interface Forms_forms {
  __typename: "FormConnection";
  pageInfo: Forms_forms_pageInfo;
  edges: (Forms_forms_edges | null)[];
}

export interface Forms {
  forms: Forms_forms;
}

export interface FormsVariables {
  companyId: string;
  after?: string | null;
  limit: number;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  types?: FORM_TYPE[] | null;
  statuses?: FORM_STATUS[] | null;
  recruitments?: string[] | null;
  applications?: string[] | null;
  showResultsFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReferenceForm
// ====================================================

export interface ReferenceForm_referenceForm_applications_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface ReferenceForm_referenceForm_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: ReferenceForm_referenceForm_applications_companyApplicant | null;
}

export interface ReferenceForm_referenceForm_addReferencesInvitations_application {
  __typename: "Application";
  id: string;
}

export interface ReferenceForm_referenceForm_addReferencesInvitations_references {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ReferenceForm_referenceForm_addReferencesInvitations {
  __typename: "AddReferencesInvitation";
  id: string;
  status: FORM_SUBMISSION_STATUS | null;
  notifiers: DateTime[];
  application: ReferenceForm_referenceForm_addReferencesInvitations_application;
  references: ReferenceForm_referenceForm_addReferencesInvitations_references[];
}

export interface ReferenceForm_referenceForm_form_participants_answeredByUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ReferenceForm_referenceForm_form_participants_reference_application {
  __typename: "Application";
  id: string;
}

export interface ReferenceForm_referenceForm_form_participants_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phoneNumber: string | null;
  application: ReferenceForm_referenceForm_form_participants_reference_application | null;
}

export interface ReferenceForm_referenceForm_form_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  answeredByUser: ReferenceForm_referenceForm_form_participants_answeredByUser | null;
  answeredAt: DateTime | null;
  status: FORM_SUBMISSION_STATUS;
  emailNotifiers: DateTime[];
  phoneNotifiers: DateTime[];
  reference: ReferenceForm_referenceForm_form_participants_reference | null;
}

export interface ReferenceForm_referenceForm_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ReferenceForm_referenceForm_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: ReferenceForm_referenceForm_form_questions_matchingEventProfile | null;
}

export interface ReferenceForm_referenceForm_form {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  participants: ReferenceForm_referenceForm_form_participants[];
  questions: ReferenceForm_referenceForm_form_questions[];
}

export interface ReferenceForm_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  createdAt: DateTime;
  title: string | null;
  applications: ReferenceForm_referenceForm_applications[] | null;
  addReferencesInvitations: ReferenceForm_referenceForm_addReferencesInvitations[];
  form: ReferenceForm_referenceForm_form;
}

export interface ReferenceForm {
  referenceForm: ReferenceForm_referenceForm | null;
}

export interface ReferenceFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReferenceFormsOfRecruitment
// ====================================================

export interface ReferenceFormsOfRecruitment_referenceFormsOfRecruitment {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface ReferenceFormsOfRecruitment {
  referenceFormsOfRecruitment: ReferenceFormsOfRecruitment_referenceFormsOfRecruitment[];
}

export interface ReferenceFormsOfRecruitmentVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReferenceForms
// ====================================================

export interface ReferenceForms_referenceForms_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ReferenceForms_referenceForms_edges_node_applications {
  __typename: "Application";
  id: string;
}

export interface ReferenceForms_referenceForms_edges_node_form_questions {
  __typename: "FormQuestion";
  id: string;
}

export interface ReferenceForms_referenceForms_edges_node_form_participants {
  __typename: "FormParticipant";
  id: string;
}

export interface ReferenceForms_referenceForms_edges_node_form {
  __typename: "Form";
  id: string;
  title: string | null;
  description: string | null;
  status: FORM_STATUS | null;
  questions: ReferenceForms_referenceForms_edges_node_form_questions[];
  participants: ReferenceForms_referenceForms_edges_node_form_participants[];
}

export interface ReferenceForms_referenceForms_edges_node {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
  createdAt: DateTime;
  applications: ReferenceForms_referenceForms_edges_node_applications[] | null;
  form: ReferenceForms_referenceForms_edges_node_form;
}

export interface ReferenceForms_referenceForms_edges {
  __typename: "ReferenceFormEdge";
  cursor: string;
  node: ReferenceForms_referenceForms_edges_node;
}

export interface ReferenceForms_referenceForms {
  __typename: "ReferenceFormConnection";
  pageInfo: ReferenceForms_referenceForms_pageInfo;
  edges: (ReferenceForms_referenceForms_edges | null)[];
}

export interface ReferenceForms {
  referenceForms: ReferenceForms_referenceForms;
}

export interface ReferenceFormsVariables {
  companyId: string;
  after?: string | null;
  limit: number;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  recruitments?: string[] | null;
  applications?: string[] | null;
  excludeFormWithApplicationId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormQuestion
// ====================================================

export interface FormQuestion_formQuestion_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface FormQuestion_formQuestion {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: FormQuestion_formQuestion_matchingEventProfile | null;
}

export interface FormQuestion {
  formQuestion: FormQuestion_formQuestion | null;
}

export interface FormQuestionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormQuestionsInForm
// ====================================================

export interface FormQuestionsInForm_formQuestionsInForm_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface FormQuestionsInForm_formQuestionsInForm {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: FormQuestionsInForm_formQuestionsInForm_matchingEventProfile | null;
}

export interface FormQuestionsInForm {
  formQuestionsInForm: FormQuestionsInForm_formQuestionsInForm[];
}

export interface FormQuestionsInFormVariables {
  formId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormQuestionsInMatchingEventTalentProfile
// ====================================================

export interface FormQuestionsInMatchingEventTalentProfile_formQuestionsInMatchingEventTalentProfile_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface FormQuestionsInMatchingEventTalentProfile_formQuestionsInMatchingEventTalentProfile {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: FormQuestionsInMatchingEventTalentProfile_formQuestionsInMatchingEventTalentProfile_matchingEventProfile | null;
}

export interface FormQuestionsInMatchingEventTalentProfile {
  formQuestionsInMatchingEventTalentProfile: FormQuestionsInMatchingEventTalentProfile_formQuestionsInMatchingEventTalentProfile[];
}

export interface FormQuestionsInMatchingEventTalentProfileVariables {
  eventId: string;
  talentProfileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EvaluatedFormQuestionAnswersForTalentProfile
// ====================================================

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_question_matchingEventProfile | null;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerCompetences_competence;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_application {
  __typename: "Application";
  id: string;
  recruitment: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_application_recruitment;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerFile | null;
  answerDocument: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerDocument | null;
  answerDocuments: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerEducations[] | null;
  answerWorkExperiences: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerWorkExperiences[] | null;
  answerPersonalData: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerPersonalData | null;
  answerCompetences: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_answerCompetences[] | null;
  application: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile_application | null;
}

export interface EvaluatedFormQuestionAnswersForTalentProfile {
  evaluatedFormQuestionAnswersForTalentProfile: EvaluatedFormQuestionAnswersForTalentProfile_evaluatedFormQuestionAnswersForTalentProfile[];
}

export interface EvaluatedFormQuestionAnswersForTalentProfileVariables {
  talentProfileId: string;
  formId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EvaluatedFormQuestionAnswers
// ====================================================

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_question_matchingEventProfile | null;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerCompetences_competence;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_application {
  __typename: "Application";
  id: string;
  recruitment: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_application_recruitment;
}

export interface EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerFile | null;
  answerDocument: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerDocument | null;
  answerDocuments: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerEducations[] | null;
  answerWorkExperiences: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerWorkExperiences[] | null;
  answerPersonalData: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerPersonalData | null;
  answerCompetences: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_answerCompetences[] | null;
  application: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers_application | null;
}

export interface EvaluatedFormQuestionAnswers {
  evaluatedFormQuestionAnswers: EvaluatedFormQuestionAnswers_evaluatedFormQuestionAnswers[];
}

export interface EvaluatedFormQuestionAnswersVariables {
  companyId: string;
  formId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormQuestionAnswerEvaluationsForForm
// ====================================================

export interface FormQuestionAnswerEvaluationsForForm_formQuestionAnswerEvaluationsForForm_formQuestion {
  __typename: "FormQuestion";
  id: string;
}

export interface FormQuestionAnswerEvaluationsForForm_formQuestionAnswerEvaluationsForForm {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  option: string | null;
  significance: REQUIREMENT_SIGNIFICANCE;
  formQuestion: FormQuestionAnswerEvaluationsForForm_formQuestionAnswerEvaluationsForForm_formQuestion | null;
}

export interface FormQuestionAnswerEvaluationsForForm {
  formQuestionAnswerEvaluationsForForm: FormQuestionAnswerEvaluationsForForm_formQuestionAnswerEvaluationsForForm[];
}

export interface FormQuestionAnswerEvaluationsForFormVariables {
  formQuestionId: string;
  formId: string;
  option?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormQuestionAnswerEvaluationsForTalentProfiles
// ====================================================

export interface FormQuestionAnswerEvaluationsForTalentProfiles_formQuestionAnswerEvaluationsForTalentProfiles_formQuestion {
  __typename: "FormQuestion";
  id: string;
}

export interface FormQuestionAnswerEvaluationsForTalentProfiles_formQuestionAnswerEvaluationsForTalentProfiles {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  option: string | null;
  significance: REQUIREMENT_SIGNIFICANCE;
  formQuestion: FormQuestionAnswerEvaluationsForTalentProfiles_formQuestionAnswerEvaluationsForTalentProfiles_formQuestion | null;
}

export interface FormQuestionAnswerEvaluationsForTalentProfiles {
  formQuestionAnswerEvaluationsForTalentProfiles: FormQuestionAnswerEvaluationsForTalentProfiles_formQuestionAnswerEvaluationsForTalentProfiles[];
}

export interface FormQuestionAnswerEvaluationsForTalentProfilesVariables {
  formQuestionId: string;
  matchingEventTalentProfileId: string;
  option?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GeneralSearch
// ====================================================

export interface GeneralSearch_generalSearch_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
  publishingStatus: RECRUITMENT_STATUS | null;
  createdAt: DateTime;
}

export interface GeneralSearch_generalSearch_applicants_applications {
  __typename: "Application";
  id: string;
}

export interface GeneralSearch_generalSearch_applicants {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  createdAt: DateTime | null;
  applications: GeneralSearch_generalSearch_applicants_applications[];
}

export interface GeneralSearch_generalSearch_documents_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface GeneralSearch_generalSearch_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  type: DOCUMENT_TYPE;
  language: string | null;
  application: GeneralSearch_generalSearch_documents_application | null;
}

export interface GeneralSearch_generalSearch {
  __typename: "GeneralSearch";
  recruitments: GeneralSearch_generalSearch_recruitments[];
  recruitmentsCount: number;
  applicants: GeneralSearch_generalSearch_applicants[];
  applicantsCount: number;
  documents: GeneralSearch_generalSearch_documents[];
  documentsCount: number;
}

export interface GeneralSearch {
  generalSearch: GeneralSearch_generalSearch;
}

export interface GeneralSearchVariables {
  companyId: string;
  searchText: string;
  applicantsLimit?: number | null;
  recruitmentsLimit?: number | null;
  documentsLimit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatedGroupCompanyApplicants
// ====================================================

export interface PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_applications_recruitment_users_user {
  __typename: "User";
  id: string;
}

export interface PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_applications_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_applications_recruitment_users_user;
}

export interface PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  users: PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_applications_recruitment_users[];
  company: PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_applications_recruitment_company;
}

export interface PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_applications {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  recruitment: PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_applications_recruitment;
}

export interface PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_companies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_companyApplicants_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_companyApplicants {
  __typename: "CompanyApplicant";
  id: string;
  createdAt: DateTime | null;
  company: PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_companyApplicants_company;
}

export interface PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items {
  __typename: "GroupCompanyApplicant";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  tags: PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_tags[];
  applications: PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_applications[];
  companies: PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_companies[];
  companyApplicants: PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items_companyApplicants[];
}

export interface PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants {
  __typename: "PaginatedGroupCompanyApplicants";
  totalCount: number;
  items: PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants_items[];
}

export interface PaginatedGroupCompanyApplicants {
  paginatedGroupCompanyApplicants: PaginatedGroupCompanyApplicants_paginatedGroupCompanyApplicants;
}

export interface PaginatedGroupCompanyApplicantsVariables {
  page?: number | null;
  pageSize?: number | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  filters?: GroupCompanyApplicantFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupCompanyApplicant
// ====================================================

export interface GroupCompanyApplicant_groupCompanyApplicant_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface GroupCompanyApplicant_groupCompanyApplicant_applications_recruitment_users_user {
  __typename: "User";
  id: string;
}

export interface GroupCompanyApplicant_groupCompanyApplicant_applications_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: GroupCompanyApplicant_groupCompanyApplicant_applications_recruitment_users_user;
}

export interface GroupCompanyApplicant_groupCompanyApplicant_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupCompanyApplicant_groupCompanyApplicant_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  users: GroupCompanyApplicant_groupCompanyApplicant_applications_recruitment_users[];
  company: GroupCompanyApplicant_groupCompanyApplicant_applications_recruitment_company;
}

export interface GroupCompanyApplicant_groupCompanyApplicant_applications {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  recruitment: GroupCompanyApplicant_groupCompanyApplicant_applications_recruitment;
}

export interface GroupCompanyApplicant_groupCompanyApplicant_companies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupCompanyApplicant_groupCompanyApplicant_companyApplicants_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupCompanyApplicant_groupCompanyApplicant_companyApplicants {
  __typename: "CompanyApplicant";
  id: string;
  createdAt: DateTime | null;
  company: GroupCompanyApplicant_groupCompanyApplicant_companyApplicants_company;
}

export interface GroupCompanyApplicant_groupCompanyApplicant {
  __typename: "GroupCompanyApplicant";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  tags: GroupCompanyApplicant_groupCompanyApplicant_tags[];
  applications: GroupCompanyApplicant_groupCompanyApplicant_applications[];
  companies: GroupCompanyApplicant_groupCompanyApplicant_companies[];
  companyApplicants: GroupCompanyApplicant_groupCompanyApplicant_companyApplicants[];
}

export interface GroupCompanyApplicant {
  groupCompanyApplicant: GroupCompanyApplicant_groupCompanyApplicant;
}

export interface GroupCompanyApplicantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupCompanyApplicantsTagFilter
// ====================================================

export interface GroupCompanyApplicantsTagFilter_groupCompanyApplicantsTagFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface GroupCompanyApplicantsTagFilter_groupCompanyApplicantsTagFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: GroupCompanyApplicantsTagFilter_groupCompanyApplicantsTagFilter_edges_node;
}

export interface GroupCompanyApplicantsTagFilter_groupCompanyApplicantsTagFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface GroupCompanyApplicantsTagFilter_groupCompanyApplicantsTagFilter {
  __typename: "FilterItemConnection";
  edges: (GroupCompanyApplicantsTagFilter_groupCompanyApplicantsTagFilter_edges | null)[];
  pageInfo: GroupCompanyApplicantsTagFilter_groupCompanyApplicantsTagFilter_pageInfo;
}

export interface GroupCompanyApplicantsTagFilter {
  groupCompanyApplicantsTagFilter: GroupCompanyApplicantsTagFilter_groupCompanyApplicantsTagFilter;
}

export interface GroupCompanyApplicantsTagFilterVariables {
  filters?: GroupCompanyApplicantFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupCompanyApplicantsRecruitmentsFilter
// ====================================================

export interface GroupCompanyApplicantsRecruitmentsFilter_groupCompanyApplicantsRecruitmentsFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface GroupCompanyApplicantsRecruitmentsFilter_groupCompanyApplicantsRecruitmentsFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: GroupCompanyApplicantsRecruitmentsFilter_groupCompanyApplicantsRecruitmentsFilter_edges_node;
}

export interface GroupCompanyApplicantsRecruitmentsFilter_groupCompanyApplicantsRecruitmentsFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface GroupCompanyApplicantsRecruitmentsFilter_groupCompanyApplicantsRecruitmentsFilter {
  __typename: "FilterItemConnection";
  edges: (GroupCompanyApplicantsRecruitmentsFilter_groupCompanyApplicantsRecruitmentsFilter_edges | null)[];
  pageInfo: GroupCompanyApplicantsRecruitmentsFilter_groupCompanyApplicantsRecruitmentsFilter_pageInfo;
}

export interface GroupCompanyApplicantsRecruitmentsFilter {
  groupCompanyApplicantsRecruitmentsFilter: GroupCompanyApplicantsRecruitmentsFilter_groupCompanyApplicantsRecruitmentsFilter;
}

export interface GroupCompanyApplicantsRecruitmentsFilterVariables {
  filters?: GroupCompanyApplicantFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupCompanyApplicantsStudentOptionsFilter
// ====================================================

export interface GroupCompanyApplicantsStudentOptionsFilter_groupCompanyApplicantsStudentOptionsFilter {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface GroupCompanyApplicantsStudentOptionsFilter {
  groupCompanyApplicantsStudentOptionsFilter: GroupCompanyApplicantsStudentOptionsFilter_groupCompanyApplicantsStudentOptionsFilter[];
}

export interface GroupCompanyApplicantsStudentOptionsFilterVariables {
  filters?: GroupCompanyApplicantFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupCompanyApplicantsCompaniesFilter
// ====================================================

export interface GroupCompanyApplicantsCompaniesFilter_groupCompanyApplicantsCompaniesFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface GroupCompanyApplicantsCompaniesFilter_groupCompanyApplicantsCompaniesFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: GroupCompanyApplicantsCompaniesFilter_groupCompanyApplicantsCompaniesFilter_edges_node;
}

export interface GroupCompanyApplicantsCompaniesFilter_groupCompanyApplicantsCompaniesFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface GroupCompanyApplicantsCompaniesFilter_groupCompanyApplicantsCompaniesFilter {
  __typename: "FilterItemConnection";
  edges: (GroupCompanyApplicantsCompaniesFilter_groupCompanyApplicantsCompaniesFilter_edges | null)[];
  pageInfo: GroupCompanyApplicantsCompaniesFilter_groupCompanyApplicantsCompaniesFilter_pageInfo;
}

export interface GroupCompanyApplicantsCompaniesFilter {
  groupCompanyApplicantsCompaniesFilter: GroupCompanyApplicantsCompaniesFilter_groupCompanyApplicantsCompaniesFilter;
}

export interface GroupCompanyApplicantsCompaniesFilterVariables {
  filters?: GroupCompanyApplicantFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupCompanyApplicantsProfessionFieldFilter
// ====================================================

export interface GroupCompanyApplicantsProfessionFieldFilter_groupCompanyApplicantsProfessionFieldFilter {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface GroupCompanyApplicantsProfessionFieldFilter {
  groupCompanyApplicantsProfessionFieldFilter: GroupCompanyApplicantsProfessionFieldFilter_groupCompanyApplicantsProfessionFieldFilter[];
}

export interface GroupCompanyApplicantsProfessionFieldFilterVariables {
  filters?: CompanyApplicantFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IdentifiedNeedForm
// ====================================================

export interface IdentifiedNeedForm_identifiedNeedForm_form_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface IdentifiedNeedForm_identifiedNeedForm_form_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface IdentifiedNeedForm_identifiedNeedForm_form_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: IdentifiedNeedForm_identifiedNeedForm_form_participants_application_companyApplicant | null;
}

export interface IdentifiedNeedForm_identifiedNeedForm_form_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: IdentifiedNeedForm_identifiedNeedForm_form_participants_application | null;
}

export interface IdentifiedNeedForm_identifiedNeedForm_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface IdentifiedNeedForm_identifiedNeedForm_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: IdentifiedNeedForm_identifiedNeedForm_form_questions_matchingEventProfile | null;
}

export interface IdentifiedNeedForm_identifiedNeedForm_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface IdentifiedNeedForm_identifiedNeedForm_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface IdentifiedNeedForm_identifiedNeedForm_form {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: IdentifiedNeedForm_identifiedNeedForm_form_companiesWithAccess[];
  participants: IdentifiedNeedForm_identifiedNeedForm_form_participants[];
  questions: IdentifiedNeedForm_identifiedNeedForm_form_questions[];
  referenceForm: IdentifiedNeedForm_identifiedNeedForm_form_referenceForm | null;
  screeningForm: IdentifiedNeedForm_identifiedNeedForm_form_screeningForm | null;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_question_matchingEventProfile | null;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerCompetences_competence;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers_application {
  __typename: "Application";
  id: string;
  recruitment: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_application_recruitment;
}

export interface IdentifiedNeedForm_identifiedNeedForm_companyAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerFile | null;
  answerDocument: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerDocument | null;
  answerDocuments: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerEducations[] | null;
  answerWorkExperiences: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerWorkExperiences[] | null;
  answerPersonalData: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerPersonalData | null;
  answerCompetences: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_answerCompetences[] | null;
  application: IdentifiedNeedForm_identifiedNeedForm_companyAnswers_application | null;
}

export interface IdentifiedNeedForm_identifiedNeedForm_identifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
  canEdit: boolean | null;
}

export interface IdentifiedNeedForm_identifiedNeedForm {
  __typename: "IdentifiedNeedForm";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  form: IdentifiedNeedForm_identifiedNeedForm_form;
  companyAnswers: IdentifiedNeedForm_identifiedNeedForm_companyAnswers[];
  identifiedNeed: IdentifiedNeedForm_identifiedNeedForm_identifiedNeed | null;
}

export interface IdentifiedNeedForm {
  identifiedNeedForm: IdentifiedNeedForm_identifiedNeedForm | null;
}

export interface IdentifiedNeedFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IdentifiedNeedForms
// ====================================================

export interface IdentifiedNeedForms_identifiedNeedForms_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_form_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_form_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_form_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: IdentifiedNeedForms_identifiedNeedForms_edges_node_form_participants_application_companyApplicant | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_form_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: IdentifiedNeedForms_identifiedNeedForms_edges_node_form_participants_application | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: IdentifiedNeedForms_identifiedNeedForms_edges_node_form_questions_matchingEventProfile | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_form {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: IdentifiedNeedForms_identifiedNeedForms_edges_node_form_companiesWithAccess[];
  participants: IdentifiedNeedForms_identifiedNeedForms_edges_node_form_participants[];
  questions: IdentifiedNeedForms_identifiedNeedForms_edges_node_form_questions[];
  referenceForm: IdentifiedNeedForms_identifiedNeedForms_edges_node_form_referenceForm | null;
  screeningForm: IdentifiedNeedForms_identifiedNeedForms_edges_node_form_screeningForm | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_question_matchingEventProfile | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerCompetences_competence;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_application {
  __typename: "Application";
  id: string;
  recruitment: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_application_recruitment;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerFile | null;
  answerDocument: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerDocument | null;
  answerDocuments: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerEducations[] | null;
  answerWorkExperiences: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerWorkExperiences[] | null;
  answerPersonalData: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerPersonalData | null;
  answerCompetences: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_answerCompetences[] | null;
  application: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers_application | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node_identifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
  canEdit: boolean | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges_node {
  __typename: "IdentifiedNeedForm";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  form: IdentifiedNeedForms_identifiedNeedForms_edges_node_form;
  companyAnswers: IdentifiedNeedForms_identifiedNeedForms_edges_node_companyAnswers[];
  identifiedNeed: IdentifiedNeedForms_identifiedNeedForms_edges_node_identifiedNeed | null;
}

export interface IdentifiedNeedForms_identifiedNeedForms_edges {
  __typename: "IdentifiedNeedFormEdge";
  cursor: string;
  node: IdentifiedNeedForms_identifiedNeedForms_edges_node;
}

export interface IdentifiedNeedForms_identifiedNeedForms {
  __typename: "IdentifiedNeedFormConnection";
  pageInfo: IdentifiedNeedForms_identifiedNeedForms_pageInfo;
  edges: (IdentifiedNeedForms_identifiedNeedForms_edges | null)[];
}

export interface IdentifiedNeedForms {
  identifiedNeedForms: IdentifiedNeedForms_identifiedNeedForms;
}

export interface IdentifiedNeedFormsVariables {
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
  identifiedNeedId?: string | null;
  after?: string | null;
  limit: number;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatedIdentifiedNeeds
// ====================================================

export interface PaginatedIdentifiedNeeds_paginatedIdentifiedNeeds_items_identifiedNeed_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface PaginatedIdentifiedNeeds_paginatedIdentifiedNeeds_items_identifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
  title: string;
  recruitment: PaginatedIdentifiedNeeds_paginatedIdentifiedNeeds_items_identifiedNeed_recruitment | null;
}

export interface PaginatedIdentifiedNeeds_paginatedIdentifiedNeeds_items {
  __typename: "IdentifiedNeedVote";
  id: string;
  createdAt: DateTime;
  approved: boolean | null;
  identifiedNeed: PaginatedIdentifiedNeeds_paginatedIdentifiedNeeds_items_identifiedNeed;
}

export interface PaginatedIdentifiedNeeds_paginatedIdentifiedNeeds {
  __typename: "PaginatedIdentifiedNeeds";
  totalCount: number;
  items: PaginatedIdentifiedNeeds_paginatedIdentifiedNeeds_items[];
}

export interface PaginatedIdentifiedNeeds {
  paginatedIdentifiedNeeds: PaginatedIdentifiedNeeds_paginatedIdentifiedNeeds;
}

export interface PaginatedIdentifiedNeedsVariables {
  companyId?: string | null;
  page?: number | null;
  pageSize?: number | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  status?: IDENTIFIED_NEED_STATUS | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IdentifiedNeed
// ====================================================

export interface IdentifiedNeed_identifiedNeed_votes_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface IdentifiedNeed_identifiedNeed_votes_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  profileImage: IdentifiedNeed_identifiedNeed_votes_user_profileImage | null;
}

export interface IdentifiedNeed_identifiedNeed_votes {
  __typename: "IdentifiedNeedVote";
  id: string;
  user: IdentifiedNeed_identifiedNeed_votes_user;
  approved: boolean | null;
  comment: string | null;
}

export interface IdentifiedNeed_identifiedNeed_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  canView: boolean;
}

export interface IdentifiedNeed_identifiedNeed_participants_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface IdentifiedNeed_identifiedNeed_participants {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: IdentifiedNeed_identifiedNeed_participants_profileImage | null;
}

export interface IdentifiedNeed_identifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string;
  canEdit: boolean | null;
  status: IDENTIFIED_NEED_STATUS;
  votes: IdentifiedNeed_identifiedNeed_votes[];
  recruitment: IdentifiedNeed_identifiedNeed_recruitment | null;
  participants: IdentifiedNeed_identifiedNeed_participants[];
}

export interface IdentifiedNeed {
  identifiedNeed: IdentifiedNeed_identifiedNeed | null;
}

export interface IdentifiedNeedVariables {
  id?: string | null;
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewGuideFromInterview
// ====================================================

export interface InterviewGuideFromInterview_interview_interviewGuide {
  __typename: "InterviewGuide";
  id: string;
  title: string | null;
}

export interface InterviewGuideFromInterview_interview {
  __typename: "Interview";
  id: string;
  title: string;
  description: string | null;
  interviewGuide: InterviewGuideFromInterview_interview_interviewGuide | null;
}

export interface InterviewGuideFromInterview {
  interview: InterviewGuideFromInterview_interview | null;
}

export interface InterviewGuideFromInterviewVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Interview
// ====================================================

export interface Interview_interview_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Interview_interview_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: Interview_interview_application_recruitment;
}

export interface Interview_interview_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Interview_interview_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Interview_interview_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: Interview_interview_users_profileImage | null;
}

export interface Interview_interview {
  __typename: "Interview";
  id: string;
  title: string;
  description: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  application: Interview_interview_application | null;
  applicant: Interview_interview_applicant | null;
  users: Interview_interview_users[];
}

export interface Interview {
  interview: Interview_interview | null;
}

export interface InterviewVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Interviews
// ====================================================

export interface Interviews_interviews_edges_node_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface Interviews_interviews_edges_node_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: Interviews_interviews_edges_node_application_recruitment;
}

export interface Interviews_interviews_edges_node_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Interviews_interviews_edges_node_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: Interviews_interviews_edges_node_users_profileImage | null;
}

export interface Interviews_interviews_edges_node {
  __typename: "Interview";
  id: string;
  title: string;
  description: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  application: Interviews_interviews_edges_node_application | null;
  users: Interviews_interviews_edges_node_users[];
}

export interface Interviews_interviews_edges {
  __typename: "InterviewEdge";
  cursor: string;
  node: Interviews_interviews_edges_node;
}

export interface Interviews_interviews_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface Interviews_interviews {
  __typename: "InterviewConnection";
  edges: (Interviews_interviews_edges | null)[];
  pageInfo: Interviews_interviews_pageInfo;
}

export interface Interviews {
  interviews: Interviews_interviews;
}

export interface InterviewsVariables {
  applicantId?: string | null;
  applicationId?: string | null;
  recruitmentId?: string | null;
  searchText?: string | null;
  after?: string | null;
  limit: number;
  sortBy?: string | null;
  sortOrder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewBookingGroup
// ====================================================

export interface InterviewBookingGroup_interviewBookingGroup_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroup_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface InterviewBookingGroup_interviewBookingGroup_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_application_recruitment;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_user_profileImage | null;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_eventMatch | null;
  application: InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_application | null;
  user: InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroup_interviewBookingGroup_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: InterviewBookingGroup_interviewBookingGroup_bookableInterviews_recruitment | null;
  company: InterviewBookingGroup_interviewBookingGroup_bookableInterviews_company;
  attendees: InterviewBookingGroup_interviewBookingGroup_bookableInterviews_attendees[];
  interviewBookingGroup: InterviewBookingGroup_interviewBookingGroup_bookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: InterviewBookingGroup_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface InterviewBookingGroup_interviewBookingGroup_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroup_interviewBookingGroup_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: InterviewBookingGroup_interviewBookingGroup_users_profileImage | null;
}

export interface InterviewBookingGroup_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string;
  defaultTitle: string | null;
  defaultDescription: string | null;
  defaultLocation: string | null;
  defaultUrl: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: InterviewBookingGroup_interviewBookingGroup_companiesWithAccess[];
  company: InterviewBookingGroup_interviewBookingGroup_company;
  recruitments: InterviewBookingGroup_interviewBookingGroup_recruitments[];
  bookableInterviews: InterviewBookingGroup_interviewBookingGroup_bookableInterviews[];
  users: InterviewBookingGroup_interviewBookingGroup_users[];
}

export interface InterviewBookingGroup {
  interviewBookingGroup: InterviewBookingGroup_interviewBookingGroup | null;
}

export interface InterviewBookingGroupVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewBookingGroups
// ====================================================

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_company {
  __typename: "Company";
  id: string;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_eventMatch_talentProfile;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_application_recruitment;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_user_profileImage | null;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_eventMatch | null;
  application: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_application | null;
  user: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_recruitment | null;
  company: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_company;
  attendees: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_attendees[];
  interviewBookingGroup: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: InterviewBookingGroups_interviewBookingGroups_edges_node_users_profileImage | null;
}

export interface InterviewBookingGroups_interviewBookingGroups_edges_node {
  __typename: "InterviewBookingGroup";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string;
  defaultTitle: string | null;
  defaultDescription: string | null;
  defaultLocation: string | null;
  defaultUrl: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: InterviewBookingGroups_interviewBookingGroups_edges_node_companiesWithAccess[];
  company: InterviewBookingGroups_interviewBookingGroups_edges_node_company;
  recruitments: InterviewBookingGroups_interviewBookingGroups_edges_node_recruitments[];
  bookableInterviews: InterviewBookingGroups_interviewBookingGroups_edges_node_bookableInterviews[];
  users: InterviewBookingGroups_interviewBookingGroups_edges_node_users[];
}

export interface InterviewBookingGroups_interviewBookingGroups_edges {
  __typename: "InterviewBookingGroupEdge";
  cursor: string;
  node: InterviewBookingGroups_interviewBookingGroups_edges_node;
}

export interface InterviewBookingGroups_interviewBookingGroups_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface InterviewBookingGroups_interviewBookingGroups {
  __typename: "InterviewBookingGroupConnection";
  edges: (InterviewBookingGroups_interviewBookingGroups_edges | null)[];
  pageInfo: InterviewBookingGroups_interviewBookingGroups_pageInfo;
}

export interface InterviewBookingGroups {
  interviewBookingGroups: InterviewBookingGroups_interviewBookingGroups;
}

export interface InterviewBookingGroupsVariables {
  companyId: string;
  recruitmentId?: string | null;
  applicationId?: string | null;
  after?: string | null;
  limit?: number | null;
  sortBy?: string | null;
  searchText?: string | null;
  sortOrder?: string | null;
  excludedRecruitmentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewBookingGroupApplication
// ====================================================

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_application_recruitment;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_user_profileImage | null;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_eventMatch | null;
  application: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_application | null;
  user: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_recruitment | null;
  company: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_company;
  attendees: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_attendees[];
  interviewBookingGroup: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_users_profileImage | null;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string;
  defaultTitle: string | null;
  defaultDescription: string | null;
  defaultLocation: string | null;
  defaultUrl: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_companiesWithAccess[];
  company: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_company;
  recruitments: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_recruitments[];
  bookableInterviews: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_bookableInterviews[];
  users: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup_users[];
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_application_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: InterviewBookingGroupApplication_interviewBookingGroupApplication_application_recruitment_company;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: InterviewBookingGroupApplication_interviewBookingGroupApplication_application_companyApplicant | null;
  recruitment: InterviewBookingGroupApplication_interviewBookingGroupApplication_application_recruitment;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_eventMatch_talentProfile;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_application_recruitment;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_user_profileImage | null;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_eventMatch | null;
  application: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_application | null;
  user: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_recruitment | null;
  company: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_company;
  attendees: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_attendees[];
  interviewBookingGroup: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_interviewBookingGroup | null;
  matchingEventTalentProfiles: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface InterviewBookingGroupApplication_interviewBookingGroupApplication {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  createdAt: DateTime;
  interviewBookingGroup: InterviewBookingGroupApplication_interviewBookingGroupApplication_interviewBookingGroup;
  application: InterviewBookingGroupApplication_interviewBookingGroupApplication_application;
  bookedInterview: InterviewBookingGroupApplication_interviewBookingGroupApplication_bookedInterview | null;
  notifiers: DateTime[];
}

export interface InterviewBookingGroupApplication {
  interviewBookingGroupApplication: InterviewBookingGroupApplication_interviewBookingGroupApplication | null;
}

export interface InterviewBookingGroupApplicationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewBookingGroupApplications
// ====================================================

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_application_recruitment;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_user_profileImage | null;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_eventMatch | null;
  application: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_application | null;
  user: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_recruitment | null;
  company: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_company;
  attendees: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_attendees[];
  interviewBookingGroup: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_users_profileImage | null;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string;
  defaultTitle: string | null;
  defaultDescription: string | null;
  defaultLocation: string | null;
  defaultUrl: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_companiesWithAccess[];
  company: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_company;
  recruitments: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_recruitments[];
  bookableInterviews: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_bookableInterviews[];
  users: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup_users[];
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_application_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_application_recruitment_company;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_application_companyApplicant | null;
  recruitment: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_application_recruitment;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_eventMatch_talentProfile;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_application_recruitment;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_user_profileImage | null;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_eventMatch | null;
  application: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_application | null;
  user: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_recruitment | null;
  company: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_company;
  attendees: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_attendees[];
  interviewBookingGroup: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_interviewBookingGroup | null;
  matchingEventTalentProfiles: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  createdAt: DateTime;
  interviewBookingGroup: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_interviewBookingGroup;
  application: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_application;
  bookedInterview: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node_bookedInterview | null;
  notifiers: DateTime[];
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_edges {
  __typename: "InterviewBookingGroupApplicationEdge";
  cursor: string;
  node: InterviewBookingGroupApplications_interviewBookingGroupApplications_edges_node;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface InterviewBookingGroupApplications_interviewBookingGroupApplications {
  __typename: "InterviewBookingGroupApplicationConnection";
  edges: (InterviewBookingGroupApplications_interviewBookingGroupApplications_edges | null)[];
  pageInfo: InterviewBookingGroupApplications_interviewBookingGroupApplications_pageInfo;
}

export interface InterviewBookingGroupApplications {
  interviewBookingGroupApplications: InterviewBookingGroupApplications_interviewBookingGroupApplications;
}

export interface InterviewBookingGroupApplicationsVariables {
  interviewBookingGroupId: string;
  after?: string | null;
  limit?: number | null;
  sortBy?: string | null;
  sortOrder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewBookingGroupUninvitedApplications
// ====================================================

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  listIndex: number | null;
  createdAt: DateTime;
  type: APPLICATION_STATUS_TYPE;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_grades_visitor | null;
  setBy: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_grades_setBy | null;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  company: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_recruitment_company;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_companyApplicant_applications {
  __typename: "Application";
  id: string;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
  email: string;
  applications: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_companyApplicant_applications[];
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_interviewBookingGroups_application {
  __typename: "Application";
  id: string;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_interviewBookingGroups {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  application: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_interviewBookingGroups_application;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_video {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_competences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_competences_competence;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_documents_file;
  type: DOCUMENT_TYPE;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_workExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  approvedForInterview: boolean;
  offeredEmployment: boolean | null;
  latestMessageTime: DateTime | null;
  messagesRead: boolean | null;
  anonymizedWords: (string | null)[];
  isAnonymized: boolean;
  status: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_status | null;
  averageGrade: number;
  grades: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_grades[];
  recruitment: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_recruitment;
  companyApplicant: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_companyApplicant | null;
  interviewBookingGroups: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_interviewBookingGroups[];
  video: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_video | null;
  competences: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_competences[];
  documents: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_documents[];
  freeText: string | null;
  educations: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_educations[];
  workExperiences: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node_workExperiences[];
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges {
  __typename: "ApplicationEdge";
  cursor: string;
  node: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges_node;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications {
  __typename: "ApplicationConnection";
  edges: (InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_edges | null)[];
  pageInfo: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications_pageInfo;
}

export interface InterviewBookingGroupUninvitedApplications {
  interviewBookingGroupUninvitedApplications: InterviewBookingGroupUninvitedApplications_interviewBookingGroupUninvitedApplications;
}

export interface InterviewBookingGroupUninvitedApplicationsVariables {
  interviewBookingGroupId: string;
  recruitmentId: string;
  searchText?: string | null;
  limit?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewBookingGroupUpcomingEventsCount
// ====================================================

export interface InterviewBookingGroupUpcomingEventsCount {
  interviewBookingGroupUpcomingEventsCount: number;
}

export interface InterviewBookingGroupUpcomingEventsCountVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewBookingGroupRecruitments
// ====================================================

export interface InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments_users_user_profileImage | null;
}

export interface InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments_users {
  __typename: "UserRecruitment";
  id: string;
  user: InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments_users_user;
}

export interface InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  status: InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments_status | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  countryCode: string | null;
  isPinned: boolean | null;
  isAnonymized: boolean;
  company: InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments_company;
  users: InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments_users[];
}

export interface InterviewBookingGroupRecruitments {
  interviewBookingGroupRecruitments: InterviewBookingGroupRecruitments_interviewBookingGroupRecruitments[];
}

export interface InterviewBookingGroupRecruitmentsVariables {
  interviewBookingGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BookableInterviews
// ====================================================

export interface BookableInterviews_bookableInterviewsExposed {
  __typename: "CalendarEventExposed";
  id: string;
  createdAt: DateTime | null;
  type: CALENDAR_EVENT_TYPE | null;
  title: string | null;
  description: string | null;
  location: string | null;
  url: string | null;
  startTime: DateTime;
  endTime: DateTime;
}

export interface BookableInterviews {
  bookableInterviewsExposed: BookableInterviews_bookableInterviewsExposed[];
}

export interface BookableInterviewsVariables {
  interviewBookingGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewGuide
// ====================================================

export interface InterviewGuide_interviewGuide_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewGuide_interviewGuide_interviewParts_questions_subQuestions {
  __typename: "InterviewQuestion";
  id: string;
  listIndex: number;
  title: string;
  answer: string | null;
}

export interface InterviewGuide_interviewGuide_interviewParts_questions {
  __typename: "InterviewQuestion";
  id: string;
  listIndex: number;
  title: string;
  category: string | null;
  answer: string | null;
  subQuestions: InterviewGuide_interviewGuide_interviewParts_questions_subQuestions[];
}

export interface InterviewGuide_interviewGuide_interviewParts {
  __typename: "InterviewPart";
  id: string;
  title: string;
  listIndex: number;
  description: string;
  questions: InterviewGuide_interviewGuide_interviewParts_questions[];
}

export interface InterviewGuide_interviewGuide_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewGuide_interviewGuide {
  __typename: "InterviewGuide";
  id: string;
  title: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: InterviewGuide_interviewGuide_companiesWithAccess[];
  interviewParts: InterviewGuide_interviewGuide_interviewParts[];
  recruitment: InterviewGuide_interviewGuide_recruitment | null;
}

export interface InterviewGuide {
  interviewGuide: InterviewGuide_interviewGuide | null;
}

export interface InterviewGuideVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewGuideTemplates
// ====================================================

export interface InterviewGuideTemplates_interviewGuideTemplates_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface InterviewGuideTemplates_interviewGuideTemplates_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewGuideTemplates_interviewGuideTemplates_edges_node_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewGuideTemplates_interviewGuideTemplates_edges_node_interviewParts_questions_subQuestions {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
  listIndex: number;
}

export interface InterviewGuideTemplates_interviewGuideTemplates_edges_node_interviewParts_questions {
  __typename: "InterviewQuestion";
  id: string;
  listIndex: number;
  title: string;
  subQuestions: InterviewGuideTemplates_interviewGuideTemplates_edges_node_interviewParts_questions_subQuestions[];
}

export interface InterviewGuideTemplates_interviewGuideTemplates_edges_node_interviewParts {
  __typename: "InterviewPart";
  id: string;
  title: string;
  listIndex: number;
  description: string;
  questions: InterviewGuideTemplates_interviewGuideTemplates_edges_node_interviewParts_questions[];
}

export interface InterviewGuideTemplates_interviewGuideTemplates_edges_node {
  __typename: "InterviewGuide";
  id: string;
  title: string | null;
  company: InterviewGuideTemplates_interviewGuideTemplates_edges_node_company | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: InterviewGuideTemplates_interviewGuideTemplates_edges_node_companiesWithAccess[];
  interviewParts: InterviewGuideTemplates_interviewGuideTemplates_edges_node_interviewParts[];
}

export interface InterviewGuideTemplates_interviewGuideTemplates_edges {
  __typename: "InterviewGuideEdge";
  cursor: string;
  node: InterviewGuideTemplates_interviewGuideTemplates_edges_node;
}

export interface InterviewGuideTemplates_interviewGuideTemplates {
  __typename: "InterviewGuideConnection";
  pageInfo: InterviewGuideTemplates_interviewGuideTemplates_pageInfo;
  edges: (InterviewGuideTemplates_interviewGuideTemplates_edges | null)[];
}

export interface InterviewGuideTemplates {
  interviewGuideTemplates: InterviewGuideTemplates_interviewGuideTemplates;
}

export interface InterviewGuideTemplatesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
  showResultsFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewPart
// ====================================================

export interface InterviewPart_interviewPart_questions {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
}

export interface InterviewPart_interviewPart {
  __typename: "InterviewPart";
  id: string;
  title: string;
  description: string;
  questions: InterviewPart_interviewPart_questions[];
}

export interface InterviewPart {
  interviewPart: InterviewPart_interviewPart | null;
}

export interface InterviewPartVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompetenceInterviewQuestions
// ====================================================

export interface CompetenceInterviewQuestions_competenceInterviewQuestions_subQuestions {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
  listIndex: number;
}

export interface CompetenceInterviewQuestions_competenceInterviewQuestions {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
  listIndex: number;
  subQuestions: CompetenceInterviewQuestions_competenceInterviewQuestions_subQuestions[];
}

export interface CompetenceInterviewQuestions {
  competenceInterviewQuestions: CompetenceInterviewQuestions_competenceInterviewQuestions[];
}

export interface CompetenceInterviewQuestionsVariables {
  competenceId: string;
  language?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InvoiceInformation
// ====================================================

export interface InvoiceInformation_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
  legalName: string | null;
  organizationNumber: string | null;
  referenceName: string | null;
  referenceNumber: string | null;
  email: string | null;
  city: string | null;
  postalAddress: string | null;
  postalCode: string | null;
  costCenter: string | null;
}

export interface InvoiceInformation {
  invoiceInformation: InvoiceInformation_invoiceInformation | null;
}

export interface InvoiceInformationVariables {
  id?: string | null;
  customerId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ValidateRecruitmentForJobtip
// ====================================================

export interface ValidateRecruitmentForJobtip {
  validateRecruitmentForJobtip: boolean;
}

export interface ValidateRecruitmentForJobtipVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobtipAds
// ====================================================

export interface JobtipAds_jobtipAds_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface JobtipAds_jobtipAds_edges_node_insight_data {
  __typename: "JobtipAdInsight";
  publisher_platform: string | null;
  impressions: number | null;
  reach: number | null;
  clicks: number | null;
  inline_link_clicks: number | null;
}

export interface JobtipAds_jobtipAds_edges_node_insight {
  __typename: "JobtipAdInsightsResponse";
  success: string | null;
  message: string | null;
  uuid: string | null;
  title: string | null;
  company: string | null;
  link: string | null;
  sub_company_id: string | null;
  sub_job_id: string | null;
  starts_at: string | null;
  ends_at: string | null;
  data: (JobtipAds_jobtipAds_edges_node_insight_data | null)[] | null;
}

export interface JobtipAds_jobtipAds_edges_node {
  __typename: "JobtipAd";
  id: string;
  createdAt: DateTime;
  amountPaid: number;
  currency: string;
  creationIndex: number;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  insight: JobtipAds_jobtipAds_edges_node_insight | null;
}

export interface JobtipAds_jobtipAds_edges {
  __typename: "JobtipAdEdge";
  cursor: string;
  node: JobtipAds_jobtipAds_edges_node;
}

export interface JobtipAds_jobtipAds {
  __typename: "JobtipAdConnection";
  pageInfo: JobtipAds_jobtipAds_pageInfo;
  edges: (JobtipAds_jobtipAds_edges | null)[];
}

export interface JobtipAds {
  jobtipAds: JobtipAds_jobtipAds;
}

export interface JobtipAdsVariables {
  recruitmentId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobtipAd
// ====================================================

export interface JobtipAd_jobtipAd_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface JobtipAd_jobtipAd_insight_data {
  __typename: "JobtipAdInsight";
  publisher_platform: string | null;
  impressions: number | null;
  reach: number | null;
  clicks: number | null;
  inline_link_clicks: number | null;
}

export interface JobtipAd_jobtipAd_insight {
  __typename: "JobtipAdInsightsResponse";
  success: string | null;
  message: string | null;
  uuid: string | null;
  title: string | null;
  company: string | null;
  link: string | null;
  sub_company_id: string | null;
  sub_job_id: string | null;
  starts_at: string | null;
  ends_at: string | null;
  data: (JobtipAd_jobtipAd_insight_data | null)[] | null;
}

export interface JobtipAd_jobtipAd {
  __typename: "JobtipAd";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  text: string | null;
  amountPaid: number;
  currency: string;
  creationIndex: number;
  activeFrom: DateTime | null;
  externalId: string | null;
  isSubmitted: boolean;
  activeTo: DateTime | null;
  useStoryImage: boolean;
  recruitment: JobtipAd_jobtipAd_recruitment;
  insight: JobtipAd_jobtipAd_insight | null;
}

export interface JobtipAd {
  jobtipAd: JobtipAd_jobtipAd;
}

export interface JobtipAdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableMatchingEvents
// ====================================================

export interface AvailableMatchingEvents_availableMatchingEvents_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface AvailableMatchingEvents_availableMatchingEvents_edges_node_requests {
  __typename: "MatchingEventCompanyRequest";
  id: string;
  companyName: string;
}

export interface AvailableMatchingEvents_availableMatchingEvents_edges_node_participants {
  __typename: "MatchingEventParticipant";
  id: string;
  companyName: string;
}

export interface AvailableMatchingEvents_availableMatchingEvents_edges_node_hostRecruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface AvailableMatchingEvents_availableMatchingEvents_edges_node_hostRecruitment {
  __typename: "Recruitment";
  id: string;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  applicationCount: number;
  company: AvailableMatchingEvents_availableMatchingEvents_edges_node_hostRecruitment_company;
}

export interface AvailableMatchingEvents_availableMatchingEvents_edges_node {
  __typename: "MatchingEvent";
  id: string;
  createdAt: DateTime;
  title: string;
  requests: AvailableMatchingEvents_availableMatchingEvents_edges_node_requests[];
  participants: AvailableMatchingEvents_availableMatchingEvents_edges_node_participants[];
  hostRecruitment: AvailableMatchingEvents_availableMatchingEvents_edges_node_hostRecruitment;
}

export interface AvailableMatchingEvents_availableMatchingEvents_edges {
  __typename: "MatchingEventEdge";
  cursor: string;
  node: AvailableMatchingEvents_availableMatchingEvents_edges_node;
}

export interface AvailableMatchingEvents_availableMatchingEvents {
  __typename: "MatchingEventConnection";
  pageInfo: AvailableMatchingEvents_availableMatchingEvents_pageInfo;
  edges: (AvailableMatchingEvents_availableMatchingEvents_edges | null)[];
}

export interface AvailableMatchingEvents {
  availableMatchingEvents: AvailableMatchingEvents_availableMatchingEvents;
}

export interface AvailableMatchingEventsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HostedMatchingEvents
// ====================================================

export interface HostedMatchingEvents_hostedMatchingEvents_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface HostedMatchingEvents_hostedMatchingEvents_edges_node_requests {
  __typename: "MatchingEventCompanyRequest";
  id: string;
  companyName: string;
}

export interface HostedMatchingEvents_hostedMatchingEvents_edges_node_participants {
  __typename: "MatchingEventParticipant";
  id: string;
  companyName: string;
}

export interface HostedMatchingEvents_hostedMatchingEvents_edges_node_hostRecruitment_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface HostedMatchingEvents_hostedMatchingEvents_edges_node_hostRecruitment_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: HostedMatchingEvents_hostedMatchingEvents_edges_node_hostRecruitment_users_user_profileImage | null;
}

export interface HostedMatchingEvents_hostedMatchingEvents_edges_node_hostRecruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: HostedMatchingEvents_hostedMatchingEvents_edges_node_hostRecruitment_users_user;
}

export interface HostedMatchingEvents_hostedMatchingEvents_edges_node_hostRecruitment {
  __typename: "Recruitment";
  id: string;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  applicationCount: number;
  users: HostedMatchingEvents_hostedMatchingEvents_edges_node_hostRecruitment_users[];
}

export interface HostedMatchingEvents_hostedMatchingEvents_edges_node {
  __typename: "MatchingEvent";
  id: string;
  createdAt: DateTime;
  title: string;
  requests: HostedMatchingEvents_hostedMatchingEvents_edges_node_requests[];
  participants: HostedMatchingEvents_hostedMatchingEvents_edges_node_participants[];
  hostRecruitment: HostedMatchingEvents_hostedMatchingEvents_edges_node_hostRecruitment;
}

export interface HostedMatchingEvents_hostedMatchingEvents_edges {
  __typename: "MatchingEventEdge";
  cursor: string;
  node: HostedMatchingEvents_hostedMatchingEvents_edges_node;
}

export interface HostedMatchingEvents_hostedMatchingEvents {
  __typename: "MatchingEventConnection";
  pageInfo: HostedMatchingEvents_hostedMatchingEvents_pageInfo;
  edges: (HostedMatchingEvents_hostedMatchingEvents_edges | null)[];
}

export interface HostedMatchingEvents {
  hostedMatchingEvents: HostedMatchingEvents_hostedMatchingEvents;
}

export interface HostedMatchingEventsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  excludedEventId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventInterviewBookingStatus
// ====================================================

export interface MatchingEventInterviewBookingStatus_matchingEvent {
  __typename: "MatchingEvent";
  id: string;
  interviewBookingStatus: MATCHING_EVENT_INTERVIEW_BOOKING_STATUS | null;
}

export interface MatchingEventInterviewBookingStatus {
  matchingEvent: MatchingEventInterviewBookingStatus_matchingEvent | null;
}

export interface MatchingEventInterviewBookingStatusVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEvent
// ====================================================

export interface MatchingEvent_matchingEvent_profiles {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
  description: string | null;
}

export interface MatchingEvent_matchingEvent_participants {
  __typename: "MatchingEventParticipant";
  id: string;
}

export interface MatchingEvent_matchingEvent_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface MatchingEvent_matchingEvent_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: MatchingEvent_matchingEvent_form_questions_matchingEventProfile | null;
}

export interface MatchingEvent_matchingEvent_form {
  __typename: "Form";
  id: string;
  title: string | null;
  type: FORM_TYPE;
  questions: MatchingEvent_matchingEvent_form_questions[];
}

export interface MatchingEvent_matchingEvent_hostRecruitment_form {
  __typename: "Form";
  id: string;
  type: FORM_TYPE;
}

export interface MatchingEvent_matchingEvent_hostRecruitment {
  __typename: "Recruitment";
  id: string;
  publishingStatus: RECRUITMENT_STATUS | null;
  form: MatchingEvent_matchingEvent_hostRecruitment_form | null;
}

export interface MatchingEvent_matchingEvent_hostCompany_childCompanies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEvent_matchingEvent_hostCompany {
  __typename: "Company";
  id: string;
  childCompanies: MatchingEvent_matchingEvent_hostCompany_childCompanies[];
}

export interface MatchingEvent_matchingEvent_middleCompanies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEvent_matchingEvent_criterias {
  __typename: "MatchingCriteria";
  id: string;
  name: string;
}

export interface MatchingEvent_matchingEvent {
  __typename: "MatchingEvent";
  id: string;
  title: string;
  description: string | null;
  descriptionApplication: string | null;
  descriptionParticipant: string | null;
  messageToApplicationOnMatch: string | null;
  messageToParticipantOnMatch: string | null;
  companiesCanAddInterviews: boolean | null;
  enableTrustcruitFeedback: boolean | null;
  status: MATCHING_EVENT_STATUS | null;
  interviewBookingStatus: MATCHING_EVENT_INTERVIEW_BOOKING_STATUS | null;
  flowOrder: MATCHING_EVENT_FLOW_ORDER | null;
  profiles: MatchingEvent_matchingEvent_profiles[];
  participants: MatchingEvent_matchingEvent_participants[];
  form: MatchingEvent_matchingEvent_form | null;
  hostRecruitment: MatchingEvent_matchingEvent_hostRecruitment;
  hostCompany: MatchingEvent_matchingEvent_hostCompany;
  middleCompanies: MatchingEvent_matchingEvent_middleCompanies[];
  criterias: MatchingEvent_matchingEvent_criterias[];
}

export interface MatchingEvent {
  matchingEvent: MatchingEvent_matchingEvent | null;
}

export interface MatchingEventVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventCompanyInvitations
// ====================================================

export interface MatchingEventCompanyInvitations_matchingEventCompanyInvitations_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface MatchingEventCompanyInvitations_matchingEventCompanyInvitations_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventCompanyInvitations_matchingEventCompanyInvitations_edges_node {
  __typename: "MatchingEventCompanyInvitation";
  id: string;
  createdAt: DateTime;
  company: MatchingEventCompanyInvitations_matchingEventCompanyInvitations_edges_node_company;
}

export interface MatchingEventCompanyInvitations_matchingEventCompanyInvitations_edges {
  __typename: "MatchingEventCompanyInvitationEdge";
  cursor: string;
  node: MatchingEventCompanyInvitations_matchingEventCompanyInvitations_edges_node;
}

export interface MatchingEventCompanyInvitations_matchingEventCompanyInvitations {
  __typename: "MatchingEventCompanyInvitationConnection";
  pageInfo: MatchingEventCompanyInvitations_matchingEventCompanyInvitations_pageInfo;
  edges: (MatchingEventCompanyInvitations_matchingEventCompanyInvitations_edges | null)[];
}

export interface MatchingEventCompanyInvitations {
  matchingEventCompanyInvitations: MatchingEventCompanyInvitations_matchingEventCompanyInvitations;
}

export interface MatchingEventCompanyInvitationsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventCompanyRequests
// ====================================================

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_question_matchingEventProfile | null;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerCompetences_competence;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_application {
  __typename: "Application";
  id: string;
  recruitment: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_application_recruitment;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerFile | null;
  answerDocument: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerDocument | null;
  answerDocuments: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerEducations[] | null;
  answerWorkExperiences: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerWorkExperiences[] | null;
  answerPersonalData: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerPersonalData | null;
  answerCompetences: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_answerCompetences[] | null;
  application: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers_application | null;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_selectedGroupCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_events {
  __typename: "MatchingEvent";
  id: string;
  title: string;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node {
  __typename: "MatchingEventCompanyRequest";
  id: string;
  createdAt: DateTime;
  companyName: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  answers: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_answers[] | null;
  selectedGroupCompany: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_selectedGroupCompany;
  events: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node_events[];
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests_edges {
  __typename: "MatchingEventCompanyRequestEdge";
  cursor: string;
  node: MatchingEventCompanyRequests_matchingEventCompanyRequests_edges_node;
}

export interface MatchingEventCompanyRequests_matchingEventCompanyRequests {
  __typename: "MatchingEventCompanyRequestConnection";
  pageInfo: MatchingEventCompanyRequests_matchingEventCompanyRequests_pageInfo;
  edges: (MatchingEventCompanyRequests_matchingEventCompanyRequests_edges | null)[];
}

export interface MatchingEventCompanyRequests {
  matchingEventCompanyRequests: MatchingEventCompanyRequests_matchingEventCompanyRequests;
}

export interface MatchingEventCompanyRequestsVariables {
  eventId?: string | null;
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChildCompanysMatchingEventParticipants
// ====================================================

export interface ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node_connectedTalentProfiles_matches_event {
  __typename: "MatchingEvent";
  id: string;
}

export interface ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node_connectedTalentProfiles_matches {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
  event: ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node_connectedTalentProfiles_matches_event;
}

export interface ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node_connectedTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  matches: ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node_connectedTalentProfiles_matches[];
}

export interface ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node_event_hostRecruitment {
  __typename: "Recruitment";
  id: string;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  publishingStatus: RECRUITMENT_STATUS | null;
}

export interface ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node_event {
  __typename: "MatchingEvent";
  id: string;
  title: string;
  hostRecruitment: ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node_event_hostRecruitment;
}

export interface ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node {
  __typename: "MatchingEventParticipant";
  id: string;
  createdAt: DateTime;
  companyName: string;
  connectedTalentProfiles: ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node_connectedTalentProfiles[];
  event: ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node_event;
}

export interface ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges {
  __typename: "MatchingEventParticipantEdge";
  cursor: string;
  node: ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges_node;
}

export interface ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants {
  __typename: "MatchingEventParticipantConnection";
  pageInfo: ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_pageInfo;
  edges: (ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants_edges | null)[];
}

export interface ChildCompanysMatchingEventParticipants {
  childCompanysMatchingEventParticipants: ChildCompanysMatchingEventParticipants_childCompanysMatchingEventParticipants;
}

export interface ChildCompanysMatchingEventParticipantsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMatchingEventParticipantInterviewBooking
// ====================================================

export interface DeleteMatchingEventParticipantInterviewBooking_deleteMatchingEventParticipantInterviewBooking {
  __typename: "CalendarEvent";
  id: string;
}

export interface DeleteMatchingEventParticipantInterviewBooking {
  deleteMatchingEventParticipantInterviewBooking: DeleteMatchingEventParticipantInterviewBooking_deleteMatchingEventParticipantInterviewBooking | null;
}

export interface DeleteMatchingEventParticipantInterviewBookingVariables {
  id: string;
  notifyMatch?: boolean | null;
  messageFromRecruiter?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventParticipantNote
// ====================================================

export interface MatchingEventParticipantNote_matchingEventParticipant {
  __typename: "MatchingEventParticipant";
  id: string;
  note: string | null;
}

export interface MatchingEventParticipantNote {
  matchingEventParticipant: MatchingEventParticipantNote_matchingEventParticipant | null;
}

export interface MatchingEventParticipantNoteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventParticipant
// ====================================================

export interface MatchingEventParticipant_matchingEventParticipant_answers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: MatchingEventParticipant_matchingEventParticipant_answers_question_matchingEventProfile | null;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: MatchingEventParticipant_matchingEventParticipant_answers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: MatchingEventParticipant_matchingEventParticipant_answers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: MatchingEventParticipant_matchingEventParticipant_answers_answerCompetences_competence;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers_application {
  __typename: "Application";
  id: string;
  recruitment: MatchingEventParticipant_matchingEventParticipant_answers_application_recruitment;
}

export interface MatchingEventParticipant_matchingEventParticipant_answers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: MatchingEventParticipant_matchingEventParticipant_answers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: MatchingEventParticipant_matchingEventParticipant_answers_answerFile | null;
  answerDocument: MatchingEventParticipant_matchingEventParticipant_answers_answerDocument | null;
  answerDocuments: MatchingEventParticipant_matchingEventParticipant_answers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: MatchingEventParticipant_matchingEventParticipant_answers_answerEducations[] | null;
  answerWorkExperiences: MatchingEventParticipant_matchingEventParticipant_answers_answerWorkExperiences[] | null;
  answerPersonalData: MatchingEventParticipant_matchingEventParticipant_answers_answerPersonalData | null;
  answerCompetences: MatchingEventParticipant_matchingEventParticipant_answers_answerCompetences[] | null;
  application: MatchingEventParticipant_matchingEventParticipant_answers_application | null;
}

export interface MatchingEventParticipant_matchingEventParticipant_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventParticipant_matchingEventParticipant_eventMatches {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
}

export interface MatchingEventParticipant_matchingEventParticipant_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
}

export interface MatchingEventParticipant_matchingEventParticipant_event_hostCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventParticipant_matchingEventParticipant_event_hostRecruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventParticipant_matchingEventParticipant_event_profiles {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
  description: string | null;
}

export interface MatchingEventParticipant_matchingEventParticipant_event {
  __typename: "MatchingEvent";
  id: string;
  title: string;
  descriptionParticipant: string | null;
  flowOrder: MATCHING_EVENT_FLOW_ORDER | null;
  interviewBookingStatus: MATCHING_EVENT_INTERVIEW_BOOKING_STATUS | null;
  companiesCanAddInterviews: boolean | null;
  hostCompany: MatchingEventParticipant_matchingEventParticipant_event_hostCompany;
  hostRecruitment: MatchingEventParticipant_matchingEventParticipant_event_hostRecruitment;
  profiles: MatchingEventParticipant_matchingEventParticipant_event_profiles[];
}

export interface MatchingEventParticipant_matchingEventParticipant_connectedTalentProfiles_formQuestionAnswerEvaluations {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  significance: REQUIREMENT_SIGNIFICANCE;
}

export interface MatchingEventParticipant_matchingEventParticipant_connectedTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  formQuestionAnswerEvaluations: MatchingEventParticipant_matchingEventParticipant_connectedTalentProfiles_formQuestionAnswerEvaluations[];
}

export interface MatchingEventParticipant_matchingEventParticipant_chosenCriteriaOptions {
  __typename: "MatchingCriteriaOption";
  id: string;
  name: string;
}

export interface MatchingEventParticipant_matchingEventParticipant {
  __typename: "MatchingEventParticipant";
  id: string;
  createdAt: DateTime;
  companyName: string;
  completedWizardSteps: string[];
  note: string | null;
  answers: MatchingEventParticipant_matchingEventParticipant_answers[] | null;
  company: MatchingEventParticipant_matchingEventParticipant_company;
  eventMatches: MatchingEventParticipant_matchingEventParticipant_eventMatches[];
  bookableInterviews: MatchingEventParticipant_matchingEventParticipant_bookableInterviews[];
  event: MatchingEventParticipant_matchingEventParticipant_event;
  connectedTalentProfiles: MatchingEventParticipant_matchingEventParticipant_connectedTalentProfiles[];
  chosenCriteriaOptions: MatchingEventParticipant_matchingEventParticipant_chosenCriteriaOptions[];
}

export interface MatchingEventParticipant {
  matchingEventParticipant: MatchingEventParticipant_matchingEventParticipant | null;
}

export interface MatchingEventParticipantVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventParticipants
// ====================================================

export interface MatchingEventParticipants_matchingEventParticipants_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface MatchingEventParticipants_matchingEventParticipants_edges_node_connectedTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
  description: string | null;
}

export interface MatchingEventParticipants_matchingEventParticipants_edges_node_company_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface MatchingEventParticipants_matchingEventParticipants_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
  users: MatchingEventParticipants_matchingEventParticipants_edges_node_company_users[];
}

export interface MatchingEventParticipants_matchingEventParticipants_edges_node {
  __typename: "MatchingEventParticipant";
  id: string;
  createdAt: DateTime;
  companyName: string;
  contactPhoneNumber: string | null;
  connectedTalentProfiles: MatchingEventParticipants_matchingEventParticipants_edges_node_connectedTalentProfiles[];
  company: MatchingEventParticipants_matchingEventParticipants_edges_node_company;
}

export interface MatchingEventParticipants_matchingEventParticipants_edges {
  __typename: "MatchingEventParticipantEdge";
  cursor: string;
  node: MatchingEventParticipants_matchingEventParticipants_edges_node;
}

export interface MatchingEventParticipants_matchingEventParticipants {
  __typename: "MatchingEventParticipantConnection";
  pageInfo: MatchingEventParticipants_matchingEventParticipants_pageInfo;
  edges: (MatchingEventParticipants_matchingEventParticipants_edges | null)[];
}

export interface MatchingEventParticipants {
  matchingEventParticipants: MatchingEventParticipants_matchingEventParticipants;
}

export interface MatchingEventParticipantsVariables {
  matchEventId: string;
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventParticipantsWithInterviews
// ====================================================

export interface MatchingEventParticipantsWithInterviews_matchingEventParticipants_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node_connectedTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
  description: string | null;
}

export interface MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node_bookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
}

export interface MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node_bookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node_bookableInterviews_attendees_eventMatch | null;
}

export interface MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  startTime: DateTime;
  endTime: DateTime;
  attendees: MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node_bookableInterviews_attendees[];
}

export interface MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node {
  __typename: "MatchingEventParticipant";
  id: string;
  createdAt: DateTime;
  companyName: string;
  connectedTalentProfiles: MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node_connectedTalentProfiles[];
  bookableInterviews: MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node_bookableInterviews[];
  company: MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node_company;
}

export interface MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges {
  __typename: "MatchingEventParticipantEdge";
  cursor: string;
  node: MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges_node;
}

export interface MatchingEventParticipantsWithInterviews_matchingEventParticipants {
  __typename: "MatchingEventParticipantConnection";
  pageInfo: MatchingEventParticipantsWithInterviews_matchingEventParticipants_pageInfo;
  edges: (MatchingEventParticipantsWithInterviews_matchingEventParticipants_edges | null)[];
}

export interface MatchingEventParticipantsWithInterviews {
  matchingEventParticipants: MatchingEventParticipantsWithInterviews_matchingEventParticipants;
}

export interface MatchingEventParticipantsWithInterviewsVariables {
  matchEventId: string;
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllMatchingEventProfiles
// ====================================================

export interface AllMatchingEventProfiles_allMatchingEventProfiles {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
  createdAt: DateTime;
}

export interface AllMatchingEventProfiles {
  allMatchingEventProfiles: AllMatchingEventProfiles_allMatchingEventProfiles[];
}

export interface AllMatchingEventProfilesVariables {
  companyId: string;
  eventId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventProfile
// ====================================================

export interface MatchingEventProfile_matchingEventProfile_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventProfile_matchingEventProfile_form_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventProfile_matchingEventProfile_form_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface MatchingEventProfile_matchingEventProfile_form_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: MatchingEventProfile_matchingEventProfile_form_participants_application_companyApplicant | null;
}

export interface MatchingEventProfile_matchingEventProfile_form_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: MatchingEventProfile_matchingEventProfile_form_participants_application | null;
}

export interface MatchingEventProfile_matchingEventProfile_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface MatchingEventProfile_matchingEventProfile_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: MatchingEventProfile_matchingEventProfile_form_questions_matchingEventProfile | null;
}

export interface MatchingEventProfile_matchingEventProfile_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface MatchingEventProfile_matchingEventProfile_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface MatchingEventProfile_matchingEventProfile_form {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: MatchingEventProfile_matchingEventProfile_form_companiesWithAccess[];
  participants: MatchingEventProfile_matchingEventProfile_form_participants[];
  questions: MatchingEventProfile_matchingEventProfile_form_questions[];
  referenceForm: MatchingEventProfile_matchingEventProfile_form_referenceForm | null;
  screeningForm: MatchingEventProfile_matchingEventProfile_form_screeningForm | null;
}

export interface MatchingEventProfile_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  company: MatchingEventProfile_matchingEventProfile_company;
  form: MatchingEventProfile_matchingEventProfile_form | null;
}

export interface MatchingEventProfile {
  matchingEventProfile: MatchingEventProfile_matchingEventProfile | null;
}

export interface MatchingEventProfileVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventProfiles
// ====================================================

export interface MatchingEventProfiles_matchingEventProfiles_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface MatchingEventProfiles_matchingEventProfiles_edges_node {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
  createdAt: DateTime;
  description: string | null;
}

export interface MatchingEventProfiles_matchingEventProfiles_edges {
  __typename: "MatchingEventProfileEdge";
  cursor: string;
  node: MatchingEventProfiles_matchingEventProfiles_edges_node;
}

export interface MatchingEventProfiles_matchingEventProfiles {
  __typename: "MatchingEventProfileConnection";
  pageInfo: MatchingEventProfiles_matchingEventProfiles_pageInfo;
  edges: (MatchingEventProfiles_matchingEventProfiles_edges | null)[];
}

export interface MatchingEventProfiles {
  matchingEventProfiles: MatchingEventProfiles_matchingEventProfiles;
}

export interface MatchingEventProfilesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllMatchingEventTalentProfiles
// ====================================================

export interface AllMatchingEventTalentProfiles_allMatchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
  createdAt: DateTime;
}

export interface AllMatchingEventTalentProfiles {
  allMatchingEventTalentProfiles: AllMatchingEventTalentProfiles_allMatchingEventTalentProfiles[];
}

export interface AllMatchingEventTalentProfilesVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventTalentProfile
// ====================================================

export interface MatchingEventTalentProfile_matchingEventTalentProfile_formQuestionAnswerEvaluations {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  significance: REQUIREMENT_SIGNIFICANCE;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_matchingEventParticipants_event {
  __typename: "MatchingEvent";
  id: string;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_matchingEventParticipants {
  __typename: "MatchingEventParticipant";
  id: string;
  event: MatchingEventTalentProfile_matchingEventTalentProfile_matchingEventParticipants_event;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_profiles {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
  description: string | null;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_matches_event {
  __typename: "MatchingEvent";
  id: string;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_matches_application_applicationFormAnswers_question {
  __typename: "FormQuestion";
  id: string;
  question: string | null;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_matches_application_applicationFormAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  answerText: string | null;
  question: MatchingEventTalentProfile_matchingEventTalentProfile_matches_application_applicationFormAnswers_question;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_matches_application_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_matches_application_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: MatchingEventTalentProfile_matchingEventTalentProfile_matches_application_documents_file;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_matches_application_matchingCriteriaOptions {
  __typename: "MatchingCriteriaOption";
  id: string;
  name: string;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_matches_application {
  __typename: "Application";
  id: string;
  freeText: string | null;
  applicationFormAnswers: MatchingEventTalentProfile_matchingEventTalentProfile_matches_application_applicationFormAnswers[];
  documents: MatchingEventTalentProfile_matchingEventTalentProfile_matches_application_documents[];
  matchingCriteriaOptions: MatchingEventTalentProfile_matchingEventTalentProfile_matches_application_matchingCriteriaOptions[];
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_matches {
  __typename: "EventMatch";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  createdManually: boolean | null;
  email: string;
  status: EVENT_MATCH_STATUS;
  matchRank: number | null;
  matchPercentage: number;
  commentFromChatGPT: string | null;
  matchPercentageFromChatGPT: number | null;
  matchPercentageNeedToHave: number;
  matchPercentageNiceToHave: number;
  event: MatchingEventTalentProfile_matchingEventTalentProfile_matches_event;
  application: MatchingEventTalentProfile_matchingEventTalentProfile_matches_application;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventTalentProfile_matchingEventTalentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  formQuestionAnswerEvaluations: MatchingEventTalentProfile_matchingEventTalentProfile_formQuestionAnswerEvaluations[];
  matchingEventParticipants: MatchingEventTalentProfile_matchingEventTalentProfile_matchingEventParticipants[];
  profiles: MatchingEventTalentProfile_matchingEventTalentProfile_profiles[];
  matches: MatchingEventTalentProfile_matchingEventTalentProfile_matches[];
  company: MatchingEventTalentProfile_matchingEventTalentProfile_company;
}

export interface MatchingEventTalentProfile {
  matchingEventTalentProfile: MatchingEventTalentProfile_matchingEventTalentProfile | null;
}

export interface MatchingEventTalentProfileVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventTalentProfiles
// ====================================================

export interface MatchingEventTalentProfiles_matchingEventTalentProfiles_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface MatchingEventTalentProfiles_matchingEventTalentProfiles_edges_node_formQuestionAnswerEvaluations {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  significance: REQUIREMENT_SIGNIFICANCE;
}

export interface MatchingEventTalentProfiles_matchingEventTalentProfiles_edges_node_matches {
  __typename: "EventMatch";
  id: string;
}

export interface MatchingEventTalentProfiles_matchingEventTalentProfiles_edges_node_matchingEventParticipants {
  __typename: "MatchingEventParticipant";
  id: string;
}

export interface MatchingEventTalentProfiles_matchingEventTalentProfiles_edges_node {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
  createdAt: DateTime;
  description: string | null;
  formQuestionAnswerEvaluations: MatchingEventTalentProfiles_matchingEventTalentProfiles_edges_node_formQuestionAnswerEvaluations[];
  matches: MatchingEventTalentProfiles_matchingEventTalentProfiles_edges_node_matches[];
  matchingEventParticipants: MatchingEventTalentProfiles_matchingEventTalentProfiles_edges_node_matchingEventParticipants[];
}

export interface MatchingEventTalentProfiles_matchingEventTalentProfiles_edges {
  __typename: "MatchingEventTalentProfileEdge";
  cursor: string;
  node: MatchingEventTalentProfiles_matchingEventTalentProfiles_edges_node;
}

export interface MatchingEventTalentProfiles_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfileConnection";
  pageInfo: MatchingEventTalentProfiles_matchingEventTalentProfiles_pageInfo;
  edges: (MatchingEventTalentProfiles_matchingEventTalentProfiles_edges | null)[];
}

export interface MatchingEventTalentProfiles {
  matchingEventTalentProfiles: MatchingEventTalentProfiles_matchingEventTalentProfiles;
}

export interface MatchingEventTalentProfilesVariables {
  participantId: string;
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TalentProfilesOfEvent
// ====================================================

export interface TalentProfilesOfEvent_talentProfilesOfEvent_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_formQuestionAnswerEvaluations {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  significance: REQUIREMENT_SIGNIFICANCE;
}

export interface TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_matches_event {
  __typename: "MatchingEvent";
  id: string;
}

export interface TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_matches {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
  createdManually: boolean | null;
  event: TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_matches_event;
}

export interface TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_matchingEventParticipants_event {
  __typename: "MatchingEvent";
  id: string;
}

export interface TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_matchingEventParticipants {
  __typename: "MatchingEventParticipant";
  id: string;
  event: TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_matchingEventParticipants_event;
}

export interface TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_profiles {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface TalentProfilesOfEvent_talentProfilesOfEvent_edges_node {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
  createdAt: DateTime;
  description: string | null;
  participantNotifiedOfMatch: boolean | null;
  formQuestionAnswerEvaluations: TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_formQuestionAnswerEvaluations[];
  company: TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_company;
  matches: TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_matches[];
  matchingEventParticipants: TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_matchingEventParticipants[];
  profiles: TalentProfilesOfEvent_talentProfilesOfEvent_edges_node_profiles[];
}

export interface TalentProfilesOfEvent_talentProfilesOfEvent_edges {
  __typename: "MatchingEventTalentProfileEdge";
  cursor: string;
  node: TalentProfilesOfEvent_talentProfilesOfEvent_edges_node;
}

export interface TalentProfilesOfEvent_talentProfilesOfEvent {
  __typename: "MatchingEventTalentProfileConnection";
  pageInfo: TalentProfilesOfEvent_talentProfilesOfEvent_pageInfo;
  edges: (TalentProfilesOfEvent_talentProfilesOfEvent_edges | null)[];
}

export interface TalentProfilesOfEvent {
  talentProfilesOfEvent: TalentProfilesOfEvent_talentProfilesOfEvent;
}

export interface TalentProfilesOfEventVariables {
  eventId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TalentProfilesOfParticipant
// ====================================================

export interface TalentProfilesOfParticipant_talentProfilesOfParticipant_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_formQuestionAnswerEvaluations {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  significance: REQUIREMENT_SIGNIFICANCE;
}

export interface TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_matches_event {
  __typename: "MatchingEvent";
  id: string;
}

export interface TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_matches {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
  createdManually: boolean | null;
  event: TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_matches_event;
}

export interface TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_matchingEventParticipants_event {
  __typename: "MatchingEvent";
  id: string;
}

export interface TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_matchingEventParticipants {
  __typename: "MatchingEventParticipant";
  id: string;
  event: TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_matchingEventParticipants_event;
}

export interface TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_profiles {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
  createdAt: DateTime;
  description: string | null;
  participantNotifiedOfMatch: boolean | null;
  formQuestionAnswerEvaluations: TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_formQuestionAnswerEvaluations[];
  company: TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_company;
  matches: TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_matches[];
  matchingEventParticipants: TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_matchingEventParticipants[];
  profiles: TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node_profiles[];
}

export interface TalentProfilesOfParticipant_talentProfilesOfParticipant_edges {
  __typename: "MatchingEventTalentProfileEdge";
  cursor: string;
  node: TalentProfilesOfParticipant_talentProfilesOfParticipant_edges_node;
}

export interface TalentProfilesOfParticipant_talentProfilesOfParticipant {
  __typename: "MatchingEventTalentProfileConnection";
  pageInfo: TalentProfilesOfParticipant_talentProfilesOfParticipant_pageInfo;
  edges: (TalentProfilesOfParticipant_talentProfilesOfParticipant_edges | null)[];
}

export interface TalentProfilesOfParticipant {
  talentProfilesOfParticipant: TalentProfilesOfParticipant_talentProfilesOfParticipant;
}

export interface TalentProfilesOfParticipantVariables {
  participantId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaElement
// ====================================================

export interface MediaElement_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface MediaElement_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MediaElement_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: MediaElement_mediaElement_createdBy_profileImage | null;
}

export interface MediaElement_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface MediaElement_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: MediaElement_mediaElement_thumbnail | null;
  createdBy: MediaElement_mediaElement_createdBy;
  tags: MediaElement_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface MediaElement {
  mediaElement: MediaElement_mediaElement;
}

export interface MediaElementVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaElements
// ====================================================

export interface MediaElements_mediaElements_items_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface MediaElements_mediaElements_items_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MediaElements_mediaElements_items_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: MediaElements_mediaElements_items_createdBy_profileImage | null;
}

export interface MediaElements_mediaElements_items_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface MediaElements_mediaElements_items {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: MediaElements_mediaElements_items_thumbnail | null;
  createdBy: MediaElements_mediaElements_items_createdBy;
  tags: MediaElements_mediaElements_items_tags[];
  name: string | null;
  description: string | null;
}

export interface MediaElements_mediaElements {
  __typename: "PaginatedMediaElements";
  totalCount: number;
  items: MediaElements_mediaElements_items[];
}

export interface MediaElements {
  mediaElements: MediaElements_mediaElements;
}

export interface MediaElementsVariables {
  companyId: string;
  page?: number | null;
  pageSize?: number | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  filters?: MediaElementFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaElementsTagsFilter
// ====================================================

export interface MediaElementsTagsFilter_mediaElementsTagsFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface MediaElementsTagsFilter_mediaElementsTagsFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: MediaElementsTagsFilter_mediaElementsTagsFilter_edges_node;
}

export interface MediaElementsTagsFilter_mediaElementsTagsFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface MediaElementsTagsFilter_mediaElementsTagsFilter {
  __typename: "FilterItemConnection";
  edges: (MediaElementsTagsFilter_mediaElementsTagsFilter_edges | null)[];
  pageInfo: MediaElementsTagsFilter_mediaElementsTagsFilter_pageInfo;
}

export interface MediaElementsTagsFilter {
  mediaElementsTagsFilter: MediaElementsTagsFilter_mediaElementsTagsFilter;
}

export interface MediaElementsTagsFilterVariables {
  filters?: MediaElementFilterInput | null;
  companyId?: string | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Media
// ====================================================

export interface Media_media_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface Media_media_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Media_media_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: Media_media_mediaElement_createdBy_profileImage | null;
}

export interface Media_media_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface Media_media_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: Media_media_mediaElement_thumbnail | null;
  createdBy: Media_media_mediaElement_createdBy;
  tags: Media_media_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface Media_media {
  __typename: "Media";
  id: string;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  maxWidth: number | null;
  size: number;
  url: string;
  mediaElement: Media_media_mediaElement;
}

export interface Media {
  media: Media_media | null;
}

export interface MediaVariables {
  id: string;
  width?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MessageTemplate
// ====================================================

export interface MessageTemplate_messageTemplate_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MessageTemplate_messageTemplate {
  __typename: "MessageTemplateWithHtml";
  id: string;
  type: MESSAGE_TYPE;
  templateName: string | null;
  description: string | null;
  isDefault: boolean | null;
  header: string;
  content: string;
  buttonText: string | null;
  buttonLink: string | null;
  html: string | null;
  language: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: MessageTemplate_messageTemplate_companiesWithAccess[];
}

export interface MessageTemplate {
  messageTemplate: MessageTemplate_messageTemplate | null;
}

export interface MessageTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MessageTemplates
// ====================================================

export interface MessageTemplates_messageTemplates_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface MessageTemplates_messageTemplates_edges_node {
  __typename: "MessageTemplate";
  id: string;
  type: MESSAGE_TYPE;
  templateName: string | null;
  description: string | null;
  language: string | null;
  isDefault: boolean | null;
  header: string;
  content: string;
  buttonText: string | null;
  buttonLink: string | null;
}

export interface MessageTemplates_messageTemplates_edges {
  __typename: "MessageTemplateEdge";
  cursor: string;
  node: MessageTemplates_messageTemplates_edges_node;
}

export interface MessageTemplates_messageTemplates {
  __typename: "MessageTemplateConnection";
  pageInfo: MessageTemplates_messageTemplates_pageInfo;
  edges: (MessageTemplates_messageTemplates_edges | null)[];
}

export interface MessageTemplates {
  messageTemplates: MessageTemplates_messageTemplates;
}

export interface MessageTemplatesVariables {
  companyId: string;
  language?: string | null;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MessageTemplatesCustom
// ====================================================

export interface MessageTemplatesCustom_messageTemplatesCustom_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface MessageTemplatesCustom_messageTemplatesCustom_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MessageTemplatesCustom_messageTemplatesCustom_edges_node_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MessageTemplatesCustom_messageTemplatesCustom_edges_node {
  __typename: "MessageTemplate";
  id: string;
  type: MESSAGE_TYPE;
  templateName: string | null;
  description: string | null;
  language: string | null;
  isDefault: boolean | null;
  header: string;
  content: string;
  buttonText: string | null;
  buttonLink: string | null;
  company: MessageTemplatesCustom_messageTemplatesCustom_edges_node_company | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: MessageTemplatesCustom_messageTemplatesCustom_edges_node_companiesWithAccess[];
}

export interface MessageTemplatesCustom_messageTemplatesCustom_edges {
  __typename: "MessageTemplateEdge";
  cursor: string;
  node: MessageTemplatesCustom_messageTemplatesCustom_edges_node;
}

export interface MessageTemplatesCustom_messageTemplatesCustom {
  __typename: "MessageTemplateConnection";
  pageInfo: MessageTemplatesCustom_messageTemplatesCustom_pageInfo;
  edges: (MessageTemplatesCustom_messageTemplatesCustom_edges | null)[];
}

export interface MessageTemplatesCustom {
  messageTemplatesCustom: MessageTemplatesCustom_messageTemplatesCustom;
}

export interface MessageTemplatesCustomVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  showResultsFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PreRenderedMessageTemplate
// ====================================================

export interface PreRenderedMessageTemplate_preRenderedMessageTemplate {
  __typename: "MessageTemplateWithHtml";
  id: string;
  type: MESSAGE_TYPE;
  templateName: string | null;
  isDefault: boolean | null;
  header: string;
  content: string;
  buttonText: string | null;
  buttonLink: string | null;
  html: string | null;
}

export interface PreRenderedMessageTemplate {
  preRenderedMessageTemplate: PreRenderedMessageTemplate_preRenderedMessageTemplate | null;
}

export interface PreRenderedMessageTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Note
// ====================================================

export interface Note_note_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Note_note_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Note_note {
  __typename: "Note";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  text: string | null;
  title: string;
  applicant: Note_note_applicant | null;
  application: Note_note_application | null;
}

export interface Note {
  note: Note_note | null;
}

export interface NoteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Notes
// ====================================================

export interface Notes_notes_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface Notes_notes_edges_node_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface Notes_notes_edges_node_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: Notes_notes_edges_node_application_recruitment;
}

export interface Notes_notes_edges_node_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Notes_notes_edges_node {
  __typename: "Note";
  id: string;
  text: string | null;
  title: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  application: Notes_notes_edges_node_application | null;
  applicant: Notes_notes_edges_node_applicant | null;
}

export interface Notes_notes_edges {
  __typename: "NoteEdge";
  node: Notes_notes_edges_node;
}

export interface Notes_notes {
  __typename: "NoteConnection";
  pageInfo: Notes_notes_pageInfo;
  edges: (Notes_notes_edges | null)[];
}

export interface Notes {
  notes: Notes_notes;
}

export interface NotesVariables {
  limit: number;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  applicationId?: string | null;
  applicantId?: string | null;
  consultantId?: string | null;
  consultantCustomerId?: string | null;
  consultantProjectId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PinnedNotifications
// ====================================================

export interface PinnedNotifications_pinnedNotifications_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface PinnedNotifications_pinnedNotifications_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PinnedNotifications_pinnedNotifications_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: PinnedNotifications_pinnedNotifications_createdBy_profileImage | null;
}

export interface PinnedNotifications_pinnedNotifications_messagingCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PinnedNotifications_pinnedNotifications_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface PinnedNotifications_pinnedNotifications_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface PinnedNotifications_pinnedNotifications_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
}

export interface PinnedNotifications_pinnedNotifications_consultant_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  company: PinnedNotifications_pinnedNotifications_consultant_company;
}

export interface PinnedNotifications_pinnedNotifications_consultantProject_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  title: string;
  company: PinnedNotifications_pinnedNotifications_consultantProject_company;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_form_referenceForm_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_form_referenceForm_recruitment {
  __typename: "Recruitment";
  id: string;
  company: PinnedNotifications_pinnedNotifications_formParticipant_form_referenceForm_recruitment_company;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
  recruitment: PinnedNotifications_pinnedNotifications_formParticipant_form_referenceForm_recruitment | null;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_form_screeningForm_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_form_screeningForm_recruitment {
  __typename: "Recruitment";
  id: string;
  company: PinnedNotifications_pinnedNotifications_formParticipant_form_screeningForm_recruitment_company;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
  recruitment: PinnedNotifications_pinnedNotifications_formParticipant_form_screeningForm_recruitment | null;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_form {
  __typename: "Form";
  id: string;
  referenceForm: PinnedNotifications_pinnedNotifications_formParticipant_form_referenceForm | null;
  screeningForm: PinnedNotifications_pinnedNotifications_formParticipant_form_screeningForm | null;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_reference_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_reference_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: PinnedNotifications_pinnedNotifications_formParticipant_reference_application_recruitment_company;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_reference_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: PinnedNotifications_pinnedNotifications_formParticipant_reference_application_recruitment;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
  application: PinnedNotifications_pinnedNotifications_formParticipant_reference_application | null;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: PinnedNotifications_pinnedNotifications_formParticipant_application_recruitment_company;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: PinnedNotifications_pinnedNotifications_formParticipant_application_recruitment;
}

export interface PinnedNotifications_pinnedNotifications_formParticipant {
  __typename: "FormParticipant";
  id: string;
  form: PinnedNotifications_pinnedNotifications_formParticipant_form;
  reference: PinnedNotifications_pinnedNotifications_formParticipant_reference | null;
  application: PinnedNotifications_pinnedNotifications_formParticipant_application | null;
}

export interface PinnedNotifications_pinnedNotifications_calendarEvent_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_calendarEvent {
  __typename: "CalendarEvent";
  id: string;
  type: CALENDAR_EVENT_TYPE;
  title: string | null;
  startTime: DateTime;
  endTime: DateTime;
  company: PinnedNotifications_pinnedNotifications_calendarEvent_company;
}

export interface PinnedNotifications_pinnedNotifications_comment_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_comment_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: PinnedNotifications_pinnedNotifications_comment_application_recruitment_company;
}

export interface PinnedNotifications_pinnedNotifications_comment_application {
  __typename: "Application";
  id: string;
  recruitment: PinnedNotifications_pinnedNotifications_comment_application_recruitment;
}

export interface PinnedNotifications_pinnedNotifications_comment_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_comment_recruitment {
  __typename: "Recruitment";
  id: string;
  company: PinnedNotifications_pinnedNotifications_comment_recruitment_company;
}

export interface PinnedNotifications_pinnedNotifications_comment_mentions {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface PinnedNotifications_pinnedNotifications_comment {
  __typename: "Comment";
  id: string;
  text: string;
  application: PinnedNotifications_pinnedNotifications_comment_application | null;
  recruitment: PinnedNotifications_pinnedNotifications_comment_recruitment | null;
  mentions: PinnedNotifications_pinnedNotifications_comment_mentions[];
}

export interface PinnedNotifications_pinnedNotifications_assignment_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_assignment {
  __typename: "Assignment";
  id: string;
  title: string;
  company: PinnedNotifications_pinnedNotifications_assignment_company;
}

export interface PinnedNotifications_pinnedNotifications_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: PinnedNotifications_pinnedNotifications_recruitment_company;
}

export interface PinnedNotifications_pinnedNotifications_interview_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface PinnedNotifications_pinnedNotifications_interview_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_interview_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: PinnedNotifications_pinnedNotifications_interview_application_recruitment_company;
}

export interface PinnedNotifications_pinnedNotifications_interview_application {
  __typename: "Application";
  id: string;
  recruitment: PinnedNotifications_pinnedNotifications_interview_application_recruitment;
}

export interface PinnedNotifications_pinnedNotifications_interview {
  __typename: "Interview";
  id: string;
  title: string;
  applicant: PinnedNotifications_pinnedNotifications_interview_applicant | null;
  application: PinnedNotifications_pinnedNotifications_interview_application | null;
}

export interface PinnedNotifications_pinnedNotifications_identifiedNeed_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_identifiedNeed_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: PinnedNotifications_pinnedNotifications_identifiedNeed_recruitment_company;
}

export interface PinnedNotifications_pinnedNotifications_identifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
  title: string;
  recruitment: PinnedNotifications_pinnedNotifications_identifiedNeed_recruitment | null;
}

export interface PinnedNotifications_pinnedNotifications_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface PinnedNotifications_pinnedNotifications_interviewBookingGroupApplication_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface PinnedNotifications_pinnedNotifications_interviewBookingGroupApplication {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  application: PinnedNotifications_pinnedNotifications_interviewBookingGroupApplication_application;
}

export interface PinnedNotifications_pinnedNotifications_applicantMessage_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_applicantMessage_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: PinnedNotifications_pinnedNotifications_applicantMessage_application_recruitment_company;
}

export interface PinnedNotifications_pinnedNotifications_applicantMessage_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: PinnedNotifications_pinnedNotifications_applicantMessage_application_recruitment;
}

export interface PinnedNotifications_pinnedNotifications_applicantMessage_applicant_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_applicantMessage_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: PinnedNotifications_pinnedNotifications_applicantMessage_applicant_company;
}

export interface PinnedNotifications_pinnedNotifications_applicantMessage {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  application: PinnedNotifications_pinnedNotifications_applicantMessage_application | null;
  applicant: PinnedNotifications_pinnedNotifications_applicantMessage_applicant | null;
}

export interface PinnedNotifications_pinnedNotifications_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface PinnedNotifications_pinnedNotifications_status {
  __typename: "Status";
  id: string;
  title: string;
}

export interface PinnedNotifications_pinnedNotifications_applicant_company {
  __typename: "Company";
  id: string;
}

export interface PinnedNotifications_pinnedNotifications_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: PinnedNotifications_pinnedNotifications_applicant_company;
}

export interface PinnedNotifications_pinnedNotifications {
  __typename: "Notification";
  id: string;
  type: NOTIFICATION_TYPE;
  createdAt: DateTime;
  isRead: boolean;
  isPinned: boolean | null;
  user: PinnedNotifications_pinnedNotifications_user;
  createdBy: PinnedNotifications_pinnedNotifications_createdBy | null;
  content: string | null;
  messagingCompany: PinnedNotifications_pinnedNotifications_messagingCompany | null;
  screeningForm: PinnedNotifications_pinnedNotifications_screeningForm | null;
  referenceForm: PinnedNotifications_pinnedNotifications_referenceForm | null;
  reference: PinnedNotifications_pinnedNotifications_reference | null;
  consultant: PinnedNotifications_pinnedNotifications_consultant | null;
  consultantProject: PinnedNotifications_pinnedNotifications_consultantProject | null;
  formParticipant: PinnedNotifications_pinnedNotifications_formParticipant | null;
  calendarEvent: PinnedNotifications_pinnedNotifications_calendarEvent | null;
  comment: PinnedNotifications_pinnedNotifications_comment | null;
  assignment: PinnedNotifications_pinnedNotifications_assignment | null;
  recruitment: PinnedNotifications_pinnedNotifications_recruitment | null;
  interview: PinnedNotifications_pinnedNotifications_interview | null;
  identifiedNeed: PinnedNotifications_pinnedNotifications_identifiedNeed | null;
  interviewBookingGroup: PinnedNotifications_pinnedNotifications_interviewBookingGroup | null;
  interviewBookingGroupApplication: PinnedNotifications_pinnedNotifications_interviewBookingGroupApplication | null;
  applicantMessage: PinnedNotifications_pinnedNotifications_applicantMessage | null;
  application: PinnedNotifications_pinnedNotifications_application | null;
  status: PinnedNotifications_pinnedNotifications_status | null;
  applicant: PinnedNotifications_pinnedNotifications_applicant | null;
}

export interface PinnedNotifications {
  pinnedNotifications: PinnedNotifications_pinnedNotifications[];
}

export interface PinnedNotificationsVariables {
  types?: NOTIFICATION_TYPE[] | null;
  excludedTypes?: NOTIFICATION_TYPE[] | null;
  status?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Notifications
// ====================================================

export interface Notifications_notifications_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface Notifications_notifications_edges_node_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Notifications_notifications_edges_node_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Notifications_notifications_edges_node_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: Notifications_notifications_edges_node_createdBy_profileImage | null;
}

export interface Notifications_notifications_edges_node_messagingCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Notifications_notifications_edges_node_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface Notifications_notifications_edges_node_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface Notifications_notifications_edges_node_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Notifications_notifications_edges_node_consultant_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  company: Notifications_notifications_edges_node_consultant_company;
}

export interface Notifications_notifications_edges_node_consultantProject_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  title: string;
  company: Notifications_notifications_edges_node_consultantProject_company;
}

export interface Notifications_notifications_edges_node_formParticipant_form_referenceForm_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_formParticipant_form_referenceForm_recruitment {
  __typename: "Recruitment";
  id: string;
  company: Notifications_notifications_edges_node_formParticipant_form_referenceForm_recruitment_company;
}

export interface Notifications_notifications_edges_node_formParticipant_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
  recruitment: Notifications_notifications_edges_node_formParticipant_form_referenceForm_recruitment | null;
}

export interface Notifications_notifications_edges_node_formParticipant_form_screeningForm_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_formParticipant_form_screeningForm_recruitment {
  __typename: "Recruitment";
  id: string;
  company: Notifications_notifications_edges_node_formParticipant_form_screeningForm_recruitment_company;
}

export interface Notifications_notifications_edges_node_formParticipant_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
  recruitment: Notifications_notifications_edges_node_formParticipant_form_screeningForm_recruitment | null;
}

export interface Notifications_notifications_edges_node_formParticipant_form {
  __typename: "Form";
  id: string;
  referenceForm: Notifications_notifications_edges_node_formParticipant_form_referenceForm | null;
  screeningForm: Notifications_notifications_edges_node_formParticipant_form_screeningForm | null;
}

export interface Notifications_notifications_edges_node_formParticipant_reference_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_formParticipant_reference_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: Notifications_notifications_edges_node_formParticipant_reference_application_recruitment_company;
}

export interface Notifications_notifications_edges_node_formParticipant_reference_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: Notifications_notifications_edges_node_formParticipant_reference_application_recruitment;
}

export interface Notifications_notifications_edges_node_formParticipant_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
  application: Notifications_notifications_edges_node_formParticipant_reference_application | null;
}

export interface Notifications_notifications_edges_node_formParticipant_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_formParticipant_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: Notifications_notifications_edges_node_formParticipant_application_recruitment_company;
}

export interface Notifications_notifications_edges_node_formParticipant_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: Notifications_notifications_edges_node_formParticipant_application_recruitment;
}

export interface Notifications_notifications_edges_node_formParticipant {
  __typename: "FormParticipant";
  id: string;
  form: Notifications_notifications_edges_node_formParticipant_form;
  reference: Notifications_notifications_edges_node_formParticipant_reference | null;
  application: Notifications_notifications_edges_node_formParticipant_application | null;
}

export interface Notifications_notifications_edges_node_calendarEvent_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_calendarEvent {
  __typename: "CalendarEvent";
  id: string;
  type: CALENDAR_EVENT_TYPE;
  title: string | null;
  startTime: DateTime;
  endTime: DateTime;
  company: Notifications_notifications_edges_node_calendarEvent_company;
}

export interface Notifications_notifications_edges_node_comment_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_comment_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: Notifications_notifications_edges_node_comment_application_recruitment_company;
}

export interface Notifications_notifications_edges_node_comment_application {
  __typename: "Application";
  id: string;
  recruitment: Notifications_notifications_edges_node_comment_application_recruitment;
}

export interface Notifications_notifications_edges_node_comment_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_comment_recruitment {
  __typename: "Recruitment";
  id: string;
  company: Notifications_notifications_edges_node_comment_recruitment_company;
}

export interface Notifications_notifications_edges_node_comment_mentions {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Notifications_notifications_edges_node_comment {
  __typename: "Comment";
  id: string;
  text: string;
  application: Notifications_notifications_edges_node_comment_application | null;
  recruitment: Notifications_notifications_edges_node_comment_recruitment | null;
  mentions: Notifications_notifications_edges_node_comment_mentions[];
}

export interface Notifications_notifications_edges_node_assignment_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_assignment {
  __typename: "Assignment";
  id: string;
  title: string;
  company: Notifications_notifications_edges_node_assignment_company;
}

export interface Notifications_notifications_edges_node_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: Notifications_notifications_edges_node_recruitment_company;
}

export interface Notifications_notifications_edges_node_interview_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Notifications_notifications_edges_node_interview_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_interview_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: Notifications_notifications_edges_node_interview_application_recruitment_company;
}

export interface Notifications_notifications_edges_node_interview_application {
  __typename: "Application";
  id: string;
  recruitment: Notifications_notifications_edges_node_interview_application_recruitment;
}

export interface Notifications_notifications_edges_node_interview {
  __typename: "Interview";
  id: string;
  title: string;
  applicant: Notifications_notifications_edges_node_interview_applicant | null;
  application: Notifications_notifications_edges_node_interview_application | null;
}

export interface Notifications_notifications_edges_node_identifiedNeed_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_identifiedNeed_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: Notifications_notifications_edges_node_identifiedNeed_recruitment_company;
}

export interface Notifications_notifications_edges_node_identifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
  title: string;
  recruitment: Notifications_notifications_edges_node_identifiedNeed_recruitment | null;
}

export interface Notifications_notifications_edges_node_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface Notifications_notifications_edges_node_interviewBookingGroupApplication_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Notifications_notifications_edges_node_interviewBookingGroupApplication {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  application: Notifications_notifications_edges_node_interviewBookingGroupApplication_application;
}

export interface Notifications_notifications_edges_node_applicantMessage_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_applicantMessage_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: Notifications_notifications_edges_node_applicantMessage_application_recruitment_company;
}

export interface Notifications_notifications_edges_node_applicantMessage_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: Notifications_notifications_edges_node_applicantMessage_application_recruitment;
}

export interface Notifications_notifications_edges_node_applicantMessage_applicant_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_applicantMessage_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: Notifications_notifications_edges_node_applicantMessage_applicant_company;
}

export interface Notifications_notifications_edges_node_applicantMessage {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  application: Notifications_notifications_edges_node_applicantMessage_application | null;
  applicant: Notifications_notifications_edges_node_applicantMessage_applicant | null;
}

export interface Notifications_notifications_edges_node_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface Notifications_notifications_edges_node_status {
  __typename: "Status";
  id: string;
  title: string;
}

export interface Notifications_notifications_edges_node_applicant_company {
  __typename: "Company";
  id: string;
}

export interface Notifications_notifications_edges_node_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: Notifications_notifications_edges_node_applicant_company;
}

export interface Notifications_notifications_edges_node {
  __typename: "Notification";
  id: string;
  type: NOTIFICATION_TYPE;
  createdAt: DateTime;
  isRead: boolean;
  isPinned: boolean | null;
  user: Notifications_notifications_edges_node_user;
  createdBy: Notifications_notifications_edges_node_createdBy | null;
  content: string | null;
  messagingCompany: Notifications_notifications_edges_node_messagingCompany | null;
  screeningForm: Notifications_notifications_edges_node_screeningForm | null;
  referenceForm: Notifications_notifications_edges_node_referenceForm | null;
  reference: Notifications_notifications_edges_node_reference | null;
  consultant: Notifications_notifications_edges_node_consultant | null;
  consultantProject: Notifications_notifications_edges_node_consultantProject | null;
  formParticipant: Notifications_notifications_edges_node_formParticipant | null;
  calendarEvent: Notifications_notifications_edges_node_calendarEvent | null;
  comment: Notifications_notifications_edges_node_comment | null;
  assignment: Notifications_notifications_edges_node_assignment | null;
  recruitment: Notifications_notifications_edges_node_recruitment | null;
  interview: Notifications_notifications_edges_node_interview | null;
  identifiedNeed: Notifications_notifications_edges_node_identifiedNeed | null;
  interviewBookingGroup: Notifications_notifications_edges_node_interviewBookingGroup | null;
  interviewBookingGroupApplication: Notifications_notifications_edges_node_interviewBookingGroupApplication | null;
  applicantMessage: Notifications_notifications_edges_node_applicantMessage | null;
  application: Notifications_notifications_edges_node_application | null;
  status: Notifications_notifications_edges_node_status | null;
  applicant: Notifications_notifications_edges_node_applicant | null;
}

export interface Notifications_notifications_edges {
  __typename: "NotificationEdge";
  node: Notifications_notifications_edges_node;
}

export interface Notifications_notifications {
  __typename: "NotificationConnection";
  pageInfo: Notifications_notifications_pageInfo;
  edges: (Notifications_notifications_edges | null)[];
}

export interface Notifications {
  notifications: Notifications_notifications;
}

export interface NotificationsVariables {
  limit: number;
  after?: string | null;
  status?: string | null;
  types?: NOTIFICATION_TYPE[] | null;
  excludedTypes?: NOTIFICATION_TYPE[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UnreadNotifications
// ====================================================

export interface UnreadNotifications {
  unreadNotifications: number;
}

export interface UnreadNotificationsVariables {
  type?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyCareerPage
// ====================================================

export interface CompanyCareerPage_companyCareerPage_versions {
  __typename: "PageVersion";
  id: string;
}

export interface CompanyCareerPage_companyCareerPage_publishedVersion {
  __typename: "PageVersion";
  id: string;
}

export interface CompanyCareerPage_companyCareerPage {
  __typename: "Page";
  id: string;
  slug: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  versions: CompanyCareerPage_companyCareerPage_versions[];
  publishedVersion: CompanyCareerPage_companyCareerPage_publishedVersion | null;
}

export interface CompanyCareerPage {
  companyCareerPage: CompanyCareerPage_companyCareerPage | null;
}

export interface CompanyCareerPageVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Page
// ====================================================

export interface Page_page_versions {
  __typename: "PageVersion";
  id: string;
}

export interface Page_page_publishedVersion {
  __typename: "PageVersion";
  id: string;
}

export interface Page_page {
  __typename: "Page";
  id: string;
  slug: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  versions: Page_page_versions[];
  publishedVersion: Page_page_publishedVersion | null;
}

export interface Page {
  page: Page_page | null;
}

export interface PageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PageVersion
// ====================================================

export interface PageVersion_pageVersion_blocks_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersion_pageVersion_blocks_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersion_pageVersion_blocks_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersion_pageVersion_blocks_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface PageVersion_pageVersion_blocks_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersion_pageVersion_blocks_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersion_pageVersion_blocks_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: PageVersion_pageVersion_blocks_backgroundMedia_mediaElement_createdBy;
  tags: PageVersion_pageVersion_blocks_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersion_pageVersion_blocks_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersion_pageVersion_blocks_backgroundMedia_mediaElement;
}

export interface PageVersion_pageVersion_blocks_pageContent_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersion_pageVersion_blocks_pageContent_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersion_pageVersion_blocks_pageContent_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersion_pageVersion_blocks_pageContent_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageVersion_pageVersion_blocks_pageContent_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersion_pageVersion_blocks_pageContent_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersion_pageVersion_blocks_pageContent_medias_mediaElement_thumbnail | null;
  createdBy: PageVersion_pageVersion_blocks_pageContent_medias_mediaElement_createdBy;
  tags: PageVersion_pageVersion_blocks_pageContent_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersion_pageVersion_blocks_pageContent_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersion_pageVersion_blocks_pageContent_medias_mediaElement;
}

export interface PageVersion_pageVersion_blocks_pageContent {
  __typename: "PageContent";
  id: string;
  content: JSON | null;
  medias: PageVersion_pageVersion_blocks_pageContent_medias[];
}

export interface PageVersion_pageVersion_blocks_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersion_pageVersion_blocks_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersion_pageVersion_blocks_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersion_pageVersion_blocks_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageVersion_pageVersion_blocks_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersion_pageVersion_blocks_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersion_pageVersion_blocks_medias_mediaElement_thumbnail | null;
  createdBy: PageVersion_pageVersion_blocks_medias_mediaElement_createdBy;
  tags: PageVersion_pageVersion_blocks_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersion_pageVersion_blocks_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersion_pageVersion_blocks_medias_mediaElement;
}

export interface PageVersion_pageVersion_blocks_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersion_pageVersion_blocks_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersion_pageVersion_blocks_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersion_pageVersion_blocks_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface PageVersion_pageVersion_blocks_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersion_pageVersion_blocks_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersion_pageVersion_blocks_inlineMedias_mediaElement_thumbnail | null;
  createdBy: PageVersion_pageVersion_blocks_inlineMedias_mediaElement_createdBy;
  tags: PageVersion_pageVersion_blocks_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersion_pageVersion_blocks_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersion_pageVersion_blocks_inlineMedias_mediaElement;
}

export interface PageVersion_pageVersion_blocks_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface PageVersion_pageVersion_blocks_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface PageVersion_pageVersion_blocks_columns_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersion_pageVersion_blocks_columns_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersion_pageVersion_blocks_columns_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersion_pageVersion_blocks_columns_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface PageVersion_pageVersion_blocks_columns_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersion_pageVersion_blocks_columns_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersion_pageVersion_blocks_columns_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: PageVersion_pageVersion_blocks_columns_backgroundMedia_mediaElement_createdBy;
  tags: PageVersion_pageVersion_blocks_columns_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersion_pageVersion_blocks_columns_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersion_pageVersion_blocks_columns_backgroundMedia_mediaElement;
}

export interface PageVersion_pageVersion_blocks_columns_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersion_pageVersion_blocks_columns_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersion_pageVersion_blocks_columns_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersion_pageVersion_blocks_columns_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageVersion_pageVersion_blocks_columns_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersion_pageVersion_blocks_columns_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersion_pageVersion_blocks_columns_medias_mediaElement_thumbnail | null;
  createdBy: PageVersion_pageVersion_blocks_columns_medias_mediaElement_createdBy;
  tags: PageVersion_pageVersion_blocks_columns_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersion_pageVersion_blocks_columns_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersion_pageVersion_blocks_columns_medias_mediaElement;
}

export interface PageVersion_pageVersion_blocks_columns_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersion_pageVersion_blocks_columns_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersion_pageVersion_blocks_columns_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersion_pageVersion_blocks_columns_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface PageVersion_pageVersion_blocks_columns_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersion_pageVersion_blocks_columns_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersion_pageVersion_blocks_columns_inlineMedias_mediaElement_thumbnail | null;
  createdBy: PageVersion_pageVersion_blocks_columns_inlineMedias_mediaElement_createdBy;
  tags: PageVersion_pageVersion_blocks_columns_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersion_pageVersion_blocks_columns_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersion_pageVersion_blocks_columns_inlineMedias_mediaElement;
}

export interface PageVersion_pageVersion_blocks_columns_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface PageVersion_pageVersion_blocks_columns_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface PageVersion_pageVersion_blocks_columns {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: PageVersion_pageVersion_blocks_columns_backgroundMedia | null;
  medias: PageVersion_pageVersion_blocks_columns_medias[];
  inlineMedias: PageVersion_pageVersion_blocks_columns_inlineMedias[];
  parent: PageVersion_pageVersion_blocks_columns_parent | null;
  themeVariables: PageVersion_pageVersion_blocks_columns_themeVariables;
}

export interface PageVersion_pageVersion_blocks {
  __typename: "PageBlock";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  isTemplate: boolean;
  name: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  blockId: string | null;
  heightType: PAGE_BLOCK_HEIGHT_TYPE;
  fixedHeight: number;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: PageVersion_pageVersion_blocks_backgroundMedia | null;
  pageContent: PageVersion_pageVersion_blocks_pageContent;
  medias: PageVersion_pageVersion_blocks_medias[];
  inlineMedias: PageVersion_pageVersion_blocks_inlineMedias[];
  themeVariables: PageVersion_pageVersion_blocks_themeVariables;
  parent: PageVersion_pageVersion_blocks_parent | null;
  columns: PageVersion_pageVersion_blocks_columns[];
}

export interface PageVersion_pageVersion_metaInfo_ogImage_image_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersion_pageVersion_metaInfo_ogImage_image_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersion_pageVersion_metaInfo_ogImage_image_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersion_pageVersion_metaInfo_ogImage_image_mediaElement_createdBy_profileImage | null;
}

export interface PageVersion_pageVersion_metaInfo_ogImage_image_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersion_pageVersion_metaInfo_ogImage_image_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersion_pageVersion_metaInfo_ogImage_image_mediaElement_thumbnail | null;
  createdBy: PageVersion_pageVersion_metaInfo_ogImage_image_mediaElement_createdBy;
  tags: PageVersion_pageVersion_metaInfo_ogImage_image_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersion_pageVersion_metaInfo_ogImage_image {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersion_pageVersion_metaInfo_ogImage_image_mediaElement;
}

export interface PageVersion_pageVersion_metaInfo_ogImage {
  __typename: "SharedImage";
  id: string;
  createdAt: DateTime;
  title: string | null;
  subtitle: string | null;
  titleColor: string | null;
  titleFont: string | null;
  subtitleFont: string | null;
  subtitleColor: string | null;
  backgroundColor: string | null;
  backgroundType: SHARED_IMAGE_BACKGROUND_TYPE;
  width: number | null;
  height: number | null;
  image: PageVersion_pageVersion_metaInfo_ogImage_image | null;
}

export interface PageVersion_pageVersion_metaInfo {
  __typename: "PageMetaInfo";
  id: string;
  pageTitle: string;
  ogTitle: string;
  ogDescription: string;
  ogSiteName: string;
  ogImage: PageVersion_pageVersion_metaInfo_ogImage;
  facebookUrl: string | null;
  twitterUrl: string | null;
  instagramUrl: string | null;
  linkedinUrl: string | null;
  youtubeUrl: string | null;
}

export interface PageVersion_pageVersion_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface PageVersion_pageVersion_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersion_pageVersion_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: PageVersion_pageVersion_createdBy_profileImage | null;
}

export interface PageVersion_pageVersion {
  __typename: "PageVersion";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  published: boolean;
  customCss: string | null;
  blocks: PageVersion_pageVersion_blocks[];
  metaInfo: PageVersion_pageVersion_metaInfo;
  themeVariables: PageVersion_pageVersion_themeVariables;
  createdBy: PageVersion_pageVersion_createdBy;
}

export interface PageVersion {
  pageVersion: PageVersion_pageVersion | null;
}

export interface PageVersionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActivePageVersion
// ====================================================

export interface ActivePageVersion_activePageVersion_blocks_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface ActivePageVersion_activePageVersion_blocks_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ActivePageVersion_activePageVersion_blocks_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface ActivePageVersion_activePageVersion_blocks_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface ActivePageVersion_activePageVersion_blocks_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: ActivePageVersion_activePageVersion_blocks_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: ActivePageVersion_activePageVersion_blocks_backgroundMedia_mediaElement_createdBy;
  tags: ActivePageVersion_activePageVersion_blocks_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: ActivePageVersion_activePageVersion_blocks_backgroundMedia_mediaElement;
}

export interface ActivePageVersion_activePageVersion_blocks_pageContent_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface ActivePageVersion_activePageVersion_blocks_pageContent_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_pageContent_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ActivePageVersion_activePageVersion_blocks_pageContent_medias_mediaElement_createdBy_profileImage | null;
}

export interface ActivePageVersion_activePageVersion_blocks_pageContent_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface ActivePageVersion_activePageVersion_blocks_pageContent_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: ActivePageVersion_activePageVersion_blocks_pageContent_medias_mediaElement_thumbnail | null;
  createdBy: ActivePageVersion_activePageVersion_blocks_pageContent_medias_mediaElement_createdBy;
  tags: ActivePageVersion_activePageVersion_blocks_pageContent_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_pageContent_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: ActivePageVersion_activePageVersion_blocks_pageContent_medias_mediaElement;
}

export interface ActivePageVersion_activePageVersion_blocks_pageContent {
  __typename: "PageContent";
  id: string;
  content: JSON | null;
  medias: ActivePageVersion_activePageVersion_blocks_pageContent_medias[];
}

export interface ActivePageVersion_activePageVersion_blocks_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface ActivePageVersion_activePageVersion_blocks_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ActivePageVersion_activePageVersion_blocks_medias_mediaElement_createdBy_profileImage | null;
}

export interface ActivePageVersion_activePageVersion_blocks_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface ActivePageVersion_activePageVersion_blocks_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: ActivePageVersion_activePageVersion_blocks_medias_mediaElement_thumbnail | null;
  createdBy: ActivePageVersion_activePageVersion_blocks_medias_mediaElement_createdBy;
  tags: ActivePageVersion_activePageVersion_blocks_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: ActivePageVersion_activePageVersion_blocks_medias_mediaElement;
}

export interface ActivePageVersion_activePageVersion_blocks_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface ActivePageVersion_activePageVersion_blocks_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ActivePageVersion_activePageVersion_blocks_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface ActivePageVersion_activePageVersion_blocks_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface ActivePageVersion_activePageVersion_blocks_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: ActivePageVersion_activePageVersion_blocks_inlineMedias_mediaElement_thumbnail | null;
  createdBy: ActivePageVersion_activePageVersion_blocks_inlineMedias_mediaElement_createdBy;
  tags: ActivePageVersion_activePageVersion_blocks_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: ActivePageVersion_activePageVersion_blocks_inlineMedias_mediaElement;
}

export interface ActivePageVersion_activePageVersion_blocks_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia_mediaElement_createdBy;
  tags: ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia_mediaElement;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ActivePageVersion_activePageVersion_blocks_columns_medias_mediaElement_createdBy_profileImage | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: ActivePageVersion_activePageVersion_blocks_columns_medias_mediaElement_thumbnail | null;
  createdBy: ActivePageVersion_activePageVersion_blocks_columns_medias_mediaElement_createdBy;
  tags: ActivePageVersion_activePageVersion_blocks_columns_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: ActivePageVersion_activePageVersion_blocks_columns_medias_mediaElement;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ActivePageVersion_activePageVersion_blocks_columns_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: ActivePageVersion_activePageVersion_blocks_columns_inlineMedias_mediaElement_thumbnail | null;
  createdBy: ActivePageVersion_activePageVersion_blocks_columns_inlineMedias_mediaElement_createdBy;
  tags: ActivePageVersion_activePageVersion_blocks_columns_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: ActivePageVersion_activePageVersion_blocks_columns_inlineMedias_mediaElement;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface ActivePageVersion_activePageVersion_blocks_columns {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: ActivePageVersion_activePageVersion_blocks_columns_backgroundMedia | null;
  medias: ActivePageVersion_activePageVersion_blocks_columns_medias[];
  inlineMedias: ActivePageVersion_activePageVersion_blocks_columns_inlineMedias[];
  parent: ActivePageVersion_activePageVersion_blocks_columns_parent | null;
  themeVariables: ActivePageVersion_activePageVersion_blocks_columns_themeVariables;
}

export interface ActivePageVersion_activePageVersion_blocks {
  __typename: "PageBlock";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  isTemplate: boolean;
  name: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  blockId: string | null;
  heightType: PAGE_BLOCK_HEIGHT_TYPE;
  fixedHeight: number;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: ActivePageVersion_activePageVersion_blocks_backgroundMedia | null;
  pageContent: ActivePageVersion_activePageVersion_blocks_pageContent;
  medias: ActivePageVersion_activePageVersion_blocks_medias[];
  inlineMedias: ActivePageVersion_activePageVersion_blocks_inlineMedias[];
  themeVariables: ActivePageVersion_activePageVersion_blocks_themeVariables;
  parent: ActivePageVersion_activePageVersion_blocks_parent | null;
  columns: ActivePageVersion_activePageVersion_blocks_columns[];
}

export interface ActivePageVersion_activePageVersion_metaInfo_ogImage_image_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface ActivePageVersion_activePageVersion_metaInfo_ogImage_image_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ActivePageVersion_activePageVersion_metaInfo_ogImage_image_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ActivePageVersion_activePageVersion_metaInfo_ogImage_image_mediaElement_createdBy_profileImage | null;
}

export interface ActivePageVersion_activePageVersion_metaInfo_ogImage_image_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface ActivePageVersion_activePageVersion_metaInfo_ogImage_image_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: ActivePageVersion_activePageVersion_metaInfo_ogImage_image_mediaElement_thumbnail | null;
  createdBy: ActivePageVersion_activePageVersion_metaInfo_ogImage_image_mediaElement_createdBy;
  tags: ActivePageVersion_activePageVersion_metaInfo_ogImage_image_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface ActivePageVersion_activePageVersion_metaInfo_ogImage_image {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: ActivePageVersion_activePageVersion_metaInfo_ogImage_image_mediaElement;
}

export interface ActivePageVersion_activePageVersion_metaInfo_ogImage {
  __typename: "SharedImage";
  id: string;
  createdAt: DateTime;
  title: string | null;
  subtitle: string | null;
  titleColor: string | null;
  titleFont: string | null;
  subtitleFont: string | null;
  subtitleColor: string | null;
  backgroundColor: string | null;
  backgroundType: SHARED_IMAGE_BACKGROUND_TYPE;
  width: number | null;
  height: number | null;
  image: ActivePageVersion_activePageVersion_metaInfo_ogImage_image | null;
}

export interface ActivePageVersion_activePageVersion_metaInfo {
  __typename: "PageMetaInfo";
  id: string;
  pageTitle: string;
  ogTitle: string;
  ogDescription: string;
  ogSiteName: string;
  ogImage: ActivePageVersion_activePageVersion_metaInfo_ogImage;
  facebookUrl: string | null;
  twitterUrl: string | null;
  instagramUrl: string | null;
  linkedinUrl: string | null;
  youtubeUrl: string | null;
}

export interface ActivePageVersion_activePageVersion_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface ActivePageVersion_activePageVersion_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ActivePageVersion_activePageVersion_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: ActivePageVersion_activePageVersion_createdBy_profileImage | null;
}

export interface ActivePageVersion_activePageVersion {
  __typename: "PageVersion";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  published: boolean;
  customCss: string | null;
  blocks: ActivePageVersion_activePageVersion_blocks[];
  metaInfo: ActivePageVersion_activePageVersion_metaInfo;
  themeVariables: ActivePageVersion_activePageVersion_themeVariables;
  createdBy: ActivePageVersion_activePageVersion_createdBy;
}

export interface ActivePageVersion {
  activePageVersion: ActivePageVersion_activePageVersion | null;
}

export interface ActivePageVersionVariables {
  pageId: string;
  pageVersionId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PageVersions
// ====================================================

export interface PageVersions_pageVersions_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface PageVersions_pageVersions_edges_node_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersions_pageVersions_edges_node_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: PageVersions_pageVersions_edges_node_createdBy_profileImage | null;
}

export interface PageVersions_pageVersions_edges_node {
  __typename: "PageVersion";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  published: boolean;
  createdBy: PageVersions_pageVersions_edges_node_createdBy;
}

export interface PageVersions_pageVersions_edges {
  __typename: "PageVersionEdge";
  cursor: string;
  node: PageVersions_pageVersions_edges_node;
}

export interface PageVersions_pageVersions {
  __typename: "PageVersionConnection";
  pageInfo: PageVersions_pageVersions_pageInfo;
  edges: (PageVersions_pageVersions_edges | null)[];
}

export interface PageVersions {
  pageVersions: PageVersions_pageVersions;
}

export interface PageVersionsVariables {
  pageId: string;
  limit: number;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TemplatePageBlocks
// ====================================================

export interface TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia_mediaElement_createdBy;
  tags: TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia_mediaElement;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias_mediaElement_createdBy_profileImage | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias_mediaElement_thumbnail | null;
  createdBy: TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias_mediaElement_createdBy;
  tags: TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias_mediaElement;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_pageContent {
  __typename: "PageContent";
  id: string;
  content: JSON | null;
  medias: TemplatePageBlocks_templatePageBlocks_edges_node_pageContent_medias[];
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: TemplatePageBlocks_templatePageBlocks_edges_node_medias_mediaElement_createdBy_profileImage | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: TemplatePageBlocks_templatePageBlocks_edges_node_medias_mediaElement_thumbnail | null;
  createdBy: TemplatePageBlocks_templatePageBlocks_edges_node_medias_mediaElement_createdBy;
  tags: TemplatePageBlocks_templatePageBlocks_edges_node_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: TemplatePageBlocks_templatePageBlocks_edges_node_medias_mediaElement;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias_mediaElement_thumbnail | null;
  createdBy: TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias_mediaElement_createdBy;
  tags: TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias_mediaElement;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia_mediaElement_createdBy;
  tags: TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia_mediaElement;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias_mediaElement_createdBy_profileImage | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias_mediaElement_thumbnail | null;
  createdBy: TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias_mediaElement_createdBy;
  tags: TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias_mediaElement;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias_mediaElement_thumbnail | null;
  createdBy: TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias_mediaElement_createdBy;
  tags: TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias_mediaElement;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node_columns {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: TemplatePageBlocks_templatePageBlocks_edges_node_columns_backgroundMedia | null;
  medias: TemplatePageBlocks_templatePageBlocks_edges_node_columns_medias[];
  inlineMedias: TemplatePageBlocks_templatePageBlocks_edges_node_columns_inlineMedias[];
  parent: TemplatePageBlocks_templatePageBlocks_edges_node_columns_parent | null;
  themeVariables: TemplatePageBlocks_templatePageBlocks_edges_node_columns_themeVariables;
}

export interface TemplatePageBlocks_templatePageBlocks_edges_node {
  __typename: "PageBlock";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  isTemplate: boolean;
  name: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  blockId: string | null;
  heightType: PAGE_BLOCK_HEIGHT_TYPE;
  fixedHeight: number;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: TemplatePageBlocks_templatePageBlocks_edges_node_backgroundMedia | null;
  pageContent: TemplatePageBlocks_templatePageBlocks_edges_node_pageContent;
  medias: TemplatePageBlocks_templatePageBlocks_edges_node_medias[];
  inlineMedias: TemplatePageBlocks_templatePageBlocks_edges_node_inlineMedias[];
  themeVariables: TemplatePageBlocks_templatePageBlocks_edges_node_themeVariables;
  parent: TemplatePageBlocks_templatePageBlocks_edges_node_parent | null;
  columns: TemplatePageBlocks_templatePageBlocks_edges_node_columns[];
}

export interface TemplatePageBlocks_templatePageBlocks_edges {
  __typename: "PageBlockEdge";
  cursor: string;
  node: TemplatePageBlocks_templatePageBlocks_edges_node;
}

export interface TemplatePageBlocks_templatePageBlocks_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface TemplatePageBlocks_templatePageBlocks {
  __typename: "PageBlockConnection";
  edges: (TemplatePageBlocks_templatePageBlocks_edges | null)[];
  pageInfo: TemplatePageBlocks_templatePageBlocks_pageInfo;
}

export interface TemplatePageBlocks {
  templatePageBlocks: TemplatePageBlocks_templatePageBlocks;
}

export interface TemplatePageBlocksVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PageBlock
// ====================================================

export interface PageBlock_pageBlock_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlock_pageBlock_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlock_pageBlock_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlock_pageBlock_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface PageBlock_pageBlock_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlock_pageBlock_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlock_pageBlock_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: PageBlock_pageBlock_backgroundMedia_mediaElement_createdBy;
  tags: PageBlock_pageBlock_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlock_pageBlock_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlock_pageBlock_backgroundMedia_mediaElement;
}

export interface PageBlock_pageBlock_pageContent_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlock_pageBlock_pageContent_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlock_pageBlock_pageContent_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlock_pageBlock_pageContent_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageBlock_pageBlock_pageContent_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlock_pageBlock_pageContent_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlock_pageBlock_pageContent_medias_mediaElement_thumbnail | null;
  createdBy: PageBlock_pageBlock_pageContent_medias_mediaElement_createdBy;
  tags: PageBlock_pageBlock_pageContent_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlock_pageBlock_pageContent_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlock_pageBlock_pageContent_medias_mediaElement;
}

export interface PageBlock_pageBlock_pageContent {
  __typename: "PageContent";
  id: string;
  content: JSON | null;
  medias: PageBlock_pageBlock_pageContent_medias[];
}

export interface PageBlock_pageBlock_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlock_pageBlock_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlock_pageBlock_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlock_pageBlock_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageBlock_pageBlock_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlock_pageBlock_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlock_pageBlock_medias_mediaElement_thumbnail | null;
  createdBy: PageBlock_pageBlock_medias_mediaElement_createdBy;
  tags: PageBlock_pageBlock_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlock_pageBlock_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlock_pageBlock_medias_mediaElement;
}

export interface PageBlock_pageBlock_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlock_pageBlock_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlock_pageBlock_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlock_pageBlock_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface PageBlock_pageBlock_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlock_pageBlock_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlock_pageBlock_inlineMedias_mediaElement_thumbnail | null;
  createdBy: PageBlock_pageBlock_inlineMedias_mediaElement_createdBy;
  tags: PageBlock_pageBlock_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlock_pageBlock_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlock_pageBlock_inlineMedias_mediaElement;
}

export interface PageBlock_pageBlock_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface PageBlock_pageBlock_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface PageBlock_pageBlock_columns_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlock_pageBlock_columns_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlock_pageBlock_columns_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlock_pageBlock_columns_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface PageBlock_pageBlock_columns_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlock_pageBlock_columns_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlock_pageBlock_columns_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: PageBlock_pageBlock_columns_backgroundMedia_mediaElement_createdBy;
  tags: PageBlock_pageBlock_columns_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlock_pageBlock_columns_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlock_pageBlock_columns_backgroundMedia_mediaElement;
}

export interface PageBlock_pageBlock_columns_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlock_pageBlock_columns_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlock_pageBlock_columns_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlock_pageBlock_columns_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageBlock_pageBlock_columns_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlock_pageBlock_columns_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlock_pageBlock_columns_medias_mediaElement_thumbnail | null;
  createdBy: PageBlock_pageBlock_columns_medias_mediaElement_createdBy;
  tags: PageBlock_pageBlock_columns_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlock_pageBlock_columns_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlock_pageBlock_columns_medias_mediaElement;
}

export interface PageBlock_pageBlock_columns_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlock_pageBlock_columns_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlock_pageBlock_columns_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlock_pageBlock_columns_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface PageBlock_pageBlock_columns_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlock_pageBlock_columns_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlock_pageBlock_columns_inlineMedias_mediaElement_thumbnail | null;
  createdBy: PageBlock_pageBlock_columns_inlineMedias_mediaElement_createdBy;
  tags: PageBlock_pageBlock_columns_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlock_pageBlock_columns_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlock_pageBlock_columns_inlineMedias_mediaElement;
}

export interface PageBlock_pageBlock_columns_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface PageBlock_pageBlock_columns_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface PageBlock_pageBlock_columns {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: PageBlock_pageBlock_columns_backgroundMedia | null;
  medias: PageBlock_pageBlock_columns_medias[];
  inlineMedias: PageBlock_pageBlock_columns_inlineMedias[];
  parent: PageBlock_pageBlock_columns_parent | null;
  themeVariables: PageBlock_pageBlock_columns_themeVariables;
}

export interface PageBlock_pageBlock {
  __typename: "PageBlock";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  isTemplate: boolean;
  name: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  blockId: string | null;
  heightType: PAGE_BLOCK_HEIGHT_TYPE;
  fixedHeight: number;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: PageBlock_pageBlock_backgroundMedia | null;
  pageContent: PageBlock_pageBlock_pageContent;
  medias: PageBlock_pageBlock_medias[];
  inlineMedias: PageBlock_pageBlock_inlineMedias[];
  themeVariables: PageBlock_pageBlock_themeVariables;
  parent: PageBlock_pageBlock_parent | null;
  columns: PageBlock_pageBlock_columns[];
}

export interface PageBlock {
  pageBlock: PageBlock_pageBlock | null;
}

export interface PageBlockVariables {
  id: string;
  pageVersionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PageContent
// ====================================================

export interface PageContent_pageContent_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageContent_pageContent_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageContent_pageContent_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageContent_pageContent_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageContent_pageContent_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageContent_pageContent_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageContent_pageContent_medias_mediaElement_thumbnail | null;
  createdBy: PageContent_pageContent_medias_mediaElement_createdBy;
  tags: PageContent_pageContent_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageContent_pageContent_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageContent_pageContent_medias_mediaElement;
}

export interface PageContent_pageContent {
  __typename: "PageContent";
  id: string;
  content: JSON | null;
  medias: PageContent_pageContent_medias[];
}

export interface PageContent {
  pageContent: PageContent_pageContent | null;
}

export interface PageContentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PageTemplate
// ====================================================

export interface PageTemplate_pageTemplate_contentBlocks_block_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageTemplate_pageTemplate_contentBlocks_block_coverImage_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageTemplate_pageTemplate_contentBlocks_block_coverImage {
  __typename: "CoverImage";
  id: string;
  fullScreen: boolean | null;
  variant: COVER_IMAGE_VARIANT | null;
  fade: boolean | null;
  height: number | null;
  file: PageTemplate_pageTemplate_contentBlocks_block_coverImage_file | null;
}

export interface PageTemplate_pageTemplate_contentBlocks_block_contactPerson_profileImage {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
}

export interface PageTemplate_pageTemplate_contentBlocks_block_contactPerson {
  __typename: "AdContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  copyFromRecruitment: boolean | null;
  profileImage: PageTemplate_pageTemplate_contentBlocks_block_contactPerson_profileImage | null;
}

export interface PageTemplate_pageTemplate_contentBlocks_block_video {
  __typename: "Video";
  id: string;
  url: string | null;
  youtubeId: string | null;
  autoPlay: boolean | null;
  loop: boolean | null;
}

export interface PageTemplate_pageTemplate_contentBlocks_block {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
  title: string | null;
  text: string | null;
  url: string | null;
  copyShortPresentationFromCompany: boolean | null;
  file: PageTemplate_pageTemplate_contentBlocks_block_file | null;
  adMetaInfo: string[] | null;
  shareButtons: SOCIAL_MEDIA_SOURCE[] | null;
  coverImage: PageTemplate_pageTemplate_contentBlocks_block_coverImage | null;
  contactPerson: PageTemplate_pageTemplate_contentBlocks_block_contactPerson | null;
  video: PageTemplate_pageTemplate_contentBlocks_block_video | null;
}

export interface PageTemplate_pageTemplate_contentBlocks {
  __typename: "ConnectedContentBlock";
  id: string;
  listIndex: number;
  block: PageTemplate_pageTemplate_contentBlocks_block;
}

export interface PageTemplate_pageTemplate {
  __typename: "PageTemplate";
  id: string;
  name: string;
  updatedAt: DateTime;
  isDefault: boolean | null;
  contentBlocks: PageTemplate_pageTemplate_contentBlocks[];
}

export interface PageTemplate {
  pageTemplate: PageTemplate_pageTemplate | null;
}

export interface PageTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PageTemplates
// ====================================================

export interface PageTemplates_pageTemplates_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface PageTemplates_pageTemplates_edges_node_contentBlocks {
  __typename: "ConnectedContentBlock";
  id: string;
}

export interface PageTemplates_pageTemplates_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PageTemplates_pageTemplates_edges_node {
  __typename: "PageTemplate";
  id: string;
  name: string;
  updatedAt: DateTime;
  isDefault: boolean | null;
  contentBlocks: PageTemplates_pageTemplates_edges_node_contentBlocks[];
  company: PageTemplates_pageTemplates_edges_node_company;
}

export interface PageTemplates_pageTemplates_edges {
  __typename: "PageTemplateEdge";
  cursor: string;
  node: PageTemplates_pageTemplates_edges_node;
}

export interface PageTemplates_pageTemplates {
  __typename: "PageTemplateConnection";
  pageInfo: PageTemplates_pageTemplates_pageInfo;
  edges: (PageTemplates_pageTemplates_edges | null)[];
}

export interface PageTemplates {
  pageTemplates: PageTemplates_pageTemplates;
}

export interface PageTemplatesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  showResultsFromParentCompanies?: boolean | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PinnedRecruitments
// ====================================================

export interface PinnedRecruitments_pinnedRecruitments_recruitment_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface PinnedRecruitments_pinnedRecruitments_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PinnedRecruitments_pinnedRecruitments_recruitment_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface PinnedRecruitments_pinnedRecruitments_recruitment_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PinnedRecruitments_pinnedRecruitments_recruitment_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: PinnedRecruitments_pinnedRecruitments_recruitment_users_user_profileImage | null;
}

export interface PinnedRecruitments_pinnedRecruitments_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: PinnedRecruitments_pinnedRecruitments_recruitment_users_user;
}

export interface PinnedRecruitments_pinnedRecruitments_recruitment {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  status: PinnedRecruitments_pinnedRecruitments_recruitment_status | null;
  company: PinnedRecruitments_pinnedRecruitments_recruitment_company;
  tags: PinnedRecruitments_pinnedRecruitments_recruitment_tags[];
  users: PinnedRecruitments_pinnedRecruitments_recruitment_users[];
  isPinned: boolean | null;
}

export interface PinnedRecruitments_pinnedRecruitments {
  __typename: "PinnedRecruitment";
  id: string;
  createdAt: DateTime;
  recruitment: PinnedRecruitments_pinnedRecruitments_recruitment;
}

export interface PinnedRecruitments {
  pinnedRecruitments: PinnedRecruitments_pinnedRecruitments[];
}

export interface PinnedRecruitmentsVariables {
  companyId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Places
// ====================================================

export interface Places_places {
  __typename: "PlacePrediction";
  id: string;
  description: string;
  reference: string | null;
}

export interface Places {
  places: Places_places[];
}

export interface PlacesVariables {
  search: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupCompanyRecruitments
// ====================================================

export interface GroupCompanyRecruitments_groupCompanyRecruitments_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupCompanyRecruitments_groupCompanyRecruitments_edges_node_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface GroupCompanyRecruitments_groupCompanyRecruitments_edges_node_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: GroupCompanyRecruitments_groupCompanyRecruitments_edges_node_users_user_profileImage | null;
}

export interface GroupCompanyRecruitments_groupCompanyRecruitments_edges_node_users {
  __typename: "UserRecruitment";
  id: string;
  user: GroupCompanyRecruitments_groupCompanyRecruitments_edges_node_users_user;
}

export interface GroupCompanyRecruitments_groupCompanyRecruitments_edges_node {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  company: GroupCompanyRecruitments_groupCompanyRecruitments_edges_node_company;
  users: GroupCompanyRecruitments_groupCompanyRecruitments_edges_node_users[];
}

export interface GroupCompanyRecruitments_groupCompanyRecruitments_edges {
  __typename: "RecruitmentEdge";
  cursor: string;
  node: GroupCompanyRecruitments_groupCompanyRecruitments_edges_node;
}

export interface GroupCompanyRecruitments_groupCompanyRecruitments_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface GroupCompanyRecruitments_groupCompanyRecruitments {
  __typename: "RecruitmentConnection";
  edges: (GroupCompanyRecruitments_groupCompanyRecruitments_edges | null)[];
  pageInfo: GroupCompanyRecruitments_groupCompanyRecruitments_pageInfo;
}

export interface GroupCompanyRecruitments {
  groupCompanyRecruitments: GroupCompanyRecruitments_groupCompanyRecruitments;
}

export interface GroupCompanyRecruitmentsVariables {
  companyId: string;
  companyIds?: string[] | null;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  publishingStatuses?: string[] | null;
  tags?: string[] | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentNextAndPrev
// ====================================================

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_next_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_next_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_next_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_next_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: RecruitmentNextAndPrev_recruitmentNextAndPrev_next_users_user_profileImage | null;
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_next_users {
  __typename: "UserRecruitment";
  id: string;
  user: RecruitmentNextAndPrev_recruitmentNextAndPrev_next_users_user;
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_next {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  status: RecruitmentNextAndPrev_recruitmentNextAndPrev_next_status | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  countryCode: string | null;
  isPinned: boolean | null;
  isAnonymized: boolean;
  company: RecruitmentNextAndPrev_recruitmentNextAndPrev_next_company;
  users: RecruitmentNextAndPrev_recruitmentNextAndPrev_next_users[];
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_previous_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_previous_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_previous_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_previous_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: RecruitmentNextAndPrev_recruitmentNextAndPrev_previous_users_user_profileImage | null;
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_previous_users {
  __typename: "UserRecruitment";
  id: string;
  user: RecruitmentNextAndPrev_recruitmentNextAndPrev_previous_users_user;
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev_previous {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  status: RecruitmentNextAndPrev_recruitmentNextAndPrev_previous_status | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  countryCode: string | null;
  isPinned: boolean | null;
  isAnonymized: boolean;
  company: RecruitmentNextAndPrev_recruitmentNextAndPrev_previous_company;
  users: RecruitmentNextAndPrev_recruitmentNextAndPrev_previous_users[];
}

export interface RecruitmentNextAndPrev_recruitmentNextAndPrev {
  __typename: "RecruitmentNextAndPrev";
  next: RecruitmentNextAndPrev_recruitmentNextAndPrev_next | null;
  previous: RecruitmentNextAndPrev_recruitmentNextAndPrev_previous | null;
}

export interface RecruitmentNextAndPrev {
  recruitmentNextAndPrev: RecruitmentNextAndPrev_recruitmentNextAndPrev | null;
}

export interface RecruitmentNextAndPrevVariables {
  companyId?: string | null;
  recruitmentId: string;
  sortOrder?: string | null;
  sortBy?: string | null;
  filters?: RecruitmentFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminRecruitment
// ====================================================

export interface AdminRecruitment_adminRecruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
}

export interface AdminRecruitment {
  adminRecruitment: AdminRecruitment_adminRecruitment | null;
}

export interface AdminRecruitmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminRecruitments
// ====================================================

export interface AdminRecruitments_recruitmentsAdmin_edges_node_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface AdminRecruitments_recruitmentsAdmin_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface AdminRecruitments_recruitmentsAdmin_edges_node {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  publishingStatus: RECRUITMENT_STATUS | null;
  status: AdminRecruitments_recruitmentsAdmin_edges_node_status | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  company: AdminRecruitments_recruitmentsAdmin_edges_node_company;
}

export interface AdminRecruitments_recruitmentsAdmin_edges {
  __typename: "RecruitmentEdge";
  cursor: string;
  node: AdminRecruitments_recruitmentsAdmin_edges_node;
}

export interface AdminRecruitments_recruitmentsAdmin_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface AdminRecruitments_recruitmentsAdmin {
  __typename: "RecruitmentConnection";
  edges: (AdminRecruitments_recruitmentsAdmin_edges | null)[];
  pageInfo: AdminRecruitments_recruitmentsAdmin_pageInfo;
}

export interface AdminRecruitments {
  recruitmentsAdmin: AdminRecruitments_recruitmentsAdmin;
}

export interface AdminRecruitmentsVariables {
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyRecruitmentUser
// ====================================================

export interface MyRecruitmentUser_myRecruitmentUser {
  __typename: "UserRecruitment";
  id: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
}

export interface MyRecruitmentUser {
  myRecruitmentUser: MyRecruitmentUser_myRecruitmentUser | null;
}

export interface MyRecruitmentUserVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatedRecruitments
// ====================================================

export interface PaginatedRecruitments_paginatedRecruitments_items_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface PaginatedRecruitments_paginatedRecruitments_items_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PaginatedRecruitments_paginatedRecruitments_items_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface PaginatedRecruitments_paginatedRecruitments_items_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PaginatedRecruitments_paginatedRecruitments_items_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: PaginatedRecruitments_paginatedRecruitments_items_users_user_profileImage | null;
}

export interface PaginatedRecruitments_paginatedRecruitments_items_users {
  __typename: "UserRecruitment";
  id: string;
  user: PaginatedRecruitments_paginatedRecruitments_items_users_user;
}

export interface PaginatedRecruitments_paginatedRecruitments_items {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  status: PaginatedRecruitments_paginatedRecruitments_items_status | null;
  company: PaginatedRecruitments_paginatedRecruitments_items_company;
  tags: PaginatedRecruitments_paginatedRecruitments_items_tags[];
  users: PaginatedRecruitments_paginatedRecruitments_items_users[];
  isPinned: boolean | null;
}

export interface PaginatedRecruitments_paginatedRecruitments {
  __typename: "PaginatedRecruitments";
  totalCount: number;
  items: PaginatedRecruitments_paginatedRecruitments_items[];
}

export interface PaginatedRecruitments {
  paginatedRecruitments: PaginatedRecruitments_paginatedRecruitments;
}

export interface PaginatedRecruitmentsVariables {
  companyId?: string | null;
  page?: number | null;
  pageSize?: number | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  filters?: RecruitmentFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentApplicationForm
// ====================================================

export interface RecruitmentApplicationForm_recruitment_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface RecruitmentApplicationForm_recruitment_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: RecruitmentApplicationForm_recruitment_form_questions_matchingEventProfile | null;
}

export interface RecruitmentApplicationForm_recruitment_form {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  questions: RecruitmentApplicationForm_recruitment_form_questions[];
}

export interface RecruitmentApplicationForm_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
  language: string | null;
  isAnonymized: boolean;
  form: RecruitmentApplicationForm_recruitment_form | null;
}

export interface RecruitmentApplicationForm {
  recruitment: RecruitmentApplicationForm_recruitment | null;
}

export interface RecruitmentApplicationFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentConsultantCustomer
// ====================================================

export interface RecruitmentConsultantCustomer_recruitment_consultantCustomer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface RecruitmentConsultantCustomer_recruitment_consultantCustomer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface RecruitmentConsultantCustomer_recruitment_consultantCustomer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentConsultantCustomer_recruitment_consultantCustomer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentConsultantCustomer_recruitment_consultantCustomer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: RecruitmentConsultantCustomer_recruitment_consultantCustomer_customerCompany_profileImage | null;
}

export interface RecruitmentConsultantCustomer_recruitment_consultantCustomer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: RecruitmentConsultantCustomer_recruitment_consultantCustomer_invoiceInformation | null;
  status: RecruitmentConsultantCustomer_recruitment_consultantCustomer_status | null;
  companiesWithAccess: RecruitmentConsultantCustomer_recruitment_consultantCustomer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: RecruitmentConsultantCustomer_recruitment_consultantCustomer_customerCompany | null;
}

export interface RecruitmentConsultantCustomer_recruitment {
  __typename: "Recruitment";
  id: string;
  useCustomerProfile: boolean | null;
  consultantCustomer: RecruitmentConsultantCustomer_recruitment_consultantCustomer | null;
}

export interface RecruitmentConsultantCustomer {
  recruitment: RecruitmentConsultantCustomer_recruitment | null;
}

export interface RecruitmentConsultantCustomerVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentFilters
// ====================================================

export interface RecruitmentFilters_recruitmentFilters {
  __typename: "FilterItem";
  id: string;
  label: string;
}

export interface RecruitmentFilters {
  recruitmentFilters: RecruitmentFilters_recruitmentFilters[];
}

export interface RecruitmentFiltersVariables {
  searchText?: string | null;
  include?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentInternalJobDescription
// ====================================================

export interface RecruitmentInternalJobDescription_recruitment {
  __typename: "Recruitment";
  id: string;
  internalJobDescription: string;
}

export interface RecruitmentInternalJobDescription {
  recruitment: RecruitmentInternalJobDescription_recruitment | null;
}

export interface RecruitmentInternalJobDescriptionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentPublishedStatus
// ====================================================

export interface RecruitmentPublishedStatus_recruitment {
  __typename: "Recruitment";
  id: string;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  type: string | null;
}

export interface RecruitmentPublishedStatus {
  recruitment: RecruitmentPublishedStatus_recruitment | null;
}

export interface RecruitmentPublishedStatusVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Recruitment
// ====================================================

export interface Recruitment_recruitment_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface Recruitment_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Recruitment_recruitment_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Recruitment_recruitment_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: Recruitment_recruitment_users_user_profileImage | null;
}

export interface Recruitment_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: Recruitment_recruitment_users_user;
}

export interface Recruitment_recruitment {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  status: Recruitment_recruitment_status | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  countryCode: string | null;
  isPinned: boolean | null;
  isAnonymized: boolean;
  company: Recruitment_recruitment_company;
  users: Recruitment_recruitment_users[];
}

export interface Recruitment {
  recruitment: Recruitment_recruitment | null;
}

export interface RecruitmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsAdDistributions
// ====================================================

export interface RecruitmentsAdDistributions_recruitment_adDistributions {
  __typename: "AdDistribution";
  id: string;
  type: AD_DISTRIBUTION_TYPE;
  title: string | null;
  text: string | null;
  isPublished: boolean | null;
  isNotSynced: boolean | null;
}

export interface RecruitmentsAdDistributions_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  professionLocaleFieldID: string | null;
  professionLocaleCode: string | null;
  professionOccupationNameID: string | null;
  adDistributions: RecruitmentsAdDistributions_recruitment_adDistributions[];
}

export interface RecruitmentsAdDistributions {
  recruitment: RecruitmentsAdDistributions_recruitment | null;
}

export interface RecruitmentsAdDistributionsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsAvailableOnInterviewBookingGroup
// ====================================================

export interface RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node_users_user_profileImage | null;
}

export interface RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node_users {
  __typename: "UserRecruitment";
  id: string;
  user: RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node_users_user;
}

export interface RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  status: RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node_status | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  countryCode: string | null;
  isPinned: boolean | null;
  isAnonymized: boolean;
  company: RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node_company;
  users: RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node_users[];
}

export interface RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges {
  __typename: "RecruitmentEdge";
  cursor: string;
  node: RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges_node;
}

export interface RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup {
  __typename: "RecruitmentConnection";
  edges: (RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_edges | null)[];
  pageInfo: RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup_pageInfo;
}

export interface RecruitmentsAvailableOnInterviewBookingGroup {
  recruitmentsAvailableOnInterviewBookingGroup: RecruitmentsAvailableOnInterviewBookingGroup_recruitmentsAvailableOnInterviewBookingGroup;
}

export interface RecruitmentsAvailableOnInterviewBookingGroupVariables {
  companyId: string;
  interviewBookingGroupId?: string | null;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsCount
// ====================================================

export interface RecruitmentsCount_recruitmentsCount {
  __typename: "RecruitmentsCount";
  active: number;
  published: number;
  drafts: number;
  notPublished: number;
  endDatePassed: number;
  archive: number;
  unauthorized: number;
}

export interface RecruitmentsCount {
  recruitmentsCount: RecruitmentsCount_recruitmentsCount;
}

export interface RecruitmentsCountVariables {
  companyId: string;
  showChildCompanyRecruitments?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsCompaniesFilter
// ====================================================

export interface RecruitmentsCompaniesFilter_recruitmentsCompaniesFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface RecruitmentsCompaniesFilter_recruitmentsCompaniesFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: RecruitmentsCompaniesFilter_recruitmentsCompaniesFilter_edges_node;
}

export interface RecruitmentsCompaniesFilter_recruitmentsCompaniesFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsCompaniesFilter_recruitmentsCompaniesFilter {
  __typename: "FilterItemConnection";
  edges: (RecruitmentsCompaniesFilter_recruitmentsCompaniesFilter_edges | null)[];
  pageInfo: RecruitmentsCompaniesFilter_recruitmentsCompaniesFilter_pageInfo;
}

export interface RecruitmentsCompaniesFilter {
  recruitmentsCompaniesFilter: RecruitmentsCompaniesFilter_recruitmentsCompaniesFilter;
}

export interface RecruitmentsCompaniesFilterVariables {
  filters?: RecruitmentFilterInput | null;
  after?: string | null;
  limit: number;
  companyId?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsStatusesFilter
// ====================================================

export interface RecruitmentsStatusesFilter_recruitmentsStatusesFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface RecruitmentsStatusesFilter_recruitmentsStatusesFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: RecruitmentsStatusesFilter_recruitmentsStatusesFilter_edges_node;
}

export interface RecruitmentsStatusesFilter_recruitmentsStatusesFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsStatusesFilter_recruitmentsStatusesFilter {
  __typename: "FilterItemConnection";
  edges: (RecruitmentsStatusesFilter_recruitmentsStatusesFilter_edges | null)[];
  pageInfo: RecruitmentsStatusesFilter_recruitmentsStatusesFilter_pageInfo;
}

export interface RecruitmentsStatusesFilter {
  recruitmentsStatusesFilter: RecruitmentsStatusesFilter_recruitmentsStatusesFilter;
}

export interface RecruitmentsStatusesFilterVariables {
  filters?: RecruitmentFilterInput | null;
  after?: string | null;
  limit: number;
  companyId?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsTagsFilter
// ====================================================

export interface RecruitmentsTagsFilter_recruitmentsTagsFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface RecruitmentsTagsFilter_recruitmentsTagsFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: RecruitmentsTagsFilter_recruitmentsTagsFilter_edges_node;
}

export interface RecruitmentsTagsFilter_recruitmentsTagsFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsTagsFilter_recruitmentsTagsFilter {
  __typename: "FilterItemConnection";
  edges: (RecruitmentsTagsFilter_recruitmentsTagsFilter_edges | null)[];
  pageInfo: RecruitmentsTagsFilter_recruitmentsTagsFilter_pageInfo;
}

export interface RecruitmentsTagsFilter {
  recruitmentsTagsFilter: RecruitmentsTagsFilter_recruitmentsTagsFilter;
}

export interface RecruitmentsTagsFilterVariables {
  companyId?: string | null;
  filters?: RecruitmentFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsProfessionFieldsFilter
// ====================================================

export interface RecruitmentsProfessionFieldsFilter_recruitmentsProfessionFieldsFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface RecruitmentsProfessionFieldsFilter_recruitmentsProfessionFieldsFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: RecruitmentsProfessionFieldsFilter_recruitmentsProfessionFieldsFilter_edges_node;
}

export interface RecruitmentsProfessionFieldsFilter_recruitmentsProfessionFieldsFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsProfessionFieldsFilter_recruitmentsProfessionFieldsFilter {
  __typename: "FilterItemConnection";
  edges: (RecruitmentsProfessionFieldsFilter_recruitmentsProfessionFieldsFilter_edges | null)[];
  pageInfo: RecruitmentsProfessionFieldsFilter_recruitmentsProfessionFieldsFilter_pageInfo;
}

export interface RecruitmentsProfessionFieldsFilter {
  recruitmentsProfessionFieldsFilter: RecruitmentsProfessionFieldsFilter_recruitmentsProfessionFieldsFilter;
}

export interface RecruitmentsProfessionFieldsFilterVariables {
  companyId?: string | null;
  filters?: RecruitmentFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsContactPersonsFilter
// ====================================================

export interface RecruitmentsContactPersonsFilter_recruitmentsContactPersonsFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface RecruitmentsContactPersonsFilter_recruitmentsContactPersonsFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: RecruitmentsContactPersonsFilter_recruitmentsContactPersonsFilter_edges_node;
}

export interface RecruitmentsContactPersonsFilter_recruitmentsContactPersonsFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsContactPersonsFilter_recruitmentsContactPersonsFilter {
  __typename: "FilterItemConnection";
  edges: (RecruitmentsContactPersonsFilter_recruitmentsContactPersonsFilter_edges | null)[];
  pageInfo: RecruitmentsContactPersonsFilter_recruitmentsContactPersonsFilter_pageInfo;
}

export interface RecruitmentsContactPersonsFilter {
  recruitmentsContactPersonsFilter: RecruitmentsContactPersonsFilter_recruitmentsContactPersonsFilter;
}

export interface RecruitmentsContactPersonsFilterVariables {
  companyId?: string | null;
  filters?: RecruitmentFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsRecruitingManagersFilter
// ====================================================

export interface RecruitmentsRecruitingManagersFilter_recruitmentsRecruitingManagersFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface RecruitmentsRecruitingManagersFilter_recruitmentsRecruitingManagersFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: RecruitmentsRecruitingManagersFilter_recruitmentsRecruitingManagersFilter_edges_node;
}

export interface RecruitmentsRecruitingManagersFilter_recruitmentsRecruitingManagersFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsRecruitingManagersFilter_recruitmentsRecruitingManagersFilter {
  __typename: "FilterItemConnection";
  edges: (RecruitmentsRecruitingManagersFilter_recruitmentsRecruitingManagersFilter_edges | null)[];
  pageInfo: RecruitmentsRecruitingManagersFilter_recruitmentsRecruitingManagersFilter_pageInfo;
}

export interface RecruitmentsRecruitingManagersFilter {
  recruitmentsRecruitingManagersFilter: RecruitmentsRecruitingManagersFilter_recruitmentsRecruitingManagersFilter;
}

export interface RecruitmentsRecruitingManagersFilterVariables {
  companyId?: string | null;
  filters?: RecruitmentFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsResponsibleRecruitersFilter
// ====================================================

export interface RecruitmentsResponsibleRecruitersFilter_recruitmentsResponsibleRecruitersFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface RecruitmentsResponsibleRecruitersFilter_recruitmentsResponsibleRecruitersFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: RecruitmentsResponsibleRecruitersFilter_recruitmentsResponsibleRecruitersFilter_edges_node;
}

export interface RecruitmentsResponsibleRecruitersFilter_recruitmentsResponsibleRecruitersFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsResponsibleRecruitersFilter_recruitmentsResponsibleRecruitersFilter {
  __typename: "FilterItemConnection";
  edges: (RecruitmentsResponsibleRecruitersFilter_recruitmentsResponsibleRecruitersFilter_edges | null)[];
  pageInfo: RecruitmentsResponsibleRecruitersFilter_recruitmentsResponsibleRecruitersFilter_pageInfo;
}

export interface RecruitmentsResponsibleRecruitersFilter {
  recruitmentsResponsibleRecruitersFilter: RecruitmentsResponsibleRecruitersFilter_recruitmentsResponsibleRecruitersFilter;
}

export interface RecruitmentsResponsibleRecruitersFilterVariables {
  companyId?: string | null;
  filters?: RecruitmentFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsConsultantCustomersFilter
// ====================================================

export interface RecruitmentsConsultantCustomersFilter_recruitmentsConsultantCustomersFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface RecruitmentsConsultantCustomersFilter_recruitmentsConsultantCustomersFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: RecruitmentsConsultantCustomersFilter_recruitmentsConsultantCustomersFilter_edges_node;
}

export interface RecruitmentsConsultantCustomersFilter_recruitmentsConsultantCustomersFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsConsultantCustomersFilter_recruitmentsConsultantCustomersFilter {
  __typename: "FilterItemConnection";
  edges: (RecruitmentsConsultantCustomersFilter_recruitmentsConsultantCustomersFilter_edges | null)[];
  pageInfo: RecruitmentsConsultantCustomersFilter_recruitmentsConsultantCustomersFilter_pageInfo;
}

export interface RecruitmentsConsultantCustomersFilter {
  recruitmentsConsultantCustomersFilter: RecruitmentsConsultantCustomersFilter_recruitmentsConsultantCustomersFilter;
}

export interface RecruitmentsConsultantCustomersFilterVariables {
  companyId?: string | null;
  filters?: RecruitmentFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsRecruitmentUsersFilter
// ====================================================

export interface RecruitmentsRecruitmentUsersFilter_recruitmentsRecruitmentUsersFilter_edges_node {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

export interface RecruitmentsRecruitmentUsersFilter_recruitmentsRecruitmentUsersFilter_edges {
  __typename: "FilterItemEdge";
  cursor: string;
  node: RecruitmentsRecruitmentUsersFilter_recruitmentsRecruitmentUsersFilter_edges_node;
}

export interface RecruitmentsRecruitmentUsersFilter_recruitmentsRecruitmentUsersFilter_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsRecruitmentUsersFilter_recruitmentsRecruitmentUsersFilter {
  __typename: "FilterItemConnection";
  edges: (RecruitmentsRecruitmentUsersFilter_recruitmentsRecruitmentUsersFilter_edges | null)[];
  pageInfo: RecruitmentsRecruitmentUsersFilter_recruitmentsRecruitmentUsersFilter_pageInfo;
}

export interface RecruitmentsRecruitmentUsersFilter {
  recruitmentsRecruitmentUsersFilter: RecruitmentsRecruitmentUsersFilter_recruitmentsRecruitmentUsersFilter;
}

export interface RecruitmentsRecruitmentUsersFilterVariables {
  companyId?: string | null;
  filters?: RecruitmentFilterInput | null;
  after?: string | null;
  limit: number;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsPageAdmin
// ====================================================

export interface RecruitmentsPageAdmin_recruitmentsPageAdmin_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentsPageAdmin_recruitmentsPageAdmin_edges_node_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentsPageAdmin_recruitmentsPageAdmin_edges_node_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: RecruitmentsPageAdmin_recruitmentsPageAdmin_edges_node_users_user_profileImage | null;
}

export interface RecruitmentsPageAdmin_recruitmentsPageAdmin_edges_node_users {
  __typename: "UserRecruitment";
  id: string;
  user: RecruitmentsPageAdmin_recruitmentsPageAdmin_edges_node_users_user;
}

export interface RecruitmentsPageAdmin_recruitmentsPageAdmin_edges_node {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  publishingStatus: RECRUITMENT_STATUS | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  adminHasInvoiced: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  company: RecruitmentsPageAdmin_recruitmentsPageAdmin_edges_node_company;
  users: RecruitmentsPageAdmin_recruitmentsPageAdmin_edges_node_users[];
}

export interface RecruitmentsPageAdmin_recruitmentsPageAdmin_edges {
  __typename: "RecruitmentEdge";
  cursor: string;
  node: RecruitmentsPageAdmin_recruitmentsPageAdmin_edges_node;
}

export interface RecruitmentsPageAdmin_recruitmentsPageAdmin_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsPageAdmin_recruitmentsPageAdmin {
  __typename: "RecruitmentConnection";
  edges: (RecruitmentsPageAdmin_recruitmentsPageAdmin_edges | null)[];
  pageInfo: RecruitmentsPageAdmin_recruitmentsPageAdmin_pageInfo;
}

export interface RecruitmentsPageAdmin {
  recruitmentsPageAdmin: RecruitmentsPageAdmin_recruitmentsPageAdmin;
}

export interface RecruitmentsPageAdminVariables {
  companyId?: string | null;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  publishingStatuses?: string[] | null;
  companies?: string[] | null;
  tags?: string[] | null;
  searchText?: string | null;
  showRecruitmentsFromChildCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsPage
// ====================================================

export interface RecruitmentsPage_recruitmentsPage_edges_node_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface RecruitmentsPage_recruitmentsPage_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentsPage_recruitmentsPage_edges_node_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentsPage_recruitmentsPage_edges_node_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: RecruitmentsPage_recruitmentsPage_edges_node_users_user_profileImage | null;
}

export interface RecruitmentsPage_recruitmentsPage_edges_node_users {
  __typename: "UserRecruitment";
  id: string;
  user: RecruitmentsPage_recruitmentsPage_edges_node_users_user;
}

export interface RecruitmentsPage_recruitmentsPage_edges_node {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  status: RecruitmentsPage_recruitmentsPage_edges_node_status | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  countryCode: string | null;
  isPinned: boolean | null;
  isAnonymized: boolean;
  company: RecruitmentsPage_recruitmentsPage_edges_node_company;
  users: RecruitmentsPage_recruitmentsPage_edges_node_users[];
}

export interface RecruitmentsPage_recruitmentsPage_edges {
  __typename: "RecruitmentEdge";
  cursor: string;
  node: RecruitmentsPage_recruitmentsPage_edges_node;
}

export interface RecruitmentsPage_recruitmentsPage_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsPage_recruitmentsPage {
  __typename: "RecruitmentConnection";
  edges: (RecruitmentsPage_recruitmentsPage_edges | null)[];
  pageInfo: RecruitmentsPage_recruitmentsPage_pageInfo;
}

export interface RecruitmentsPage {
  recruitmentsPage: RecruitmentsPage_recruitmentsPage;
}

export interface RecruitmentsPageVariables {
  companyId: string;
  customerId?: string | null;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  publishingStatuses?: string[] | null;
  companies?: string[] | null;
  tags?: string[] | null;
  professionFields?: string[] | null;
  searchText?: string | null;
  showRecruitmentsFromChildCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentsSimple
// ====================================================

export interface RecruitmentsSimple_recruitmentsSimple_edges_node_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface RecruitmentsSimple_recruitmentsSimple_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentsSimple_recruitmentsSimple_edges_node_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentsSimple_recruitmentsSimple_edges_node_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: RecruitmentsSimple_recruitmentsSimple_edges_node_users_user_profileImage | null;
}

export interface RecruitmentsSimple_recruitmentsSimple_edges_node_users {
  __typename: "UserRecruitment";
  id: string;
  user: RecruitmentsSimple_recruitmentsSimple_edges_node_users_user;
}

export interface RecruitmentsSimple_recruitmentsSimple_edges_node {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  status: RecruitmentsSimple_recruitmentsSimple_edges_node_status | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  countryCode: string | null;
  isPinned: boolean | null;
  isAnonymized: boolean;
  company: RecruitmentsSimple_recruitmentsSimple_edges_node_company;
  users: RecruitmentsSimple_recruitmentsSimple_edges_node_users[];
}

export interface RecruitmentsSimple_recruitmentsSimple_edges {
  __typename: "RecruitmentEdge";
  cursor: string;
  node: RecruitmentsSimple_recruitmentsSimple_edges_node;
}

export interface RecruitmentsSimple_recruitmentsSimple_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface RecruitmentsSimple_recruitmentsSimple {
  __typename: "RecruitmentConnection";
  edges: (RecruitmentsSimple_recruitmentsSimple_edges | null)[];
  pageInfo: RecruitmentsSimple_recruitmentsSimple_pageInfo;
}

export interface RecruitmentsSimple {
  recruitmentsSimple: RecruitmentsSimple_recruitmentsSimple;
}

export interface RecruitmentsSimpleVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentSummaryFromAdId
// ====================================================

export interface RecruitmentSummaryFromAdId_recruitmentSummaryFromAdId_contactPerson {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface RecruitmentSummaryFromAdId_recruitmentSummaryFromAdId {
  __typename: "RecruitmentSummary";
  id: string | null;
  title: string | null;
  internalJobDescription: string | null;
  identifiedNeedDescription: string | null;
  contactPerson: RecruitmentSummaryFromAdId_recruitmentSummaryFromAdId_contactPerson | null;
}

export interface RecruitmentSummaryFromAdId {
  recruitmentSummaryFromAdId: RecruitmentSummaryFromAdId_recruitmentSummaryFromAdId;
}

export interface RecruitmentSummaryFromAdIdVariables {
  adId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentSummary
// ====================================================

export interface RecruitmentSummary_recruitment_ad {
  __typename: "Ad";
  id: string;
  title: string | null;
}

export interface RecruitmentSummary_recruitment {
  __typename: "Recruitment";
  id: string;
  enabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  dateSpanEnabled: boolean | null;
  createdAt: DateTime;
  title: string;
  applicationCount: number;
  newApplicationCount: number;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  ad: RecruitmentSummary_recruitment_ad;
}

export interface RecruitmentSummary {
  recruitment: RecruitmentSummary_recruitment | null;
}

export interface RecruitmentSummaryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentUsers
// ====================================================

export interface RecruitmentUsers_recruitmentUsers_user_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentUsers_recruitmentUsers_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentUsers_recruitmentUsers_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  company: RecruitmentUsers_recruitmentUsers_user_company;
  profileImage: RecruitmentUsers_recruitmentUsers_user_profileImage | null;
}

export interface RecruitmentUsers_recruitmentUsers {
  __typename: "UserRecruitment";
  id: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
  user: RecruitmentUsers_recruitmentUsers_user;
}

export interface RecruitmentUsers_notAddedUsers_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentUsers_notAddedUsers_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentUsers_notAddedUsers {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  profileImage: RecruitmentUsers_notAddedUsers_profileImage | null;
  company: RecruitmentUsers_notAddedUsers_company;
}

export interface RecruitmentUsers {
  recruitmentUsers: RecruitmentUsers_recruitmentUsers[];
  notAddedUsers: RecruitmentUsers_notAddedUsers[];
}

export interface RecruitmentUsersVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpontaniousRecruitment
// ====================================================

export interface SpontaniousRecruitment_spontaniousRecruitment_company {
  __typename: "Company";
  id: string;
}

export interface SpontaniousRecruitment_spontaniousRecruitment_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface SpontaniousRecruitment_spontaniousRecruitment_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: SpontaniousRecruitment_spontaniousRecruitment_users_user_profileImage | null;
}

export interface SpontaniousRecruitment_spontaniousRecruitment_users {
  __typename: "UserRecruitment";
  id: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
  user: SpontaniousRecruitment_spontaniousRecruitment_users_user;
}

export interface SpontaniousRecruitment_spontaniousRecruitment {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  publishingStatus: RECRUITMENT_STATUS | null;
  enabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  company: SpontaniousRecruitment_spontaniousRecruitment_company;
  newApplicationCount: number;
  users: SpontaniousRecruitment_spontaniousRecruitment_users[];
}

export interface SpontaniousRecruitment {
  spontaniousRecruitment: SpontaniousRecruitment_spontaniousRecruitment | null;
}

export interface SpontaniousRecruitmentVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserRecruitment
// ====================================================

export interface UserRecruitment_userRecruitment_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UserRecruitment_userRecruitment_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  subscribedMailNotificationTypes: NOTIFICATION_TYPE[];
  subscribedToNotificationsStatus: SUBSCRIBED_TO_NOTIFICATIONS_STATUS | null;
  profileImage: UserRecruitment_userRecruitment_user_profileImage | null;
}

export interface UserRecruitment_userRecruitment {
  __typename: "UserRecruitment";
  id: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
  user: UserRecruitment_userRecruitment_user;
}

export interface UserRecruitment {
  userRecruitment: UserRecruitment_userRecruitment | null;
}

export interface UserRecruitmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentCompetence
// ====================================================

export interface RecruitmentCompetence_recruitmentCompetence_competence_interviewQuestions_subQuestions {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
}

export interface RecruitmentCompetence_recruitmentCompetence_competence_interviewQuestions {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
  subQuestions: RecruitmentCompetence_recruitmentCompetence_competence_interviewQuestions_subQuestions[];
}

export interface RecruitmentCompetence_recruitmentCompetence_competence {
  __typename: "Competence";
  id: string;
  name: string;
  type: COMPETENCE_TYPE | null;
  interviewQuestions: RecruitmentCompetence_recruitmentCompetence_competence_interviewQuestions[];
}

export interface RecruitmentCompetence_recruitmentCompetence {
  __typename: "RecruitmentCompetence";
  id: string;
  required: boolean | null;
  listIndex: number | null;
  competence: RecruitmentCompetence_recruitmentCompetence_competence;
}

export interface RecruitmentCompetence {
  recruitmentCompetence: RecruitmentCompetence_recruitmentCompetence | null;
}

export interface RecruitmentCompetenceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentCompetences
// ====================================================

export interface RecruitmentCompetences_recruitmentCompetences_competence_referenceQuestionsForm_questions {
  __typename: "FormQuestion";
  id: string;
  question: string | null;
  language: string | null;
  title: string | null;
}

export interface RecruitmentCompetences_recruitmentCompetences_competence_referenceQuestionsForm {
  __typename: "Form";
  id: string;
  questions: RecruitmentCompetences_recruitmentCompetences_competence_referenceQuestionsForm_questions[];
}

export interface RecruitmentCompetences_recruitmentCompetences_competence_interviewQuestions_subQuestions {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
}

export interface RecruitmentCompetences_recruitmentCompetences_competence_interviewQuestions {
  __typename: "InterviewQuestion";
  id: string;
  title: string;
  language: string | null;
  subQuestions: RecruitmentCompetences_recruitmentCompetences_competence_interviewQuestions_subQuestions[];
}

export interface RecruitmentCompetences_recruitmentCompetences_competence {
  __typename: "Competence";
  id: string;
  name: string;
  type: COMPETENCE_TYPE | null;
  referenceQuestionsForm: RecruitmentCompetences_recruitmentCompetences_competence_referenceQuestionsForm | null;
  interviewQuestions: RecruitmentCompetences_recruitmentCompetences_competence_interviewQuestions[];
}

export interface RecruitmentCompetences_recruitmentCompetences {
  __typename: "RecruitmentCompetence";
  id: string;
  required: boolean | null;
  listIndex: number | null;
  competence: RecruitmentCompetences_recruitmentCompetences_competence;
}

export interface RecruitmentCompetences {
  recruitmentCompetences: RecruitmentCompetences_recruitmentCompetences[];
}

export interface RecruitmentCompetencesVariables {
  recruitmentId?: string | null;
  recruitmentTemplateId?: string | null;
  type?: COMPETENCE_TYPE | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentCompleteApplicationPage
// ====================================================

export interface RecruitmentCompleteApplicationPage_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  useCustomCompleteApplicationPage: boolean | null;
  customCompleteApplicationPageTitle: string | null;
  customCompleteApplicationPageDescription: string | null;
}

export interface RecruitmentCompleteApplicationPage {
  recruitment: RecruitmentCompleteApplicationPage_recruitment | null;
}

export interface RecruitmentCompleteApplicationPageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentInfo
// ====================================================

export interface RecruitmentInfo_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface RecruitmentInfo_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  publishingStatus: RECRUITMENT_STATUS | null;
  type: string | null;
  applicationCount: number;
  newApplicationCount: number;
  company: RecruitmentInfo_recruitment_company;
}

export interface RecruitmentInfo {
  recruitment: RecruitmentInfo_recruitment | null;
}

export interface RecruitmentInfoVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentSpecification
// ====================================================

export interface RecruitmentSpecification_recruitment_form {
  __typename: "Form";
  id: string;
  type: FORM_TYPE;
  description: string | null;
}

export interface RecruitmentSpecification_recruitment_recruitingManager {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface RecruitmentSpecification_recruitment_responsibleRecruiter {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface RecruitmentSpecification_recruitment_contactPerson {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface RecruitmentSpecification_recruitment_desiredCompetences_competence {
  __typename: "Competence";
  id: string;
  name: string;
  type: COMPETENCE_TYPE | null;
}

export interface RecruitmentSpecification_recruitment_desiredCompetences {
  __typename: "RecruitmentCompetence";
  id: string;
  required: boolean | null;
  competence: RecruitmentSpecification_recruitment_desiredCompetences_competence;
}

export interface RecruitmentSpecification_recruitment_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentSpecification_recruitment_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  profileImage: RecruitmentSpecification_recruitment_users_user_profileImage | null;
}

export interface RecruitmentSpecification_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
  user: RecruitmentSpecification_recruitment_users_user;
}

export interface RecruitmentSpecification_recruitment_company_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentSpecification_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
  websiteUrl: string | null;
  postalCode: string | null;
  postalAddress: string | null;
  type: COMPANY_TYPE | null;
  profileImage: RecruitmentSpecification_recruitment_company_profileImage | null;
  companyWebFont: string | null;
}

export interface RecruitmentSpecification_recruitment_ad_coverImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentSpecification_recruitment_ad_blocks_block_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentSpecification_recruitment_ad_blocks_block_coverImage_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentSpecification_recruitment_ad_blocks_block_coverImage {
  __typename: "CoverImage";
  id: string;
  fullScreen: boolean | null;
  variant: COVER_IMAGE_VARIANT | null;
  fade: boolean | null;
  height: number | null;
  file: RecruitmentSpecification_recruitment_ad_blocks_block_coverImage_file | null;
}

export interface RecruitmentSpecification_recruitment_ad_blocks_block_contactPerson_profileImage {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
}

export interface RecruitmentSpecification_recruitment_ad_blocks_block_contactPerson {
  __typename: "AdContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  copyFromRecruitment: boolean | null;
  profileImage: RecruitmentSpecification_recruitment_ad_blocks_block_contactPerson_profileImage | null;
}

export interface RecruitmentSpecification_recruitment_ad_blocks_block_video {
  __typename: "Video";
  id: string;
  url: string | null;
  youtubeId: string | null;
  autoPlay: boolean | null;
  loop: boolean | null;
}

export interface RecruitmentSpecification_recruitment_ad_blocks_block {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
  title: string | null;
  text: string | null;
  url: string | null;
  copyShortPresentationFromCompany: boolean | null;
  file: RecruitmentSpecification_recruitment_ad_blocks_block_file | null;
  adMetaInfo: string[] | null;
  shareButtons: SOCIAL_MEDIA_SOURCE[] | null;
  coverImage: RecruitmentSpecification_recruitment_ad_blocks_block_coverImage | null;
  contactPerson: RecruitmentSpecification_recruitment_ad_blocks_block_contactPerson | null;
  video: RecruitmentSpecification_recruitment_ad_blocks_block_video | null;
}

export interface RecruitmentSpecification_recruitment_ad_blocks {
  __typename: "ConnectedContentBlock";
  id: string;
  listIndex: number;
  block: RecruitmentSpecification_recruitment_ad_blocks_block;
}

export interface RecruitmentSpecification_recruitment_ad {
  __typename: "Ad";
  id: string;
  title: string | null;
  design: string | null;
  content: string[];
  useCustomOgImage: boolean | null;
  ogDescription: string | null;
  coverImage: RecruitmentSpecification_recruitment_ad_coverImage | null;
  blocks: RecruitmentSpecification_recruitment_ad_blocks[];
}

export interface RecruitmentSpecification_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  completedWizardSteps: string[];
  professionType: PROFESSION_TYPE | null;
  professionLocaleFieldID: string | null;
  professionLocaleCode: string | null;
  professionOccupationNameID: string | null;
  type: string | null;
  isPrivate: boolean | null;
  isAnonymized: boolean;
  enabled: boolean | null;
  role: string | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  location: string | null;
  municipality: string | null;
  countryCode: string | null;
  extent: number | null;
  employmentType: EMPLOYMENT_TYPE | null;
  employmentTemporaryType: EMPLOYMENT_TEMPORARY_TYPE | null;
  useCustomerProfile: boolean | null;
  salaryType: SALARY_TYPE | null;
  numberOfEmployments: number | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  internalJobDescription: string;
  applicationIncludes: string[];
  messageToLateAdVisitors: string | null;
  applicationCount: number;
  form: RecruitmentSpecification_recruitment_form | null;
  recruitingManager: RecruitmentSpecification_recruitment_recruitingManager | null;
  responsibleRecruiter: RecruitmentSpecification_recruitment_responsibleRecruiter | null;
  contactPerson: RecruitmentSpecification_recruitment_contactPerson | null;
  desiredCompetences: RecruitmentSpecification_recruitment_desiredCompetences[];
  users: RecruitmentSpecification_recruitment_users[];
  company: RecruitmentSpecification_recruitment_company;
  ad: RecruitmentSpecification_recruitment_ad;
}

export interface RecruitmentSpecification {
  recruitment: RecruitmentSpecification_recruitment | null;
}

export interface RecruitmentSpecificationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentTeams
// ====================================================

export interface RecruitmentTeams_recruitmentTeams_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface RecruitmentTeams_recruitmentTeams_edges_node_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentTeams_recruitmentTeams_edges_node_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  subscribedMailNotificationTypes: NOTIFICATION_TYPE[];
  subscribedToNotificationsStatus: SUBSCRIBED_TO_NOTIFICATIONS_STATUS | null;
  profileImage: RecruitmentTeams_recruitmentTeams_edges_node_users_user_profileImage | null;
}

export interface RecruitmentTeams_recruitmentTeams_edges_node_users {
  __typename: "UserRecruitment";
  id: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
  user: RecruitmentTeams_recruitmentTeams_edges_node_users_user;
}

export interface RecruitmentTeams_recruitmentTeams_edges_node {
  __typename: "RecruitmentTeam";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  users: RecruitmentTeams_recruitmentTeams_edges_node_users[];
}

export interface RecruitmentTeams_recruitmentTeams_edges {
  __typename: "RecruitmentTeamEdge";
  cursor: string;
  node: RecruitmentTeams_recruitmentTeams_edges_node;
}

export interface RecruitmentTeams_recruitmentTeams {
  __typename: "RecruitmentTeamConnection";
  pageInfo: RecruitmentTeams_recruitmentTeams_pageInfo;
  edges: (RecruitmentTeams_recruitmentTeams_edges | null)[];
}

export interface RecruitmentTeams {
  recruitmentTeams: RecruitmentTeams_recruitmentTeams;
}

export interface RecruitmentTeamsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentTeam
// ====================================================

export interface RecruitmentTeam_recruitmentTeam_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentTeam_recruitmentTeam_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  subscribedMailNotificationTypes: NOTIFICATION_TYPE[];
  subscribedToNotificationsStatus: SUBSCRIBED_TO_NOTIFICATIONS_STATUS | null;
  profileImage: RecruitmentTeam_recruitmentTeam_users_user_profileImage | null;
}

export interface RecruitmentTeam_recruitmentTeam_users {
  __typename: "UserRecruitment";
  id: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
  user: RecruitmentTeam_recruitmentTeam_users_user;
}

export interface RecruitmentTeam_recruitmentTeam {
  __typename: "RecruitmentTeam";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  users: RecruitmentTeam_recruitmentTeam_users[];
}

export interface RecruitmentTeam {
  recruitmentTeam: RecruitmentTeam_recruitmentTeam | null;
}

export interface RecruitmentTeamVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsRecruitmentTemplateNameAvailable
// ====================================================

export interface IsRecruitmentTemplateNameAvailable {
  isRecruitmentTemplateNameAvailable: boolean;
}

export interface IsRecruitmentTemplateNameAvailableVariables {
  companyId: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentTemplate
// ====================================================

export interface RecruitmentTemplate_recruitmentTemplate_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentTemplate_recruitmentTemplate_companiesUsingThisAsDefault {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentTemplate_recruitmentTemplate_company {
  __typename: "Company";
  id: string;
  name: string;
  companyWebFont: string | null;
  websiteUrl: string | null;
  postalCode: string | null;
  postalAddress: string | null;
}

export interface RecruitmentTemplate_recruitmentTemplate_form {
  __typename: "Form";
  id: string;
}

export interface RecruitmentTemplate_recruitmentTemplate_ad_coverImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentTemplate_recruitmentTemplate_ad_blocks_block_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentTemplate_recruitmentTemplate_ad_blocks_block_video {
  __typename: "Video";
  id: string;
  url: string | null;
  youtubeId: string | null;
  autoPlay: boolean | null;
  loop: boolean | null;
}

export interface RecruitmentTemplate_recruitmentTemplate_ad_blocks_block {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
  title: string | null;
  text: string | null;
  url: string | null;
  file: RecruitmentTemplate_recruitmentTemplate_ad_blocks_block_file | null;
  video: RecruitmentTemplate_recruitmentTemplate_ad_blocks_block_video | null;
}

export interface RecruitmentTemplate_recruitmentTemplate_ad_blocks {
  __typename: "ConnectedContentBlock";
  id: string;
  listIndex: number;
  block: RecruitmentTemplate_recruitmentTemplate_ad_blocks_block;
}

export interface RecruitmentTemplate_recruitmentTemplate_ad {
  __typename: "Ad";
  id: string;
  title: string | null;
  design: string | null;
  content: string[];
  coverImage: RecruitmentTemplate_recruitmentTemplate_ad_coverImage | null;
  blocks: RecruitmentTemplate_recruitmentTemplate_ad_blocks[];
}

export interface RecruitmentTemplate_recruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
  name: string;
  language: string | null;
  numberOfEmployments: number | null;
  salaryType: SALARY_TYPE | null;
  salaryDescription: string | null;
  professionType: PROFESSION_TYPE | null;
  professionLocaleFieldID: string | null;
  professionLocaleCode: string | null;
  professionOccupationNameID: string | null;
  municipalityID: string | null;
  dateSpanEnabled: boolean | null;
  location: string | null;
  extent: number | null;
  employmentType: EMPLOYMENT_TYPE | null;
  employmentTemporaryType: EMPLOYMENT_TEMPORARY_TYPE | null;
  internalJobDescription: string;
  messageToLateAdVisitors: string | null;
  useCompanyAddress: boolean | null;
  postalCode: string | null;
  postalAddress: string | null;
  municipality: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  useCustomCompleteApplicationPage: boolean | null;
  customCompleteApplicationPageTitle: string | null;
  customCompleteApplicationPageDescription: string | null;
  countryCode: string | null;
  companiesWithAccess: RecruitmentTemplate_recruitmentTemplate_companiesWithAccess[];
  companiesUsingThisAsDefault: RecruitmentTemplate_recruitmentTemplate_companiesUsingThisAsDefault[];
  company: RecruitmentTemplate_recruitmentTemplate_company;
  form: RecruitmentTemplate_recruitmentTemplate_form | null;
  ad: RecruitmentTemplate_recruitmentTemplate_ad | null;
}

export interface RecruitmentTemplate {
  recruitmentTemplate: RecruitmentTemplate_recruitmentTemplate | null;
}

export interface RecruitmentTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentTemplates
// ====================================================

export interface RecruitmentTemplates_recruitmentTemplates_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface RecruitmentTemplates_recruitmentTemplates_edges_node_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentTemplates_recruitmentTemplates_edges_node_companiesUsingThisAsDefault {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentTemplates_recruitmentTemplates_edges_node_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentTemplates_recruitmentTemplates_edges_node_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface RecruitmentTemplates_recruitmentTemplates_edges_node_desiredCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface RecruitmentTemplates_recruitmentTemplates_edges_node_desiredCompetences {
  __typename: "RecruitmentCompetence";
  id: string;
  competence: RecruitmentTemplates_recruitmentTemplates_edges_node_desiredCompetences_competence;
}

export interface RecruitmentTemplates_recruitmentTemplates_edges_node {
  __typename: "RecruitmentTemplate";
  id: string;
  updatedAt: DateTime;
  name: string;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: RecruitmentTemplates_recruitmentTemplates_edges_node_companiesWithAccess[];
  companiesUsingThisAsDefault: RecruitmentTemplates_recruitmentTemplates_edges_node_companiesUsingThisAsDefault[];
  company: RecruitmentTemplates_recruitmentTemplates_edges_node_company;
  tags: RecruitmentTemplates_recruitmentTemplates_edges_node_tags[];
  desiredCompetences: RecruitmentTemplates_recruitmentTemplates_edges_node_desiredCompetences[];
}

export interface RecruitmentTemplates_recruitmentTemplates_edges {
  __typename: "RecruitmentTemplateEdge";
  cursor: string;
  node: RecruitmentTemplates_recruitmentTemplates_edges_node;
}

export interface RecruitmentTemplates_recruitmentTemplates {
  __typename: "RecruitmentTemplateConnection";
  pageInfo: RecruitmentTemplates_recruitmentTemplates_pageInfo;
  edges: (RecruitmentTemplates_recruitmentTemplates_edges | null)[];
}

export interface RecruitmentTemplates {
  recruitmentTemplates: RecruitmentTemplates_recruitmentTemplates;
}

export interface RecruitmentTemplatesVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  showTemplatesFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatedRecruitmentVisitorAccesses
// ====================================================

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_recruitment_users_user {
  __typename: "User";
  id: string;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_recruitment_users_user;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  company: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_recruitment_company;
  users: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_recruitment_users[];
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_tengaiInterview {
  __typename: "TengaiInterview";
  id: string;
  status: TENGAI_INTERVIEW_STATUS;
  totalPersonalityIndicatorScore: number | null;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_applicationFormAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_applicationFormAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_applicationFormAnswers_question_matchingEventProfile | null;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_applicationFormAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_applicationFormAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_grades_setBy {
  __typename: "User";
  id: string;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_grades {
  __typename: "ApplicantGrade";
  id: string;
  setBy: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_grades_setBy | null;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_addedByUser_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_addedByUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_addedByUser_profileImage | null;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  education: string | null;
  endDate: DateTime | null;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  createdAt: DateTime;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_documents_file;
  type: DOCUMENT_TYPE;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  createdAt: DateTime;
  averageGrade: number;
  needsVerification: boolean | null;
  note: string | null;
  computedMatchRanking: number | null;
  computedMatchScore: number;
  matchPercentageNeedToHave: number;
  matchPercentageNiceToHave: number;
  alvaLabsTestScore: number;
  alvaLabsCandidateCreatedAt: DateTime | null;
  recruitment: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_recruitment;
  tengaiInterview: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_tengaiInterview | null;
  applicationFormAnswers: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_applicationFormAnswers[];
  companyApplicant: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_companyApplicant | null;
  grades: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_grades[];
  addedByUser: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_addedByUser | null;
  educations: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_educations[];
  status: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_status | null;
  tags: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_tags[];
  documents: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications_documents[];
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions {
  __typename: "RecruitmentVisitorPermissions";
  id: string;
  name: string | null;
  isTemplate: boolean | null;
  basePermissions: RECRUITMENT_VISITOR_PERMISSION[];
  applicationPermissions: APPLICATION_VISITOR_PERMISSION[];
  applicationAccessType: APPLICATION_VISITOR_ACCESS_TYPE;
  applications: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_applications[];
  recruitment: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions_recruitment;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  createdAt: DateTime;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  createdAt: DateTime;
  email: string;
  firstName: string | null;
  lastName: string | null;
  permissions: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_permissions;
  recruitment: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items_recruitment;
}

export interface PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses {
  __typename: "PaginatedRecruitmentVisitorAccesses";
  items: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses_items[];
  totalCount: number;
}

export interface PaginatedRecruitmentVisitorAccesses {
  paginatedRecruitmentVisitorAccesses: PaginatedRecruitmentVisitorAccesses_paginatedRecruitmentVisitorAccesses;
}

export interface PaginatedRecruitmentVisitorAccessesVariables {
  recruitmentId: string;
  page?: number | null;
  pageSize?: number | null;
  sortBy?: string | null;
  sortOrder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecruitmentVisitorAccess
// ====================================================

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_recruitment_users_user {
  __typename: "User";
  id: string;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_recruitment_users_user;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  company: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_recruitment_company;
  users: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_recruitment_users[];
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_tengaiInterview {
  __typename: "TengaiInterview";
  id: string;
  status: TENGAI_INTERVIEW_STATUS;
  totalPersonalityIndicatorScore: number | null;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_applicationFormAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_applicationFormAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_applicationFormAnswers_question_matchingEventProfile | null;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_applicationFormAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_applicationFormAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_grades_setBy {
  __typename: "User";
  id: string;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_grades {
  __typename: "ApplicantGrade";
  id: string;
  setBy: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_grades_setBy | null;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_addedByUser_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_addedByUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_addedByUser_profileImage | null;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  education: string | null;
  endDate: DateTime | null;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  createdAt: DateTime;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_documents_file;
  type: DOCUMENT_TYPE;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  createdAt: DateTime;
  averageGrade: number;
  needsVerification: boolean | null;
  note: string | null;
  computedMatchRanking: number | null;
  computedMatchScore: number;
  matchPercentageNeedToHave: number;
  matchPercentageNiceToHave: number;
  alvaLabsTestScore: number;
  alvaLabsCandidateCreatedAt: DateTime | null;
  recruitment: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_recruitment;
  tengaiInterview: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_tengaiInterview | null;
  applicationFormAnswers: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_applicationFormAnswers[];
  companyApplicant: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_companyApplicant | null;
  grades: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_grades[];
  addedByUser: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_addedByUser | null;
  educations: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_educations[];
  status: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_status | null;
  tags: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_tags[];
  documents: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications_documents[];
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions {
  __typename: "RecruitmentVisitorPermissions";
  id: string;
  name: string | null;
  isTemplate: boolean | null;
  basePermissions: RECRUITMENT_VISITOR_PERMISSION[];
  applicationPermissions: APPLICATION_VISITOR_PERMISSION[];
  applicationAccessType: APPLICATION_VISITOR_ACCESS_TYPE;
  applications: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_applications[];
  recruitment: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions_recruitment;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  createdAt: DateTime;
}

export interface RecruitmentVisitorAccess_recruitmentVisitorAccess {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  createdAt: DateTime;
  email: string;
  firstName: string | null;
  lastName: string | null;
  permissions: RecruitmentVisitorAccess_recruitmentVisitorAccess_permissions;
  recruitment: RecruitmentVisitorAccess_recruitmentVisitorAccess_recruitment;
}

export interface RecruitmentVisitorAccess {
  recruitmentVisitorAccess: RecruitmentVisitorAccess_recruitmentVisitorAccess;
}

export interface RecruitmentVisitorAccessVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScoringMatrix
// ====================================================

export interface ScoringMatrix_scoringMatrix_questions_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface ScoringMatrix_scoringMatrix_questions {
  __typename: "ScoringMatrixQuestion";
  id: string;
  recruitment: ScoringMatrix_scoringMatrix_questions_recruitment | null;
  index: number;
  description: string | null;
  descriptionLabels: string[];
  question: string;
  type: ScoringMatrixQuestionType | null;
}

export interface ScoringMatrix_scoringMatrix {
  __typename: "ScoringMatrix";
  id: string;
  createdAt: DateTime;
  questions: ScoringMatrix_scoringMatrix_questions[];
}

export interface ScoringMatrix {
  scoringMatrix: ScoringMatrix_scoringMatrix | null;
}

export interface ScoringMatrixVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScoringMatrixAnswers
// ====================================================

export interface ScoringMatrixAnswers_scoringMatrixAnswers_question {
  __typename: "ScoringMatrixQuestion";
  id: string;
}

export interface ScoringMatrixAnswers_scoringMatrixAnswers {
  __typename: "ScoringMatrixAnswer";
  id: string;
  question: ScoringMatrixAnswers_scoringMatrixAnswers_question;
  grade: number;
}

export interface ScoringMatrixAnswers {
  scoringMatrixAnswers: ScoringMatrixAnswers_scoringMatrixAnswers[];
}

export interface ScoringMatrixAnswersVariables {
  interviewId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScoringMatrixQuestion
// ====================================================

export interface ScoringMatrixQuestion_scoringMatrixQuestion {
  __typename: "ScoringMatrixQuestion";
  id: string;
  index: number;
  description: string | null;
  descriptionLabels: string[];
  question: string;
  type: ScoringMatrixQuestionType | null;
}

export interface ScoringMatrixQuestion {
  scoringMatrixQuestion: ScoringMatrixQuestion_scoringMatrixQuestion | null;
}

export interface ScoringMatrixQuestionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScreeningForm
// ====================================================

export interface ScreeningForm_screeningForm_form_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface ScreeningForm_screeningForm_form_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: ScreeningForm_screeningForm_form_participants_application_companyApplicant | null;
}

export interface ScreeningForm_screeningForm_form_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  answeredAt: DateTime | null;
  status: FORM_SUBMISSION_STATUS;
  phoneNotifiers: DateTime[];
  emailNotifiers: DateTime[];
  application: ScreeningForm_screeningForm_form_participants_application | null;
}

export interface ScreeningForm_screeningForm_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ScreeningForm_screeningForm_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: ScreeningForm_screeningForm_form_questions_matchingEventProfile | null;
}

export interface ScreeningForm_screeningForm_form {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  participants: ScreeningForm_screeningForm_form_participants[];
  questions: ScreeningForm_screeningForm_form_questions[];
}

export interface ScreeningForm_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  createdAt: DateTime;
  title: string | null;
  form: ScreeningForm_screeningForm_form;
}

export interface ScreeningForm {
  screeningForm: ScreeningForm_screeningForm | null;
}

export interface ScreeningFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScreeningFormsOfRecruitment
// ====================================================

export interface ScreeningFormsOfRecruitment_screeningFormsOfRecruitment {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface ScreeningFormsOfRecruitment {
  screeningFormsOfRecruitment: ScreeningFormsOfRecruitment_screeningFormsOfRecruitment[];
}

export interface ScreeningFormsOfRecruitmentVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScreeningForms
// ====================================================

export interface ScreeningForms_screeningForms_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ScreeningForms_screeningForms_edges_node_form_questions {
  __typename: "FormQuestion";
  id: string;
}

export interface ScreeningForms_screeningForms_edges_node_form_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ScreeningForms_screeningForms_edges_node_form_participants {
  __typename: "FormParticipant";
  id: string;
  application: ScreeningForms_screeningForms_edges_node_form_participants_application | null;
}

export interface ScreeningForms_screeningForms_edges_node_form {
  __typename: "Form";
  id: string;
  title: string | null;
  description: string | null;
  status: FORM_STATUS | null;
  questions: ScreeningForms_screeningForms_edges_node_form_questions[];
  participants: ScreeningForms_screeningForms_edges_node_form_participants[];
}

export interface ScreeningForms_screeningForms_edges_node {
  __typename: "ScreeningForm";
  id: string;
  createdAt: DateTime;
  title: string | null;
  form: ScreeningForms_screeningForms_edges_node_form;
}

export interface ScreeningForms_screeningForms_edges {
  __typename: "ScreeningFormEdge";
  cursor: string;
  node: ScreeningForms_screeningForms_edges_node;
}

export interface ScreeningForms_screeningForms {
  __typename: "ScreeningFormConnection";
  pageInfo: ScreeningForms_screeningForms_pageInfo;
  edges: (ScreeningForms_screeningForms_edges | null)[];
}

export interface ScreeningForms {
  screeningForms: ScreeningForms_screeningForms;
}

export interface ScreeningFormsVariables {
  companyId: string;
  after?: string | null;
  limit: number;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  recruitments?: string[] | null;
  excludeFormWithApplicationId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SharedImage
// ====================================================

export interface SharedImage_sharedImage_image_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface SharedImage_sharedImage_image_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface SharedImage_sharedImage_image_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: SharedImage_sharedImage_image_mediaElement_createdBy_profileImage | null;
}

export interface SharedImage_sharedImage_image_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface SharedImage_sharedImage_image_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: SharedImage_sharedImage_image_mediaElement_thumbnail | null;
  createdBy: SharedImage_sharedImage_image_mediaElement_createdBy;
  tags: SharedImage_sharedImage_image_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface SharedImage_sharedImage_image {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: SharedImage_sharedImage_image_mediaElement;
}

export interface SharedImage_sharedImage {
  __typename: "SharedImage";
  id: string;
  createdAt: DateTime;
  title: string | null;
  subtitle: string | null;
  titleColor: string | null;
  titleFont: string | null;
  subtitleFont: string | null;
  subtitleColor: string | null;
  backgroundColor: string | null;
  backgroundType: SHARED_IMAGE_BACKGROUND_TYPE;
  width: number | null;
  height: number | null;
  image: SharedImage_sharedImage_image | null;
}

export interface SharedImage {
  sharedImage: SharedImage_sharedImage | null;
}

export interface SharedImageVariables {
  recruitmentId?: string | null;
  pageVersionId?: string | null;
  jobTipAdId?: string | null;
  jobTipImageType?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminStatistics
// ====================================================

export interface AdminStatistics_adminStatistics_adViewData {
  __typename: "TimeLineItem";
  date: string;
  value: number;
}

export interface AdminStatistics_adminStatistics_sourcePages {
  __typename: "SourcePage";
  source: string;
  numberOfSessions: number;
}

export interface AdminStatistics_adminStatistics_days {
  __typename: "CountItem";
  name: string;
  amount: number;
}

export interface AdminStatistics_adminStatistics {
  __typename: "AdminStatistics";
  totalNumberOfApplications: number;
  totalNumberOfRecruitments: number;
  totalNumberOfCompanies: number;
  totalNumberOfUsers: number;
  adViewData: AdminStatistics_adminStatistics_adViewData[];
  sourcePages: AdminStatistics_adminStatistics_sourcePages[];
  days: AdminStatistics_adminStatistics_days[];
}

export interface AdminStatistics {
  adminStatistics: AdminStatistics_adminStatistics | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdViews
// ====================================================

export interface AdViews {
  adViews: number;
}

export interface AdViewsVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicantsStatistics
// ====================================================

export interface ApplicantsStatistics_applicantsStatistics_applicantData {
  __typename: "TimeLineItem";
  date: string;
  value: number;
}

export interface ApplicantsStatistics_applicantsStatistics_professionLocaleFieldIDs {
  __typename: "SourcePage";
  source: string;
  numberOfSessions: number;
}

export interface ApplicantsStatistics_applicantsStatistics_professionLocaleFieldIDsTotal {
  __typename: "SourcePage";
  source: string;
  numberOfSessions: number;
}

export interface ApplicantsStatistics_applicantsStatistics {
  __typename: "ApplicantsStatistics";
  applicantData: ApplicantsStatistics_applicantsStatistics_applicantData[];
  professionLocaleFieldIDs: ApplicantsStatistics_applicantsStatistics_professionLocaleFieldIDs[];
  totalNumberOfApplicants: number;
  professionLocaleFieldIDsTotal: ApplicantsStatistics_applicantsStatistics_professionLocaleFieldIDsTotal[];
}

export interface ApplicantsStatistics {
  applicantsStatistics: ApplicantsStatistics_applicantsStatistics | null;
}

export interface ApplicantsStatisticsVariables {
  companyId: string;
  showResultsFromChildCompanies?: boolean | null;
  from?: DateTime | null;
  to?: DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DemographicsStatistics
// ====================================================

export interface DemographicsStatistics_demographicsStatistics_ages {
  __typename: "BarChartItem";
  name: string;
  value: number;
}

export interface DemographicsStatistics_demographicsStatistics {
  __typename: "DemographicsStatistics";
  totalNumberOfFemaleViewers: number;
  totalNumberOfMaleViewers: number;
  ages: DemographicsStatistics_demographicsStatistics_ages[];
}

export interface DemographicsStatistics {
  demographicsStatistics: DemographicsStatistics_demographicsStatistics | null;
}

export interface DemographicsStatisticsVariables {
  companyId: string;
  recruitmentId?: string | null;
  showResultsFromChildCompanies?: boolean | null;
  from?: DateTime | null;
  to?: DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DevicesStatistics
// ====================================================

export interface DevicesStatistics_devicesStatistics_browsers {
  __typename: "SourcePage";
  source: string;
  numberOfSessions: number;
}

export interface DevicesStatistics_devicesStatistics {
  __typename: "DevicesStatistics";
  totalNumberOfDesktopViews: number;
  totalNumberOfMobileViews: number;
  totalNumberOfTabletViews: number;
  browsers: DevicesStatistics_devicesStatistics_browsers[];
}

export interface DevicesStatistics {
  devicesStatistics: DevicesStatistics_devicesStatistics | null;
}

export interface DevicesStatisticsVariables {
  companyId: string;
  recruitmentId?: string | null;
  showResultsFromChildCompanies?: boolean | null;
  from?: DateTime | null;
  to?: DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InternalStatistics
// ====================================================

export interface InternalStatistics_internalStatistics {
  __typename: "InternalStatistics";
  numberOfCompanies: number;
  numberOfCompaniesTotal: number;
  numberOfRecruitments: number;
  numberOfRecruitmentsTotal: number;
  numberOfApplicants: number;
  numberOfApplicantsTotal: number;
  numberOfUsers: number;
  numberOfUsersTotal: number;
  numberOfSentMessages: number;
  numberOfSentMessagesTotal: number;
  numberOfReceivedMessages: number;
  numberOfReceivedMessagesTotal: number;
  numberOfInterviews: number;
  numberOfInterviewsTotal: number;
  numberOfAssignments: number;
  numberOfAssignmentsTotal: number;
  numberOfCalendarEvents: number;
  numberOfCalendarEventsTotal: number;
  numberOfScreeningForms: number;
  numberOfScreeningFormsTotal: number;
  numberOfReferenceForms: number;
  numberOfReferenceFormsTotal: number;
  numberOfContracts: number;
  numberOfContractsTotal: number;
  numberOfComments: number;
  numberOfCommentsTotal: number;
  numberOfTags: number;
  numberOfTagsTotal: number;
  numberOfScoringMatrices: number;
  numberOfScoringMatricesTotal: number;
  numberOfCompetences: number;
  numberOfCompetencesTotal: number;
}

export interface InternalStatistics {
  internalStatistics: InternalStatistics_internalStatistics | null;
}

export interface InternalStatisticsVariables {
  companyId: string;
  recruitmentId?: string | null;
  showResultsFromChildCompanies?: boolean | null;
  from?: DateTime | null;
  to?: DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MatchingEventCompanyStatistics
// ====================================================

export interface MatchingEventCompanyStatistics_matchingEventCompanyStatistics_mostCommonNeedToHaveByCompany {
  __typename: "BarChartItem";
  name: string;
  value: number;
}

export interface MatchingEventCompanyStatistics_matchingEventCompanyStatistics_mostCommonAnswerByApplicant {
  __typename: "BarChartItem";
  name: string;
  value: number;
}

export interface MatchingEventCompanyStatistics_matchingEventCompanyStatistics {
  __typename: "MatchingEventCompanyStatistics";
  numberOfMatchingEvents: number;
  numberOfCompanies: number;
  numberOfUsers: number;
  numberOfApplicants: number;
  numberOfApprovedMatches: number;
  numberOfDeniedMatchesByHost: number;
  numberOfDeniedMatchesByCompany: number;
  numberOfInterviews: number;
  numberOfTalentProfiles: number;
  numberOfApplicantsPerCompany: number;
  numberOfMeetingsPerCompany: number;
  numberOfMatchesPerCompany: number;
  mostCommonNeedToHaveByCompany: MatchingEventCompanyStatistics_matchingEventCompanyStatistics_mostCommonNeedToHaveByCompany[];
  mostCommonAnswerByApplicant: MatchingEventCompanyStatistics_matchingEventCompanyStatistics_mostCommonAnswerByApplicant[];
}

export interface MatchingEventCompanyStatistics {
  matchingEventCompanyStatistics: MatchingEventCompanyStatistics_matchingEventCompanyStatistics | null;
}

export interface MatchingEventCompanyStatisticsVariables {
  companyId: string;
  matchingEventId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewStatistics
// ====================================================

export interface OverviewStatistics_overviewStatistics_recruitmentData {
  __typename: "TimeLineItem";
  date: string;
  value: number;
}

export interface OverviewStatistics_overviewStatistics_applicationData {
  __typename: "TimeLineItem";
  date: string;
  value: number;
}

export interface OverviewStatistics_overviewStatistics_adViewData {
  __typename: "TimeLineItem";
  date: string;
  value: number;
}

export interface OverviewStatistics_overviewStatistics_sourcePages {
  __typename: "SourcePage";
  source: string;
  numberOfSessions: number;
}

export interface OverviewStatistics_overviewStatistics_days {
  __typename: "CountItem";
  name: string;
  amount: number;
}

export interface OverviewStatistics_overviewStatistics {
  __typename: "OverviewStatistics";
  recruitmentData: OverviewStatistics_overviewStatistics_recruitmentData[];
  applicationData: OverviewStatistics_overviewStatistics_applicationData[];
  adViewData: OverviewStatistics_overviewStatistics_adViewData[];
  sourcePages: OverviewStatistics_overviewStatistics_sourcePages[];
  days: OverviewStatistics_overviewStatistics_days[];
}

export interface OverviewStatistics {
  overviewStatistics: OverviewStatistics_overviewStatistics | null;
}

export interface OverviewStatisticsVariables {
  companyId: string;
  recruitmentId?: string | null;
  showResultsFromChildCompanies?: boolean | null;
  from?: DateTime | null;
  to?: DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TemplatesStatistics
// ====================================================

export interface TemplatesStatistics_templatesStatistics {
  __typename: "TemplatesStatistics";
  numberOfRecruitmentTemplatesTotal: number;
  numberOfAdTemplatesTotal: number;
  numberOfMessageTemplatesTotal: number;
  numberOfInterviewTemplatesTotal: number;
  numberOfApplicationFormTemplatesTotal: number;
  numberOfReferenceFormTemplatesTotal: number;
  numberOfScreeningFormTemplatesTotal: number;
  numberOfFileTemplatesTotal: number;
}

export interface TemplatesStatistics {
  templatesStatistics: TemplatesStatistics_templatesStatistics | null;
}

export interface TemplatesStatisticsVariables {
  companyId: string;
  showResultsFromChildCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ViewsStatistics
// ====================================================

export interface ViewsStatistics_viewsStatistics_applicationData {
  __typename: "TimeLineItem";
  date: string;
  value: number;
}

export interface ViewsStatistics_viewsStatistics_adViews {
  __typename: "TimeLineItem";
  date: string;
  value: number;
}

export interface ViewsStatistics_viewsStatistics_applyViews {
  __typename: "TimeLineItem";
  date: string;
  value: number;
}

export interface ViewsStatistics_viewsStatistics_adSourcePages {
  __typename: "SourcePage";
  source: string;
  numberOfSessions: number;
}

export interface ViewsStatistics_viewsStatistics_applySourcePages {
  __typename: "SourcePage";
  source: string;
  numberOfSessions: number;
}

export interface ViewsStatistics_viewsStatistics_completeSourcePages {
  __typename: "SourcePage";
  source: string;
  numberOfSessions: number;
}

export interface ViewsStatistics_viewsStatistics {
  __typename: "ViewsStatistics";
  applicationData: ViewsStatistics_viewsStatistics_applicationData[];
  adViews: ViewsStatistics_viewsStatistics_adViews[];
  applyViews: ViewsStatistics_viewsStatistics_applyViews[];
  adSourcePages: ViewsStatistics_viewsStatistics_adSourcePages[];
  applySourcePages: ViewsStatistics_viewsStatistics_applySourcePages[];
  completeSourcePages: ViewsStatistics_viewsStatistics_completeSourcePages[];
}

export interface ViewsStatistics {
  viewsStatistics: ViewsStatistics_viewsStatistics | null;
}

export interface ViewsStatisticsVariables {
  companyId: string;
  recruitmentId?: string | null;
  showResultsFromChildCompanies?: boolean | null;
  from?: DateTime | null;
  to?: DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Statuses
// ====================================================

export interface Statuses_statuses_company {
  __typename: "Company";
  id: string;
}

export interface Statuses_statuses {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
  description: string | null;
  company: Statuses_statuses_company;
  recruitmentCount: number;
  consultantCount: number;
  customerCount: number;
  projectCount: number;
}

export interface Statuses {
  statuses: Statuses_statuses[];
}

export interface StatusesVariables {
  companyId: string;
  type: STATUS_TYPE;
  recruitmentFilters?: RecruitmentFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Status
// ====================================================

export interface Status_status_company {
  __typename: "Company";
  id: string;
}

export interface Status_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
  description: string | null;
  company: Status_status_company;
}

export interface Status {
  status: Status_status | null;
}

export interface StatusVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StatusDeadline
// ====================================================

export interface StatusDeadline_statusDeadline_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface StatusDeadline_statusDeadline_status {
  __typename: "Status";
  id: string;
}

export interface StatusDeadline_statusDeadline {
  __typename: "StatusDeadline";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  deadline: DateTime;
  recruitment: StatusDeadline_statusDeadline_recruitment | null;
  status: StatusDeadline_statusDeadline_status;
}

export interface StatusDeadline {
  statusDeadline: StatusDeadline_statusDeadline | null;
}

export interface StatusDeadlineVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StatusDeadlines
// ====================================================

export interface StatusDeadlines_statusDeadlines_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface StatusDeadlines_statusDeadlines_status {
  __typename: "Status";
  id: string;
}

export interface StatusDeadlines_statusDeadlines {
  __typename: "StatusDeadline";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  deadline: DateTime;
  recruitment: StatusDeadlines_statusDeadlines_recruitment | null;
  status: StatusDeadlines_statusDeadlines_status;
}

export interface StatusDeadlines {
  statusDeadlines: StatusDeadlines_statusDeadlines[];
}

export interface StatusDeadlinesVariables {
  recruitmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TagLabels
// ====================================================

export interface TagLabels_tagLabels {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface TagLabels {
  tagLabels: TagLabels_tagLabels[];
}

export interface TagLabelsVariables {
  companyId?: string | null;
  applicationId?: string | null;
  applicantId?: string | null;
  recruitmentId?: string | null;
  consultantId?: string | null;
  groupCompanyApplicantId?: string | null;
  consultantProjectId?: string | null;
  recruitmentTemplateId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TagLabelsSearch
// ====================================================

export interface TagLabelsSearch_tagLabelsSearch_edges_node {
  __typename: "BaseTag";
  id: string;
  tag: string;
}

export interface TagLabelsSearch_tagLabelsSearch_edges {
  __typename: "BaseTagEdge";
  cursor: string;
  node: TagLabelsSearch_tagLabelsSearch_edges_node;
}

export interface TagLabelsSearch_tagLabelsSearch_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface TagLabelsSearch_tagLabelsSearch {
  __typename: "BaseTagConnection";
  edges: (TagLabelsSearch_tagLabelsSearch_edges | null)[];
  pageInfo: TagLabelsSearch_tagLabelsSearch_pageInfo;
}

export interface TagLabelsSearch {
  tagLabelsSearch: TagLabelsSearch_tagLabelsSearch;
}

export interface TagLabelsSearchVariables {
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  searchText?: string | null;
  excludedApplicationId?: string | null;
  excludedApplicantId?: string | null;
  excludedGroupCompanyApplicantId?: string | null;
  excludedRecruitmentId?: string | null;
  excludedConsultantProjectId?: string | null;
  excludedRecruitmentTemplateId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TengaiInterview
// ====================================================

export interface TengaiInterview_tengaiInterview {
  __typename: "TengaiInterview";
  id: string;
  status: TENGAI_INTERVIEW_STATUS;
  updatedAt: DateTime;
  totalPersonalityIndicatorScore: number | null;
  personalityIndicatorScore: number | null;
  behaviouralIndicatorScore: number | null;
  interviewUrl: string | null;
}

export interface TengaiInterview {
  tengaiInterview: TengaiInterview_tengaiInterview | null;
}

export interface TengaiInterviewVariables {
  applicationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Educations
// ====================================================

export interface Educations_educations {
  __typename: "SearchableEducation";
  id: string;
  education: string;
}

export interface Educations {
  educations: Educations_educations[];
}

export interface EducationsVariables {
  name: string;
  university: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Universities
// ====================================================

export interface Universities_universities {
  __typename: "SearchableUniversity";
  id: string;
  name: string;
}

export interface Universities {
  universities: Universities_universities[];
}

export interface UniversitiesVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyUsers
// ====================================================

export interface CompanyUsers_companyUsers_edges_node_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CompanyUsers_companyUsers_edges_node {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: CompanyUsers_companyUsers_edges_node_profileImage | null;
}

export interface CompanyUsers_companyUsers_edges {
  __typename: "UserEdge";
  cursor: string;
  node: CompanyUsers_companyUsers_edges_node;
}

export interface CompanyUsers_companyUsers_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface CompanyUsers_companyUsers {
  __typename: "UserConnection";
  edges: (CompanyUsers_companyUsers_edges | null)[];
  pageInfo: CompanyUsers_companyUsers_pageInfo;
}

export interface CompanyUsers {
  companyUsers: CompanyUsers_companyUsers;
}

export interface CompanyUsersVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  includeSelf?: boolean | null;
  sortBy?: string | null;
  onlyInvited?: boolean | null;
  searchText?: string | null;
  showResultsFromParentCompanies?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InvitedUsers
// ====================================================

export interface InvitedUsers_invitedUsers_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface InvitedUsers_invitedUsers_edges_node {
  __typename: "UserInvitation";
  id: string;
  createdAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  privilegeLevel: USER_PRIVILEGE_LEVEL | null;
}

export interface InvitedUsers_invitedUsers_edges {
  __typename: "UserInvitationEdge";
  cursor: string;
  node: InvitedUsers_invitedUsers_edges_node;
}

export interface InvitedUsers_invitedUsers {
  __typename: "UserInvitationConnection";
  pageInfo: InvitedUsers_invitedUsers_pageInfo;
  edges: (InvitedUsers_invitedUsers_edges | null)[];
}

export interface InvitedUsers {
  invitedUsers: InvitedUsers_invitedUsers;
}

export interface InvitedUsersVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: User
// ====================================================

export interface User_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface User_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: User_user_profileImage | null;
}

export interface User {
  user: User_user | null;
}

export interface UserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Users
// ====================================================

export interface Users_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Users_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: Users_users_profileImage | null;
}

export interface Users {
  users: Users_users[];
}

export interface UsersVariables {
  companyId: string;
  recruitmentId?: string | null;
  searchText?: string | null;
  includeSelf?: boolean | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Me
// ====================================================

export interface Me_me_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Me_me_company_childCompanies_childCompanies {
  __typename: "Company";
  id: string;
}

export interface Me_me_company_childCompanies {
  __typename: "Company";
  id: string;
  childCompanies: Me_me_company_childCompanies_childCompanies[];
}

export interface Me_me_company_parentCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Me_me_company_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface Me_me_company_enabledFeatures {
  __typename: "EnabledFeatures";
  id: string;
  assignments: boolean | null;
  calendar: boolean | null;
  consultantProjects: boolean | null;
  contracts: boolean | null;
  screeningForms: boolean | null;
  referenceForms: boolean | null;
  trustcruit: boolean | null;
  applicationsRankedWithAI: boolean | null;
  interviewBooking: boolean | null;
  interviewBookingGroups: boolean | null;
  alvaLabs: boolean | null;
  tengai: boolean | null;
  allApplicationsPage: boolean | null;
  aiGeneration: boolean | null;
  careerPage: boolean | null;
  mediaLibrary: boolean | null;
  jobtip: boolean | null;
  evaluationForm: boolean | null;
  statusDeadlines: boolean | null;
  videoInApplications: boolean | null;
}

export interface Me_me_company {
  __typename: "Company";
  id: string;
  name: string;
  apiKey: string | null;
  language: string | null;
  type: COMPANY_TYPE | null;
  childCompanies: Me_me_company_childCompanies[];
  parentCompany: Me_me_company_parentCompany | null;
  profileImage: Me_me_company_profileImage | null;
  enabledFeatures: Me_me_company_enabledFeatures | null;
}

export interface Me_me {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  language: string | null;
  phoneNumber: string | null;
  jobTitle: string | null;
  profileImage: Me_me_profileImage | null;
  company: Me_me_company;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  subscribedMailNotificationTypes: NOTIFICATION_TYPE[];
  subscribedToNotificationsStatus: SUBSCRIBED_TO_NOTIFICATIONS_STATUS | null;
}

export interface Me {
  me: Me_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UsersAdmin
// ====================================================

export interface UsersAdmin_usersAdmin_items_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UsersAdmin_usersAdmin_items_userAgents {
  __typename: "UserAgent";
  id: string;
  userAgent: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  usageCount: number;
}

export interface UsersAdmin_usersAdmin_items {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: UsersAdmin_usersAdmin_items_profileImage | null;
  userAgents: UsersAdmin_usersAdmin_items_userAgents[];
}

export interface UsersAdmin_usersAdmin {
  __typename: "PaginatedUsers";
  totalCount: number;
  items: UsersAdmin_usersAdmin_items[];
}

export interface UsersAdmin {
  usersAdmin: UsersAdmin_usersAdmin;
}

export interface UsersAdminVariables {
  page?: number | null;
  pageSize?: number | null;
  searchText?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserInvitation
// ====================================================

export interface UserInvitation_userInvitation {
  __typename: "UserInvitationExposed";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  signedUp: boolean;
  privilegeLevel: USER_PRIVILEGE_LEVEL | null;
}

export interface UserInvitation {
  userInvitation: UserInvitation_userInvitation | null;
}

export interface UserInvitationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllCompanysVariables
// ====================================================

export interface AllCompanysVariables_allCompanysVariables_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface AllCompanysVariables_allCompanysVariables_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface AllCompanysVariables_allCompanysVariables {
  __typename: "Variable";
  id: string;
  key: string;
  value: string;
  description: string | null;
  company: AllCompanysVariables_allCompanysVariables_company;
  createdAt: DateTime;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: AllCompanysVariables_allCompanysVariables_companiesWithAccess[];
}

export interface AllCompanysVariables {
  allCompanysVariables: AllCompanysVariables_allCompanysVariables[];
}

export interface AllCompanysVariablesVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Variable
// ====================================================

export interface Variable_variable_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Variable_variable_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Variable_variable {
  __typename: "Variable";
  id: string;
  key: string;
  value: string;
  description: string | null;
  company: Variable_variable_company;
  createdAt: DateTime;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: Variable_variable_companiesWithAccess[];
}

export interface Variable {
  variable: Variable_variable | null;
}

export interface VariableVariables {
  companyId: string;
  key?: string | null;
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Variables
// ====================================================

export interface Variables_variables_items_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Variables_variables_items_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Variables_variables_items {
  __typename: "Variable";
  id: string;
  key: string;
  value: string;
  description: string | null;
  company: Variables_variables_items_company;
  createdAt: DateTime;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: Variables_variables_items_companiesWithAccess[];
}

export interface Variables_variables {
  __typename: "PaginatedVariables";
  totalCount: number;
  items: Variables_variables_items[];
}

export interface Variables {
  variables: Variables_variables;
}

export interface VariablesVariables {
  key: string;
  companyId?: string | null;
  page?: number | null;
  pageSize?: number | null;
  searchText?: string | null;
  searchTextOptions?: string[] | null;
  sortBy?: string | null;
  sortOrder?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Widget
// ====================================================

export interface Widget_widget {
  __typename: "Widget";
  id: string;
  name: string;
  description: string | null;
  type: WIDGET_TYPE;
  customCss: boolean | null;
  css: string | null;
  allowSorting: boolean | null;
  showCompanyLogoOnAds: boolean | null;
  showCompanyNameOnAds: boolean | null;
  showExpiredAds: boolean | null;
  primaryColor: string | null;
  secondaryColor: string | null;
}

export interface Widget {
  widget: Widget_widget | null;
}

export interface WidgetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Widgets
// ====================================================

export interface Widgets_widgets_edges_node {
  __typename: "Widget";
  id: string;
  name: string;
  description: string | null;
  type: WIDGET_TYPE;
  customCss: boolean | null;
  css: string | null;
  allowSorting: boolean | null;
  showCompanyLogoOnAds: boolean | null;
  showCompanyNameOnAds: boolean | null;
  showExpiredAds: boolean | null;
  primaryColor: string | null;
  secondaryColor: string | null;
}

export interface Widgets_widgets_edges {
  __typename: "WidgetEdge";
  cursor: string;
  node: Widgets_widgets_edges_node;
}

export interface Widgets_widgets_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface Widgets_widgets {
  __typename: "WidgetConnection";
  edges: (Widgets_widgets_edges | null)[];
  pageInfo: Widgets_widgets_pageInfo;
}

export interface Widgets {
  widgets: Widgets_widgets;
}

export interface WidgetsVariables {
  companyId: string;
  limit: number;
  after?: string | null;
  sortOrder?: string | null;
  sortBy?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ActivityParts
// ====================================================

export interface ActivityParts_comment_mentions {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ActivityParts_comment_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_comment_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: ActivityParts_comment_application_recruitment_company;
}

export interface ActivityParts_comment_application {
  __typename: "Application";
  id: string;
  recruitment: ActivityParts_comment_application_recruitment;
}

export interface ActivityParts_comment_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_comment_recruitment {
  __typename: "Recruitment";
  id: string;
  company: ActivityParts_comment_recruitment_company;
}

export interface ActivityParts_comment_applicant_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_comment_applicant {
  __typename: "CompanyApplicant";
  id: string;
  company: ActivityParts_comment_applicant_company;
}

export interface ActivityParts_comment_assignment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_comment_assignment {
  __typename: "Assignment";
  id: string;
  company: ActivityParts_comment_assignment_company;
}

export interface ActivityParts_comment_consultantProject_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_comment_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  company: ActivityParts_comment_consultantProject_company;
}

export interface ActivityParts_comment {
  __typename: "Comment";
  id: string;
  text: string;
  mentions: ActivityParts_comment_mentions[];
  application: ActivityParts_comment_application | null;
  recruitment: ActivityParts_comment_recruitment | null;
  applicant: ActivityParts_comment_applicant | null;
  assignment: ActivityParts_comment_assignment | null;
  consultantProject: ActivityParts_comment_consultantProject | null;
}

export interface ActivityParts_consultant_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  company: ActivityParts_consultant_company;
}

export interface ActivityParts_applicantMessage_applicant_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_applicantMessage_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: ActivityParts_applicantMessage_applicant_company;
}

export interface ActivityParts_applicantMessage_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_applicantMessage_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: ActivityParts_applicantMessage_application_recruitment_company;
}

export interface ActivityParts_applicantMessage_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: ActivityParts_applicantMessage_application_recruitment;
}

export interface ActivityParts_applicantMessage {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  applicant: ActivityParts_applicantMessage_applicant | null;
  application: ActivityParts_applicantMessage_application | null;
}

export interface ActivityParts_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: ActivityParts_recruitment_company;
}

export interface ActivityParts_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ActivityParts_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: ActivityParts_application_recruitment_company;
}

export interface ActivityParts_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: ActivityParts_application_companyApplicant | null;
  recruitment: ActivityParts_application_recruitment;
}

export interface ActivityParts_calendarEvent_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_calendarEvent {
  __typename: "CalendarEvent";
  id: string;
  startTime: DateTime;
  title: string | null;
  company: ActivityParts_calendarEvent_company;
}

export interface ActivityParts_applicationStatus_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_applicationStatus_recruitment {
  __typename: "Recruitment";
  id: string;
  company: ActivityParts_applicationStatus_recruitment_company;
}

export interface ActivityParts_applicationStatus_recruitmentTemplate_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_applicationStatus_recruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
  company: ActivityParts_applicationStatus_recruitmentTemplate_company;
}

export interface ActivityParts_applicationStatus {
  __typename: "ApplicationStatus";
  id: string;
  type: APPLICATION_STATUS_TYPE;
  title: string | null;
  listIndex: number | null;
  color: string | null;
  recruitment: ActivityParts_applicationStatus_recruitment | null;
  recruitmentTemplate: ActivityParts_applicationStatus_recruitmentTemplate | null;
}

export interface ActivityParts_applicant_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: ActivityParts_applicant_company;
}

export interface ActivityParts_consultantProject_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  title: string;
  company: ActivityParts_consultantProject_company;
}

export interface ActivityParts_consultantCustomer_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_consultantCustomer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
  company: ActivityParts_consultantCustomer_company;
}

export interface ActivityParts_connectedStatus_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_connectedStatus {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
  company: ActivityParts_connectedStatus_company;
}

export interface ActivityParts_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ActivityParts_user_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: ActivityParts_user_profileImage | null;
  company: ActivityParts_user_company;
}

export interface ActivityParts_assignment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_assignment_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface ActivityParts_assignment_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface ActivityParts_assignment_application {
  __typename: "Application";
  id: string;
  recruitment: ActivityParts_assignment_application_recruitment;
}

export interface ActivityParts_assignment {
  __typename: "Assignment";
  id: string;
  title: string;
  company: ActivityParts_assignment_company;
  recruitment: ActivityParts_assignment_recruitment | null;
  application: ActivityParts_assignment_application | null;
}

export interface ActivityParts_contract_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ActivityParts_contract_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface ActivityParts_contract_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: ActivityParts_contract_application_recruitment;
}

export interface ActivityParts_contract_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ActivityParts_contract_customer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
}

export interface ActivityParts_contract_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_contract {
  __typename: "Contract";
  id: string;
  title: string;
  type: CONTRACT_TYPE | null;
  applicant: ActivityParts_contract_applicant | null;
  application: ActivityParts_contract_application | null;
  consultant: ActivityParts_contract_consultant | null;
  customer: ActivityParts_contract_customer | null;
  company: ActivityParts_contract_company | null;
}

export interface ActivityParts_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
  company: ActivityParts_interviewBookingGroup_company;
}

export interface ActivityParts_interviewBookingGroupApplication_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  startTime: DateTime;
  endTime: DateTime;
}

export interface ActivityParts_interviewBookingGroupApplication_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ActivityParts_interviewBookingGroupApplication_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: ActivityParts_interviewBookingGroupApplication_application_recruitment;
}

export interface ActivityParts_interviewBookingGroupApplication_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_interviewBookingGroupApplication_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  company: ActivityParts_interviewBookingGroupApplication_interviewBookingGroup_company;
}

export interface ActivityParts_interviewBookingGroupApplication {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  bookedInterview: ActivityParts_interviewBookingGroupApplication_bookedInterview | null;
  application: ActivityParts_interviewBookingGroupApplication_application;
  interviewBookingGroup: ActivityParts_interviewBookingGroupApplication_interviewBookingGroup;
}

export interface ActivityParts_referenceForm_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_referenceForm_form {
  __typename: "Form";
  id: string;
  title: string | null;
}

export interface ActivityParts_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
  company: ActivityParts_referenceForm_company;
  form: ActivityParts_referenceForm_form;
}

export interface ActivityParts_screeningForm_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_screeningForm_form {
  __typename: "Form";
  id: string;
  title: string | null;
}

export interface ActivityParts_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
  company: ActivityParts_screeningForm_company;
  form: ActivityParts_screeningForm_form;
}

export interface ActivityParts_formParticipant_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface ActivityParts_formParticipant_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: ActivityParts_formParticipant_application_recruitment_company;
}

export interface ActivityParts_formParticipant_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: ActivityParts_formParticipant_application_recruitment;
}

export interface ActivityParts_formParticipant_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ActivityParts_formParticipant {
  __typename: "FormParticipant";
  id: string;
  application: ActivityParts_formParticipant_application | null;
  reference: ActivityParts_formParticipant_reference | null;
}

export interface ActivityParts {
  __typename: "Activity";
  id: string;
  type: ActivityType;
  createdAt: DateTime;
  grade: number | null;
  status: STATUS | null;
  comment: ActivityParts_comment | null;
  consultant: ActivityParts_consultant | null;
  applicantMessage: ActivityParts_applicantMessage | null;
  recruitment: ActivityParts_recruitment | null;
  application: ActivityParts_application | null;
  calendarEvent: ActivityParts_calendarEvent | null;
  applicationStatus: ActivityParts_applicationStatus | null;
  applicant: ActivityParts_applicant | null;
  consultantProject: ActivityParts_consultantProject | null;
  consultantCustomer: ActivityParts_consultantCustomer | null;
  connectedStatus: ActivityParts_connectedStatus | null;
  user: ActivityParts_user | null;
  assignment: ActivityParts_assignment | null;
  contract: ActivityParts_contract | null;
  interviewBookingGroup: ActivityParts_interviewBookingGroup | null;
  interviewBookingGroupApplication: ActivityParts_interviewBookingGroupApplication | null;
  referenceForm: ActivityParts_referenceForm | null;
  screeningForm: ActivityParts_screeningForm | null;
  formParticipant: ActivityParts_formParticipant | null;
  assignmentMove: ASSIGNMENT_STATUS | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdContactPersonParts
// ====================================================

export interface AdContactPersonParts_profileImage {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
}

export interface AdContactPersonParts {
  __typename: "AdContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  copyFromRecruitment: boolean | null;
  profileImage: AdContactPersonParts_profileImage | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminCompanyParts
// ====================================================

export interface AdminCompanyParts_applicantMessages {
  __typename: "ApplicantMessage";
  id: string;
  createdAt: DateTime;
  sentVia: MESSAGE_CHANNEL[];
}

export interface AdminCompanyParts_users {
  __typename: "User";
  id: string;
}

export interface AdminCompanyParts_recruitments {
  __typename: "Recruitment";
  id: string;
}

export interface AdminCompanyParts {
  __typename: "Company";
  id: string;
  createdAt: DateTime;
  name: string;
  type: COMPANY_TYPE | null;
  paymentType: COMPANY_PAYMENT_TYPE | null;
  agreementExpirationDate: DateTime | null;
  applicantMessages: AdminCompanyParts_applicantMessages[];
  users: AdminCompanyParts_users[];
  averageMonthlyCost: number;
  isPayingCustomer: boolean | null;
  recruitments: AdminCompanyParts_recruitments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdParts
// ====================================================

export interface AdParts_coverImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AdParts_blocks_block_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AdParts_blocks_block_coverImage_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AdParts_blocks_block_coverImage {
  __typename: "CoverImage";
  id: string;
  fullScreen: boolean | null;
  variant: COVER_IMAGE_VARIANT | null;
  fade: boolean | null;
  height: number | null;
  file: AdParts_blocks_block_coverImage_file | null;
}

export interface AdParts_blocks_block_contactPerson_profileImage {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
}

export interface AdParts_blocks_block_contactPerson {
  __typename: "AdContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  copyFromRecruitment: boolean | null;
  profileImage: AdParts_blocks_block_contactPerson_profileImage | null;
}

export interface AdParts_blocks_block_video {
  __typename: "Video";
  id: string;
  url: string | null;
  youtubeId: string | null;
  autoPlay: boolean | null;
  loop: boolean | null;
}

export interface AdParts_blocks_block {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
  title: string | null;
  text: string | null;
  url: string | null;
  copyShortPresentationFromCompany: boolean | null;
  file: AdParts_blocks_block_file | null;
  adMetaInfo: string[] | null;
  shareButtons: SOCIAL_MEDIA_SOURCE[] | null;
  coverImage: AdParts_blocks_block_coverImage | null;
  contactPerson: AdParts_blocks_block_contactPerson | null;
  video: AdParts_blocks_block_video | null;
}

export interface AdParts_blocks {
  __typename: "ConnectedContentBlock";
  id: string;
  listIndex: number;
  block: AdParts_blocks_block;
}

export interface AdParts {
  __typename: "Ad";
  id: string;
  title: string | null;
  design: string | null;
  content: string[];
  titleFont: string | null;
  textFont: string | null;
  applyButtonText: string | null;
  applyButtonLink: string | null;
  useCustomOgImage: boolean | null;
  ogDescription: string | null;
  coverImage: AdParts_coverImage | null;
  blocks: AdParts_blocks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ApplicantCompetenceParts
// ====================================================

export interface ApplicantCompetenceParts_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface ApplicantCompetenceParts {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: ApplicantCompetenceParts_competence;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ApplicationParts
// ====================================================

export interface ApplicationParts_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  listIndex: number | null;
  createdAt: DateTime;
  type: APPLICATION_STATUS_TYPE;
}

export interface ApplicationParts_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ApplicationParts_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ApplicationParts_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: ApplicationParts_grades_visitor | null;
  setBy: ApplicationParts_grades_setBy | null;
}

export interface ApplicationParts_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ApplicationParts_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  company: ApplicationParts_recruitment_company;
}

export interface ApplicationParts_companyApplicant_applications {
  __typename: "Application";
  id: string;
}

export interface ApplicationParts_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
  email: string;
  applications: ApplicationParts_companyApplicant_applications[];
}

export interface ApplicationParts_interviewBookingGroups_application {
  __typename: "Application";
  id: string;
}

export interface ApplicationParts_interviewBookingGroups {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  application: ApplicationParts_interviewBookingGroups_application;
}

export interface ApplicationParts_video {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ApplicationParts_competences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface ApplicationParts_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: ApplicationParts_competences_competence;
}

export interface ApplicationParts_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface ApplicationParts_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: ApplicationParts_documents_file;
  type: DOCUMENT_TYPE;
}

export interface ApplicationParts_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface ApplicationParts_workExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface ApplicationParts {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  approvedForInterview: boolean;
  offeredEmployment: boolean | null;
  latestMessageTime: DateTime | null;
  messagesRead: boolean | null;
  anonymizedWords: (string | null)[];
  isAnonymized: boolean;
  status: ApplicationParts_status | null;
  averageGrade: number;
  grades: ApplicationParts_grades[];
  recruitment: ApplicationParts_recruitment;
  companyApplicant: ApplicationParts_companyApplicant | null;
  interviewBookingGroups: ApplicationParts_interviewBookingGroups[];
  video: ApplicationParts_video | null;
  competences: ApplicationParts_competences[];
  documents: ApplicationParts_documents[];
  freeText: string | null;
  educations: ApplicationParts_educations[];
  workExperiences: ApplicationParts_workExperiences[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ApplicationStatusParts
// ====================================================

export interface ApplicationStatusParts_messageTemplate_company {
  __typename: "Company";
  id: string;
}

export interface ApplicationStatusParts_messageTemplate {
  __typename: "MessageTemplate";
  id: string;
  templateName: string | null;
  header: string;
  content: string;
  company: ApplicationStatusParts_messageTemplate_company | null;
}

export interface ApplicationStatusParts_eventTriggers {
  __typename: "EventTrigger";
  id: string;
  actionType: EVENT_TRIGGER_ACTION_TYPE;
  screeningFormId: string | null;
  referenceFormId: string | null;
}

export interface ApplicationStatusParts {
  __typename: "ApplicationStatus";
  id: string;
  type: APPLICATION_STATUS_TYPE;
  listIndex: number | null;
  title: string | null;
  description: string | null;
  color: string | null;
  applicationCount: number;
  messageTemplate: ApplicationStatusParts_messageTemplate | null;
  eventTriggers: ApplicationStatusParts_eventTriggers[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssignmentParts
// ====================================================

export interface AssignmentParts_column {
  __typename: "AssignmentColumn";
  id: string;
  title: string;
}

export interface AssignmentParts_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AssignmentParts_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: AssignmentParts_createdBy_profileImage | null;
}

export interface AssignmentParts_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface AssignmentParts_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  profileImage: AssignmentParts_users_profileImage | null;
}

export interface AssignmentParts_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface AssignmentParts_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: AssignmentParts_application_companyApplicant | null;
}

export interface AssignmentParts_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface AssignmentParts_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface AssignmentParts_comments {
  __typename: "Comment";
  id: string;
}

export interface AssignmentParts {
  __typename: "Assignment";
  id: string;
  status: ASSIGNMENT_STATUS | null;
  title: string;
  description: string | null;
  order: number;
  column: AssignmentParts_column | null;
  createdBy: AssignmentParts_createdBy | null;
  users: AssignmentParts_users[];
  application: AssignmentParts_application | null;
  applicant: AssignmentParts_applicant | null;
  recruitment: AssignmentParts_recruitment | null;
  comments: AssignmentParts_comments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseTagParts
// ====================================================

export interface BaseTagParts {
  __typename: "BaseTag";
  id: string;
  tag: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BaseVariableParts
// ====================================================

export interface BaseVariableParts_companies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface BaseVariableParts_variables_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface BaseVariableParts_variables {
  __typename: "Variable";
  id: string;
  key: string;
  value: string;
  company: BaseVariableParts_variables_company;
}

export interface BaseVariableParts {
  __typename: "BaseVariable";
  id: string;
  key: string;
  companies: BaseVariableParts_companies[];
  variables: BaseVariableParts_variables[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CalendarEventParts
// ====================================================

export interface CalendarEventParts_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CalendarEventParts_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CalendarEventParts_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CalendarEventParts_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: CalendarEventParts_attendees_eventMatch_talentProfile;
}

export interface CalendarEventParts_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface CalendarEventParts_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: CalendarEventParts_attendees_application_recruitment;
}

export interface CalendarEventParts_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CalendarEventParts_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CalendarEventParts_attendees_user_profileImage | null;
}

export interface CalendarEventParts_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: CalendarEventParts_attendees_eventMatch | null;
  application: CalendarEventParts_attendees_application | null;
  user: CalendarEventParts_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface CalendarEventParts_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface CalendarEventParts_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface CalendarEventParts {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: CalendarEventParts_recruitment | null;
  company: CalendarEventParts_company;
  attendees: CalendarEventParts_attendees[];
  interviewBookingGroup: CalendarEventParts_interviewBookingGroup | null;
  matchingEventTalentProfiles: CalendarEventParts_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyApplicantParts
// ====================================================

export interface CompanyApplicantParts_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CompanyApplicantParts_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: CompanyApplicantParts_applications_recruitment_company;
}

export interface CompanyApplicantParts_applications_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface CompanyApplicantParts_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  recruitment: CompanyApplicantParts_applications_recruitment;
  educations: CompanyApplicantParts_applications_educations[];
}

export interface CompanyApplicantParts_messages_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CompanyApplicantParts_messages_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CompanyApplicantParts_messages_user_profileImage | null;
}

export interface CompanyApplicantParts_messages {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  sentVia: MESSAGE_CHANNEL[];
  sentByApplicant: boolean;
  createdAt: DateTime;
  user: CompanyApplicantParts_messages_user | null;
}

export interface CompanyApplicantParts_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface CompanyApplicantParts_workExperiences {
  __typename: "WorkExperience";
  id: string;
  position: string | null;
  companyName: string | null;
}

export interface CompanyApplicantParts_competences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface CompanyApplicantParts_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: CompanyApplicantParts_competences_competence;
}

export interface CompanyApplicantParts_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface CompanyApplicantParts_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface CompanyApplicantParts_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: CompanyApplicantParts_grades_visitor | null;
  setBy: CompanyApplicantParts_grades_setBy | null;
}

export interface CompanyApplicantParts_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface CompanyApplicantParts {
  __typename: "CompanyApplicant";
  id: string;
  createdAt: DateTime | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  averageGrade: number;
  isAnonymized: boolean;
  applications: CompanyApplicantParts_applications[];
  messagesRead: boolean | null;
  latestMessageTime: DateTime | null;
  messages: CompanyApplicantParts_messages[];
  educations: CompanyApplicantParts_educations[];
  workExperiences: CompanyApplicantParts_workExperiences[];
  competences: CompanyApplicantParts_competences[];
  grades: CompanyApplicantParts_grades[];
  tags: CompanyApplicantParts_tags[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyParts
// ====================================================

export interface CompanyParts_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CompanyParts_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: CompanyParts_users_profileImage | null;
}

export interface CompanyParts_parentCompany_parentCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CompanyParts_parentCompany {
  __typename: "Company";
  id: string;
  name: string;
  parentCompany: CompanyParts_parentCompany_parentCompany | null;
}

export interface CompanyParts_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface CompanyParts_companyFont {
  __typename: "File";
  id: string;
  url: string | null;
  filename: string;
}

export interface CompanyParts_childCompanies {
  __typename: "Company";
  id: string;
}

export interface CompanyParts_defaultRecruitmentTemplate {
  __typename: "RecruitmentTemplate";
  id: string;
}

export interface CompanyParts_enabledFeatures {
  __typename: "EnabledFeatures";
  id: string;
  assignments: boolean | null;
  calendar: boolean | null;
  consultantProjects: boolean | null;
  contracts: boolean | null;
  screeningForms: boolean | null;
  referenceForms: boolean | null;
  trustcruit: boolean | null;
  applicationsRankedWithAI: boolean | null;
  interviewBooking: boolean | null;
  interviewBookingGroups: boolean | null;
  alvaLabs: boolean | null;
  tengai: boolean | null;
  allApplicationsPage: boolean | null;
  aiGeneration: boolean | null;
  careerPage: boolean | null;
  mediaLibrary: boolean | null;
  jobtip: boolean | null;
  evaluationForm: boolean | null;
  statusDeadlines: boolean | null;
  videoInApplications: boolean | null;
}

export interface CompanyParts {
  __typename: "Company";
  id: string;
  createdAt: DateTime;
  name: string;
  slug: string | null;
  subdomain: string | null;
  orgNumber: string | null;
  primaryColor: string | null;
  language: string | null;
  shortPresentation: string | null;
  postalCode: string | null;
  postalAddress: string | null;
  municipality: string | null;
  websiteUrl: string | null;
  type: COMPANY_TYPE | null;
  applicationCount: number;
  users: CompanyParts_users[];
  parentCompany: CompanyParts_parentCompany | null;
  profileImage: CompanyParts_profileImage | null;
  companyFont: CompanyParts_companyFont | null;
  companyWebFont: string | null;
  titleFont: string | null;
  textFont: string | null;
  apiKey: string | null;
  childCompanies: CompanyParts_childCompanies[];
  defaultRecruitmentTemplate: CompanyParts_defaultRecruitmentTemplate | null;
  enabledFeatures: CompanyParts_enabledFeatures | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ConnectedContentBlockParts
// ====================================================

export interface ConnectedContentBlockParts_block_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConnectedContentBlockParts_block_coverImage_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConnectedContentBlockParts_block_coverImage {
  __typename: "CoverImage";
  id: string;
  fullScreen: boolean | null;
  variant: COVER_IMAGE_VARIANT | null;
  fade: boolean | null;
  height: number | null;
  file: ConnectedContentBlockParts_block_coverImage_file | null;
}

export interface ConnectedContentBlockParts_block_contactPerson_profileImage {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
}

export interface ConnectedContentBlockParts_block_contactPerson {
  __typename: "AdContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  copyFromRecruitment: boolean | null;
  profileImage: ConnectedContentBlockParts_block_contactPerson_profileImage | null;
}

export interface ConnectedContentBlockParts_block_video {
  __typename: "Video";
  id: string;
  url: string | null;
  youtubeId: string | null;
  autoPlay: boolean | null;
  loop: boolean | null;
}

export interface ConnectedContentBlockParts_block {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
  title: string | null;
  text: string | null;
  url: string | null;
  copyShortPresentationFromCompany: boolean | null;
  file: ConnectedContentBlockParts_block_file | null;
  adMetaInfo: string[] | null;
  shareButtons: SOCIAL_MEDIA_SOURCE[] | null;
  coverImage: ConnectedContentBlockParts_block_coverImage | null;
  contactPerson: ConnectedContentBlockParts_block_contactPerson | null;
  video: ConnectedContentBlockParts_block_video | null;
}

export interface ConnectedContentBlockParts {
  __typename: "ConnectedContentBlock";
  id: string;
  listIndex: number;
  block: ConnectedContentBlockParts_block;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ConsultantCustomerParts
// ====================================================

export interface ConsultantCustomerParts_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConsultantCustomerParts_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ConsultantCustomerParts_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ConsultantCustomerParts_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantCustomerParts_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: ConsultantCustomerParts_customerCompany_profileImage | null;
}

export interface ConsultantCustomerParts {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: ConsultantCustomerParts_invoiceInformation | null;
  status: ConsultantCustomerParts_status | null;
  companiesWithAccess: ConsultantCustomerParts_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: ConsultantCustomerParts_customerCompany | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ConsultantParts
// ====================================================

export interface ConsultantParts_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ConsultantParts_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface ConsultantParts_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: ConsultantParts_grades_visitor | null;
  setBy: ConsultantParts_grades_setBy | null;
}

export interface ConsultantParts_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ConsultantParts_formAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface ConsultantParts_formAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: ConsultantParts_formAnswers_question_matchingEventProfile | null;
}

export interface ConsultantParts_formAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: ConsultantParts_formAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface ConsultantParts_projects_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConsultantParts_projects_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface ConsultantParts_projects_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantParts_projects_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConsultantParts_projects_users_user_profileImage | null;
}

export interface ConsultantParts_projects_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface ConsultantParts_projects_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: ConsultantParts_projects_users_user;
  consultantProject: ConsultantParts_projects_users_consultantProject;
}

export interface ConsultantParts_projects_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConsultantParts_projects_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ConsultantParts_projects_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ConsultantParts_projects_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantParts_projects_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: ConsultantParts_projects_customer_customerCompany_profileImage | null;
}

export interface ConsultantParts_projects_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: ConsultantParts_projects_customer_invoiceInformation | null;
  status: ConsultantParts_projects_customer_status | null;
  companiesWithAccess: ConsultantParts_projects_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: ConsultantParts_projects_customer_customerCompany | null;
}

export interface ConsultantParts_projects_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ConsultantParts_projects_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantParts_projects_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConsultantParts_projects_responsible_profileImage | null;
}

export interface ConsultantParts_projects_tags {
  __typename: "TagLabel";
  id: string;
}

export interface ConsultantParts_projects_comments {
  __typename: "Comment";
  id: string;
}

export interface ConsultantParts_projects_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ConsultantParts_projects_files {
  __typename: "File";
  id: string;
}

export interface ConsultantParts_projects_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface ConsultantParts_projects_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: ConsultantParts_projects_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface ConsultantParts_projects {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: ConsultantParts_projects_invoiceInformation;
  status: ConsultantParts_projects_status | null;
  users: ConsultantParts_projects_users[];
  customer: ConsultantParts_projects_customer | null;
  customerName: string | null;
  recruitment: ConsultantParts_projects_recruitment | null;
  responsible: ConsultantParts_projects_responsible | null;
  tags: ConsultantParts_projects_tags[];
  comments: ConsultantParts_projects_comments[];
  consultants: ConsultantParts_projects_consultants[];
  files: ConsultantParts_projects_files[];
  fieldExtensionValues: ConsultantParts_projects_fieldExtensionValues[];
}

export interface ConsultantParts_applicant {
  __typename: "CompanyApplicant";
  id: string;
  phoneNumber: string | null;
}

export interface ConsultantParts_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface ConsultantParts_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: ConsultantParts_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface ConsultantParts {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  averageGrade: number;
  createdAt: DateTime;
  note: string | null;
  grades: ConsultantParts_grades[];
  status: ConsultantParts_status | null;
  formAnswers: ConsultantParts_formAnswers[];
  projects: ConsultantParts_projects[];
  applicant: ConsultantParts_applicant;
  fieldExtensionValues: ConsultantParts_fieldExtensionValues[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ConsultantProjectParts
// ====================================================

export interface ConsultantProjectParts_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConsultantProjectParts_status {
  __typename: "Status";
  id: string;
  title: string;
  description: string | null;
  listIndex: number | null;
}

export interface ConsultantProjectParts_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProjectParts_users_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConsultantProjectParts_users_user_profileImage | null;
}

export interface ConsultantProjectParts_users_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface ConsultantProjectParts_users {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: ConsultantProjectParts_users_user;
  consultantProject: ConsultantProjectParts_users_consultantProject;
}

export interface ConsultantProjectParts_customer_invoiceInformation {
  __typename: "InvoiceInformation";
  id: string;
}

export interface ConsultantProjectParts_customer_status {
  __typename: "Status";
  id: string;
  title: string;
  listIndex: number | null;
}

export interface ConsultantProjectParts_customer_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ConsultantProjectParts_customer_customerCompany_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProjectParts_customer_customerCompany {
  __typename: "Company";
  id: string;
  name: string;
  primaryColor: string | null;
  profileImage: ConsultantProjectParts_customer_customerCompany_profileImage | null;
}

export interface ConsultantProjectParts_customer {
  __typename: "ConsultantCustomer";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  note: string | null;
  invoiceInformation: ConsultantProjectParts_customer_invoiceInformation | null;
  status: ConsultantProjectParts_customer_status | null;
  companiesWithAccess: ConsultantProjectParts_customer_companiesWithAccess[];
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  customerCompany: ConsultantProjectParts_customer_customerCompany | null;
}

export interface ConsultantProjectParts_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ConsultantProjectParts_responsible_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProjectParts_responsible {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConsultantProjectParts_responsible_profileImage | null;
}

export interface ConsultantProjectParts_tags {
  __typename: "TagLabel";
  id: string;
}

export interface ConsultantProjectParts_comments {
  __typename: "Comment";
  id: string;
}

export interface ConsultantProjectParts_consultants {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface ConsultantProjectParts_files {
  __typename: "File";
  id: string;
}

export interface ConsultantProjectParts_fieldExtensionValues_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface ConsultantProjectParts_fieldExtensionValues {
  __typename: "FieldExtensionValue";
  id: string;
  extension: ConsultantProjectParts_fieldExtensionValues_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

export interface ConsultantProjectParts {
  __typename: "ConsultantProject";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  isActive: boolean;
  activeFrom: DateTime | null;
  note: string | null;
  activeTo: DateTime | null;
  businessArea: string | null;
  projectNumber: string | null;
  offerStatus: OFFER_STATUS | null;
  startCost: number;
  recruitmentCost: number;
  invoiceInformation: ConsultantProjectParts_invoiceInformation;
  status: ConsultantProjectParts_status | null;
  users: ConsultantProjectParts_users[];
  customer: ConsultantProjectParts_customer | null;
  customerName: string | null;
  recruitment: ConsultantProjectParts_recruitment | null;
  responsible: ConsultantProjectParts_responsible | null;
  tags: ConsultantProjectParts_tags[];
  comments: ConsultantProjectParts_comments[];
  consultants: ConsultantProjectParts_consultants[];
  files: ConsultantProjectParts_files[];
  fieldExtensionValues: ConsultantProjectParts_fieldExtensionValues[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ConsultantProjectUserParts
// ====================================================

export interface ConsultantProjectUserParts_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ConsultantProjectUserParts_user {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: ConsultantProjectUserParts_user_profileImage | null;
}

export interface ConsultantProjectUserParts_consultantProject {
  __typename: "ConsultantProject";
  id: string;
}

export interface ConsultantProjectUserParts {
  __typename: "ConsultantProjectUser";
  id: string;
  createdAt: DateTime;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
  user: ConsultantProjectUserParts_user;
  consultantProject: ConsultantProjectUserParts_consultantProject;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContactPersonParts
// ====================================================

export interface ContactPersonParts {
  __typename: "ContactPerson";
  id: string;
  createdAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  role: string | null;
  description: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContentBlockParts
// ====================================================

export interface ContentBlockParts_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ContentBlockParts_coverImage_file {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ContentBlockParts_coverImage {
  __typename: "CoverImage";
  id: string;
  fullScreen: boolean | null;
  variant: COVER_IMAGE_VARIANT | null;
  fade: boolean | null;
  height: number | null;
  file: ContentBlockParts_coverImage_file | null;
}

export interface ContentBlockParts_contactPerson_profileImage {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
}

export interface ContentBlockParts_contactPerson {
  __typename: "AdContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  jobTitle: string | null;
  phoneNumber: string | null;
  copyFromRecruitment: boolean | null;
  profileImage: ContentBlockParts_contactPerson_profileImage | null;
}

export interface ContentBlockParts_video {
  __typename: "Video";
  id: string;
  url: string | null;
  youtubeId: string | null;
  autoPlay: boolean | null;
  loop: boolean | null;
}

export interface ContentBlockParts {
  __typename: "ContentBlock";
  id: string;
  type: CONTENT_BLOCK_TYPE;
  title: string | null;
  text: string | null;
  url: string | null;
  copyShortPresentationFromCompany: boolean | null;
  file: ContentBlockParts_file | null;
  adMetaInfo: string[] | null;
  shareButtons: SOCIAL_MEDIA_SOURCE[] | null;
  coverImage: ContentBlockParts_coverImage | null;
  contactPerson: ContentBlockParts_contactPerson | null;
  video: ContentBlockParts_video | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContractParts
// ====================================================

export interface ContractParts_scriveContract_file {
  __typename: "ScriveFile";
  id: string;
  name: string;
}

export interface ContractParts_scriveContract_parties_fields {
  __typename: "ScrivePartyField";
  type: string;
  order: number | null;
  value: string;
}

export interface ContractParts_scriveContract_parties {
  __typename: "ScriveParty";
  id: string;
  sign_time: string | null;
  rejected_time: string | null;
  rejection_reason: string | null;
  email_delivery_status: string | null;
  signatory_role: string | null;
  fields: ContractParts_scriveContract_parties_fields[];
}

export interface ContractParts_scriveContract {
  __typename: "ScriveContract";
  id: string;
  title: string;
  status: string | null;
  file: ContractParts_scriveContract_file | null;
  parties: ContractParts_scriveContract_parties[];
}

export interface ContractParts_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface ContractParts_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface ContractParts_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface ContractParts_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: ContractParts_application_companyApplicant | null;
  recruitment: ContractParts_application_recruitment;
}

export interface ContractParts_consultant_applicant {
  __typename: "CompanyApplicant";
  id: string;
}

export interface ContractParts_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  applicant: ContractParts_consultant_applicant;
}

export interface ContractParts_customer {
  __typename: "ConsultantCustomer";
  id: string;
  name: string;
}

export interface ContractParts_contactPersons {
  __typename: "ContactPerson";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface ContractParts_customParties {
  __typename: "CustomContractParty";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface ContractParts_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface ContractParts_users {
  __typename: "User";
  id: string;
  profileImage: ContractParts_users_profileImage | null;
  firstName: string | null;
  lastName: string | null;
}

export interface ContractParts {
  __typename: "ContractWithScrive";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  scriveId: string;
  type: CONTRACT_TYPE | null;
  scriveContract: ContractParts_scriveContract | null;
  applicant: ContractParts_applicant | null;
  reminders: DateTime[];
  application: ContractParts_application | null;
  consultant: ContractParts_consultant | null;
  customer: ContractParts_customer | null;
  contactPersons: ContractParts_contactPersons[] | null;
  customParties: ContractParts_customParties[] | null;
  users: ContractParts_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CustomPartyParts
// ====================================================

export interface CustomPartyParts {
  __typename: "CustomContractParty";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DepartmentParts
// ====================================================

export interface DepartmentParts_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface DepartmentParts {
  __typename: "Department";
  id: string;
  name: string;
  description: string | null;
  recruitments: DepartmentParts_recruitments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DocumentParts
// ====================================================

export interface DocumentParts_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface DocumentParts {
  __typename: "Document";
  id: string;
  displayName: string;
  file: DocumentParts_file;
  type: DOCUMENT_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EducationParts
// ====================================================

export interface EducationParts {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EnabledFeaturesParts
// ====================================================

export interface EnabledFeaturesParts {
  __typename: "EnabledFeatures";
  id: string;
  assignments: boolean | null;
  calendar: boolean | null;
  consultantProjects: boolean | null;
  contracts: boolean | null;
  screeningForms: boolean | null;
  referenceForms: boolean | null;
  trustcruit: boolean | null;
  applicationsRankedWithAI: boolean | null;
  interviewBooking: boolean | null;
  interviewBookingGroups: boolean | null;
  alvaLabs: boolean | null;
  tengai: boolean | null;
  allApplicationsPage: boolean | null;
  aiGeneration: boolean | null;
  careerPage: boolean | null;
  mediaLibrary: boolean | null;
  jobtip: boolean | null;
  evaluationForm: boolean | null;
  statusDeadlines: boolean | null;
  videoInApplications: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventMatchParts
// ====================================================

export interface EventMatchParts_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface EventMatchParts_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface EventMatchParts_application {
  __typename: "Application";
  id: string;
}

export interface EventMatchParts {
  __typename: "EventMatch";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  createdManually: boolean | null;
  status: EVENT_MATCH_STATUS;
  matchRank: number | null;
  matchPercentageNeedToHave: number;
  matchPercentageNiceToHave: number;
  interviewBookingReminders: DateTime[];
  bookedInterview: EventMatchParts_bookedInterview | null;
  talentProfile: EventMatchParts_talentProfile;
  application: EventMatchParts_application;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FieldExtensionParts
// ====================================================

export interface FieldExtensionParts {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FieldExtensionValueParts
// ====================================================

export interface FieldExtensionValueParts_extension {
  __typename: "FieldExtension";
  id: string;
  title: string;
  listIndex: number;
  description: string | null;
  type: FIELD_EXTENSION_TYPE;
  valueType: FIELD_EXTENSION_VALUE_TYPE;
}

export interface FieldExtensionValueParts {
  __typename: "FieldExtensionValue";
  id: string;
  extension: FieldExtensionValueParts_extension;
  text: string | null;
  number: number;
  array: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FileTemplateParts
// ====================================================

export interface FileTemplateParts_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface FileTemplateParts_file {
  __typename: "File";
  id: string;
  filename: string;
  url: string | null;
  mimetype: string;
}

export interface FileTemplateParts {
  __typename: "FileTemplate";
  id: string;
  createdAt: DateTime;
  name: string;
  description: string | null;
  company: FileTemplateParts_company;
  file: FileTemplateParts_file;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FilterItemParts
// ====================================================

export interface FilterItemParts {
  __typename: "FilterItem";
  id: string;
  label: string;
  subLabel: string | null;
  count: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormParts
// ====================================================

export interface FormParts_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface FormParts_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface FormParts_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: FormParts_participants_application_companyApplicant | null;
}

export interface FormParts_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: FormParts_participants_application | null;
}

export interface FormParts_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface FormParts_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: FormParts_questions_matchingEventProfile | null;
}

export interface FormParts_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface FormParts_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface FormParts {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: FormParts_companiesWithAccess[];
  participants: FormParts_participants[];
  questions: FormParts_questions[];
  referenceForm: FormParts_referenceForm | null;
  screeningForm: FormParts_screeningForm | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormQuestionAnswerEvaluationParts
// ====================================================

export interface FormQuestionAnswerEvaluationParts_formQuestion {
  __typename: "FormQuestion";
  id: string;
}

export interface FormQuestionAnswerEvaluationParts {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  option: string | null;
  significance: REQUIREMENT_SIGNIFICANCE;
  formQuestion: FormQuestionAnswerEvaluationParts_formQuestion | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormQuestionAnswerParts
// ====================================================

export interface FormQuestionAnswerParts_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface FormQuestionAnswerParts_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: FormQuestionAnswerParts_question_matchingEventProfile | null;
}

export interface FormQuestionAnswerParts_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface FormQuestionAnswerParts_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface FormQuestionAnswerParts_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: FormQuestionAnswerParts_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface FormQuestionAnswerParts_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface FormQuestionAnswerParts_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: FormQuestionAnswerParts_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface FormQuestionAnswerParts_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface FormQuestionAnswerParts_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface FormQuestionAnswerParts_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface FormQuestionAnswerParts_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface FormQuestionAnswerParts_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: FormQuestionAnswerParts_answerCompetences_competence;
}

export interface FormQuestionAnswerParts_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface FormQuestionAnswerParts_application {
  __typename: "Application";
  id: string;
  recruitment: FormQuestionAnswerParts_application_recruitment;
}

export interface FormQuestionAnswerParts {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: FormQuestionAnswerParts_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: FormQuestionAnswerParts_answerFile | null;
  answerDocument: FormQuestionAnswerParts_answerDocument | null;
  answerDocuments: FormQuestionAnswerParts_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: FormQuestionAnswerParts_answerEducations[] | null;
  answerWorkExperiences: FormQuestionAnswerParts_answerWorkExperiences[] | null;
  answerPersonalData: FormQuestionAnswerParts_answerPersonalData | null;
  answerCompetences: FormQuestionAnswerParts_answerCompetences[] | null;
  application: FormQuestionAnswerParts_application | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormQuestionParts
// ====================================================

export interface FormQuestionParts_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface FormQuestionParts {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: FormQuestionParts_matchingEventProfile | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GroupCompanyApplicantParts
// ====================================================

export interface GroupCompanyApplicantParts_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface GroupCompanyApplicantParts_applications_recruitment_users_user {
  __typename: "User";
  id: string;
}

export interface GroupCompanyApplicantParts_applications_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: GroupCompanyApplicantParts_applications_recruitment_users_user;
}

export interface GroupCompanyApplicantParts_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupCompanyApplicantParts_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  users: GroupCompanyApplicantParts_applications_recruitment_users[];
  company: GroupCompanyApplicantParts_applications_recruitment_company;
}

export interface GroupCompanyApplicantParts_applications {
  __typename: "Application";
  id: string;
  createdAt: DateTime;
  recruitment: GroupCompanyApplicantParts_applications_recruitment;
}

export interface GroupCompanyApplicantParts_companies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupCompanyApplicantParts_companyApplicants_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupCompanyApplicantParts_companyApplicants {
  __typename: "CompanyApplicant";
  id: string;
  createdAt: DateTime | null;
  company: GroupCompanyApplicantParts_companyApplicants_company;
}

export interface GroupCompanyApplicantParts {
  __typename: "GroupCompanyApplicant";
  id: string;
  createdAt: DateTime;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  tags: GroupCompanyApplicantParts_tags[];
  applications: GroupCompanyApplicantParts_applications[];
  companies: GroupCompanyApplicantParts_companies[];
  companyApplicants: GroupCompanyApplicantParts_companyApplicants[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IdentifiedNeedFormParts
// ====================================================

export interface IdentifiedNeedFormParts_form_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface IdentifiedNeedFormParts_form_participants_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface IdentifiedNeedFormParts_form_participants_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: IdentifiedNeedFormParts_form_participants_application_companyApplicant | null;
}

export interface IdentifiedNeedFormParts_form_participants {
  __typename: "FormParticipant";
  id: string;
  createdAt: DateTime;
  application: IdentifiedNeedFormParts_form_participants_application | null;
}

export interface IdentifiedNeedFormParts_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface IdentifiedNeedFormParts_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: IdentifiedNeedFormParts_form_questions_matchingEventProfile | null;
}

export interface IdentifiedNeedFormParts_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface IdentifiedNeedFormParts_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface IdentifiedNeedFormParts_form {
  __typename: "Form";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  submitButtonText: string | null;
  type: FORM_TYPE;
  status: FORM_STATUS | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: IdentifiedNeedFormParts_form_companiesWithAccess[];
  participants: IdentifiedNeedFormParts_form_participants[];
  questions: IdentifiedNeedFormParts_form_questions[];
  referenceForm: IdentifiedNeedFormParts_form_referenceForm | null;
  screeningForm: IdentifiedNeedFormParts_form_screeningForm | null;
}

export interface IdentifiedNeedFormParts_companyAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface IdentifiedNeedFormParts_companyAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: IdentifiedNeedFormParts_companyAnswers_question_matchingEventProfile | null;
}

export interface IdentifiedNeedFormParts_companyAnswers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface IdentifiedNeedFormParts_companyAnswers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface IdentifiedNeedFormParts_companyAnswers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: IdentifiedNeedFormParts_companyAnswers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface IdentifiedNeedFormParts_companyAnswers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface IdentifiedNeedFormParts_companyAnswers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: IdentifiedNeedFormParts_companyAnswers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface IdentifiedNeedFormParts_companyAnswers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface IdentifiedNeedFormParts_companyAnswers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface IdentifiedNeedFormParts_companyAnswers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface IdentifiedNeedFormParts_companyAnswers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface IdentifiedNeedFormParts_companyAnswers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: IdentifiedNeedFormParts_companyAnswers_answerCompetences_competence;
}

export interface IdentifiedNeedFormParts_companyAnswers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface IdentifiedNeedFormParts_companyAnswers_application {
  __typename: "Application";
  id: string;
  recruitment: IdentifiedNeedFormParts_companyAnswers_application_recruitment;
}

export interface IdentifiedNeedFormParts_companyAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: IdentifiedNeedFormParts_companyAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: IdentifiedNeedFormParts_companyAnswers_answerFile | null;
  answerDocument: IdentifiedNeedFormParts_companyAnswers_answerDocument | null;
  answerDocuments: IdentifiedNeedFormParts_companyAnswers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: IdentifiedNeedFormParts_companyAnswers_answerEducations[] | null;
  answerWorkExperiences: IdentifiedNeedFormParts_companyAnswers_answerWorkExperiences[] | null;
  answerPersonalData: IdentifiedNeedFormParts_companyAnswers_answerPersonalData | null;
  answerCompetences: IdentifiedNeedFormParts_companyAnswers_answerCompetences[] | null;
  application: IdentifiedNeedFormParts_companyAnswers_application | null;
}

export interface IdentifiedNeedFormParts_identifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
  canEdit: boolean | null;
}

export interface IdentifiedNeedFormParts {
  __typename: "IdentifiedNeedForm";
  id: string;
  createdAt: DateTime;
  title: string | null;
  description: string | null;
  form: IdentifiedNeedFormParts_form;
  companyAnswers: IdentifiedNeedFormParts_companyAnswers[];
  identifiedNeed: IdentifiedNeedFormParts_identifiedNeed | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewBookingGroupApplicationParts
// ====================================================

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_company {
  __typename: "Company";
  id: string;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_eventMatch_talentProfile;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_application_recruitment;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_user_profileImage | null;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_eventMatch | null;
  application: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_application | null;
  user: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_recruitment | null;
  company: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_company;
  attendees: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_attendees[];
  interviewBookingGroup: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: InterviewBookingGroupApplicationParts_interviewBookingGroup_users_profileImage | null;
}

export interface InterviewBookingGroupApplicationParts_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string;
  defaultTitle: string | null;
  defaultDescription: string | null;
  defaultLocation: string | null;
  defaultUrl: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: InterviewBookingGroupApplicationParts_interviewBookingGroup_companiesWithAccess[];
  company: InterviewBookingGroupApplicationParts_interviewBookingGroup_company;
  recruitments: InterviewBookingGroupApplicationParts_interviewBookingGroup_recruitments[];
  bookableInterviews: InterviewBookingGroupApplicationParts_interviewBookingGroup_bookableInterviews[];
  users: InterviewBookingGroupApplicationParts_interviewBookingGroup_users[];
}

export interface InterviewBookingGroupApplicationParts_application_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  email: string;
}

export interface InterviewBookingGroupApplicationParts_application_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplicationParts_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: InterviewBookingGroupApplicationParts_application_recruitment_company;
}

export interface InterviewBookingGroupApplicationParts_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  companyApplicant: InterviewBookingGroupApplicationParts_application_companyApplicant | null;
  recruitment: InterviewBookingGroupApplicationParts_application_recruitment;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: InterviewBookingGroupApplicationParts_bookedInterview_attendees_eventMatch_talentProfile;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: InterviewBookingGroupApplicationParts_bookedInterview_attendees_application_recruitment;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InterviewBookingGroupApplicationParts_bookedInterview_attendees_user_profileImage | null;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: InterviewBookingGroupApplicationParts_bookedInterview_attendees_eventMatch | null;
  application: InterviewBookingGroupApplicationParts_bookedInterview_attendees_application | null;
  user: InterviewBookingGroupApplicationParts_bookedInterview_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupApplicationParts_bookedInterview {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: InterviewBookingGroupApplicationParts_bookedInterview_recruitment | null;
  company: InterviewBookingGroupApplicationParts_bookedInterview_company;
  attendees: InterviewBookingGroupApplicationParts_bookedInterview_attendees[];
  interviewBookingGroup: InterviewBookingGroupApplicationParts_bookedInterview_interviewBookingGroup | null;
  matchingEventTalentProfiles: InterviewBookingGroupApplicationParts_bookedInterview_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface InterviewBookingGroupApplicationParts {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  createdAt: DateTime;
  interviewBookingGroup: InterviewBookingGroupApplicationParts_interviewBookingGroup;
  application: InterviewBookingGroupApplicationParts_application;
  bookedInterview: InterviewBookingGroupApplicationParts_bookedInterview | null;
  notifiers: DateTime[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewBookingGroupParts
// ====================================================

export interface InterviewBookingGroupParts_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupParts_company {
  __typename: "Company";
  id: string;
}

export interface InterviewBookingGroupParts_recruitments {
  __typename: "Recruitment";
  id: string;
  title: string;
  type: string | null;
}

export interface InterviewBookingGroupParts_bookableInterviews_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupParts_bookableInterviews_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InterviewBookingGroupParts_bookableInterviews_attendees_eventMatch_talentProfile {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupParts_bookableInterviews_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
  talentProfile: InterviewBookingGroupParts_bookableInterviews_attendees_eventMatch_talentProfile;
}

export interface InterviewBookingGroupParts_bookableInterviews_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface InterviewBookingGroupParts_bookableInterviews_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: InterviewBookingGroupParts_bookableInterviews_attendees_application_recruitment;
}

export interface InterviewBookingGroupParts_bookableInterviews_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupParts_bookableInterviews_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: InterviewBookingGroupParts_bookableInterviews_attendees_user_profileImage | null;
}

export interface InterviewBookingGroupParts_bookableInterviews_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: InterviewBookingGroupParts_bookableInterviews_attendees_eventMatch | null;
  application: InterviewBookingGroupParts_bookableInterviews_attendees_application | null;
  user: InterviewBookingGroupParts_bookableInterviews_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface InterviewBookingGroupParts_bookableInterviews_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface InterviewBookingGroupParts_bookableInterviews_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface InterviewBookingGroupParts_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: InterviewBookingGroupParts_bookableInterviews_recruitment | null;
  company: InterviewBookingGroupParts_bookableInterviews_company;
  attendees: InterviewBookingGroupParts_bookableInterviews_attendees[];
  interviewBookingGroup: InterviewBookingGroupParts_bookableInterviews_interviewBookingGroup | null;
  matchingEventTalentProfiles: InterviewBookingGroupParts_bookableInterviews_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

export interface InterviewBookingGroupParts_users_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface InterviewBookingGroupParts_users {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: InterviewBookingGroupParts_users_profileImage | null;
}

export interface InterviewBookingGroupParts {
  __typename: "InterviewBookingGroup";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string;
  defaultTitle: string | null;
  defaultDescription: string | null;
  defaultLocation: string | null;
  defaultUrl: string | null;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: InterviewBookingGroupParts_companiesWithAccess[];
  company: InterviewBookingGroupParts_company;
  recruitments: InterviewBookingGroupParts_recruitments[];
  bookableInterviews: InterviewBookingGroupParts_bookableInterviews[];
  users: InterviewBookingGroupParts_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MatchingEventBookableInterviewParts
// ====================================================

export interface MatchingEventBookableInterviewParts_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventBookableInterviewParts_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventBookableInterviewParts_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: MatchingEventBookableInterviewParts_attendees_application_recruitment;
}

export interface MatchingEventBookableInterviewParts_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MatchingEventBookableInterviewParts_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: MatchingEventBookableInterviewParts_attendees_user_profileImage | null;
}

export interface MatchingEventBookableInterviewParts_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  application: MatchingEventBookableInterviewParts_attendees_application | null;
  user: MatchingEventBookableInterviewParts_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface MatchingEventBookableInterviewParts_matchingEventCalendarEvents_matchingEventParticipant_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventBookableInterviewParts_matchingEventCalendarEvents_matchingEventParticipant {
  __typename: "MatchingEventParticipant";
  id: string;
  company: MatchingEventBookableInterviewParts_matchingEventCalendarEvents_matchingEventParticipant_company;
}

export interface MatchingEventBookableInterviewParts_matchingEventCalendarEvents_attendees_eventMatch {
  __typename: "EventMatch";
  id: string;
  firstName: string;
  lastName: string;
}

export interface MatchingEventBookableInterviewParts_matchingEventCalendarEvents_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  eventMatch: MatchingEventBookableInterviewParts_matchingEventCalendarEvents_attendees_eventMatch | null;
}

export interface MatchingEventBookableInterviewParts_matchingEventCalendarEvents {
  __typename: "CalendarEvent";
  id: string;
  startTime: DateTime;
  endTime: DateTime;
  matchingEventParticipant: MatchingEventBookableInterviewParts_matchingEventCalendarEvents_matchingEventParticipant | null;
  attendees: MatchingEventBookableInterviewParts_matchingEventCalendarEvents_attendees[];
}

export interface MatchingEventBookableInterviewParts {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  recruitment: MatchingEventBookableInterviewParts_recruitment | null;
  attendees: MatchingEventBookableInterviewParts_attendees[];
  matchingEventCalendarEvents: MatchingEventBookableInterviewParts_matchingEventCalendarEvents[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MatchingEventChildCompanyCalendarEventParts
// ====================================================

export interface MatchingEventChildCompanyCalendarEventParts_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventChildCompanyCalendarEventParts_attendees_application_recruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventChildCompanyCalendarEventParts_attendees_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: MatchingEventChildCompanyCalendarEventParts_attendees_application_recruitment;
}

export interface MatchingEventChildCompanyCalendarEventParts_attendees_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MatchingEventChildCompanyCalendarEventParts_attendees_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: MatchingEventChildCompanyCalendarEventParts_attendees_user_profileImage | null;
}

export interface MatchingEventChildCompanyCalendarEventParts_attendees {
  __typename: "CalendarEventAttendee";
  id: string;
  application: MatchingEventChildCompanyCalendarEventParts_attendees_application | null;
  user: MatchingEventChildCompanyCalendarEventParts_attendees_user | null;
  status: CALENDAR_EVENT_ATTENDEE_STATUS;
}

export interface MatchingEventChildCompanyCalendarEventParts_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface MatchingEventChildCompanyCalendarEventParts_matchingEventTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
}

export interface MatchingEventChildCompanyCalendarEventParts {
  __typename: "CalendarEvent";
  id: string;
  title: string | null;
  description: string | null;
  childCompanyHasSelectedBookableInterview: boolean;
  recruitment: MatchingEventChildCompanyCalendarEventParts_recruitment | null;
  attendees: MatchingEventChildCompanyCalendarEventParts_attendees[];
  interviewBookingGroup: MatchingEventChildCompanyCalendarEventParts_interviewBookingGroup | null;
  matchingEventTalentProfiles: MatchingEventChildCompanyCalendarEventParts_matchingEventTalentProfiles[];
  type: CALENDAR_EVENT_TYPE;
  startTime: DateTime;
  endTime: DateTime;
  location: string | null;
  url: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MatchingEventParticipantParts
// ====================================================

export interface MatchingEventParticipantParts_answers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface MatchingEventParticipantParts_answers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: MatchingEventParticipantParts_answers_question_matchingEventProfile | null;
}

export interface MatchingEventParticipantParts_answers_answerFile {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  filename: string;
  mimetype: string;
}

export interface MatchingEventParticipantParts_answers_answerDocument_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface MatchingEventParticipantParts_answers_answerDocument {
  __typename: "Document";
  id: string;
  displayName: string;
  file: MatchingEventParticipantParts_answers_answerDocument_file;
  type: DOCUMENT_TYPE;
}

export interface MatchingEventParticipantParts_answers_answerDocuments_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface MatchingEventParticipantParts_answers_answerDocuments {
  __typename: "Document";
  id: string;
  displayName: string;
  file: MatchingEventParticipantParts_answers_answerDocuments_file;
  type: DOCUMENT_TYPE;
}

export interface MatchingEventParticipantParts_answers_answerEducations {
  __typename: "Education";
  id: string;
  school: string | null;
  degree: string | null;
  education: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface MatchingEventParticipantParts_answers_answerWorkExperiences {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

export interface MatchingEventParticipantParts_answers_answerPersonalData {
  __typename: "PersonalData";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface MatchingEventParticipantParts_answers_answerCompetences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface MatchingEventParticipantParts_answers_answerCompetences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: MatchingEventParticipantParts_answers_answerCompetences_competence;
}

export interface MatchingEventParticipantParts_answers_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface MatchingEventParticipantParts_answers_application {
  __typename: "Application";
  id: string;
  recruitment: MatchingEventParticipantParts_answers_application_recruitment;
}

export interface MatchingEventParticipantParts_answers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: MatchingEventParticipantParts_answers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerNumberMin: number | null;
  answerNumberMax: number | null;
  answerFile: MatchingEventParticipantParts_answers_answerFile | null;
  answerDocument: MatchingEventParticipantParts_answers_answerDocument | null;
  answerDocuments: MatchingEventParticipantParts_answers_answerDocuments[] | null;
  answerArray: string[] | null;
  answerEducations: MatchingEventParticipantParts_answers_answerEducations[] | null;
  answerWorkExperiences: MatchingEventParticipantParts_answers_answerWorkExperiences[] | null;
  answerPersonalData: MatchingEventParticipantParts_answers_answerPersonalData | null;
  answerCompetences: MatchingEventParticipantParts_answers_answerCompetences[] | null;
  application: MatchingEventParticipantParts_answers_application | null;
}

export interface MatchingEventParticipantParts_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventParticipantParts_eventMatches {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
}

export interface MatchingEventParticipantParts_bookableInterviews {
  __typename: "CalendarEvent";
  id: string;
}

export interface MatchingEventParticipantParts_event_hostCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventParticipantParts_event_hostRecruitment {
  __typename: "Recruitment";
  id: string;
}

export interface MatchingEventParticipantParts_event_profiles {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
  description: string | null;
}

export interface MatchingEventParticipantParts_event {
  __typename: "MatchingEvent";
  id: string;
  title: string;
  descriptionParticipant: string | null;
  flowOrder: MATCHING_EVENT_FLOW_ORDER | null;
  interviewBookingStatus: MATCHING_EVENT_INTERVIEW_BOOKING_STATUS | null;
  companiesCanAddInterviews: boolean | null;
  hostCompany: MatchingEventParticipantParts_event_hostCompany;
  hostRecruitment: MatchingEventParticipantParts_event_hostRecruitment;
  profiles: MatchingEventParticipantParts_event_profiles[];
}

export interface MatchingEventParticipantParts_connectedTalentProfiles_formQuestionAnswerEvaluations {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  significance: REQUIREMENT_SIGNIFICANCE;
}

export interface MatchingEventParticipantParts_connectedTalentProfiles {
  __typename: "MatchingEventTalentProfile";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  formQuestionAnswerEvaluations: MatchingEventParticipantParts_connectedTalentProfiles_formQuestionAnswerEvaluations[];
}

export interface MatchingEventParticipantParts_chosenCriteriaOptions {
  __typename: "MatchingCriteriaOption";
  id: string;
  name: string;
}

export interface MatchingEventParticipantParts {
  __typename: "MatchingEventParticipant";
  id: string;
  createdAt: DateTime;
  companyName: string;
  completedWizardSteps: string[];
  note: string | null;
  answers: MatchingEventParticipantParts_answers[] | null;
  company: MatchingEventParticipantParts_company;
  eventMatches: MatchingEventParticipantParts_eventMatches[];
  bookableInterviews: MatchingEventParticipantParts_bookableInterviews[];
  event: MatchingEventParticipantParts_event;
  connectedTalentProfiles: MatchingEventParticipantParts_connectedTalentProfiles[];
  chosenCriteriaOptions: MatchingEventParticipantParts_chosenCriteriaOptions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MatchingEventParts
// ====================================================

export interface MatchingEventParts_profiles {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
  description: string | null;
}

export interface MatchingEventParts_participants {
  __typename: "MatchingEventParticipant";
  id: string;
}

export interface MatchingEventParts_form_questions_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface MatchingEventParts_form_questions {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: MatchingEventParts_form_questions_matchingEventProfile | null;
}

export interface MatchingEventParts_form {
  __typename: "Form";
  id: string;
  title: string | null;
  type: FORM_TYPE;
  questions: MatchingEventParts_form_questions[];
}

export interface MatchingEventParts_hostRecruitment_form {
  __typename: "Form";
  id: string;
  type: FORM_TYPE;
}

export interface MatchingEventParts_hostRecruitment {
  __typename: "Recruitment";
  id: string;
  publishingStatus: RECRUITMENT_STATUS | null;
  form: MatchingEventParts_hostRecruitment_form | null;
}

export interface MatchingEventParts_hostCompany_childCompanies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventParts_hostCompany {
  __typename: "Company";
  id: string;
  childCompanies: MatchingEventParts_hostCompany_childCompanies[];
}

export interface MatchingEventParts_middleCompanies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventParts_criterias {
  __typename: "MatchingCriteria";
  id: string;
  name: string;
}

export interface MatchingEventParts {
  __typename: "MatchingEvent";
  id: string;
  title: string;
  description: string | null;
  descriptionApplication: string | null;
  descriptionParticipant: string | null;
  messageToApplicationOnMatch: string | null;
  messageToParticipantOnMatch: string | null;
  companiesCanAddInterviews: boolean | null;
  enableTrustcruitFeedback: boolean | null;
  status: MATCHING_EVENT_STATUS | null;
  interviewBookingStatus: MATCHING_EVENT_INTERVIEW_BOOKING_STATUS | null;
  flowOrder: MATCHING_EVENT_FLOW_ORDER | null;
  profiles: MatchingEventParts_profiles[];
  participants: MatchingEventParts_participants[];
  form: MatchingEventParts_form | null;
  hostRecruitment: MatchingEventParts_hostRecruitment;
  hostCompany: MatchingEventParts_hostCompany;
  middleCompanies: MatchingEventParts_middleCompanies[];
  criterias: MatchingEventParts_criterias[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MatchingEventTalentProfileParts
// ====================================================

export interface MatchingEventTalentProfileParts_formQuestionAnswerEvaluations {
  __typename: "FormQuestionAnswerEvaluation";
  id: string;
  significance: REQUIREMENT_SIGNIFICANCE;
}

export interface MatchingEventTalentProfileParts_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MatchingEventTalentProfileParts_matches_event {
  __typename: "MatchingEvent";
  id: string;
}

export interface MatchingEventTalentProfileParts_matches {
  __typename: "EventMatch";
  id: string;
  status: EVENT_MATCH_STATUS;
  createdManually: boolean | null;
  event: MatchingEventTalentProfileParts_matches_event;
}

export interface MatchingEventTalentProfileParts_matchingEventParticipants_event {
  __typename: "MatchingEvent";
  id: string;
}

export interface MatchingEventTalentProfileParts_matchingEventParticipants {
  __typename: "MatchingEventParticipant";
  id: string;
  event: MatchingEventTalentProfileParts_matchingEventParticipants_event;
}

export interface MatchingEventTalentProfileParts_profiles {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface MatchingEventTalentProfileParts {
  __typename: "MatchingEventTalentProfile";
  id: string;
  title: string;
  createdAt: DateTime;
  description: string | null;
  participantNotifiedOfMatch: boolean | null;
  formQuestionAnswerEvaluations: MatchingEventTalentProfileParts_formQuestionAnswerEvaluations[];
  company: MatchingEventTalentProfileParts_company;
  matches: MatchingEventTalentProfileParts_matches[];
  matchingEventParticipants: MatchingEventTalentProfileParts_matchingEventParticipants[];
  profiles: MatchingEventTalentProfileParts_profiles[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MediaElementParts
// ====================================================

export interface MediaElementParts_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface MediaElementParts_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MediaElementParts_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: MediaElementParts_createdBy_profileImage | null;
}

export interface MediaElementParts_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface MediaElementParts {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: MediaElementParts_thumbnail | null;
  createdBy: MediaElementParts_createdBy;
  tags: MediaElementParts_tags[];
  name: string | null;
  description: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MediaParts
// ====================================================

export interface MediaParts_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface MediaParts_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MediaParts_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: MediaParts_mediaElement_createdBy_profileImage | null;
}

export interface MediaParts_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface MediaParts_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: MediaParts_mediaElement_thumbnail | null;
  createdBy: MediaParts_mediaElement_createdBy;
  tags: MediaParts_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface MediaParts {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: MediaParts_mediaElement;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MeParts
// ====================================================

export interface MeParts_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MeParts_company_childCompanies_childCompanies {
  __typename: "Company";
  id: string;
}

export interface MeParts_company_childCompanies {
  __typename: "Company";
  id: string;
  childCompanies: MeParts_company_childCompanies_childCompanies[];
}

export interface MeParts_company_parentCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface MeParts_company_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface MeParts_company_enabledFeatures {
  __typename: "EnabledFeatures";
  id: string;
  assignments: boolean | null;
  calendar: boolean | null;
  consultantProjects: boolean | null;
  contracts: boolean | null;
  screeningForms: boolean | null;
  referenceForms: boolean | null;
  trustcruit: boolean | null;
  applicationsRankedWithAI: boolean | null;
  interviewBooking: boolean | null;
  interviewBookingGroups: boolean | null;
  alvaLabs: boolean | null;
  tengai: boolean | null;
  allApplicationsPage: boolean | null;
  aiGeneration: boolean | null;
  careerPage: boolean | null;
  mediaLibrary: boolean | null;
  jobtip: boolean | null;
  evaluationForm: boolean | null;
  statusDeadlines: boolean | null;
  videoInApplications: boolean | null;
}

export interface MeParts_company {
  __typename: "Company";
  id: string;
  name: string;
  apiKey: string | null;
  language: string | null;
  type: COMPANY_TYPE | null;
  childCompanies: MeParts_company_childCompanies[];
  parentCompany: MeParts_company_parentCompany | null;
  profileImage: MeParts_company_profileImage | null;
  enabledFeatures: MeParts_company_enabledFeatures | null;
}

export interface MeParts {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  language: string | null;
  phoneNumber: string | null;
  jobTitle: string | null;
  profileImage: MeParts_profileImage | null;
  company: MeParts_company;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  subscribedMailNotificationTypes: NOTIFICATION_TYPE[];
  subscribedToNotificationsStatus: SUBSCRIBED_TO_NOTIFICATIONS_STATUS | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NotificationParts
// ====================================================

export interface NotificationParts_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface NotificationParts_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface NotificationParts_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: NotificationParts_createdBy_profileImage | null;
}

export interface NotificationParts_messagingCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface NotificationParts_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
}

export interface NotificationParts_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
}

export interface NotificationParts_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
}

export interface NotificationParts_consultant_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_consultant {
  __typename: "Consultant";
  id: string;
  firstName: string;
  lastName: string;
  company: NotificationParts_consultant_company;
}

export interface NotificationParts_consultantProject_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_consultantProject {
  __typename: "ConsultantProject";
  id: string;
  title: string;
  company: NotificationParts_consultantProject_company;
}

export interface NotificationParts_formParticipant_form_referenceForm_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_formParticipant_form_referenceForm_recruitment {
  __typename: "Recruitment";
  id: string;
  company: NotificationParts_formParticipant_form_referenceForm_recruitment_company;
}

export interface NotificationParts_formParticipant_form_referenceForm {
  __typename: "ReferenceForm";
  id: string;
  title: string | null;
  recruitment: NotificationParts_formParticipant_form_referenceForm_recruitment | null;
}

export interface NotificationParts_formParticipant_form_screeningForm_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_formParticipant_form_screeningForm_recruitment {
  __typename: "Recruitment";
  id: string;
  company: NotificationParts_formParticipant_form_screeningForm_recruitment_company;
}

export interface NotificationParts_formParticipant_form_screeningForm {
  __typename: "ScreeningForm";
  id: string;
  title: string | null;
  recruitment: NotificationParts_formParticipant_form_screeningForm_recruitment | null;
}

export interface NotificationParts_formParticipant_form {
  __typename: "Form";
  id: string;
  referenceForm: NotificationParts_formParticipant_form_referenceForm | null;
  screeningForm: NotificationParts_formParticipant_form_screeningForm | null;
}

export interface NotificationParts_formParticipant_reference_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_formParticipant_reference_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: NotificationParts_formParticipant_reference_application_recruitment_company;
}

export interface NotificationParts_formParticipant_reference_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: NotificationParts_formParticipant_reference_application_recruitment;
}

export interface NotificationParts_formParticipant_reference {
  __typename: "Reference";
  id: string;
  firstName: string;
  lastName: string;
  application: NotificationParts_formParticipant_reference_application | null;
}

export interface NotificationParts_formParticipant_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_formParticipant_application_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: NotificationParts_formParticipant_application_recruitment_company;
}

export interface NotificationParts_formParticipant_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: NotificationParts_formParticipant_application_recruitment;
}

export interface NotificationParts_formParticipant {
  __typename: "FormParticipant";
  id: string;
  form: NotificationParts_formParticipant_form;
  reference: NotificationParts_formParticipant_reference | null;
  application: NotificationParts_formParticipant_application | null;
}

export interface NotificationParts_calendarEvent_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_calendarEvent {
  __typename: "CalendarEvent";
  id: string;
  type: CALENDAR_EVENT_TYPE;
  title: string | null;
  startTime: DateTime;
  endTime: DateTime;
  company: NotificationParts_calendarEvent_company;
}

export interface NotificationParts_comment_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_comment_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: NotificationParts_comment_application_recruitment_company;
}

export interface NotificationParts_comment_application {
  __typename: "Application";
  id: string;
  recruitment: NotificationParts_comment_application_recruitment;
}

export interface NotificationParts_comment_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_comment_recruitment {
  __typename: "Recruitment";
  id: string;
  company: NotificationParts_comment_recruitment_company;
}

export interface NotificationParts_comment_mentions {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface NotificationParts_comment {
  __typename: "Comment";
  id: string;
  text: string;
  application: NotificationParts_comment_application | null;
  recruitment: NotificationParts_comment_recruitment | null;
  mentions: NotificationParts_comment_mentions[];
}

export interface NotificationParts_assignment_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_assignment {
  __typename: "Assignment";
  id: string;
  title: string;
  company: NotificationParts_assignment_company;
}

export interface NotificationParts_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: NotificationParts_recruitment_company;
}

export interface NotificationParts_interview_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
}

export interface NotificationParts_interview_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_interview_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: NotificationParts_interview_application_recruitment_company;
}

export interface NotificationParts_interview_application {
  __typename: "Application";
  id: string;
  recruitment: NotificationParts_interview_application_recruitment;
}

export interface NotificationParts_interview {
  __typename: "Interview";
  id: string;
  title: string;
  applicant: NotificationParts_interview_applicant | null;
  application: NotificationParts_interview_application | null;
}

export interface NotificationParts_identifiedNeed_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_identifiedNeed_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: NotificationParts_identifiedNeed_recruitment_company;
}

export interface NotificationParts_identifiedNeed {
  __typename: "IdentifiedNeed";
  id: string;
  title: string;
  recruitment: NotificationParts_identifiedNeed_recruitment | null;
}

export interface NotificationParts_interviewBookingGroup {
  __typename: "InterviewBookingGroup";
  id: string;
  title: string;
}

export interface NotificationParts_interviewBookingGroupApplication_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface NotificationParts_interviewBookingGroupApplication {
  __typename: "InterviewBookingGroupApplication";
  id: string;
  application: NotificationParts_interviewBookingGroupApplication_application;
}

export interface NotificationParts_applicantMessage_application_recruitment_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_applicantMessage_application_recruitment {
  __typename: "Recruitment";
  id: string;
  company: NotificationParts_applicantMessage_application_recruitment_company;
}

export interface NotificationParts_applicantMessage_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  recruitment: NotificationParts_applicantMessage_application_recruitment;
}

export interface NotificationParts_applicantMessage_applicant_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_applicantMessage_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: NotificationParts_applicantMessage_applicant_company;
}

export interface NotificationParts_applicantMessage {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  application: NotificationParts_applicantMessage_application | null;
  applicant: NotificationParts_applicantMessage_applicant | null;
}

export interface NotificationParts_application {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
}

export interface NotificationParts_status {
  __typename: "Status";
  id: string;
  title: string;
}

export interface NotificationParts_applicant_company {
  __typename: "Company";
  id: string;
}

export interface NotificationParts_applicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  company: NotificationParts_applicant_company;
}

export interface NotificationParts {
  __typename: "Notification";
  id: string;
  type: NOTIFICATION_TYPE;
  createdAt: DateTime;
  isRead: boolean;
  isPinned: boolean | null;
  user: NotificationParts_user;
  createdBy: NotificationParts_createdBy | null;
  content: string | null;
  messagingCompany: NotificationParts_messagingCompany | null;
  screeningForm: NotificationParts_screeningForm | null;
  referenceForm: NotificationParts_referenceForm | null;
  reference: NotificationParts_reference | null;
  consultant: NotificationParts_consultant | null;
  consultantProject: NotificationParts_consultantProject | null;
  formParticipant: NotificationParts_formParticipant | null;
  calendarEvent: NotificationParts_calendarEvent | null;
  comment: NotificationParts_comment | null;
  assignment: NotificationParts_assignment | null;
  recruitment: NotificationParts_recruitment | null;
  interview: NotificationParts_interview | null;
  identifiedNeed: NotificationParts_identifiedNeed | null;
  interviewBookingGroup: NotificationParts_interviewBookingGroup | null;
  interviewBookingGroupApplication: NotificationParts_interviewBookingGroupApplication | null;
  applicantMessage: NotificationParts_applicantMessage | null;
  application: NotificationParts_application | null;
  status: NotificationParts_status | null;
  applicant: NotificationParts_applicant | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageContentParts
// ====================================================

export interface PageContentParts_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageContentParts_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageContentParts_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageContentParts_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageContentParts_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageContentParts_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageContentParts_medias_mediaElement_thumbnail | null;
  createdBy: PageContentParts_medias_mediaElement_createdBy;
  tags: PageContentParts_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageContentParts_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageContentParts_medias_mediaElement;
}

export interface PageContentParts {
  __typename: "PageContent";
  id: string;
  content: JSON | null;
  medias: PageContentParts_medias[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageThemeVariablesParts
// ====================================================

export interface PageThemeVariablesParts {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageBlockParts
// ====================================================

export interface PageBlockParts_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlockParts_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlockParts_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlockParts_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface PageBlockParts_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlockParts_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlockParts_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: PageBlockParts_backgroundMedia_mediaElement_createdBy;
  tags: PageBlockParts_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlockParts_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlockParts_backgroundMedia_mediaElement;
}

export interface PageBlockParts_pageContent_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlockParts_pageContent_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlockParts_pageContent_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlockParts_pageContent_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageBlockParts_pageContent_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlockParts_pageContent_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlockParts_pageContent_medias_mediaElement_thumbnail | null;
  createdBy: PageBlockParts_pageContent_medias_mediaElement_createdBy;
  tags: PageBlockParts_pageContent_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlockParts_pageContent_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlockParts_pageContent_medias_mediaElement;
}

export interface PageBlockParts_pageContent {
  __typename: "PageContent";
  id: string;
  content: JSON | null;
  medias: PageBlockParts_pageContent_medias[];
}

export interface PageBlockParts_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlockParts_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlockParts_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlockParts_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageBlockParts_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlockParts_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlockParts_medias_mediaElement_thumbnail | null;
  createdBy: PageBlockParts_medias_mediaElement_createdBy;
  tags: PageBlockParts_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlockParts_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlockParts_medias_mediaElement;
}

export interface PageBlockParts_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlockParts_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlockParts_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlockParts_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface PageBlockParts_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlockParts_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlockParts_inlineMedias_mediaElement_thumbnail | null;
  createdBy: PageBlockParts_inlineMedias_mediaElement_createdBy;
  tags: PageBlockParts_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlockParts_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlockParts_inlineMedias_mediaElement;
}

export interface PageBlockParts_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface PageBlockParts_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface PageBlockParts_columns_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlockParts_columns_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlockParts_columns_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlockParts_columns_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface PageBlockParts_columns_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlockParts_columns_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlockParts_columns_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: PageBlockParts_columns_backgroundMedia_mediaElement_createdBy;
  tags: PageBlockParts_columns_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlockParts_columns_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlockParts_columns_backgroundMedia_mediaElement;
}

export interface PageBlockParts_columns_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlockParts_columns_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlockParts_columns_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlockParts_columns_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageBlockParts_columns_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlockParts_columns_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlockParts_columns_medias_mediaElement_thumbnail | null;
  createdBy: PageBlockParts_columns_medias_mediaElement_createdBy;
  tags: PageBlockParts_columns_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlockParts_columns_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlockParts_columns_medias_mediaElement;
}

export interface PageBlockParts_columns_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageBlockParts_columns_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageBlockParts_columns_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageBlockParts_columns_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface PageBlockParts_columns_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageBlockParts_columns_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageBlockParts_columns_inlineMedias_mediaElement_thumbnail | null;
  createdBy: PageBlockParts_columns_inlineMedias_mediaElement_createdBy;
  tags: PageBlockParts_columns_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageBlockParts_columns_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageBlockParts_columns_inlineMedias_mediaElement;
}

export interface PageBlockParts_columns_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface PageBlockParts_columns_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface PageBlockParts_columns {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: PageBlockParts_columns_backgroundMedia | null;
  medias: PageBlockParts_columns_medias[];
  inlineMedias: PageBlockParts_columns_inlineMedias[];
  parent: PageBlockParts_columns_parent | null;
  themeVariables: PageBlockParts_columns_themeVariables;
}

export interface PageBlockParts {
  __typename: "PageBlock";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  isTemplate: boolean;
  name: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  blockId: string | null;
  heightType: PAGE_BLOCK_HEIGHT_TYPE;
  fixedHeight: number;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: PageBlockParts_backgroundMedia | null;
  pageContent: PageBlockParts_pageContent;
  medias: PageBlockParts_medias[];
  inlineMedias: PageBlockParts_inlineMedias[];
  themeVariables: PageBlockParts_themeVariables;
  parent: PageBlockParts_parent | null;
  columns: PageBlockParts_columns[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageMetaInfoParts
// ====================================================

export interface PageMetaInfoParts_ogImage_image_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageMetaInfoParts_ogImage_image_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageMetaInfoParts_ogImage_image_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageMetaInfoParts_ogImage_image_mediaElement_createdBy_profileImage | null;
}

export interface PageMetaInfoParts_ogImage_image_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageMetaInfoParts_ogImage_image_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageMetaInfoParts_ogImage_image_mediaElement_thumbnail | null;
  createdBy: PageMetaInfoParts_ogImage_image_mediaElement_createdBy;
  tags: PageMetaInfoParts_ogImage_image_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageMetaInfoParts_ogImage_image {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageMetaInfoParts_ogImage_image_mediaElement;
}

export interface PageMetaInfoParts_ogImage {
  __typename: "SharedImage";
  id: string;
  createdAt: DateTime;
  title: string | null;
  subtitle: string | null;
  titleColor: string | null;
  titleFont: string | null;
  subtitleFont: string | null;
  subtitleColor: string | null;
  backgroundColor: string | null;
  backgroundType: SHARED_IMAGE_BACKGROUND_TYPE;
  width: number | null;
  height: number | null;
  image: PageMetaInfoParts_ogImage_image | null;
}

export interface PageMetaInfoParts {
  __typename: "PageMetaInfo";
  id: string;
  pageTitle: string;
  ogTitle: string;
  ogDescription: string;
  ogSiteName: string;
  ogImage: PageMetaInfoParts_ogImage;
  facebookUrl: string | null;
  twitterUrl: string | null;
  instagramUrl: string | null;
  linkedinUrl: string | null;
  youtubeUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageVersionParts
// ====================================================

export interface PageVersionParts_blocks_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersionParts_blocks_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersionParts_blocks_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersionParts_blocks_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface PageVersionParts_blocks_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersionParts_blocks_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersionParts_blocks_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: PageVersionParts_blocks_backgroundMedia_mediaElement_createdBy;
  tags: PageVersionParts_blocks_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersionParts_blocks_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersionParts_blocks_backgroundMedia_mediaElement;
}

export interface PageVersionParts_blocks_pageContent_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersionParts_blocks_pageContent_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersionParts_blocks_pageContent_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersionParts_blocks_pageContent_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageVersionParts_blocks_pageContent_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersionParts_blocks_pageContent_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersionParts_blocks_pageContent_medias_mediaElement_thumbnail | null;
  createdBy: PageVersionParts_blocks_pageContent_medias_mediaElement_createdBy;
  tags: PageVersionParts_blocks_pageContent_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersionParts_blocks_pageContent_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersionParts_blocks_pageContent_medias_mediaElement;
}

export interface PageVersionParts_blocks_pageContent {
  __typename: "PageContent";
  id: string;
  content: JSON | null;
  medias: PageVersionParts_blocks_pageContent_medias[];
}

export interface PageVersionParts_blocks_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersionParts_blocks_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersionParts_blocks_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersionParts_blocks_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageVersionParts_blocks_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersionParts_blocks_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersionParts_blocks_medias_mediaElement_thumbnail | null;
  createdBy: PageVersionParts_blocks_medias_mediaElement_createdBy;
  tags: PageVersionParts_blocks_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersionParts_blocks_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersionParts_blocks_medias_mediaElement;
}

export interface PageVersionParts_blocks_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersionParts_blocks_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersionParts_blocks_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersionParts_blocks_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface PageVersionParts_blocks_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersionParts_blocks_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersionParts_blocks_inlineMedias_mediaElement_thumbnail | null;
  createdBy: PageVersionParts_blocks_inlineMedias_mediaElement_createdBy;
  tags: PageVersionParts_blocks_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersionParts_blocks_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersionParts_blocks_inlineMedias_mediaElement;
}

export interface PageVersionParts_blocks_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface PageVersionParts_blocks_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface PageVersionParts_blocks_columns_backgroundMedia_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersionParts_blocks_columns_backgroundMedia_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersionParts_blocks_columns_backgroundMedia_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersionParts_blocks_columns_backgroundMedia_mediaElement_createdBy_profileImage | null;
}

export interface PageVersionParts_blocks_columns_backgroundMedia_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersionParts_blocks_columns_backgroundMedia_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersionParts_blocks_columns_backgroundMedia_mediaElement_thumbnail | null;
  createdBy: PageVersionParts_blocks_columns_backgroundMedia_mediaElement_createdBy;
  tags: PageVersionParts_blocks_columns_backgroundMedia_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersionParts_blocks_columns_backgroundMedia {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersionParts_blocks_columns_backgroundMedia_mediaElement;
}

export interface PageVersionParts_blocks_columns_medias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersionParts_blocks_columns_medias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersionParts_blocks_columns_medias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersionParts_blocks_columns_medias_mediaElement_createdBy_profileImage | null;
}

export interface PageVersionParts_blocks_columns_medias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersionParts_blocks_columns_medias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersionParts_blocks_columns_medias_mediaElement_thumbnail | null;
  createdBy: PageVersionParts_blocks_columns_medias_mediaElement_createdBy;
  tags: PageVersionParts_blocks_columns_medias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersionParts_blocks_columns_medias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersionParts_blocks_columns_medias_mediaElement;
}

export interface PageVersionParts_blocks_columns_inlineMedias_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersionParts_blocks_columns_inlineMedias_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersionParts_blocks_columns_inlineMedias_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersionParts_blocks_columns_inlineMedias_mediaElement_createdBy_profileImage | null;
}

export interface PageVersionParts_blocks_columns_inlineMedias_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersionParts_blocks_columns_inlineMedias_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersionParts_blocks_columns_inlineMedias_mediaElement_thumbnail | null;
  createdBy: PageVersionParts_blocks_columns_inlineMedias_mediaElement_createdBy;
  tags: PageVersionParts_blocks_columns_inlineMedias_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersionParts_blocks_columns_inlineMedias {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersionParts_blocks_columns_inlineMedias_mediaElement;
}

export interface PageVersionParts_blocks_columns_parent {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
}

export interface PageVersionParts_blocks_columns_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface PageVersionParts_blocks_columns {
  __typename: "PageBlock";
  id: string;
  blockId: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: PageVersionParts_blocks_columns_backgroundMedia | null;
  medias: PageVersionParts_blocks_columns_medias[];
  inlineMedias: PageVersionParts_blocks_columns_inlineMedias[];
  parent: PageVersionParts_blocks_columns_parent | null;
  themeVariables: PageVersionParts_blocks_columns_themeVariables;
}

export interface PageVersionParts_blocks {
  __typename: "PageBlock";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  isTemplate: boolean;
  name: string | null;
  pagePosition: number;
  type: PAGE_BLOCK_TYPE;
  customCss: string | null;
  content: JSON | null;
  blockId: string | null;
  heightType: PAGE_BLOCK_HEIGHT_TYPE;
  fixedHeight: number;
  backgroundType: PAGE_BLOCK_BACKGROUND_TYPE;
  backgroundOverlayOpacity: number;
  backgroundMedia: PageVersionParts_blocks_backgroundMedia | null;
  pageContent: PageVersionParts_blocks_pageContent;
  medias: PageVersionParts_blocks_medias[];
  inlineMedias: PageVersionParts_blocks_inlineMedias[];
  themeVariables: PageVersionParts_blocks_themeVariables;
  parent: PageVersionParts_blocks_parent | null;
  columns: PageVersionParts_blocks_columns[];
}

export interface PageVersionParts_metaInfo_ogImage_image_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface PageVersionParts_metaInfo_ogImage_image_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersionParts_metaInfo_ogImage_image_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: PageVersionParts_metaInfo_ogImage_image_mediaElement_createdBy_profileImage | null;
}

export interface PageVersionParts_metaInfo_ogImage_image_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface PageVersionParts_metaInfo_ogImage_image_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: PageVersionParts_metaInfo_ogImage_image_mediaElement_thumbnail | null;
  createdBy: PageVersionParts_metaInfo_ogImage_image_mediaElement_createdBy;
  tags: PageVersionParts_metaInfo_ogImage_image_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface PageVersionParts_metaInfo_ogImage_image {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: PageVersionParts_metaInfo_ogImage_image_mediaElement;
}

export interface PageVersionParts_metaInfo_ogImage {
  __typename: "SharedImage";
  id: string;
  createdAt: DateTime;
  title: string | null;
  subtitle: string | null;
  titleColor: string | null;
  titleFont: string | null;
  subtitleFont: string | null;
  subtitleColor: string | null;
  backgroundColor: string | null;
  backgroundType: SHARED_IMAGE_BACKGROUND_TYPE;
  width: number | null;
  height: number | null;
  image: PageVersionParts_metaInfo_ogImage_image | null;
}

export interface PageVersionParts_metaInfo {
  __typename: "PageMetaInfo";
  id: string;
  pageTitle: string;
  ogTitle: string;
  ogDescription: string;
  ogSiteName: string;
  ogImage: PageVersionParts_metaInfo_ogImage;
  facebookUrl: string | null;
  twitterUrl: string | null;
  instagramUrl: string | null;
  linkedinUrl: string | null;
  youtubeUrl: string | null;
}

export interface PageVersionParts_themeVariables {
  __typename: "PageThemeVariables";
  id: string;
  primaryColor: string | null;
  secondaryColor: string | null;
  backgroundColor: string | null;
  titleColor: string | null;
  textColor: string | null;
}

export interface PageVersionParts_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersionParts_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: PageVersionParts_createdBy_profileImage | null;
}

export interface PageVersionParts {
  __typename: "PageVersion";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  published: boolean;
  customCss: string | null;
  blocks: PageVersionParts_blocks[];
  metaInfo: PageVersionParts_metaInfo;
  themeVariables: PageVersionParts_themeVariables;
  createdBy: PageVersionParts_createdBy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageVersionSmallParts
// ====================================================

export interface PageVersionSmallParts_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface PageVersionSmallParts_createdBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: PageVersionSmallParts_createdBy_profileImage | null;
}

export interface PageVersionSmallParts {
  __typename: "PageVersion";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  published: boolean;
  createdBy: PageVersionSmallParts_createdBy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageParts
// ====================================================

export interface PageParts_versions {
  __typename: "PageVersion";
  id: string;
}

export interface PageParts_publishedVersion {
  __typename: "PageVersion";
  id: string;
}

export interface PageParts {
  __typename: "Page";
  id: string;
  slug: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  versions: PageParts_versions[];
  publishedVersion: PageParts_publishedVersion | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PinnedRecruitmentParts
// ====================================================

export interface PinnedRecruitmentParts_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface PinnedRecruitmentParts {
  __typename: "PinnedRecruitment";
  id: string;
  createdAt: DateTime;
  recruitment: PinnedRecruitmentParts_recruitment;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RecruitmentParts
// ====================================================

export interface RecruitmentParts_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface RecruitmentParts_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentParts_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentParts_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: RecruitmentParts_users_user_profileImage | null;
}

export interface RecruitmentParts_users {
  __typename: "UserRecruitment";
  id: string;
  user: RecruitmentParts_users_user;
}

export interface RecruitmentParts {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  status: RecruitmentParts_status | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  countryCode: string | null;
  isPinned: boolean | null;
  isAnonymized: boolean;
  company: RecruitmentParts_company;
  users: RecruitmentParts_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RecruitmentTeamParts
// ====================================================

export interface RecruitmentTeamParts_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentTeamParts_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  subscribedMailNotificationTypes: NOTIFICATION_TYPE[];
  subscribedToNotificationsStatus: SUBSCRIBED_TO_NOTIFICATIONS_STATUS | null;
  profileImage: RecruitmentTeamParts_users_user_profileImage | null;
}

export interface RecruitmentTeamParts_users {
  __typename: "UserRecruitment";
  id: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
  user: RecruitmentTeamParts_users_user;
}

export interface RecruitmentTeamParts {
  __typename: "RecruitmentTeam";
  id: string;
  createdAt: DateTime;
  title: string;
  description: string | null;
  users: RecruitmentTeamParts_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RecruitmentVisitorAccessParts
// ====================================================

export interface RecruitmentVisitorAccessParts_permissions_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_recruitment_users_user {
  __typename: "User";
  id: string;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: RecruitmentVisitorAccessParts_permissions_applications_recruitment_users_user;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  company: RecruitmentVisitorAccessParts_permissions_applications_recruitment_company;
  users: RecruitmentVisitorAccessParts_permissions_applications_recruitment_users[];
}

export interface RecruitmentVisitorAccessParts_permissions_applications_tengaiInterview {
  __typename: "TengaiInterview";
  id: string;
  status: TENGAI_INTERVIEW_STATUS;
  totalPersonalityIndicatorScore: number | null;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_applicationFormAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_applicationFormAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: RecruitmentVisitorAccessParts_permissions_applications_applicationFormAnswers_question_matchingEventProfile | null;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_applicationFormAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: RecruitmentVisitorAccessParts_permissions_applications_applicationFormAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_grades_setBy {
  __typename: "User";
  id: string;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_grades {
  __typename: "ApplicantGrade";
  id: string;
  setBy: RecruitmentVisitorAccessParts_permissions_applications_grades_setBy | null;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_addedByUser_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_addedByUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: RecruitmentVisitorAccessParts_permissions_applications_addedByUser_profileImage | null;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  education: string | null;
  endDate: DateTime | null;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  createdAt: DateTime;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface RecruitmentVisitorAccessParts_permissions_applications_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: RecruitmentVisitorAccessParts_permissions_applications_documents_file;
  type: DOCUMENT_TYPE;
}

export interface RecruitmentVisitorAccessParts_permissions_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  createdAt: DateTime;
  averageGrade: number;
  needsVerification: boolean | null;
  note: string | null;
  computedMatchRanking: number | null;
  computedMatchScore: number;
  matchPercentageNeedToHave: number;
  matchPercentageNiceToHave: number;
  alvaLabsTestScore: number;
  alvaLabsCandidateCreatedAt: DateTime | null;
  recruitment: RecruitmentVisitorAccessParts_permissions_applications_recruitment;
  tengaiInterview: RecruitmentVisitorAccessParts_permissions_applications_tengaiInterview | null;
  applicationFormAnswers: RecruitmentVisitorAccessParts_permissions_applications_applicationFormAnswers[];
  companyApplicant: RecruitmentVisitorAccessParts_permissions_applications_companyApplicant | null;
  grades: RecruitmentVisitorAccessParts_permissions_applications_grades[];
  addedByUser: RecruitmentVisitorAccessParts_permissions_applications_addedByUser | null;
  educations: RecruitmentVisitorAccessParts_permissions_applications_educations[];
  status: RecruitmentVisitorAccessParts_permissions_applications_status | null;
  tags: RecruitmentVisitorAccessParts_permissions_applications_tags[];
  documents: RecruitmentVisitorAccessParts_permissions_applications_documents[];
}

export interface RecruitmentVisitorAccessParts_permissions_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface RecruitmentVisitorAccessParts_permissions {
  __typename: "RecruitmentVisitorPermissions";
  id: string;
  name: string | null;
  isTemplate: boolean | null;
  basePermissions: RECRUITMENT_VISITOR_PERMISSION[];
  applicationPermissions: APPLICATION_VISITOR_PERMISSION[];
  applicationAccessType: APPLICATION_VISITOR_ACCESS_TYPE;
  applications: RecruitmentVisitorAccessParts_permissions_applications[];
  recruitment: RecruitmentVisitorAccessParts_permissions_recruitment;
}

export interface RecruitmentVisitorAccessParts_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  createdAt: DateTime;
}

export interface RecruitmentVisitorAccessParts {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  createdAt: DateTime;
  email: string;
  firstName: string | null;
  lastName: string | null;
  permissions: RecruitmentVisitorAccessParts_permissions;
  recruitment: RecruitmentVisitorAccessParts_recruitment;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RecruitmentVisitorPermissionsParts
// ====================================================

export interface RecruitmentVisitorPermissionsParts_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RecruitmentVisitorPermissionsParts_applications_recruitment_users_user {
  __typename: "User";
  id: string;
}

export interface RecruitmentVisitorPermissionsParts_applications_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: RecruitmentVisitorPermissionsParts_applications_recruitment_users_user;
}

export interface RecruitmentVisitorPermissionsParts_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  company: RecruitmentVisitorPermissionsParts_applications_recruitment_company;
  users: RecruitmentVisitorPermissionsParts_applications_recruitment_users[];
}

export interface RecruitmentVisitorPermissionsParts_applications_tengaiInterview {
  __typename: "TengaiInterview";
  id: string;
  status: TENGAI_INTERVIEW_STATUS;
  totalPersonalityIndicatorScore: number | null;
}

export interface RecruitmentVisitorPermissionsParts_applications_applicationFormAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface RecruitmentVisitorPermissionsParts_applications_applicationFormAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: RecruitmentVisitorPermissionsParts_applications_applicationFormAnswers_question_matchingEventProfile | null;
}

export interface RecruitmentVisitorPermissionsParts_applications_applicationFormAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: RecruitmentVisitorPermissionsParts_applications_applicationFormAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface RecruitmentVisitorPermissionsParts_applications_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
}

export interface RecruitmentVisitorPermissionsParts_applications_grades_setBy {
  __typename: "User";
  id: string;
}

export interface RecruitmentVisitorPermissionsParts_applications_grades {
  __typename: "ApplicantGrade";
  id: string;
  setBy: RecruitmentVisitorPermissionsParts_applications_grades_setBy | null;
}

export interface RecruitmentVisitorPermissionsParts_applications_addedByUser_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface RecruitmentVisitorPermissionsParts_applications_addedByUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: RecruitmentVisitorPermissionsParts_applications_addedByUser_profileImage | null;
}

export interface RecruitmentVisitorPermissionsParts_applications_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  education: string | null;
  endDate: DateTime | null;
}

export interface RecruitmentVisitorPermissionsParts_applications_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  createdAt: DateTime;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
}

export interface RecruitmentVisitorPermissionsParts_applications_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface RecruitmentVisitorPermissionsParts_applications_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface RecruitmentVisitorPermissionsParts_applications_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: RecruitmentVisitorPermissionsParts_applications_documents_file;
  type: DOCUMENT_TYPE;
}

export interface RecruitmentVisitorPermissionsParts_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  createdAt: DateTime;
  averageGrade: number;
  needsVerification: boolean | null;
  note: string | null;
  computedMatchRanking: number | null;
  computedMatchScore: number;
  matchPercentageNeedToHave: number;
  matchPercentageNiceToHave: number;
  alvaLabsTestScore: number;
  alvaLabsCandidateCreatedAt: DateTime | null;
  recruitment: RecruitmentVisitorPermissionsParts_applications_recruitment;
  tengaiInterview: RecruitmentVisitorPermissionsParts_applications_tengaiInterview | null;
  applicationFormAnswers: RecruitmentVisitorPermissionsParts_applications_applicationFormAnswers[];
  companyApplicant: RecruitmentVisitorPermissionsParts_applications_companyApplicant | null;
  grades: RecruitmentVisitorPermissionsParts_applications_grades[];
  addedByUser: RecruitmentVisitorPermissionsParts_applications_addedByUser | null;
  educations: RecruitmentVisitorPermissionsParts_applications_educations[];
  status: RecruitmentVisitorPermissionsParts_applications_status | null;
  tags: RecruitmentVisitorPermissionsParts_applications_tags[];
  documents: RecruitmentVisitorPermissionsParts_applications_documents[];
}

export interface RecruitmentVisitorPermissionsParts_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface RecruitmentVisitorPermissionsParts {
  __typename: "RecruitmentVisitorPermissions";
  id: string;
  name: string | null;
  isTemplate: boolean | null;
  basePermissions: RECRUITMENT_VISITOR_PERMISSION[];
  applicationPermissions: APPLICATION_VISITOR_PERMISSION[];
  applicationAccessType: APPLICATION_VISITOR_ACCESS_TYPE;
  applications: RecruitmentVisitorPermissionsParts_applications[];
  recruitment: RecruitmentVisitorPermissionsParts_recruitment;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SharedImageParts
// ====================================================

export interface SharedImageParts_image_mediaElement_thumbnail {
  __typename: "MediaElement";
  id: string;
  url: string;
}

export interface SharedImageParts_image_mediaElement_createdBy_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface SharedImageParts_image_mediaElement_createdBy {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: SharedImageParts_image_mediaElement_createdBy_profileImage | null;
}

export interface SharedImageParts_image_mediaElement_tags {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

export interface SharedImageParts_image_mediaElement {
  __typename: "MediaElement";
  id: string;
  createdAt: DateTime;
  url: string;
  type: MEDIA_TYPE;
  filename: string;
  mimetype: string;
  blurhash: string | null;
  size: number;
  width: number;
  height: number;
  isThumbnail: boolean;
  isProcessing: boolean;
  thumbnail: SharedImageParts_image_mediaElement_thumbnail | null;
  createdBy: SharedImageParts_image_mediaElement_createdBy;
  tags: SharedImageParts_image_mediaElement_tags[];
  name: string | null;
  description: string | null;
}

export interface SharedImageParts_image {
  __typename: "Media";
  id: string;
  type: MEDIA_TYPE;
  width: number | null;
  height: number | null;
  blur: number | null;
  blurhash: string | null;
  grayscale: boolean | null;
  brightness: number | null;
  contrast: number | null;
  cropX: number | null;
  cropY: number | null;
  cropWidth: number | null;
  cropHeight: number | null;
  format: string | null;
  url: string;
  maxWidth: number | null;
  size: number;
  mediaElement: SharedImageParts_image_mediaElement;
}

export interface SharedImageParts {
  __typename: "SharedImage";
  id: string;
  createdAt: DateTime;
  title: string | null;
  subtitle: string | null;
  titleColor: string | null;
  titleFont: string | null;
  subtitleFont: string | null;
  subtitleColor: string | null;
  backgroundColor: string | null;
  backgroundType: SHARED_IMAGE_BACKGROUND_TYPE;
  width: number | null;
  height: number | null;
  image: SharedImageParts_image | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StatusDeadlineParts
// ====================================================

export interface StatusDeadlineParts_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
}

export interface StatusDeadlineParts_status {
  __typename: "Status";
  id: string;
}

export interface StatusDeadlineParts {
  __typename: "StatusDeadline";
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  deadline: DateTime;
  recruitment: StatusDeadlineParts_recruitment | null;
  status: StatusDeadlineParts_status;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StatusParts
// ====================================================

export interface StatusParts_company {
  __typename: "Company";
  id: string;
}

export interface StatusParts {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
  description: string | null;
  company: StatusParts_company;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TableApplicationParts
// ====================================================

export interface TableApplicationParts_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TableApplicationParts_recruitment_users_user {
  __typename: "User";
  id: string;
}

export interface TableApplicationParts_recruitment_users {
  __typename: "UserRecruitment";
  id: string;
  user: TableApplicationParts_recruitment_users_user;
}

export interface TableApplicationParts_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  language: string | null;
  company: TableApplicationParts_recruitment_company;
  users: TableApplicationParts_recruitment_users[];
}

export interface TableApplicationParts_tengaiInterview {
  __typename: "TengaiInterview";
  id: string;
  status: TENGAI_INTERVIEW_STATUS;
  totalPersonalityIndicatorScore: number | null;
}

export interface TableApplicationParts_applicationFormAnswers_question_matchingEventProfile {
  __typename: "MatchingEventProfile";
  id: string;
  title: string;
}

export interface TableApplicationParts_applicationFormAnswers_question {
  __typename: "FormQuestion";
  id: string;
  createdAt: DateTime;
  question: string | null;
  title: string | null;
  language: string | null;
  description: string | null;
  answerSuffix: string | null;
  required: boolean | null;
  listIndex: number;
  type: FORM_QUESTION_TYPE;
  options: string[] | null;
  matchingEventProfileId: string | null;
  matchingEventProfile: TableApplicationParts_applicationFormAnswers_question_matchingEventProfile | null;
}

export interface TableApplicationParts_applicationFormAnswers {
  __typename: "FormQuestionAnswer";
  id: string;
  createdAt: DateTime;
  question: TableApplicationParts_applicationFormAnswers_question;
  answerText: string | null;
  answerNumber: number | null;
  answerArray: string[] | null;
}

export interface TableApplicationParts_companyApplicant {
  __typename: "CompanyApplicant";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
}

export interface TableApplicationParts_grades_setBy {
  __typename: "User";
  id: string;
}

export interface TableApplicationParts_grades {
  __typename: "ApplicantGrade";
  id: string;
  setBy: TableApplicationParts_grades_setBy | null;
}

export interface TableApplicationParts_addedByUser_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface TableApplicationParts_addedByUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: TableApplicationParts_addedByUser_profileImage | null;
}

export interface TableApplicationParts_educations {
  __typename: "Education";
  id: string;
  school: string | null;
  education: string | null;
  endDate: DateTime | null;
}

export interface TableApplicationParts_status {
  __typename: "ApplicationStatus";
  id: string;
  color: string | null;
  title: string | null;
  createdAt: DateTime;
  listIndex: number | null;
  type: APPLICATION_STATUS_TYPE;
}

export interface TableApplicationParts_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface TableApplicationParts_documents_file {
  __typename: "File";
  id: string;
  url: string | null;
  downloadUrl: string | null;
  mimetype: string;
}

export interface TableApplicationParts_documents {
  __typename: "Document";
  id: string;
  displayName: string;
  file: TableApplicationParts_documents_file;
  type: DOCUMENT_TYPE;
}

export interface TableApplicationParts {
  __typename: "Application";
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  createdAt: DateTime;
  averageGrade: number;
  needsVerification: boolean | null;
  note: string | null;
  computedMatchRanking: number | null;
  computedMatchScore: number;
  matchPercentageNeedToHave: number;
  matchPercentageNiceToHave: number;
  alvaLabsTestScore: number;
  alvaLabsCandidateCreatedAt: DateTime | null;
  recruitment: TableApplicationParts_recruitment;
  tengaiInterview: TableApplicationParts_tengaiInterview | null;
  applicationFormAnswers: TableApplicationParts_applicationFormAnswers[];
  companyApplicant: TableApplicationParts_companyApplicant | null;
  grades: TableApplicationParts_grades[];
  addedByUser: TableApplicationParts_addedByUser | null;
  educations: TableApplicationParts_educations[];
  status: TableApplicationParts_status | null;
  tags: TableApplicationParts_tags[];
  documents: TableApplicationParts_documents[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TableCompanyApplicantParts
// ====================================================

export interface TableCompanyApplicantParts_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TableCompanyApplicantParts_applications_recruitment_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TableCompanyApplicantParts_applications_recruitment {
  __typename: "Recruitment";
  id: string;
  title: string;
  company: TableCompanyApplicantParts_applications_recruitment_company;
}

export interface TableCompanyApplicantParts_applications_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface TableCompanyApplicantParts_applications {
  __typename: "Application";
  id: string;
  firstName: string;
  recruitment: TableCompanyApplicantParts_applications_recruitment;
  educations: TableCompanyApplicantParts_applications_educations[];
}

export interface TableCompanyApplicantParts_messages_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface TableCompanyApplicantParts_messages_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: TableCompanyApplicantParts_messages_user_profileImage | null;
}

export interface TableCompanyApplicantParts_messages {
  __typename: "ApplicantMessage";
  id: string;
  subject: string | null;
  text: string;
  sentVia: MESSAGE_CHANNEL[];
  sentByApplicant: boolean;
  createdAt: DateTime;
  user: TableCompanyApplicantParts_messages_user | null;
}

export interface TableCompanyApplicantParts_educations {
  __typename: "Education";
  id: string;
  education: string | null;
  school: string | null;
  degree: string | null;
}

export interface TableCompanyApplicantParts_workExperiences {
  __typename: "WorkExperience";
  id: string;
  position: string | null;
  companyName: string | null;
}

export interface TableCompanyApplicantParts_competences_competence {
  __typename: "Competence";
  id: string;
  type: COMPETENCE_TYPE | null;
  name: string;
}

export interface TableCompanyApplicantParts_competences {
  __typename: "ApplicantCompetence";
  id: string;
  grade: number;
  competence: TableCompanyApplicantParts_competences_competence;
}

export interface TableCompanyApplicantParts_grades_visitor {
  __typename: "RecruitmentVisitorAccess";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TableCompanyApplicantParts_grades_setBy {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TableCompanyApplicantParts_grades {
  __typename: "ApplicantGrade";
  id: string;
  grade: number;
  visitor: TableCompanyApplicantParts_grades_visitor | null;
  setBy: TableCompanyApplicantParts_grades_setBy | null;
}

export interface TableCompanyApplicantParts_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface TableCompanyApplicantParts {
  __typename: "CompanyApplicant";
  id: string;
  createdAt: DateTime | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  averageGrade: number;
  company: TableCompanyApplicantParts_company;
  applications: TableCompanyApplicantParts_applications[];
  messagesRead: boolean | null;
  latestMessageTime: DateTime | null;
  messages: TableCompanyApplicantParts_messages[];
  educations: TableCompanyApplicantParts_educations[];
  workExperiences: TableCompanyApplicantParts_workExperiences[];
  competences: TableCompanyApplicantParts_competences[];
  grades: TableCompanyApplicantParts_grades[];
  tags: TableCompanyApplicantParts_tags[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TableRecruitmentParts
// ====================================================

export interface TableRecruitmentParts_status {
  __typename: "Status";
  id: string;
  type: STATUS_TYPE;
  color: string | null;
  listIndex: number | null;
  title: string;
}

export interface TableRecruitmentParts_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TableRecruitmentParts_tags {
  __typename: "TagLabel";
  id: string;
  title: string;
}

export interface TableRecruitmentParts_users_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface TableRecruitmentParts_users_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  profileImage: TableRecruitmentParts_users_user_profileImage | null;
}

export interface TableRecruitmentParts_users {
  __typename: "UserRecruitment";
  id: string;
  user: TableRecruitmentParts_users_user;
}

export interface TableRecruitmentParts {
  __typename: "Recruitment";
  id: string;
  createdAt: DateTime;
  title: string;
  type: string | null;
  language: string | null;
  publishingStatus: RECRUITMENT_STATUS | null;
  enabled: boolean | null;
  dateSpanEnabled: boolean | null;
  activeFrom: DateTime | null;
  activeTo: DateTime | null;
  applicationCount: number;
  newApplicationCount: number;
  professionFieldName: string | null;
  status: TableRecruitmentParts_status | null;
  company: TableRecruitmentParts_company;
  tags: TableRecruitmentParts_tags[];
  users: TableRecruitmentParts_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TagLabelParts
// ====================================================

export interface TagLabelParts {
  __typename: "TagLabel";
  id: string;
  tag: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TagParts
// ====================================================

export interface TagParts {
  __typename: "Tag";
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserAgentParts
// ====================================================

export interface UserAgentParts {
  __typename: "UserAgent";
  id: string;
  userAgent: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  usageCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserParts
// ====================================================

export interface UserParts_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UserParts {
  __typename: "User";
  id: string;
  auth0Id: string | null;
  createdAt: DateTime;
  updatedAt: DateTime;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  privilegeLevel: USER_PRIVILEGE_LEVEL;
  profileImage: UserParts_profileImage | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserRecruitmentParts
// ====================================================

export interface UserRecruitmentParts_user_profileImage {
  __typename: "File";
  id: string;
  url: string | null;
}

export interface UserRecruitmentParts_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  subscribedMailNotificationTypes: NOTIFICATION_TYPE[];
  subscribedToNotificationsStatus: SUBSCRIBED_TO_NOTIFICATIONS_STATUS | null;
  profileImage: UserRecruitmentParts_user_profileImage | null;
}

export interface UserRecruitmentParts {
  __typename: "UserRecruitment";
  id: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
  user: UserRecruitmentParts_user;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VariableParts
// ====================================================

export interface VariableParts_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface VariableParts_companiesWithAccess {
  __typename: "Company";
  id: string;
  name: string;
}

export interface VariableParts {
  __typename: "Variable";
  id: string;
  key: string;
  value: string;
  description: string | null;
  company: VariableParts_company;
  createdAt: DateTime;
  shareAccessStatus: TEMPLATE_SHARE_STATUS | null;
  companiesWithAccess: VariableParts_companiesWithAccess[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WidgetParts
// ====================================================

export interface WidgetParts {
  __typename: "Widget";
  id: string;
  name: string;
  description: string | null;
  type: WIDGET_TYPE;
  customCss: boolean | null;
  css: string | null;
  allowSorting: boolean | null;
  showCompanyLogoOnAds: boolean | null;
  showCompanyNameOnAds: boolean | null;
  showExpiredAds: boolean | null;
  primaryColor: string | null;
  secondaryColor: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkExperienceParts
// ====================================================

export interface WorkExperienceParts {
  __typename: "WorkExperience";
  id: string;
  companyName: string | null;
  position: string | null;
  location: string | null;
  isOngoing: boolean | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  description: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AD_DISTRIBUTION_TYPE {
  ARBETSFORMEDLINGEN = "ARBETSFORMEDLINGEN",
  META = "META",
}

export enum APPLICATION_STATUS_TYPE {
  DISCARDED = "DISCARDED",
  HIRED = "HIRED",
  INTRESTING = "INTRESTING",
  NEW = "NEW",
  OTHER = "OTHER",
  PENDING = "PENDING",
}

export enum APPLICATION_VISITOR_ACCESS_TYPE {
  ALL = "ALL",
  SPECIFIC = "SPECIFIC",
}

export enum APPLICATION_VISITOR_PERMISSION {
  APPLICATION_FORM = "APPLICATION_FORM",
  BASE_INFORMATION = "BASE_INFORMATION",
  COMMENTS = "COMMENTS",
  FILE_UPLOADS = "FILE_UPLOADS",
  GRADE = "GRADE",
  MESSAGES = "MESSAGES",
  NOTES = "NOTES",
  REFERENCE_FORMS = "REFERENCE_FORMS",
  SCREENING_FORMS = "SCREENING_FORMS",
  STATUS = "STATUS",
  TAGS = "TAGS",
}

export enum ASSIGNMENT_STATUS {
  DOING = "DOING",
  DONE = "DONE",
  TODO = "TODO",
}

export enum ActivityType {
  APPLICANT_ADDED_REFERENCES = "APPLICANT_ADDED_REFERENCES",
  APPLICANT_GRADE = "APPLICANT_GRADE",
  APPLICANT_STATUS_CHANGE = "APPLICANT_STATUS_CHANGE",
  APPLICATION_CREATED = "APPLICATION_CREATED",
  APPLICATION_STATUS_CHANGE = "APPLICATION_STATUS_CHANGE",
  APPLICATION_SUBMISSION_UPDATED_BY_RECRUITER = "APPLICATION_SUBMISSION_UPDATED_BY_RECRUITER",
  ASSIGNMENT_COMMENT = "ASSIGNMENT_COMMENT",
  ASSIGNMENT_CREATE = "ASSIGNMENT_CREATE",
  ASSIGNMENT_MOVE = "ASSIGNMENT_MOVE",
  CALENDAR_EVENT_ACCEPTED = "CALENDAR_EVENT_ACCEPTED",
  CALENDAR_EVENT_DECLINED = "CALENDAR_EVENT_DECLINED",
  COMMENT = "COMMENT",
  CONSULTANT_CREATED = "CONSULTANT_CREATED",
  CONSULTANT_CUSTOMER_CREATE = "CONSULTANT_CUSTOMER_CREATE",
  CONSULTANT_GRADE = "CONSULTANT_GRADE",
  CONSULTANT_PROFILE_UPDATED = "CONSULTANT_PROFILE_UPDATED",
  CONSULTANT_PROFILE_UPDATED_BY_RECRUITER = "CONSULTANT_PROFILE_UPDATED_BY_RECRUITER",
  CONSULTANT_PROJECT_COST_CREATE = "CONSULTANT_PROJECT_COST_CREATE",
  CONSULTANT_PROJECT_CREATE = "CONSULTANT_PROJECT_CREATE",
  CONSULTANT_PROJECT_STATUS_CHANGE = "CONSULTANT_PROJECT_STATUS_CHANGE",
  CONTRACT_CREATED = "CONTRACT_CREATED",
  CONTRACT_DELETED = "CONTRACT_DELETED",
  FORM_ANSWERED = "FORM_ANSWERED",
  FORM_APPLICANT_ADDED = "FORM_APPLICANT_ADDED",
  FORM_APPLICANT_DELETED = "FORM_APPLICANT_DELETED",
  FORM_SENT = "FORM_SENT",
  INTERVIEW_BOOKED_BY_APPLICATION = "INTERVIEW_BOOKED_BY_APPLICATION",
  INTERVIEW_BOOKED_BY_RECRUITER = "INTERVIEW_BOOKED_BY_RECRUITER",
  INTERVIEW_BOOKING_GROUP_APPLICATION_ADDED = "INTERVIEW_BOOKING_GROUP_APPLICATION_ADDED",
  INTERVIEW_BOOKING_GROUP_CREATED = "INTERVIEW_BOOKING_GROUP_CREATED",
  INTERVIEW_BOOKING_GROUP_UPDATED = "INTERVIEW_BOOKING_GROUP_UPDATED",
  INTERVIEW_CANCELED_BY_APPLICATION = "INTERVIEW_CANCELED_BY_APPLICATION",
  INTERVIEW_INVITE_DECLINED_BY_APPLICATION = "INTERVIEW_INVITE_DECLINED_BY_APPLICATION",
  MESSAGE_FROM_APPLICANT = "MESSAGE_FROM_APPLICANT",
  MESSAGE_FROM_COMPANY = "MESSAGE_FROM_COMPANY",
  MESSAGE_TO_APPLICANT = "MESSAGE_TO_APPLICANT",
  MESSAGE_TO_COMPANY = "MESSAGE_TO_COMPANY",
  RECRUITER_ADDED_REFERENCE = "RECRUITER_ADDED_REFERENCE",
  RECRUITMENT_CREATE = "RECRUITMENT_CREATE",
  REFERENCE_FORM_CREATE = "REFERENCE_FORM_CREATE",
  REFERENCE_FORM_REFERENCES_REQUESTED = "REFERENCE_FORM_REFERENCES_REQUESTED",
  SCREENING_FORM_CREATE = "SCREENING_FORM_CREATE",
  STATUS_CHANGE = "STATUS_CHANGE",
  STATUS_CREATED = "STATUS_CREATED",
  STATUS_DELETED = "STATUS_DELETED",
}

export enum CALENDAR_EVENT_ATTENDEE_STATUS {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  DELEGATED = "DELEGATED",
  UNANSWERED = "UNANSWERED",
}

export enum CALENDAR_EVENT_TYPE {
  BOOKABLE_INTERVIEW = "BOOKABLE_INTERVIEW",
  EXTERNAL_SOURCE = "EXTERNAL_SOURCE",
  INTERVIEW_BOOKING = "INTERVIEW_BOOKING",
  MANUALLY_BOOKED_INTERVIEW = "MANUALLY_BOOKED_INTERVIEW",
  MATCHING_EVENT_APPLICANT_BOOKABLE_INTERVIEW = "MATCHING_EVENT_APPLICANT_BOOKABLE_INTERVIEW",
  MATCHING_EVENT_COMPANY_BOOKABLE_INTERVIEW = "MATCHING_EVENT_COMPANY_BOOKABLE_INTERVIEW",
  OTHER = "OTHER",
}

export enum COMPANY_PAYMENT_TYPE {
  ANNUALLY = "ANNUALLY",
  MONTHLY = "MONTHLY",
  PER_RECRUITMENT = "PER_RECRUITMENT",
  UNKNOWN = "UNKNOWN",
}

export enum COMPANY_TYPE {
  CHILD_EVENT_MATCHING = "CHILD_EVENT_MATCHING",
  CONSULTANT = "CONSULTANT",
  CONSULTANT_CUSTOMER = "CONSULTANT_CUSTOMER",
  DEFAULT = "DEFAULT",
  FREE = "FREE",
  GROUP = "GROUP",
  GROUP_EVENT_MATCHING = "GROUP_EVENT_MATCHING",
  REFERENCE = "REFERENCE",
}

export enum COMPETENCE_ANSWER_TYPE {
  GRADE = "GRADE",
  YES_NO_ANSWER = "YES_NO_ANSWER",
}

export enum COMPETENCE_TYPE {
  SKILL = "SKILL",
  SOCIAL = "SOCIAL",
}

export enum CONSULTANT_PROJECT_PRIVILEGE_LEVEL {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
  VIEWER = "VIEWER",
}

export enum CONTENT_BLOCK_TYPE {
  AD_LIST = "AD_LIST",
  AD_META_INFO = "AD_META_INFO",
  COMPANY_DESCRIPTION = "COMPANY_DESCRIPTION",
  CONTACT = "CONTACT",
  CONTACT_PERSON = "CONTACT_PERSON",
  COVER_IMAGE = "COVER_IMAGE",
  COVER_VIDEO = "COVER_VIDEO",
  SHARE_BUTTONS = "SHARE_BUTTONS",
  SPONTANEOUS_APPLICATION = "SPONTANEOUS_APPLICATION",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

export enum CONTRACT_TYPE {
  APPLICANT_CONTRACT = "APPLICANT_CONTRACT",
  APPLICATION_CONTRACT = "APPLICATION_CONTRACT",
  CONSULTANT_CONTRACT = "CONSULTANT_CONTRACT",
  CONSULTANT_CUSTOMER_CONTRACT = "CONSULTANT_CUSTOMER_CONTRACT",
  CUSTOM_CONTRACT = "CUSTOM_CONTRACT",
}

export enum COST_TYPE {
  FIXED = "FIXED",
  FIXED_BUDGET = "FIXED_BUDGET",
  RUNNING = "RUNNING",
  RUNNING_BUDGET = "RUNNING_BUDGET",
}

export enum COVER_IMAGE_VARIANT {
  FULLSCREEN = "FULLSCREEN",
  KEEP_PROPORTIONS = "KEEP_PROPORTIONS",
  WIDE_FIXED_HEIGHT = "WIDE_FIXED_HEIGHT",
  WIDE_KEEP_PROPORTIONS = "WIDE_KEEP_PROPORTIONS",
}

export enum DOCUMENT_TYPE {
  OTHER = "OTHER",
  PERSONAL_LETTER = "PERSONAL_LETTER",
  RESUME = "RESUME",
  VIDEO = "VIDEO",
}

export enum EMPLOYMENT_TEMPORARY_TYPE {
  ELEVEN_DAYS_TO_THREE_MONTHS = "ELEVEN_DAYS_TO_THREE_MONTHS",
  NONE = "NONE",
  SIX_MONTHS_OR_MORE = "SIX_MONTHS_OR_MORE",
  SUMMER_JOB = "SUMMER_JOB",
  TEN_DAYS_MAX = "TEN_DAYS_MAX",
  THREE_TO_SIX_MONTHS = "THREE_TO_SIX_MONTHS",
}

export enum EMPLOYMENT_TYPE {
  APPRENTICE = "APPRENTICE",
  INDEFINITE = "INDEFINITE",
  INTERNSHIP = "INTERNSHIP",
  NEEDS = "NEEDS",
  NONE = "NONE",
  PROBATIONARY = "PROBATIONARY",
  PROVISION_BASED = "PROVISION_BASED",
  SEASON = "SEASON",
  SUBSITUTE = "SUBSITUTE",
  TEMPORARY = "TEMPORARY",
  TRAINEE = "TRAINEE",
}

export enum ERROR_TYPE {
  APPLYING_FOR_JOB = "APPLYING_FOR_JOB",
  CRUITIVE_ADMIN = "CRUITIVE_ADMIN",
  INBOUND_MAIL = "INBOUND_MAIL",
  MAIL = "MAIL",
  OTHER = "OTHER",
  STATUS_CHANGE = "STATUS_CHANGE",
}

export enum EVENT_MATCH_STATUS {
  APPROVED = "APPROVED",
  AWAITING_APPROVAL_FROM_APPLICANT = "AWAITING_APPROVAL_FROM_APPLICANT",
  AWAITING_APPROVAL_FROM_COMPANY = "AWAITING_APPROVAL_FROM_COMPANY",
  AWAITING_APPROVAL_FROM_HOST = "AWAITING_APPROVAL_FROM_HOST",
  DENIED_BY_APPLICANT = "DENIED_BY_APPLICANT",
  DENIED_BY_COMPANY = "DENIED_BY_COMPANY",
  DENIED_BY_HOST = "DENIED_BY_HOST",
}

export enum EVENT_TRIGGER_ACTION_TYPE {
  ALVA_LABS_SEND_TEST = "ALVA_LABS_SEND_TEST",
  REFERENCE_FORM_SEND_QUESTIONS_TO_REFERENCES = "REFERENCE_FORM_SEND_QUESTIONS_TO_REFERENCES",
  REFERENCE_FORM_SEND_REFERENCES_REQUEST = "REFERENCE_FORM_SEND_REFERENCES_REQUEST",
  SCREENING_FORM_SEND_QUESTIONS = "SCREENING_FORM_SEND_QUESTIONS",
  TENGAI_INVITE_TO_INTERVIEW = "TENGAI_INVITE_TO_INTERVIEW",
  TRUSTCRUIT_DENIED = "TRUSTCRUIT_DENIED",
  TRUSTCRUIT_HIRED = "TRUSTCRUIT_HIRED",
  TRUSTCRUIT_INTERVIEWED = "TRUSTCRUIT_INTERVIEWED",
  TRUSTCRUIT_NEW_APPLICATION = "TRUSTCRUIT_NEW_APPLICATION",
  TRUSTCRUIT_ONBOARDING = "TRUSTCRUIT_ONBOARDING",
}

export enum FIELD_EXTENSION_TYPE {
  APPLICANT = "APPLICANT",
  APPLICATION = "APPLICATION",
  CONSULTANT = "CONSULTANT",
  CONSULTANT_PROJECT = "CONSULTANT_PROJECT",
}

export enum FIELD_EXTENSION_VALUE_TYPE {
  CHECKBOXES = "CHECKBOXES",
  FILE_UPLOAD = "FILE_UPLOAD",
  GRADE = "GRADE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  PARAGRAPH = "PARAGRAPH",
  SHORT_TEXT = "SHORT_TEXT",
}

export enum FORM_QUESTION_TYPE {
  CHECKBOXES = "CHECKBOXES",
  COVER_LETTER_UPLOAD = "COVER_LETTER_UPLOAD",
  EDUCATION = "EDUCATION",
  FILE_UPLOAD = "FILE_UPLOAD",
  GRADE = "GRADE",
  GRADES = "GRADES",
  INFO_TEXT_BLOCK = "INFO_TEXT_BLOCK",
  INTERVAL_SLIDER = "INTERVAL_SLIDER",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  MULTIPLE_DOCUMENTS_UPLOAD = "MULTIPLE_DOCUMENTS_UPLOAD",
  PARAGRAPH = "PARAGRAPH",
  PERCENTAGE_SLIDER = "PERCENTAGE_SLIDER",
  PERSONAL_INFO = "PERSONAL_INFO",
  RESUME_UPLOAD = "RESUME_UPLOAD",
  SHORT_ANSWER = "SHORT_ANSWER",
  SKILLS = "SKILLS",
  SOCIAL_COMPETENCES = "SOCIAL_COMPETENCES",
  VALUE_SLIDER = "VALUE_SLIDER",
  VIDEO = "VIDEO",
  WORK_EXPERIENCE = "WORK_EXPERIENCE",
}

export enum FORM_STATUS {
  DRAFT = "DRAFT",
  FINISHED = "FINISHED",
  PUBLISHED = "PUBLISHED",
  TEMPLATE = "TEMPLATE",
}

export enum FORM_SUBMISSION_STATUS {
  ANSWERED = "ANSWERED",
  BOUNCED = "BOUNCED",
  REJECTED = "REJECTED",
  UNANSWERED = "UNANSWERED",
}

export enum FORM_TYPE {
  APPLICATION_FORM = "APPLICATION_FORM",
  COMPETENCE_REFERENCE_FORM = "COMPETENCE_REFERENCE_FORM",
  CONSULTANT_PROFILE_FORM = "CONSULTANT_PROFILE_FORM",
  IDENTIFIED_NEED_FORM = "IDENTIFIED_NEED_FORM",
  MATCHING_EVENT_FORM = "MATCHING_EVENT_FORM",
  REFERENCE_FORM = "REFERENCE_FORM",
  SCREENING_FORM = "SCREENING_FORM",
}

export enum IDENTIFIED_NEED_STATUS {
  APPROVED = "APPROVED",
  DRAFT = "DRAFT",
  READY_FOR_REVIEW = "READY_FOR_REVIEW",
  REJECTED = "REJECTED",
  REVIEWED = "REVIEWED",
}

export enum LANGUAGE_STYLE {
  FORMAL = "FORMAL",
  INFORMAL = "INFORMAL",
  NEUTRAL = "NEUTRAL",
  PLAYFUL = "PLAYFUL",
}

export enum MATCHING_EVENT_FLOW_ORDER {
  HOST_APPLICANT_COMPANY = "HOST_APPLICANT_COMPANY",
  HOST_COMPANY_APPLICANT = "HOST_COMPANY_APPLICANT",
}

export enum MATCHING_EVENT_INTERVIEW_BOOKING_STATUS {
  CLOSED = "CLOSED",
  MATCHES_INTERVIEW_BOOKING_OPEN = "MATCHES_INTERVIEW_BOOKING_OPEN",
  PARTICIPANT_INTERVIEW_BOOKING_SELECTION = "PARTICIPANT_INTERVIEW_BOOKING_SELECTION",
}

export enum MATCHING_EVENT_STATUS {
  ACTIVE = "ACTIVE",
  FINISHED = "FINISHED",
}

export enum MEDIA_TYPE {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum MESSAGE_CHANNEL {
  APPLICANT_PAGE = "APPLICANT_PAGE",
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export enum MESSAGE_STATUS {
  BOUNCED = "BOUNCED",
  DELIVERED = "DELIVERED",
  READ = "READ",
  UNREAD = "UNREAD",
}

export enum MESSAGE_TYPE {
  ADD_APPLICATION_TO_RECRUITMENT_CONFIRM = "ADD_APPLICATION_TO_RECRUITMENT_CONFIRM",
  ADD_APPLICATION_TO_RECRUITMENT_REJECT = "ADD_APPLICATION_TO_RECRUITMENT_REJECT",
  ADD_APPLICATION_TO_RECRUITMENT_REQUEST = "ADD_APPLICATION_TO_RECRUITMENT_REQUEST",
  APPLICANT_ADDED_TO_RECRUITMENT = "APPLICANT_ADDED_TO_RECRUITMENT",
  APPLICATION_CONFIRMATION = "APPLICATION_CONFIRMATION",
  APPLICATION_DISCARDED = "APPLICATION_DISCARDED",
  HIRE_APPLICANT = "HIRE_APPLICANT",
  INTERVIEW_BOOKED = "INTERVIEW_BOOKED",
  INTERVIEW_BOOKING = "INTERVIEW_BOOKING",
  MESSAGE_FROM_COMPANY = "MESSAGE_FROM_COMPANY",
  MESSAGE_TO_COMPANY = "MESSAGE_TO_COMPANY",
  NEW_APPLICANT_MESSAGE = "NEW_APPLICANT_MESSAGE",
  NEW_MESSAGE = "NEW_MESSAGE",
  NEW_MESSAGE_FROM_APPLICANT = "NEW_MESSAGE_FROM_APPLICANT",
  OTHER = "OTHER",
  SPONTANEOUS_CONFIRMATION = "SPONTANEOUS_CONFIRMATION",
}

export enum NOTIFICATION_TYPE {
  ADDED_TO_ASSIGNMENT = "ADDED_TO_ASSIGNMENT",
  ADDED_TO_CALENDAR_EVENT = "ADDED_TO_CALENDAR_EVENT",
  ADDED_TO_CONSULTANT_PROJECT = "ADDED_TO_CONSULTANT_PROJECT",
  ADDED_TO_IDENTIFIED_NEED = "ADDED_TO_IDENTIFIED_NEED",
  ADDED_TO_INTERVIEW = "ADDED_TO_INTERVIEW",
  ADDED_TO_RECRUITMENT = "ADDED_TO_RECRUITMENT",
  ADD_APPLICATION_TO_RECRUITMENT_CONFIRM = "ADD_APPLICATION_TO_RECRUITMENT_CONFIRM",
  ADD_APPLICATION_TO_RECRUITMENT_REJECT = "ADD_APPLICATION_TO_RECRUITMENT_REJECT",
  APPLICANT_ADDED_TO_RECRUITMENT = "APPLICANT_ADDED_TO_RECRUITMENT",
  APPLICANT_MESSAGE_BOUNCED = "APPLICANT_MESSAGE_BOUNCED",
  CALENDAR_EVENT_ACCEPTED = "CALENDAR_EVENT_ACCEPTED",
  CALENDAR_EVENT_DECLINED = "CALENDAR_EVENT_DECLINED",
  CALENDAR_EVENT_REMINDER_INTERVIEW_BOOKING = "CALENDAR_EVENT_REMINDER_INTERVIEW_BOOKING",
  CALENDAR_EVENT_REMINDER_MATCHING_EVENT_INTERVIEW = "CALENDAR_EVENT_REMINDER_MATCHING_EVENT_INTERVIEW",
  CALENDAR_EVENT_REMINDER_OTHER = "CALENDAR_EVENT_REMINDER_OTHER",
  COMMENT_ANSWER = "COMMENT_ANSWER",
  COMMENT_LIKE = "COMMENT_LIKE",
  CONSULTANT_PROFILE_UPDATED = "CONSULTANT_PROFILE_UPDATED",
  CONTRACT_SIGNED = "CONTRACT_SIGNED",
  FORM_SUBMISSION = "FORM_SUBMISSION",
  HOST_MATCHES_ON_TALENT_PROFILE = "HOST_MATCHES_ON_TALENT_PROFILE",
  IDENTIFIED_NEED_ACCEPTED = "IDENTIFIED_NEED_ACCEPTED",
  IDENTIFIED_NEED_REJECTED = "IDENTIFIED_NEED_REJECTED",
  INTERVIEW_BOOKED = "INTERVIEW_BOOKED",
  INTERVIEW_BOOKED_BY_APPLICATION = "INTERVIEW_BOOKED_BY_APPLICATION",
  INTERVIEW_CANCELED = "INTERVIEW_CANCELED",
  INTERVIEW_CANCELED_BY_APPLICATION = "INTERVIEW_CANCELED_BY_APPLICATION",
  INTERVIEW_INVITE_DECLINED_BY_APPLICATION = "INTERVIEW_INVITE_DECLINED_BY_APPLICATION",
  LAST_APPLICATION_DATE_PASSED = "LAST_APPLICATION_DATE_PASSED",
  MENTION_IN_COMMENT = "MENTION_IN_COMMENT",
  MESSAGE_FROM_APPLICANT = "MESSAGE_FROM_APPLICANT",
  MESSAGE_FROM_COMPANY = "MESSAGE_FROM_COMPANY",
  NEW_APPLICATION = "NEW_APPLICATION",
  NEW_ROLE_FIT_ALVA_LABS = "NEW_ROLE_FIT_ALVA_LABS",
  PARTICIPANT_MATCHES_ON_TALENT_PROFILE = "PARTICIPANT_MATCHES_ON_TALENT_PROFILE",
  RECRUITMENT_STATUS_DEADLINE_PASSED = "RECRUITMENT_STATUS_DEADLINE_PASSED",
  REFERENCES_ADDED = "REFERENCES_ADDED",
  REFERENCE_MESSAGE_BOUNCED = "REFERENCE_MESSAGE_BOUNCED",
  TENGAI_INTERVIEW_UPDATED = "TENGAI_INTERVIEW_UPDATED",
}

export enum OFFER_STATUS {
  ACCEPTED = "ACCEPTED",
  DENIED = "DENIED",
  NONE = "NONE",
  SENT = "SENT",
}

export enum PAGE_BLOCK_BACKGROUND_TYPE {
  COLOR = "COLOR",
  MEDIA = "MEDIA",
}

export enum PAGE_BLOCK_HEIGHT_TYPE {
  AUTOMATIC = "AUTOMATIC",
  FIXED = "FIXED",
  SCREEN = "SCREEN",
}

export enum PAGE_BLOCK_TYPE {
  AD_LIST = "AD_LIST",
  AD_META_INFO = "AD_META_INFO",
  COLUMNS = "COLUMNS",
  COMPANY_DESCRIPTION = "COMPANY_DESCRIPTION",
  COMPANY_PERKS = "COMPANY_PERKS",
  CONTACT_PERSON = "CONTACT_PERSON",
  COVER_MEDIA = "COVER_MEDIA",
  FIND_US = "FIND_US",
  IMAGE = "IMAGE",
  IMAGE_CAROUSEL = "IMAGE_CAROUSEL",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

export enum PAGE_TEMPLATE_TYPE {
  AD = "AD",
  CAREER_PAGE = "CAREER_PAGE",
}

export enum PROFESSION_TYPE {
  ADMINISTRATION = "ADMINISTRATION",
  CONSTRUCTION = "CONSTRUCTION",
  CRAFTS = "CRAFTS",
  CULTURE_MEDIA_DESIGN = "CULTURE_MEDIA_DESIGN",
  DATA_IT = "DATA_IT",
  ECONOMY_LAW = "ECONOMY_LAW",
  ENVIRONMENT_HEALTH_PROTECTION = "ENVIRONMENT_HEALTH_PROTECTION",
  HEALTH_CARE = "HEALTH_CARE",
  HOTEL_RESTAURANT = "HOTEL_RESTAURANT",
  INDUSTRY_MANUFACTURING = "INDUSTRY_MANUFACTURING",
  INSTALLATION_OPERATION_MAINTENANCE = "INSTALLATION_OPERATION_MAINTENANCE",
  PEDAGOGY = "PEDAGOGY",
  SALES_MARKETING = "SALES_MARKETING",
  SCIENCE = "SCIENCE",
  SECURITY = "SECURITY",
  SOCIAL_WORK = "SOCIAL_WORK",
  TECHNOLOGY = "TECHNOLOGY",
  TRANSPORT = "TRANSPORT",
  UNSPECIFIED = "UNSPECIFIED",
  WELLNESS_BODY_CARE = "WELLNESS_BODY_CARE",
}

export enum RECRUITMENT_PRIVILEGE_LEVEL {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
  VIEWER = "VIEWER",
}

export enum RECRUITMENT_STATUS {
  COMPLETED = "COMPLETED",
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export enum RECRUITMENT_TYPE {
  MATCHING_EVENT_CHILD = "MATCHING_EVENT_CHILD",
  MATCHING_EVENT_HOST = "MATCHING_EVENT_HOST",
  NORMAL = "NORMAL",
  PRIVATE = "PRIVATE",
  SPONTANIOUS = "SPONTANIOUS",
}

export enum RECRUITMENT_VISITOR_PERMISSION {
  APPLICATIONS = "APPLICATIONS",
  COMMENTS = "COMMENTS",
  IDENTIFIED_NEED = "IDENTIFIED_NEED",
  PREVIEW_AD = "PREVIEW_AD",
  PREVIEW_APPLICATION_FORM = "PREVIEW_APPLICATION_FORM",
  REQUIREMENTS_PROFILE = "REQUIREMENTS_PROFILE",
  STATISTICS = "STATISTICS",
}

export enum REQUIREMENT_SIGNIFICANCE {
  NEED_TO_HAVE = "NEED_TO_HAVE",
  NICE_TO_HAVE = "NICE_TO_HAVE",
  NOT_IMPORTANT = "NOT_IMPORTANT",
}

export enum SALARY_TYPE {
  COMISSION_ONLY = "COMISSION_ONLY",
  FIXED_SALARY = "FIXED_SALARY",
  FIXED_SALARY_PLUS_COMISSION = "FIXED_SALARY_PLUS_COMISSION",
  NONE = "NONE",
}

export enum SHARED_IMAGE_BACKGROUND_TYPE {
  COLOR = "COLOR",
  IMAGE = "IMAGE",
}

export enum SOCIAL_MEDIA_SOURCE {
  COPY_URL = "COPY_URL",
  EMAIL = "EMAIL",
  FACEBOOK = "FACEBOOK",
  LINKEDIN = "LINKEDIN",
  TWITTER = "TWITTER",
  WHATSAPP = "WHATSAPP",
}

export enum STATUS {
  APPROVED_FOR_INTERVIEW = "APPROVED_FOR_INTERVIEW",
  DISCARDED = "DISCARDED",
  DRAFT = "DRAFT",
  HIRED = "HIRED",
  INTERVIEWED = "INTERVIEWED",
  INTERVIEW_BOOKED = "INTERVIEW_BOOKED",
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
}

export enum STATUS_TYPE {
  CONSULTANTS = "CONSULTANTS",
  CONSULTANT_CUSTOMERS = "CONSULTANT_CUSTOMERS",
  CONSULTANT_PROJECTS = "CONSULTANT_PROJECTS",
  RECRUITMENTS = "RECRUITMENTS",
}

export enum SUBSCRIBED_TO_NOTIFICATIONS_STATUS {
  ALL = "ALL",
  NONE = "NONE",
  SELECTED = "SELECTED",
}

export enum ScoringMatrixQuestionType {
  DIGITAL = "DIGITAL",
  GRADE = "GRADE",
}

export enum TEMPLATE_SHARE_STATUS {
  ALL_CHILD_COMPANIES_CAN_ACCESS = "ALL_CHILD_COMPANIES_CAN_ACCESS",
  PRIVATE = "PRIVATE",
  SELECTED_CHILD_COMPANIES_CAN_ACCESS = "SELECTED_CHILD_COMPANIES_CAN_ACCESS",
}

export enum TENGAI_INTERVIEW_STATUS {
  CANDIDATE_HAS_BOOKED_INTERVIEW = "CANDIDATE_HAS_BOOKED_INTERVIEW",
  INTERVIEW_COMPLETED = "INTERVIEW_COMPLETED",
  WAITING_FOR_CANDIDATE_TO_BOOK_INTERVIEW = "WAITING_FOR_CANDIDATE_TO_BOOK_INTERVIEW",
  WAITING_FOR_RECRUITER_TO_REVIEW = "WAITING_FOR_RECRUITER_TO_REVIEW",
}

export enum USER_PRIVILEGE_LEVEL {
  ADMIN = "ADMIN",
  INVITED = "INVITED",
  SUPER_ADMIN = "SUPER_ADMIN",
  TERMINATED = "TERMINATED",
  UNVERIFIED = "UNVERIFIED",
  USER = "USER",
  VIEWER = "VIEWER",
}

export enum WIDGET_TYPE {
  AD_GRID = "AD_GRID",
  AD_LIST = "AD_LIST",
}

export interface AdContactPersonUpdateInput {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  jobTitle?: string | null;
  copyFromRecruitment?: boolean | null;
  profileImage?: FileUploadInput | null;
  deleteProfileImage?: boolean | null;
}

export interface AdDistributionUpdateInput {
  adDistributionID?: string | null;
  title?: string | null;
  text?: string | null;
  type?: AD_DISTRIBUTION_TYPE | null;
  isPublished?: boolean | null;
  isNotSynced?: boolean | null;
  publishedDate?: DateTime | null;
  expirationDate?: DateTime | null;
}

export interface AddedConsultantProjectUserInput {
  userId: string;
  privilegeLevel: CONSULTANT_PROJECT_PRIVILEGE_LEVEL;
}

export interface AddedUserInput {
  userId: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
}

export interface AdminCompaniesFilterInput {
  searchText?: string | null;
  typeFilter?: COMPANY_TYPE[] | null;
  paymentTypeFilter?: COMPANY_PAYMENT_TYPE[] | null;
}

export interface ApplicationFilterInput {
  searchText?: string | null;
  searchTextOptions?: string[] | null;
  getChildCompaniesApplications?: boolean | null;
  getNotOwnedApplications?: boolean | null;
  companyFilter?: string[] | null;
  recruitmentFilter?: string[] | null;
  gradeFilter?: number[] | null;
  statusFilter?: string[] | null;
  statusNameFilter?: string[] | null;
  competenceFilter?: string[] | null;
  tagFilter?: string[] | null;
  studentOptionsFilter?: string[] | null;
}

export interface BaseTagFilterInput {
  searchText?: string | null;
  searchTextOptions?: string[] | null;
  companyFilter?: string[] | null;
  applicationFilter?: string[] | null;
  recruitmentFilter?: string[] | null;
  companyApplicantFilter?: string[] | null;
  recruitmentTemplateFilter?: string[] | null;
  groupCompanyApplicantFilter?: string[] | null;
  mediaElementFilter?: string[] | null;
}

export interface BasicPersonalInformationInput {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
}

export interface BillingInformationUpdateInput {
  description?: string | null;
  annualCost?: number | null;
  monthlyCost?: number | null;
  monthlyCostPerUser?: number | null;
  costPerRecruitment?: number | null;
}

export interface CalendarEventTimeInput {
  startTime: string;
  endTime: string;
}

export interface CompanyApplicantFilterInput {
  searchText?: string | null;
  searchTextOptions?: string[] | null;
  companyFilter?: string[] | null;
  recruitmentFilter?: string[] | null;
  educationFilter?: string[] | null;
  gradeFilter?: number[] | null;
  tagFilter?: string[] | null;
  professionFieldFilter?: string[] | null;
  excludedRecruitmentId?: string | null;
  showApplicantsFromChildCompanies?: boolean | null;
}

export interface CompanyUpdateInput {
  subdomain?: string | null;
  name?: string | null;
  slug?: string | null;
  type?: COMPANY_TYPE | null;
  shortPresentation?: string | null;
  orgNumber?: string | null;
  primaryColor?: string | null;
  apiKey?: string | null;
  postalCode?: string | null;
  language?: string | null;
  postalAddress?: string | null;
  municipality?: string | null;
  websiteUrl?: string | null;
  companyWebFont?: string | null;
  titleFont?: string | null;
  textFont?: string | null;
  scriveEmail?: string | null;
  scrivePassword?: string | null;
  arbetsformedlingenSenderId?: string | null;
  isPayingCustomer?: boolean | null;
  averageMonthlyCost?: number | null;
  trustcruitCustomerSprancherId?: string | null;
  preciserCustomerId?: string | null;
  alvaLabsTestProfileId?: string | null;
  alvaLabsRequireLogicTest?: boolean | null;
  alvaLabsRequirePersonalityTest?: boolean | null;
  alvaLabsKey?: string | null;
}

export interface CompetenceInput {
  name: string;
  type?: COMPETENCE_TYPE | null;
  grade?: number | null;
}

export interface CustomContractPartyInput {
  firstName: string;
  lastName: string;
  email: string;
}

export interface EditedFormQuestionAnswerInput {
  questionId: string;
  answerId?: string | null;
  answerText?: string | null;
  answerNumber?: number | null;
  answerNumberMin?: number | null;
  answerNumberMax?: number | null;
  answerFile?: Upload | null;
  answerDocument?: FileUploadInput | null;
  answerArray?: string[] | null;
  answerPersonalData?: PersonalDataInput | null;
  answerEducations?: EducationInput[] | null;
  answerWorkExperiences?: WorkExperienceInput[] | null;
  answerCompetences?: CompetenceInput[] | null;
}

export interface EducationInput {
  school?: string | null;
  degree?: string | null;
  education?: string | null;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
}

export interface EventTriggerInput {
  id?: string | null;
  type: EVENT_TRIGGER_ACTION_TYPE;
  screeningFormId?: string | null;
  referenceFormId?: string | null;
}

export interface FileUploadInput {
  filename: string;
  originalFilename?: string | null;
  mimetype: string;
  encoding?: string | null;
  size: number;
  url: string;
  bucketUrl?: string | null;
  path?: string | null;
  hasChanged?: boolean | null;
}

export interface FormQuestionAnswerImportInput {
  questionId: string;
  answerId: string;
}

export interface FormQuestionFilter {
  questionId?: string | null;
  options?: string[] | null;
  grade?: number | null;
  grades?: FormQuestionGradesFilter[] | null;
}

export interface FormQuestionGradesFilter {
  option?: string | null;
  grade?: number | null;
}

export interface GroupCompanyApplicantFilterInput {
  searchText?: string | null;
  searchTextOptions?: string[] | null;
  companyFilter?: string[] | null;
  recruitmentFilter?: string[] | null;
  tagFilter?: string[] | null;
  professionFieldFilter?: string[] | null;
  studentOptionsFilter?: string[] | null;
}

export interface InterviewQuestionInput {
  question: string;
  subQuestions: string[];
}

export interface MediaElementFilterInput {
  searchText?: string | null;
  searchTextOptions?: string[] | null;
  companyFilter?: string[] | null;
  tagFilter?: string[] | null;
  mediaTypeFilter?: string[] | null;
}

export interface MediaUpdateInput {
  width?: number | null;
  height?: number | null;
  maxWidth?: number | null;
  blur?: number | null;
  grayscale?: boolean | null;
  brightness?: number | null;
  contrast?: number | null;
  cropX?: number | null;
  cropY?: number | null;
  cropWidth?: number | null;
  cropHeight?: number | null;
  format?: string | null;
  mediaElementId?: string | null;
}

export interface PageMetaInfoInput {
  pageTitle?: string | null;
  ogTitle?: string | null;
  ogDescription?: string | null;
  ogSiteName?: string | null;
  facebookUrl?: string | null;
  twitterUrl?: string | null;
  instagramUrl?: string | null;
  linkedinUrl?: string | null;
  youtubeUrl?: string | null;
}

export interface PageThemeVariablesInput {
  primaryColor?: string | null;
  secondaryColor?: string | null;
  backgroundColor?: string | null;
  titleColor?: string | null;
  textColor?: string | null;
}

export interface PersonalDataInput {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface RecruitmentFilterInput {
  searchText?: string | null;
  searchTextOptions?: string[] | null;
  getChildCompaniesApplications?: boolean | null;
  getNotOwnedApplications?: boolean | null;
  companyFilter?: string[] | null;
  publishingStatusFilter?: string[] | null;
  statusFilter?: string[] | null;
  tagFilter?: string[] | null;
  professionFieldFilter?: string[] | null;
  contactPersonFilter?: string[] | null;
  recruitingManagerFilter?: string[] | null;
  responsibleRecruiterFilter?: string[] | null;
  recruitmentUserFilter?: string[] | null;
  consultantCustomerFilter?: string[] | null;
}

export interface RecruitmentUpdate {
  language?: string | null;
  type?: RECRUITMENT_TYPE | null;
  numberOfEmployments?: number | null;
  salaryType?: SALARY_TYPE | null;
  salaryDescription?: string | null;
  enabled?: boolean | null;
  title?: string | null;
  role?: string | null;
  professionType?: PROFESSION_TYPE | null;
  professionLocaleFieldID?: string | null;
  professionLocaleCode?: string | null;
  professionOccupationNameID?: string | null;
  municipalityID?: string | null;
  dateSpanEnabled?: boolean | null;
  activeFrom?: DateTime | null;
  activeTo?: DateTime | null;
  location?: string | null;
  extent?: number | null;
  employmentType?: EMPLOYMENT_TYPE | null;
  employmentTemporaryType?: EMPLOYMENT_TEMPORARY_TYPE | null;
  publishingStatus?: RECRUITMENT_STATUS | null;
  internalJobDescription?: string | null;
  useCustomerProfile?: boolean | null;
  messageToLateAdVisitors?: string | null;
  useCompanyAddress?: boolean | null;
  postalCode?: string | null;
  postalAddress?: string | null;
  municipality?: string | null;
  countryCode?: string | null;
  useCustomCompleteApplicationPage?: boolean | null;
  customCompleteApplicationPageTitle?: string | null;
  customCompleteApplicationPageDescription?: string | null;
}

export interface RecruitmentUpdateInput {
  language?: string | null;
  type?: RECRUITMENT_TYPE | null;
  numberOfEmployments?: number | null;
  salaryType?: SALARY_TYPE | null;
  salaryDescription?: string | null;
  enabled?: boolean | null;
  title?: string | null;
  role?: string | null;
  professionType?: PROFESSION_TYPE | null;
  professionLocaleFieldID?: string | null;
  professionLocaleCode?: string | null;
  professionOccupationNameID?: string | null;
  municipalityID?: string | null;
  dateSpanEnabled?: boolean | null;
  activeFrom?: DateTime | null;
  activeTo?: DateTime | null;
  location?: string | null;
  extent?: number | null;
  employmentType?: EMPLOYMENT_TYPE | null;
  employmentTemporaryType?: EMPLOYMENT_TEMPORARY_TYPE | null;
  status?: RECRUITMENT_STATUS | null;
  internalJobDescription?: string | null;
  useCustomerProfile?: boolean | null;
  messageToLateAdVisitors?: string | null;
  useCompanyAddress?: boolean | null;
  postalCode?: string | null;
  postalAddress?: string | null;
  municipality?: string | null;
  alvaLabsTestProfileId?: string | null;
  alvaLabsJobId?: string | null;
  countryCode?: string | null;
}

export interface RecruitmentVisitorUpdateInput {
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  basePermissions: RECRUITMENT_VISITOR_PERMISSION[];
  applicationPermissions: APPLICATION_VISITOR_PERMISSION[];
  applicationAccessType: APPLICATION_VISITOR_ACCESS_TYPE;
  applicationIds: string[];
}

export interface ReferenceUpdateInput {
  firstName: string;
  lastName: string;
  email?: string | null;
  phoneNumber?: string | null;
}

export interface SelectOrCreateMessageTemplateInput {
  useMessageTemplateId?: boolean | null;
  messageTemplateId?: string | null;
  subject?: string | null;
  body?: string | null;
}

export interface SharedImageUpdateInput {
  title?: string | null;
  subtitle?: string | null;
  titleColor?: string | null;
  titleFont?: string | null;
  titleFontSize?: number | null;
  subtitleColor?: string | null;
  subtitleFont?: string | null;
  subtitleFontSize?: number | null;
  backgroundColor?: string | null;
  backgroundType?: SHARED_IMAGE_BACKGROUND_TYPE | null;
  mediaElementId?: string | null;
}

export interface UserRecruitmentInput {
  userId: string;
  privilegeLevel: RECRUITMENT_PRIVILEGE_LEVEL;
}

export interface WidgetUpdateInput {
  name?: string | null;
  description?: string | null;
  type?: WIDGET_TYPE | null;
  customCss?: boolean | null;
  css?: string | null;
  allowSorting?: boolean | null;
  showCompanyLogoOnAds?: boolean | null;
  showCompanyNameOnAds?: boolean | null;
  showExpiredAds?: boolean | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
}

export interface WorkExperienceInput {
  companyName?: string | null;
  position?: string | null;
  location?: string | null;
  isOngoing?: boolean | null;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  description?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
