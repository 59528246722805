import CompanyParts from '@/api/fragments/CompanyParts'
import { gql } from '@apollo/client'

export default gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      ...CompanyParts
    }
  }
  ${CompanyParts}
`
